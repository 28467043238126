import { Fragment } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import PropTypes from "prop-types";

const CustomTablePaginate = (props) => {
	const { limit, total, next, prev, currentPage, onClick } = props;

	return (
		<Fragment>
			{total > limit ? (
				<div className="me-0">
					<span className="pe-2 fw-bolder">
						{currentPage * limit - (limit - 1)} - {currentPage * limit} of {total}
					</span>
					{/* prev button */}
					{prev ? (
						<ChevronLeft className="cursor-pointer" onClick={() => onClick("prev")} />
					) : (
						<ChevronLeft className="text-muted" />
					)}
					{/* next button */}
					{next ? (
						<ChevronRight className="cursor-pointer" onClick={() => onClick("next")} />
					) : (
						<ChevronRight className="text-muted" />
					)}
				</div>
			) : null}
		</Fragment>
	);
};

CustomTablePaginate.propTypes = {
	total: PropTypes.number.isRequired,
	limit: PropTypes.number.isRequired,
	next: PropTypes.bool.isRequired,
	prev: PropTypes.bool.isRequired,
	currentPage: PropTypes.number.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default CustomTablePaginate;
