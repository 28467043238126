import { useEffect, useState } from "react";
import { Card } from "reactstrap";
import Rank1 from "../../../../assets/images/peringkat/1.svg";
import Rank2 from "../../../../assets/images/peringkat/2.svg";
import Rank3 from "../../../../assets/images/peringkat/3.svg";
import LineChart from "../../../../assets/images/LineChart.png";
import RangkingPerformance from "./rangking-line-chart";
import set_profile, { img_error } from "../../../../helpers/default_profile";

const CardSimpleCol = ({
  rank,
  title,
  data: {
    photo_url,
    name,
    workunit,
    badge_image1,
    badge_name1,
    badge_value1,
    badge_image2,
    badge_name2,
    badge_value2,
    badge_image3,
    badge_name3,
    badge_value3,
    EndDate,
    performances,
  },
}) => {
  const [rankImage, setRankImage] = useState(null);
  useEffect(() => {
    if (rank === 1) {
      setRankImage(Rank1);
    } else if (rank === 2) {
      setRankImage(Rank2);
    } else if (rank === 3) {
      setRankImage(Rank3);
    }
  }, [rank]);

  const formatDateDifference = (endDateStr) => {
    const endDate = new Date(endDateStr);
    const today = new Date();
    const timeDifferenceInMs = today - endDate;
    const timeDifferenceInDays = Math.floor(
      timeDifferenceInMs / (1000 * 60 * 60 * 24)
    );
    return timeDifferenceInDays;
  };

  return (
    <>
      <Card className="border p-2" style={{ minWidth: "30px", minHeight:"250px", height:"100%", justifyContent:"space-between" }}>
        <img
          src={set_profile(photo_url || "")}
          onError={(e) => img_error(e)}
          alt={name}
          width="70px"
          height="70px"
          className="rounded-circle mx-auto"
        />
        {title !== "modal" ? (
          <div className="text-center mt-2 mb-3">
            <p className="fw-semibold mb-0 fs-13">{name}</p>
            <p className="fs-11 text-muted mb-0">{workunit}</p>
          </div>
        ) : null}
        <div className="justify-content-center align-items-center mx-auto mb-2">
          {/* Tampilkan gambar sesuai dengan rank */}
          {rankImage && (
            <img
              src={rankImage}
              alt={`rank${rank}`}
              width="50px"
              height="60px"
            />
          )}
        </div>
        {title === "modal" ? (
          <>
            <div className="text-center">
              <p className="fw-semibold mb-0 fs-12">{name}</p>
              <p className="fs-10 text-muted">{workunit}</p>
            </div>
            <p className="mb-2 fs-10 text-center">
              Aktifitas Berita (7 hr Terakhir)
            </p>
            {/* <img src={LineChart} width="100%" /> */}
            <span width="90%">
              <RangkingPerformance performances={performances} width={"100%"} />
            </span>

            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                {badge_image1 ? (
                  <img src={badge_image1} alt="" height="30px" />
                ) : (
                  <div style={{ height: "30px" }}></div>
                )}
                <p className="mb-0 mt-1 fs-11 fw-semibold">{badge_value1}</p>
                <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
                  {badge_name1}
                </p>
              </div>
              <div className="text-center">
                {badge_image2 ? (
                  <img src={badge_image2} alt="" height="30px" />
                ) : (
                  <div style={{ height: "30px" }}></div>
                )}
                <p className="mb-0 mt-1 fs-11 fw-semibold">{badge_value2}</p>
                <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
                  {badge_name2}
                </p>
              </div>
              <div className="text-center">
                {badge_image3 ? (
                  <img src={badge_image3} alt="" height="30px" />
                ) : (
                  <div style={{ height: "30px" }}></div>
                )}
                <p className="mb-0 mt-1 fs-11 fw-semibold">{badge_value3}</p>
                <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
                  {badge_name3}
                </p>
              </div>
            </div>
          </>
        ) : null}
      </Card>
    </>
  );
};

export default CardSimpleCol;
