import { useContext } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { SocketContext } from "../../context/SocketContext";
import { useDispatch, useSelector } from "react-redux";
import { clearChatActive, openChatBox, setShowGroupDetail } from "../../slices/chat/reducer";
import { getRoomArchive, getRoomChat, markAsUnread } from "../../slices/thunks";

const MoreButton = ({ items }) => {

    const { archiveMsg,unArchiveMsg, endMsg } = useContext(SocketContext)
    const chat = useSelector(state => state.Chat)
    const dispatch = useDispatch()

    const groupDetail = () => {
        dispatch(setShowGroupDetail());
    };

    const handleArchiveMsg = id => {
        archiveMsg(id)

    };

    const handleEndMsg = (id) => {
        endMsg(id)
        dispatch(getRoomChat());
        setTimeout(() => {
            dispatch(clearChatActive())
            dispatch(openChatBox(false))
        }, 500)
    }

    const handleMarkAsUnread = (id) => {
        dispatch(markAsUnread({id}))
        dispatch(getRoomChat())
        setTimeout(() => {
            dispatch(clearChatActive())
            dispatch(openChatBox(false))
        }, 500)
    }

    

    if (chat.roomArchiveList.find(room => room.id === items.id)) {
        return (
            <UncontrolledDropdown className="align-self-center message-box-drop">
                <DropdownToggle tag="a" role="button">
                    <i className=" ri-more-2-fill fs-20"></i>
                </DropdownToggle>
                <DropdownMenu>
                <DropdownItem
                    onClick={() => unArchiveMsg(items.id)}
                    className="d-flex align-items-center gap-2"
                >
                    <i className=" ri-close-fill fs-20"></i> Batalkan
                    Arsipkan
                </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }

    return (
        <div style={{ zIndex: 10 }}>
            <UncontrolledDropdown className="align-self-center message-box-drop">
                <DropdownToggle tag="a" role="button">
                    <i className=" ri-more-2-fill fs-20"></i>
                </DropdownToggle>
                <DropdownMenu>
                    {items.type === "group" && (
                        <DropdownItem onClick={() => groupDetail()} className="d-flex align-items-center gap-2"> <i className=" ri-eye-line fs-20"></i>Lihat Detail Group</DropdownItem>
                    )}
                    <DropdownItem onClick={() => handleEndMsg(items.id)} className="d-flex align-items-center gap-2"> <i className=" ri-close-fill fs-20"></i> Akhiri Percakapan</DropdownItem>
                    <DropdownItem onClick={() => handleArchiveMsg(items.id)} className="d-flex align-items-center gap-2"><i className=" ri-archive-line fs-20"></i>Arsipkan Percakapan</DropdownItem>
                    <DropdownItem onClick={() => handleMarkAsUnread(items.id)} className="d-flex align-items-center gap-2"><i className=" ri-user-unfollow-line fs-20"></i>Tandai Belum Dibaca</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

export default MoreButton