import { Fragment, useEffect, useState } from "react";
import { getEmployeBiodata } from "../../../slices/thunks";
import SearchInput from "../search-input";
import SubmitButton from "../submit-button";
import set_profile, { img_error } from "../../../helpers/default_profile";

const { useSelector, useDispatch } = require("react-redux");
const {
  ListGroupItem,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} = require("reactstrap");
const { default: SimpleBar } = require("simplebar-react");

const SelectSingleUser = (props) => {
  // props
  const { show, setShow, title, size, onSubmit } = props;

  // redux state
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.EmployeBiodata);
  //   states
  const [selectedUser, setSelectedUser] = useState();

  const handleClick = (id) => {
    setSelectedUser(id);
  };

  const search = (text) => {
    dispatch(getEmployeBiodata({
        "keyword" : text
    }));
  };

  return (
    <Fragment>
      <Modal
        isOpen={show}
        toggle={setShow}
        size={size ?? "sm"}
        centered
      >
        <ModalHeader className="modal-title" toggle={setShow}>
          {title}
        </ModalHeader>
        <ModalBody>
          <SearchInput submit={search} />
          <SimpleBar style={{ maxHeight: "40vh" }}>
            {employees.data &&
              employees.data.map((item, index) => {
                return (
                  <ListGroupItem
                    key={index}
                    className={`cursor-pointer p-3 border ${
                      selectedUser === item.uuid && "text-white bg-primary "
                    }`}
                    onClick={() => handleClick(item.uuid)}
                  >
                    <div className="d-flex justify-content-left align-items-center">
                      {item.photo === "" ? (
                        <div
                          className="bg-primary d-flex justify-content-center align-items-center me-2"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                        >
                          <i className="bx bx-user fs-22"></i>
                        </div>
                      ) : (
                        <img
                          src={set_profile(item.photo)}
                          onError={(e)=> img_error(e)}
                          width="40px"
                          height="40px"
                          className="me-2"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                        />
                      )}
                      <div className="d-flex flex-column">
                        <span className="fw-bolder">{item.name}</span>
                        <small className="text-truncate fw-bolder text-muted mb-0">
                          {item.workunit}
                        </small>
                      </div>
                    </div>
                  </ListGroupItem>
                );
              })}
          </SimpleBar>
          <Button
            color="primary"
            className="my-2 w-100"
            onClick={() => {
              onSubmit(selectedUser);
            }}
          >
            Tambahkan
          </Button>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default SelectSingleUser;
