import { Fragment } from "react"

const SendBroadcast = ({ send }) => {
    return (
        <Fragment>
            <button className="d-flex" onClick={() => send('siacc')} style={{
                padding: "20px",
                alignItems: "center",
                gap: 4,
                columnGap: "15px",
                border: "1px solid gray",
                borderRadius: "10px",
                width: "100%",
                background: "none"
            }}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.6209 15.5764C28.6209 15.5764 29.9786 20.6395 24.8426 24.294C19.7067 27.9485 16.0886 30.0197 16.0703 30.8532C16.0558 30.0197 12.434 27.9485 7.29807 24.294C2.16213 20.6395 3.51982 15.5764 3.51982 15.5764L3.84744 15.5217C4.09132 16.1442 4.48807 16.7812 5.08138 17.4072C5.08138 17.4072 7.82957 20.2464 11.0545 18.8705C11.0545 18.8705 12.8599 20.4247 14.3013 22.6888C15.2586 24.1885 16.0521 26.0012 16.0521 27.8757H16.0922C16.0922 26.0012 16.8857 24.1885 17.8394 22.6888C19.2808 20.4247 21.0862 18.8705 21.0862 18.8705C24.3148 20.2464 27.0593 17.4072 27.0593 17.4072C27.6526 16.7812 28.0494 16.1442 28.2969 15.5217L28.6209 15.5764Z" fill="#193A21" />
                    <path d="M27.0591 9.57764C27.0591 9.57764 29.5306 12.4023 28.2966 15.5217C28.0491 16.1441 27.6524 16.7811 27.0591 17.4072C27.0591 17.4072 24.3146 20.2464 21.0859 18.8705V16.3371C21.0859 16.3371 26.2947 16.439 24.5584 10.7825V10.7789C24.5694 10.7716 24.5839 10.7679 24.5948 10.7607L27.0409 9.58501C27.0481 9.58126 27.0518 9.58126 27.0591 9.57764Z" fill="#186137" />
                    <path d="M26.7973 8.98797L27.0594 9.57766C27.0522 9.58129 27.0485 9.58129 27.0412 9.58491C26.164 9.94891 25.3487 10.342 24.5952 10.7606C24.5842 10.7678 24.5697 10.7715 24.5588 10.7788V10.7824C18.2762 14.2585 16.0923 19.2235 16.0923 19.2235H16.0522C16.0522 19.2235 13.8646 14.2586 7.58203 10.7824V10.7788C7.57109 10.7715 7.55653 10.7678 7.54566 10.7606C6.79216 10.342 5.97684 9.94891 5.09959 9.58491C5.09234 9.58129 5.08866 9.58129 5.08141 9.57766C4.89578 9.50122 4.70647 9.42479 4.51722 9.35197L4.41528 9.26097C4.41528 9.18454 4.40434 9.10447 4.37891 9.02804L5.34347 8.98797C5.34347 8.98797 9.58403 2.92016 16.6928 3.51716C16.6928 3.51716 22.0108 3.26235 26.7973 8.98797Z" fill="#186137" />
                    <path d="M24.5583 10.7825C26.2945 16.439 21.0858 16.337 21.0858 16.337V18.8704C21.0858 18.8704 19.2804 20.4247 17.839 22.6887C17.839 22.6887 16.0918 21.4657 16.0918 19.2235C16.0918 19.2235 18.2757 14.2586 24.5583 10.7825Z" fill="#13361E" />
                    <path d="M16.0926 19.2236C16.0926 21.4658 17.8398 22.6889 17.8398 22.6889C16.8861 24.1886 16.0926 26.0013 16.0926 27.8758H16.0526C16.0526 26.0013 15.2591 24.1886 14.3018 22.6889C14.3018 22.6889 16.0526 21.4659 16.0526 19.2236H16.0926Z" fill="#186137" />
                    <path d="M16.052 19.2235C16.052 21.4657 14.3012 22.6887 14.3012 22.6887C12.8598 20.4247 11.0544 18.8704 11.0544 18.8704V16.337C11.0544 16.337 5.84563 16.439 7.58188 10.7825C13.8644 14.2585 16.052 19.2235 16.052 19.2235Z" fill="#13361E" />
                    <path d="M11.0545 16.3371V18.8704C7.82952 20.2463 5.08139 17.4072 5.08139 17.4072C4.48808 16.7811 4.09133 16.1441 3.84746 15.5217C2.61352 12.4023 5.08139 9.57764 5.08139 9.57764C5.08864 9.58126 5.09233 9.58126 5.09958 9.58489L7.54564 10.7606C7.55658 10.7678 7.57114 10.7715 7.58202 10.7788V10.7824C5.84577 16.439 11.0545 16.3371 11.0545 16.3371Z" fill="#186137" />
                    <path d="M5.34375 8.98797C5.34375 8.98797 9.58431 2.92016 16.6931 3.51716C16.6931 3.51716 22.0111 3.26235 26.7976 8.98797" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M27.06 9.57764C27.06 9.57764 29.5315 12.4023 28.2976 15.5217C28.0501 16.1441 27.6534 16.7811 27.06 17.4072C27.06 17.4072 24.3155 20.2464 21.0869 18.8705V16.3371C21.0869 16.3371 26.2957 16.439 24.5594 10.7825" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.84 22.6888C17.84 22.6888 16.0928 21.4658 16.0928 19.2236C16.0928 19.2236 18.2767 14.2587 24.5593 10.7825V10.7789C24.5702 10.7716 24.5848 10.7679 24.5956 10.7607C25.3491 10.3421 26.1645 9.94899 27.0417 9.58499C27.049 9.58136 27.0526 9.58136 27.0599 9.57774C27.2455 9.5013 27.4348 9.42486 27.6241 9.35205" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M21.0868 18.8704C21.0868 18.8704 19.2813 20.4246 17.84 22.6887C16.8863 24.1884 16.0928 26.001 16.0928 27.8756" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.08139 9.57764C5.08139 9.57764 2.61352 12.4023 3.84746 15.5217C4.09133 16.1441 4.48808 16.7811 5.08139 17.4072C5.08139 17.4072 7.82958 20.2464 11.0545 18.8705V16.3371C11.0545 16.3371 5.84577 16.439 7.58202 10.7825" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14.3018 22.6888C14.3018 22.6888 16.0526 21.4658 16.0526 19.2236C16.0526 19.2236 13.865 14.2587 7.58239 10.7825V10.7789C7.57145 10.7716 7.55689 10.7679 7.54602 10.7607C6.79252 10.3421 5.9772 9.94899 5.09995 9.58499C5.0927 9.58136 5.08902 9.58136 5.08177 9.57774C4.89614 9.5013 4.70683 9.42486 4.51758 9.35205" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M11.0547 18.8704C11.0547 18.8704 12.8601 20.4246 14.3015 22.6887C15.2588 24.1884 16.0523 26.001 16.0523 27.8756" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.51982 15.5762C3.51982 15.5762 2.16213 20.6394 7.29807 24.2939C12.434 27.9484 16.0558 30.0195 16.0703 30.853" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M28.6209 15.5764C28.6209 15.5764 29.9786 20.6396 24.8426 24.2941C19.7067 27.9486 16.0886 30.0197 16.0703 30.8533" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.412055 3.79006C0.412076 3.7899 0.412096 3.78976 0.808312 3.84465C1.20453 3.89954 1.20455 3.89941 1.20456 3.89929L1.20426 3.90164L1.20251 3.9162C1.20094 3.92991 1.19863 3.95145 1.19611 3.97994C1.19105 4.03699 1.18515 4.12147 1.18241 4.22643C1.17689 4.43738 1.18429 4.72558 1.23459 5.03712C1.33701 5.67149 1.6033 6.32845 2.21457 6.69347C2.91344 7.11244 3.48243 7.48438 3.9022 7.828C4.31147 8.16301 4.63272 8.51307 4.75921 8.9033C4.79969 9.02545 4.8155 9.14827 4.8155 9.2609H4.0155C4.0155 9.22124 4.00972 9.18464 3.99968 9.15454L3.9984 9.1507C3.94608 8.98839 3.77814 8.76029 3.39547 8.44705C3.02263 8.14185 2.4954 7.79457 1.80383 7.37997C0.897229 6.83861 0.563517 5.89981 0.444817 5.16464C0.384556 4.79142 0.376252 4.4515 0.382683 4.20552C0.385912 4.08202 0.392894 3.98084 0.39923 3.90934C0.4024 3.87355 0.405418 3.8451 0.40774 3.82489L0.410638 3.80083L0.411572 3.79363L0.411888 3.79127L0.412055 3.79006ZM30.8383 3.89868C30.8383 3.8988 30.8383 3.89892 31.2346 3.84465C31.6309 3.79037 31.6309 3.79052 31.631 3.79067L31.6314 3.79425L31.6324 3.80145L31.6352 3.8255C31.6375 3.84571 31.6405 3.87416 31.6436 3.90995C31.6498 3.98145 31.6567 4.08262 31.6597 4.20612C31.6658 4.45208 31.657 4.79197 31.5962 5.16515C31.4766 5.90023 31.1413 6.8391 30.2349 7.38033C29.4421 7.85566 28.869 8.24057 28.4967 8.57288C28.1115 8.9167 28.0238 9.13223 28.0238 9.2609H27.2238C27.2238 8.77076 27.5438 8.35112 27.9639 7.97607C28.3969 7.58959 29.0265 7.17202 29.8241 6.69383C30.4356 6.32868 30.7033 5.67107 30.8066 5.03661C30.8573 4.72502 30.8651 4.4368 30.8599 4.22584C30.8574 4.12087 30.8516 4.03638 30.8466 3.97933C30.8441 3.95084 30.8419 3.9293 30.8403 3.91558L30.8386 3.90102L30.8383 3.89868Z" fill="#186138" />
                </svg>
                Kirim Ke Aplikasi SIACC Agen
            </button>
            <button className="d-flex" onClick={() => send('whatsapp')} style={{
                padding: "20px",
                alignItems: "center",
                gap: 4,
                columnGap: "15px",
                border: "1px solid gray",
                borderRadius: "10px",
                width: "100%",
                marginTop: "20px",
                background: "none"
            }}>
                <svg width="35" height="35" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "-6px" }}>
                    <rect width="56" height="56" rx="28" fill="#D0D8D2" />
                    <path d="M27.7938 14.8988C30.99 14.8461 34.0954 15.9616 36.5275 18.036C38.9596 20.1104 40.551 23.0011 41.0031 26.1655C41.4553 29.3299 40.737 32.5506 38.9832 35.2231C37.2294 37.8956 34.5606 39.8361 31.4776 40.6806C28.536 41.4864 25.4102 41.2415 22.6359 39.9963L16.4178 41.1045C15.9417 41.1893 15.4576 41.0055 15.1577 40.6261C14.8579 40.2467 14.791 39.7331 14.9836 39.2896L16.8657 34.9551C15.3172 32.4661 14.6426 29.5229 14.9629 26.5946C15.3106 23.417 16.8059 20.4755 19.1683 18.3221C21.5306 16.1687 24.5977 14.9514 27.7938 14.8988Z" fill="#44624D" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M23.3266 22.6704C23.6958 22.3012 24.1966 22.0938 24.7188 22.0938C25.2409 22.0938 25.7417 22.3012 26.1109 22.6704C26.4801 23.0396 26.6875 23.5404 26.6875 24.0625V25.375C26.6875 25.8971 26.4801 26.3979 26.1109 26.7671C25.9781 26.8999 25.8283 27.0117 25.6667 27.1005C25.9245 27.8413 26.3475 28.5221 26.9127 29.0873C27.4779 29.6525 28.1587 30.0755 28.8995 30.3333C28.9883 30.1717 29.1001 30.0219 29.2329 29.8891C29.6021 29.5199 30.1029 29.3125 30.625 29.3125H31.9375C32.4596 29.3125 32.9604 29.5199 33.3296 29.8891C33.6988 30.2583 33.9062 30.7591 33.9062 31.2812C33.9062 31.8034 33.6988 32.3042 33.3296 32.6734C32.9604 33.0426 32.4596 33.25 31.9375 33.25H30.625C28.5364 33.25 26.5334 32.4203 25.0565 30.9435C23.5797 29.4666 22.75 27.4636 22.75 25.375V24.0625C22.75 23.5404 22.9574 23.0396 23.3266 22.6704Z" fill="#D0D8D2" />
                </svg>
                Kirim Ke Whatsapp Agen
            </button>
        </Fragment>
    )
}

export default SendBroadcast