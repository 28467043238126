import React              from "react";

//login
import Login              from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout             from "../pages/Authentication/Logout";

// User Profile
import UserProfile        from "../pages/Authentication/user-profile";
import Dashboard          from "../pages/Dashboard";
import UbahPassword       from "../pages/Authentication/UbahPassword";
import LogUpdate          from "../pages/Authentication/LogUpdate";
import Notifikasi         from "../pages/Authentication/Notifikasi";
import AddWidget          from "../pages/AddWidget";
import Splash             from "../pages/Splash/Splash";
import Notification from "../pages/Notification";
import Endpoint from "../pages/endpoint";
import SiaccNotification from "../pages/Notification/SiaccNotification";
import DashboardExeNotification from "../pages/Notification/DashboardExeNotification";
import SumberData from "../pages/Sumberdata";
import ExportTable from "../Components/Common/export-table";
import Share from "../pages/Share";
import ManagementDefaultDashboard from "../pages/ManagementDefaultDashboard";
import LandingPage from "../pages/LandingPage";

const authProtectedRoutes = [
	{ path: "/", component: <Dashboard /> },
	{ path: "/:page", component: <Dashboard /> },
	{ path: "/index", component: <Dashboard /> },

	
	//
	{ path: "/sumber-data", component: <SumberData /> },
	{ path: "/endpoint", component: <Endpoint /> },
	{ path: "/management-default-dashboard", component: <ManagementDefaultDashboard /> },
	{ path: "/management-default-dashboard/add-widget", component: <AddWidget isDefault={true} /> },
	
	//User Profile
	{ path: "/profile", component: <UserProfile /> },
	{ path: "/ubahpassword", component: <UbahPassword /> },
	{ path: "/notifikasi", component: <Notifikasi /> },
	{ path: "/logupdate", component: <LogUpdate /> },
	
	// notification
	{ path: "/notifications", component: <Notification /> },
	{ path: "/notifications/siacc", component: <SiaccNotification /> },
	{ path: "/notifications/dashboard", component: <DashboardExeNotification /> },
	
	{ path: "/add-widget", component: <AddWidget /> },
	
	{ path: "/splash", component: <Splash /> },
	{ path: "/landing-page", component: <LandingPage /> }
];

const publicRoutes = [
	// Authentication Page
	{ path: "/:id/print", component: <ExportTable /> },
	{ path: "/share/:id/print", component: <ExportTable /> },
	{ path: "/logout", component: <Logout /> },
	{ path: "/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgetPasswordPage /> },
	{ path: "/share/:id", component: <Share/>},
];

export { authProtectedRoutes, publicRoutes };
