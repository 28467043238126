import PropTypes from 'prop-types';
import { Fragment } from "react"
import { Button, UncontrolledTooltip } from "reactstrap"


const ActionButton = props => {

    // props
    const {
        id,
        title,
        color,
        onClick,
        children,
        style,
        className
    } = props

    // content
    return (
        <Fragment>
            <Button
                className={`btn-icon rounded-circle ${className}`}
                color={`flat-${color ?? 'secondary'}`}
                id={id}
                style={style}
                onClick={onClick}
            >
                {children}
            </Button>
         
        </Fragment>
    )
}

// validation props
ActionButton.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['danger', 'success', 'secondary', 'warning', 'info']),
    onClick: PropTypes.func,
    children: PropTypes.element.isRequired,
    style: PropTypes.object,
    className: PropTypes.string
};


export default ActionButton