import React, { useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CustomToast from "../CustomToast";
import {  useLocation, useNavigate } from "react-router-dom";

const Print = (props) => {

	const location = useLocation();
	const navigate = useNavigate();

	const { hash, pathname, search } = location;


	const handleClick  = () => {
		if(props?.id ){
			window.open(props.id + "/print")
		}else{
			window.open(pathname + "/print")

		}
	}

	return (
		<>
			<span
				className="mdi mdi-printer-outline fs-4 px-2 cursor-pointer"
				onClick={handleClick} 
			></span>
		</>
	);
};

export default Print;
