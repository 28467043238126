import { useState } from "react";
import { Card } from "reactstrap";
import { img_error } from "../../../../helpers/default_profile";
import NewsDetail from "./news-detail";
import "./index.scss"

const NewsItem = ({ data, isSimple }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getLevel = (level) => {
    switch (level) {
      case "national":
        return "Nasional";
      case "local":
        return "Lokal";
      default:
        return level;
    }
  };

  return (
    <>
      <NewsDetail
        show={isModalOpen}
        setShow={() => setIsModalOpen(!isModalOpen)}
        data={data}
      />
      <Card
        className="p-2 border d-flex flex-column justify-content-between cursor-pointer"
        style={{ width: "100%", height: "min-content" }}
        onClick={() => setIsModalOpen(!isModalOpen)}
      >
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex ">
            <img
              src={data.photo_author_url}
              onError={(e) => img_error(e)}
              alt=""
              height="30px"
              width="30px"
              className="rounded rounded-circle "
            />
            <div className="align-self-center ms-2">
              <p className="fs-12 mb-0 fw-semibold">{data.author}</p>
              <p className="fs-10 mb-0 text-muted">{data.workunit}</p>
            </div>
          </div>
          <span className="fw-bold mt-3 title-wrap" style={{ height: "3em" }}>
            {data.title}
          </span>
          <img
            src={data.image_url}
            className="rounded my-2"
            height={isSimple ? "200px" : "120px"}
            style={{ objectFit: "cover" }}
          />
          <p className="fs-10">{data.description.substring(0, 250)}...</p>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <span className="mdi mdi-comment-multiple-outline fs-10">
              <span className="ms-1">{data.total_comment}</span>
            </span>
            <span className="mdi mdi-eye-outline fs-10 ms-2">
              <span className="ms-1">{data.total_views}</span>
            </span>
          </div>
          <span className="mdi mdi-earth fs-10">
            <span className="ms-1">{getLevel(data.level)}</span>
          </span>
        </div>
      </Card>
    </>
  );
};

export default NewsItem;
