import { Fragment } from "react"
import { Card, CardBody } from "reactstrap"
import imageEmpty from "../../../../assets/images/table/box.png"

const CustomTableEmptyData = () => {

    return (
        <Card>
            <CardBody>
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img src={imageEmpty} width="120px" className="mb-1" />
                    <h4 className="fw-bolder">Data Tidak Ditemukan</h4>
                </div>
            </CardBody>
        </Card>

    )
}

export default CustomTableEmptyData