import { getNotifDashboard, updateNotifDashboard } from "./thunk";

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	message: null,
	loading: true,
	data: null,
	success: false,
};

const notificationDashboardSlice = createSlice({
	name: "notification dashboard",
	initialState,
	reducers: {
		setNotifDashboard: (state, action) => {
			state.data = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getNotifDashboard.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					state.data = payload;
					state.success = true;
					state.loading = false;
					state.message = "Sukses Menampilkan Data";
				} else {
					state.data = null;
					state.success = false;
					state.loading = false;
					state.message = "Gagal Menampilkan Data";
				}
			})
			.addCase(updateNotifDashboard.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					state.data = payload;
					state.success = true;
					state.loading = false;
					state.message = "Sukses Memperbarui Data";
				} else {
					state.data = null;
					state.success = false;
					state.loading = false;
					state.message = "Gagal Memperbarui Data";
				}
			});
	},
});

export const { setNotifDashboard } = notificationDashboardSlice.actions;

export default notificationDashboardSlice.reducer;
