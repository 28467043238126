import { Trash2 } from "feather-icons-react/build/IconComponents";
import { useEffect, useState } from "react";
import { Plus } from "react-feather";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import SubmitButton from "../../Components/Common/submit-button";
import { APIClient } from "../../helpers/api_helper";
import { DATA_COLLECTOR_BASE } from "../../helpers/url_helper";
import CustomToast from "../../Components/Common/CustomToast";
import DynamicInput from "./dynamic-input";
import EndpointTabs from "./tabs";
import EndpointChild from "./endpoint-child";

const CreateEndpoint = (props) => {
  // props
  const { show, onClose, getData, dataType, dataSource, getDataSource } = props;

  // states
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState("");
  const [dataKeys, setDataKeys] = useState([]);
  const [uniqueId, setUniqueId] = useState("");
  const [dataBody, setDataBody] = useState("");
  const [namaWidget, setNamaWidget] = useState("");
  const [dataMethod, setDataMethod] = useState("");
  const [dataSourceId, setDataSourceId] = useState("");
  const [iterateField, setIterateField] = useState("");
  const [iterateType, setIterateType] = useState("");
  const [filterWorkunit, setFilterWorkunit] = useState(0);
  const [directCall, setDirectCall] = useState(0);
  const [filterWorkunitType, setFilterWorkunitType] = useState("work_unit_ids");
  const [scheduledMode, setScheduledMode] = useState(0);
  const [schedulingTime, setSchedulingTime] = useState(0);
  const [dataTypeGroupId, setDataTypeGroupId] = useState("");
  const [dataTypeName, setDataTypeName] = useState("");
  const [tab, setTab] = useState(1);
  const [child, setChild] = useState([]);
  const [getDataType, setGetDataType] = useState("");
  const [totalPageKey, setTotalPageKey] = useState("");
  const [paginationParamKey, setPaginationParamKey] = useState("");

  const [periods, setPeriods] = useState({
    is_daily: 0,
    is_weekly: 0,
    is_monthly: 0,
    is_yearly: 0,
    is_custom: 0,
  });

  const [periodsForm, setPeriodsForm] = useState({
    is_daily: null,
    is_weekly: null,
    is_monthly: null,
    is_yearly: null,
    is_custom: null,
  });

  const handleClear = () => {
    setDataSourceId("");
    setNamaWidget("");
    setDataMethod("");
    setDataBody("");
    setPath("");
    setDataTypeGroupId("");
    setSchedulingTime(0);
    setScheduledMode(0);
    setIterateField("");
    setUniqueId("");
    setDataKeys([]);
    setChild([]);
    onClose();
    getData();
    getDataSource();
  };

  // func http
  const api = new APIClient();

  const handleCreateEndpoint = () => {
    setLoading(true);
    const keys = dataKeys.map((key, i) => {
      if (iterateType === "key-value") {
        if (i === 0) {
          key.key = "label";
          key.value_data = "label";
        } else if (i === 3) {
          key.key = "value";
          key.value_data = "value";
        } else {
          key.value_data = "";
          key.value_data = "";
        }

        return key;
      }

      return key;
    });

    var period_is_yearly = false;

    if (
      !periods["is_yearly"] &&
      !periods["is_weekly"] &&
      !periods["is_monthly"] &&
      !periods["is_daily"]
    ) {
      period_is_yearly = true;
    } else if (periods["is_yearly"]) {
      period_is_yearly = true;
    }

    let dataPost = {
      name: namaWidget,
      path: path,
      datasource_id: dataSourceId,
      datatype_group_id: dataTypeGroupId,
      scheduling_time: schedulingTime,
      scheduled_mode: scheduledMode,
      iterate_field: iterateField,
      iterate_type: iterateType,
      filter_workunit: filterWorkunit,
      direct_call: directCall,
      filter_workunit_type: filterWorkunitType,
      unique_id: uniqueId,
      method: dataMethod,
      body_request: dataBody.length !== 0 ? JSON.parse(dataBody) : "",
      keys: keys,
      pagination_param_key: paginationParamKey,
      total_page_key: totalPageKey,
      get_data_type: getDataType,
      is_daily: periods["is_daily"],
      is_weekly: periods["is_weekly"],
      is_monthly: periods["is_monthly"],
      is_yearly: period_is_yearly ? 1 : 0,
      is_custom: periods["is_custom"],
      endpoint_scheduler: {
        is_daily: periods["is_daily"] === 1 ? true : false,
        is_weekly: periods["is_weekly"] === 1 ? true : false,
        is_yearly: period_is_yearly,
        is_monthly: periods["is_monthly"] === 1 ? true : false,
        is_custom: false,
        data: {
          daily_period_data:
            periodsForm["is_daily"] === null
              ? null
              : {
                additional_parameter: parseAdditionalParam(
                  periodsForm["is_daily"]
                ),
              },
          weekly_period_data:
            periodsForm["is_weekly"] === null
              ? null
              : {
                additional_parameter: parseAdditionalParam(
                  periodsForm["is_weekly"]
                ),
              },
          yearly_period_data:
            periodsForm["is_yearly"] === null
              ? null
              : {
                additional_parameter: parseAdditionalParam(
                  periodsForm["is_yearly"]
                ),
              },
          custom_period_data:
            periodsForm["is_custom"] === null
              ? null
              : {
                additional_parameter: parseAdditionalParam(
                  periodsForm["is_custom"]
                ),
              },
          monthly_period_data:
            periodsForm["is_monthly"] === null
              ? null
              : {
                additional_parameter: parseAdditionalParam(
                  periodsForm["is_monthly"]
                ),
              },
        },
      },
      endpoint_child: [...child],
    };

    api
      .create(DATA_COLLECTOR_BASE + "/endpoints/", dataPost)
      .then((res) => {
        handleClear();
        CustomToast("success", "Data Endpoint berhasil ditambahkan!");
        getData();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Tidak dapat menambahkan data endpoint", error);
        CustomToast("danger", "Data Endpoint gagal ditambahkan!");
        setLoading(false);
      });
  };

  const getDataTypeKeys = () => {
    api
      .get(DATA_COLLECTOR_BASE + "/datatype-group/?list=true")
      .then((response) => {
        const data = response.data;

        const selectedId = dataTypeGroupId;
        const selectedDataType = data.find((item) => item.id === selectedId);

        if (selectedDataType) {
          const fields = selectedDataType.fields.map((field) => ({
            id: field.id,
            key: "",
            key_type: field.field_type,
            value_data: field.field_name,
          }));
          setDataKeys(fields);
          setDataTypeName(selectedDataType.name);
        } else {
          console.info("Tidak ada data dengan id yang dipilih");
        }
      })
      .catch((error) => {
        CustomToast("danger", error);
      });
  };

  /** parseAdditionalParam parse an array of object into map object.
   * It set the key from the array's key and value from array's value
   * then return the result.
   *
   * @param {Array<Map<String, String>>} data
   * @returns {Map<String,String>}
   *
   * */
  const parseAdditionalParam = (data) => {
    let ap = {};
    for (const d of data) {
      ap[d.key] = d.value;
    }

    return ap;
  };

  /**
   * handlePeriodsChange evaluate the periods checkbox input whenever
   * the value change. It set the periods state, add or remove form object
   * from periodsForm state.
   *
   * @param {boolean} checked
   * @param {string} name
   * @returns
   *
   * */
  const handlePeriodsChange = (checked, name) => {
    setPeriods((prev) => ({
      ...prev,
      [name]: checked ? 1 : 0,
    }));

    if (checked) {
      const form = { key: "", value: "" };
      let current = periodsForm[name];

      if (current === null) {
        current = [];
        current.push(form);
      } else {
        current.push(form);
      }

      setPeriodsForm((prev) => ({
        ...prev,
        [name]: current,
      }));

      return;
    }

    setPeriodsForm((prev) => ({
      ...prev,
      [name]: null,
    }));

    return;
  };

  /**
   * handlePeriodsFormAction is function for action button for each
   * form input of periods.
   *
   * @param {string} name name of periodsForm
   * @param {number} index current index of iteration
   * @param {string} state state of button
   * @returns
   *
   * */
  const handlePeriodsFormAction = (name, index, state) => {
    const form = { key: "", value: "" };
    const current = periodsForm[name];

    if (state === "add") {
      current.push(form);
    }

    if (state === "remove") {
      current.splice(index, 1);
    }

    setPeriodsForm((prev) => ({
      ...prev,
      [name]: current,
    }));
  };

  const handlePeriodsFormInput = (name, key, index, value) => {
    const current = periodsForm[name];
    current[index][key] = value;

    setPeriodsForm((prev) => ({
      ...prev,
      [name]: current,
    }));
  };

  const handleInputChange = (index, field, value) => {
    const updatedKeys = [...dataKeys];
    updatedKeys[index] = {
      ...updatedKeys[index],
      [field]: value,
    };
    setDataKeys(updatedKeys);
  };

  useEffect(() => {
    if (dataTypeGroupId.length !== 0) {
      getDataTypeKeys();
    }
  }, [dataTypeGroupId]);

  return (
    <>
      <Modal isOpen={show} toggle={onClose} fullscreen>
        <ModalHeader toggle={onClose}>Tambah Endpoint</ModalHeader>

        <ModalBody>
          {/* <EndpointTabs child={child} setChild={setChild} tab={tab} setTab={setTab} tab1={<Tab1 />} tab2={<EndpointChild child={child} setChild={setChild} />} /> */}
          <Row className="g-2">
            <Col xs={12}>
              <div
                className="d-flex align-content-center"
                style={{ gap: "8px" }}
              >
                <Button
                  className="fw-semibold rounded-pill"
                  color={tab != 1 ? "link" : "primary"}
                  outline={tab != 1}
                  onClick={() => setTab(1)}
                >
                  Endpoint Data
                </Button>
                <Button
                  className="fw-semibold rounded-pill"
                  color={tab != 2 ? "link" : "primary"}
                  outline={tab != 2}
                  onClick={() => setTab(2)}
                >
                  Endpoint Child
                </Button>
              </div>
            </Col>
            <Col xs={12}>
              <TabContent activeTab={tab}>
                <TabPane tabId={1}>
                  <Row>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="data_source">Sumber Data</Label>
                        <Input
                          id="data_source"
                          name="data_source"
                          type="select"
                          value={dataSourceId}
                          onChange={(e) => setDataSourceId(e.target.value)}
                        >
                          <option value="" disabled hidden>
                            Pilih Sumber Data
                          </option>
                          {dataSource && dataSource.length > 0 ? (
                            dataSource.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))
                          ) : (
                            <option value="">No data available</option>
                          )}
                        </Input>
                      </FormGroup>
                      <FormGroup>
                        <Label for="nama_widget">Nama Widget</Label>
                        <Input
                          id="nama_widget"
                          name="nama_widget"
                          placeholder="Masukkan Nama Widget"
                          type="text"
                          value={namaWidget}
                          onChange={(e) => setNamaWidget(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="dataMethod">Method</Label>
                        <Input
                          id="dataMethod"
                          name="dataMethod"
                          type="select"
                          value={dataMethod}
                          onChange={(e) => setDataMethod(e.target.value)}
                        >
                          <option disabled value="">
                            Pilih Method
                          </option>
                          <option value="Get">Get</option>
                          <option value="Post">Post</option>
                        </Input>
                      </FormGroup>
                      {dataMethod === "" || dataMethod !== "Post" ? null : (
                        <FormGroup>
                          <Label for="dataBody">Body</Label>
                          <Input
                            id="dataBody"
                            name="dataBody"
                            placeholder="Masukkan Body"
                            type="textarea"
                            value={dataBody}
                            onChange={(e) => setDataBody(e.target.value)}
                            style={{ height: "200px" }}
                          />
                        </FormGroup>
                      )}

                      <FormGroup>
                        <Label for="path">Path</Label>
                        <Input
                          id="path"
                          name="path"
                          placeholder="Masukkan path"
                          type="text"
                          value={path.startsWith("/") ? path : "/" + path}
                          onChange={(e) => setPath(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="iterate_field">Iterate Field</Label>
                        <Input
                          id="iterate_field"
                          placeholder="Masukkan Iterate Field"
                          name="iterate_field"
                          type="text"
                          value={iterateField}
                          onChange={(e) => setIterateField(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="iterate_field">Iterate Type</Label>
                        <Input
                          id="iterate_field"
                          placeholder="Masukkan Iterate Field"
                          name="iterate_field"
                          type="select"
                          value={iterateType}
                          onChange={(e) => setIterateType(e.target.value)}
                        >
                          <option disabled value="">
                            Pilih Tipe Iterasi
                          </option>
                          <option value="array">Array</option>
                          <option value="object">Object</option>
                          <option value="key-value">Key Value</option>
                        </Input>
                      </FormGroup>

                      <FormGroup>
                        <Label for="unique_id">Unique Id</Label>
                        <Input
                          id="unique_id"
                          placeholder="Masukkan Unique Id"
                          name="unique_id"
                          type="text"
                          value={uniqueId}
                          onChange={(e) => setUniqueId(e.target.value)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="unique_id">Pagination Param Key</Label>
                        <Input
                          id="unique_id"
                          placeholder="Masukkan Unique Id"
                          name="unique_id"
                          type="text"
                          value={paginationParamKey}
                          onChange={(e) =>
                            setPaginationParamKey(e.target.value)
                          }
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="unique_id">Total Page Key</Label>
                        <Input
                          id="unique_id"
                          placeholder="Masukkan Unique Id"
                          name="unique_id"
                          type="text"
                          value={totalPageKey}
                          onChange={(e) => setTotalPageKey(e.target.value)}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="data_source">Get Data Type</Label>
                        <Input
                          id="data_source"
                          name="data_source"
                          type="select"
                          value={getDataType}
                          onChange={(e) => setGetDataType(e.target.value)}
                        >
                          <option value="" disabled hidden>
                            Pilih Sumber Data
                          </option>
                          <option value="list">List</option>
                          <option value="pagination">Pagination</option>
                        </Input>
                      </FormGroup>

                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              type="checkbox"
                              id="scheduled_mode"
                              name="scheduled_mode"
                              checked={scheduledMode === 1}
                              onChange={(e) =>
                                setScheduledMode(e.target.checked ? 1 : 0)
                              }
                            />{" "}
                            <Label check for="scheduled_mode" className="">
                              Ambil data secara berkala (Jam)
                            </Label>
                            {scheduledMode === 1 && (
                              <Input
                                id="scheduling_time"
                                className="mt-2"
                                placeholder="Masukkan Data berdasarkan Jam"
                                name="scheduling_time"
                                type="number"
                                value={schedulingTime}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (value === "") {
                                    setSchedulingTime(0);
                                  } else {
                                    const parsedValue = parseInt(value);
                                    if (!isNaN(parsedValue)) {
                                      setSchedulingTime(parsedValue);
                                    }
                                  }
                                }}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              type="checkbox"
                              id="filter_workunit"
                              name="filter_workunit"
                              checked={filterWorkunit === 1}
                              onChange={(e) =>
                                setFilterWorkunit(e.target.checked ? 1 : 0)
                              }
                            />{" "}
                            <Label check for="filter_workunit" className="">
                              Filter Workunit
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Input
                              type="checkbox"
                              id="direct_call"
                              name="direct_call"
                              checked={directCall === 1}
                              onChange={(e) =>
                                setDirectCall(e.target.checked ? 1 : 0)
                              }
                            />{" "}
                            <Label check for="direct_call" className="">
                              Akses Langsung
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="data_source">Filter Workunit Type</Label>
                        <Input
                          id="data_source"
                          name="filter_workunit_type"
                          type="select"
                          value={filterWorkunitType}
                          onChange={(e) =>
                            setFilterWorkunitType(e.target.value)
                          }
                        >
                          <option value="" disabled hidden>
                            Pilih Filter Workunit Type
                          </option>
                          <option value="work_unit_ids">work_unit_ids</option>
                          <option value="work_unit_id">work_unit_id</option>
                          <option value="work_unit_id_by_login">
                            work_unit_id_by_login
                          </option>
                          <option value="work_unit_id_and_work_unit_ids">
                            work_unit_id_and_work_unit_ids
                          </option>
                        </Input>
                      </FormGroup>

                      <h5 className="mb-3">Periode Endpoint</h5>
                      {Object.keys(periods).map((period, index) => (
                        <div key={"periods" + index} className="mb-3">
                          <FormGroup check>
                            <Label for={period} check>
                              <Input
                                id={period}
                                name={period}
                                type="checkbox"
                                onChange={(e) =>
                                  handlePeriodsChange(e.target.checked, period)
                                }
                              ></Input>
                              {period === "is_daily"
                                ? "Harian"
                                : period === "is_weekly"
                                  ? "Mingguan"
                                  : period === "is_monthly"
                                    ? "Bulanan"
                                    : period === "is_yearly"
                                      ? "Tahunan"
                                      : "Custom"}
                            </Label>
                          </FormGroup>
                          {periods[period] === 1 && (
                            <div>
                              {periodsForm[period]?.map((p, i) => (
                                <Row className="mt-2">
                                  <Col md={4}>
                                    <Input
                                      id=""
                                      placeholder="Key"
                                      onChange={(e) =>
                                        handlePeriodsFormInput(
                                          period,
                                          "key",
                                          i,
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                  <Col md={5}>
                                    <Input
                                      id=""
                                      placeholder="Value"
                                      onChange={(e) =>
                                        handlePeriodsFormInput(
                                          period,
                                          "value",
                                          i,
                                          e.target.value
                                        )
                                      }
                                    ></Input>
                                  </Col>
                                  <Col>
                                    <Button
                                      size="sm"
                                      color="success"
                                      onClick={() =>
                                        handlePeriodsFormAction(
                                          period,
                                          i,
                                          "add"
                                        )
                                      }
                                    >
                                      <Plus />
                                    </Button>
                                  </Col>
                                  {i >= 1 && (
                                    <Col>
                                      <Button
                                        size="sm"
                                        color="danger"
                                        onClick={() =>
                                          handlePeriodsFormAction(
                                            period,
                                            i,
                                            "remove"
                                          )
                                        }
                                      >
                                        <Trash2 />
                                      </Button>
                                    </Col>
                                  )}
                                </Row>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </Col>
                    <Col md={8}>
                      <FormGroup>
                        <Label for="type_data">Type Data</Label>
                        <Input
                          id="type_data"
                          name="type_data"
                          type="select"
                          value={dataTypeGroupId}
                          onChange={(e) => {
                            setDataTypeGroupId(e.target.value);
                            getDataTypeKeys(e.target.value);
                          }}
                        >
                          <option value="" disabled hidden>
                            Pilih Type Data
                          </option>
                          {dataType && dataType.length > 0 ? (
                            dataType.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))
                          ) : (
                            <option value="">No data available</option>
                          )}
                        </Input>
                      </FormGroup>

                      {/* mapping sini */}
                      {dataTypeName !== "TABLE" &&
                        dataKeys.map((item, index) => (
                          <Row key={item.id}>
                            <Col md={4}>
                              <FormGroup>
                                <Label for={item.value_data}>Label</Label>
                                <Input
                                  id={item.value_data}
                                  disabled
                                  name={item.value_data}
                                  type="text"
                                  placeholder={item.value_data}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "value_data",
                                      item.value_data
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>

                            <Col md={4}>
                              <FormGroup>
                                <Label for={item.field_type}>Type</Label>
                                <Input
                                  id={item.field_type}
                                  name={item.field_type}
                                  type="select"
                                  value={item.key_type}
                                  onChange={(e) =>
                                    handleInputChange(
                                      index,
                                      "key_type",
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="default" defaultValue>
                                    Default
                                  </option>
                                  <option value="outter_key">Outer Key</option>
                                  <option value="default_value">
                                    Default Value
                                  </option>
                                  <option value="array">Array</option>
                                  <option value="ranking_performance">
                                    Ranking Performance
                                  </option>
                                  <option value="chainable">Chainable</option>
                                  <option value="unused">Unused</option>
                                </Input>
                              </FormGroup>
                            </Col>

                            <Col md={4}>
                              <FormGroup>
                                <Label for={item.key}>Value Data</Label>
                                {item.value_data === "widget_type" ? (
                                  <Input
                                    id={item.key}
                                    name={item.key}
                                    type="select"
                                    disabled={
                                      iterateType === "key-value" ? true : false
                                    }
                                    value={item.key}
                                    onChange={(e) => {
                                      handleInputChange(
                                        index,
                                        "key",
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <option disabled value="">
                                      Pilih Tipe Widget
                                    </option>
                                    <option value="percentage">Percentage</option>
                                    <option value="chart">Chart</option>
                                    <option value="performance">Performance</option>
                                    <option value="user">User</option>

                                  </Input>
                                ) : (
                                  <Input
                                    id={item.key}
                                    placeholder={"Masukkan Value Data"}
                                    name={item.key}
                                    type="text"
                                    disabled={
                                      iterateType === "key-value" ? true : false
                                    }
                                    value={item.key}
                                    onChange={(e) => {
                                      handleInputChange(
                                        index,
                                        "key",
                                        e.target.value
                                      );
                                    }}
                                  />
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        ))}

                      {dataTypeName === "TABLE" && (
                        <DynamicInput setDataKeys={setDataKeys} />
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <EndpointChild child={child} setChild={setChild} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            Batal
          </Button>
          <SubmitButton
            loading={loading}
            title="Simpan"
            onClick={handleCreateEndpoint}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateEndpoint;
