import React, { useEffect, useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Collapse, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// Import Data
// import navdata                           from "../LayoutMenuData";
//i18n

import { deleteDashboard, getDashboards, getMenuDashboards } from "../../slices/thunks";
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import { useDispatch, useSelector } from "react-redux";
import { setDashboard } from "../../slices/dashboards/reducer";
import { changeLeftSidebarSize } from "../../slices/layouts/reducer";
import LoadingMenus from "./loading";
import "./menu.css"

const VerticalLayout = (props) => {
  // const navData = navdata().props.children;
  const path = props.router.location.pathname;

  const dispatch = useDispatch();
  const dashboards = useSelector((state) => state.Dashboards);

  // useEffect(() => {
  //   if (dashboards.isInitial || dashboards.isUpdate) {
  //     dispatch(getMenuDashboards());
  //   }
  // }, [dashboards.isInitial, dashboards.isUpdate]);

  /*
 layout settings
 */
  const { leftsidbarSizeType, sidebarVisibilitytype, layoutType } = useSelector(
    (state) => ({
      leftsidbarSizeType: state.Layout.leftsidbarSizeType,
      sidebarVisibilitytype: state.Layout.sidebarVisibilitytype,
      layoutType: state.Layout.layoutType,
    })
  );

  const { leftSidebarSize } = useSelector((state) => ({
    leftSidebarSize: state.Layout.leftSidebarSize,
  }));

  //vertical and semibox resize events
  const resizeSidebarMenu = useCallback(() => {
    var windowSize = document.documentElement.clientWidth;
    if (windowSize >= 1025) {
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute(
          "data-sidebar-size",
          leftsidbarSizeType
        );
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute(
          "data-sidebar-size",
          leftsidbarSizeType
        );
      }
      if (
        (sidebarVisibilitytype === "show" ||
          layoutType === "vertical" ||
          layoutType === "twocolumn") &&
        document.querySelector(".hamburger-icon")
      ) {
        document.querySelector(".hamburger-icon")?.classList.remove("open");
      } else {
        document.querySelector(".hamburger-icon")?.classList.add("open");
      }
    } else if (windowSize < 1025 && windowSize > 767) {
      document.body.classList.remove("twocolumn-panel");
      if (document.documentElement.getAttribute("data-layout") === "vertical") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (document.documentElement.getAttribute("data-layout") === "semibox") {
        document.documentElement.setAttribute("data-sidebar-size", "sm");
      }
      if (document.querySelector(".hamburger-icon")) {
        document.querySelector(".hamburger-icon")?.classList.add("open");
      }
    } else if (windowSize <= 767) {
      document.body.classList.remove("vertical-sidebar-enable");
      if (
        document.documentElement.getAttribute("data-layout") !== "horizontal"
      ) {
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
      if (document.querySelector(".hamburger-icon")) {
        document.querySelector(".hamburger-icon")?.classList.add("open");
      }
    }

    dispatch(changeLeftSidebarSize(document.documentElement.getAttribute("data-sidebar-size")));
  }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

  useEffect(() => {
    window.addEventListener("resize", resizeSidebarMenu, true);
  }, [resizeSidebarMenu]);

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
    const initMenu = () => {
      const pathName = process.env.PUBLIC_URL + path;
      const ul = document.getElementById("navbar-nav");
      const items = ul.getElementsByTagName("a");
      let itemsArray = [...items]; // converts NodeList to Array
      removeActivation(itemsArray);
      let matchingMenuItem = itemsArray.find((x) => {
        return x.pathname === dashboards.currentPath;
      });
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    if (props.layoutType === "vertical") {
      initMenu();
    }
  }, [path, props.layoutType, dashboards.map]);

  function activateParentDropdown(item) {
    item.classList.add("active");
    let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

    if (parentCollapseDiv) {
      // to set aria expand true remaining
      parentCollapseDiv.classList.add("show");
      parentCollapseDiv.parentElement.children[0].classList.add("active");
      parentCollapseDiv.parentElement.children[0].setAttribute(
        "aria-expanded",
        "true"
      );
      if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
        parentCollapseDiv.parentElement
          .closest(".collapse")
          .classList.add("show");
        if (
          parentCollapseDiv.parentElement.closest(".collapse")
            .previousElementSibling
        )
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.classList.add("active");
        if (
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
        ) {
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .classList.add("show");
          parentCollapseDiv.parentElement
            .closest(".collapse")
            .previousElementSibling.closest(".collapse")
            .previousElementSibling.classList.add("active");
        }
      }
      return false;
    }
    return false;
  }

  const removeActivation = (items) => {
    let actiItems = items.filter((x) => x.classList.contains("active"));

    actiItems.forEach((item) => {
      if (item.classList.contains("menu-link")) {
        if (!item.classList.contains("active")) {
          item.setAttribute("aria-expanded", false);
        }
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
      }
      if (item.classList.contains("nav-link")) {
        if (item.nextElementSibling) {
          item.nextElementSibling.classList.remove("show");
        }
        item.setAttribute("aria-expanded", false);
      }
      item.classList.remove("active");
    });
  };

  const [confirmationModal, setConfirmationModal] = useState(false)
  const [selectId, setSelecId] = useState(null)
  const toggleConfirmationModal = () =>{
    setConfirmationModal(!confirmationModal)
  }

  const handleDelete = () => {
    dispatch(deleteDashboard(selectId))
    toggleConfirmationModal()
    props.setToggleDelete(false)
  }

  const handleClick = (item) => {
    if (props.toggleDelete) {
      // return console.log("delete" , dashboards.map[item]._id)
      setSelecId(dashboards.map[item]._id)
      toggleConfirmationModal()
      return
    }
    dispatch(setDashboard(dashboards.map[item].path))
  }

  return (
    <React.Fragment>
      {dashboards.isLoading && <LoadingMenus />}
      {!dashboards.isLoading && Object.keys(dashboards.map ?? {}).map((item, key) => {
        return (
          <li className="nav-item" key={key}>
            <Link
              className="nav-link menu-link" style={{ paddingRight: "14px" }}
              to={dashboards.map[item].path ? dashboards.map[item].path : "/#"}
              onClick={() => handleClick(item)}
            >
              <i className="ri-dashboard-2-line" style={{ marginLeft: leftSidebarSize == "sm" ? "-5px" : "" }}></i>
              <span>{props.t(dashboards.map[item].label)}</span>
              {props.toggleDelete ? (
                <span
                  className={
                    "badge badge-pill bg-danger"
                  }
                  data-key="t-new"
                >
                  X
                </span>
              ) : null}
            </Link>
          </li>
        );
      })}

      <Modal isOpen={confirmationModal} toggle={toggleConfirmationModal}>
        <ModalHeader toggle={toggleConfirmationModal}>Konfirmasi</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          Apakah Anda yakin akan menghapus dashboard ini?
        </ModalBody>
        <ModalFooter className="gap-2 justify-content-center">
          <Button color="primary" outline onClick={toggleConfirmationModal}>
            Tidak
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Ya
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

VerticalLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(VerticalLayout));
