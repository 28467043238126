import moment from "moment";

const getTimeAgo = (stringDate) => {

    moment.updateLocale('id', {
        relativeTime: {
            future: "dalam %s",
            past: "%s yang lalu",
            s: 'beberapa detik yang lalu',
            ss: '%d detik yang lalu',
            m: "semenit yang lalu",
            mm: "%d menit yang lalu",
            h: "satu jam yang lalu",
            hh: "%d jam yang lalu",
            d: "sehari yang lalu",
            dd: "%d hari yang lalu",
            w: "seminggu yang lalu",
            ww: "%d minggu yang lalu",
            M: "sebulan yang lalu",
            MM: "%d bulan yang lalu",
            y: "setahun yang lalu",
            yy: "%d tahun yang lalu"
        }
    });
    
    moment.locale('id');
    return moment(stringDate).fromNow(true);
};

const dateTime = (stringDate) => {
    // console.log(stringDate) 
    // if((new Date(stringDate) !== "Invalid Date") && !isNaN(new Date(stringDate))){
    //     return stringDate
    // }
     return new Intl.DateTimeFormat("id-ID", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      }).format(new Date(stringDate))
}

const DateTimeFormat = {
    getTimeAgo, dateTime
}

export default DateTimeFormat