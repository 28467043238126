/**
 * BACKEND API INTEGRATION
 *
 * Each engine endpoint should be registered here
 * so it could be imported and used by api provider
 * such as axios or fetch.
 *
 */
export const API_BASEURL =
  !process.env.NODE_ENV || process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_API
    : window._env_.REACT_APP_API; // API gateway baseurl
export const API_SIACC =
  !process.env.NODE_ENV || process.env.NODE_ENV !== "production"
    ? "https://dash.sysdevteam.net/apis-siacc"
    : window._env_.REACT_APP_SIACC_API; //SIACC baseurl
export const WEBSOCKET_SIACC =
  !process.env.NODE_ENV || process.env.NODE_ENV !== "production"
    ? "wss://dash.sysdevteam.net/apis-siacc"
    : window._env_.REACT_APP_SOCKET_SIACC; //SIACC baseurl
// export const WEBSOCKET_SIACC = "wss://antmedia.underdev.team/WebRTCAppEE/websocket"

export const LOG_BASE = API_BASEURL + "/log";
export const AUTH_BASE = API_BASEURL + "/auth"; // Auth Engine
export const WIDGET_SYNC_BASE = API_BASEURL + "/widget-sync"; // Widget sync engine
export const DATA_COLLECTOR_BASE = API_BASEURL + "/data-collector"; // Data collector engine

export const CHAT_BASE = API_SIACC + "/communication";
export const NOTIF_SIACC_BASE = API_SIACC + "/notification";
export const EMPLOYE_SIACC = API_SIACC + "/employee-biodata";
export const LANDINGPAGE_SIACC = API_SIACC + "/landing-page?by_login=true";

//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//JOB APPLICATION
export const GET_API_KEY = "/api-key";
