import { Col, Row } from "reactstrap";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import RankingCol from "./ranking-col";
import RankingRow from "./ranking-row";
import PerfectScrollbar from "react-perfect-scrollbar";

const Ranking = ({ source }) => {
  const { loading, data, failed, getSource } = useGetWidget({ source });

  function rewriteRanking(ranking) {
    if (typeof ranking === "string") {
      var checktype = ranking.search("/");
      if (checktype > 0) {
        return parseInt(ranking.split("/")[0]);
      } else {
        return parseInt(ranking);
      }
    } else {
      return ranking;
    }
  }

  let newData = [];
  if (data) {
    data.map((res, index) => {
      data[index]["ranking"] = rewriteRanking(res.ranking);
    });
    newData = data;
    newData.sort(function (a, b) {
      return a.ranking - b.ranking;
    });
  }

  return (
    <BoundWidget
      loading={loading}
      data={data}
      failed={failed}
      onReload={getSource}
    >
      {newData && (
        <>
          <Row className="me-0">
            {newData.slice(0, 3).map((item, index) => (
              <Col
                key={item.id}
                className="pe-0"
                style={{ marginBottom: "0.5rem" }}
              >
                <RankingCol rank={index + 1} data={item}></RankingCol>
              </Col>
            ))}
          </Row>
          <Row>
            {newData.slice(3).map((item, index) => (
              <Col key={item.id} xs={12}>
                <RankingRow rank={index + 4} data={item}></RankingRow>
              </Col>
            ))}
          </Row>
        </>
      )}
    </BoundWidget>
  );
};

export default Ranking;
