import React from "react";
import { Card } from "reactstrap";
import Export from "./Export";
import Print from "./Print";
import Share from "./Share";

const ExportShare = (props) => {
  return (
    <>
      <Card className="print-hidden d-inline-block shadow-lg rounded mb-0">
        {!props.isShare && <Share/>}
        <Export />
        <Print id={props.dashboardId}/>
      </Card>
    </>
  );
};

export default ExportShare;
