import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient } from "../../helpers/api_helper";
import { CHAT_BASE } from "../../helpers/url_helper";

const api = new APIClient()

export const getRoomChat = createAsyncThunk(
    "siacc/getroomchat",
    async () => {
        try{
            const response = await api.get(`${CHAT_BASE}/room/by-uuid`)
            return response.data
        } catch (err){
            return null
        }
    }
)

export const getRoomArchive = createAsyncThunk(
    "siacc/getroomarchive",
    async () => {
        try{
            const response = await api.get(`${CHAT_BASE}/room/archive`)
            return response.data
        } catch (err){
            return null
        }
    }
)

export const getMsgByRoom = createAsyncThunk(
    "siacc/getmessage",
    async (payload) => {
        try{
            const response = await api.get(`${CHAT_BASE}/messages/${payload}`)
            return response.data
        }catch(err){
            return null
        }
    }
)

export const markAsRead = createAsyncThunk(
    "siacc/markasread",
    async (payload) => {
        try {
            const response = await api.create(`${CHAT_BASE}/room/read`, payload)
            return response.data
        } catch (error) {
            return null
        }
    }
)

export const markAsUnread = createAsyncThunk(
    "siacc/markasunread",
    async (payload) => {
        try {
            const response = await api.put(`${CHAT_BASE}/room/un-read`, payload)
            return response.data
        } catch (error) {
            return null
        }
    }
)

export const setAdminGroup = createAsyncThunk(
    "siacc/setadmingroup",
    async (payload) => {
        try {
            const response = await api.put(`${CHAT_BASE}/room/admin`, payload)
            return response.data
        } catch (error) {
            return null
        }
    }
)

export const removeGroupMember = createAsyncThunk(
    "siacc/removegroupmember",
    async (payload) => {
        try {
            const response = await api.delete(`${CHAT_BASE}/room/user`, {data: payload})
            return response.data
        } catch (error) {
            return null
        }
    }
)

export const addGroupMember = createAsyncThunk(
    "siacc/addgroupmember",
    async(payload) => {
        try {
            const response = await api.create(`${CHAT_BASE}/room/user`,payload)
            return response.data
        } catch (error) {
            return console.log(error)
        }
    }
)

export const uploadAttachment = createAsyncThunk(
    "siacc/uploadattachment",
    async(payload) => {
      
        try {
            const response = await api.createMultipart(`${CHAT_BASE}/attachment`, payload)
            return response.data
        } catch (error) {
            return console.log(error)
        }
    }
)