import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import user_info from "../../helpers/user_info";
import {
  getMenuDashboards,
  getNotifDashboard,
  getNotifSiacc,
} from "../../slices/thunks";
import { Card, Col, Row, Container } from "reactstrap";
import SidebarNotification from "./SidebarNotification";
import SimpleBar from "simplebar-react";

const Notification = () => {
  const dispatch = useDispatch();

  const dashboardNotification = useSelector(
    (state) => state.NotificationDashboard
  );
  const siaccNotification = useSelector((state) => state.NotificationSiacc);

  const { data: dashboardData, pagination: dashboardPagination } =
    dashboardNotification;
  const { data: siaccData, pagination: siaccPagination } = siaccNotification;

  useEffect(() => {
    if (!dashboardData || dashboardData.length === 0) {
      dispatch(getNotifDashboard(user_info().uuid_user));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMenuDashboards());
    if (!siaccData || siaccData.length === 0) {
      dispatch(getNotifSiacc(user_info().uuid));
    }
  }, [dispatch]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (dashboardPagination && dashboardPagination.has_next) {
        const nextDashboardPage = dashboardPagination.current_page + 1;
        dispatch(getNotifDashboard(user_info().uuid_user, nextDashboardPage));
      }

      if (siaccPagination && siaccPagination.has_next) {
        const nextSiaccPage = siaccPagination.current_page + 1;
        dispatch(getNotifSiacc(user_info().uuid, nextSiaccPage));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dashboardPagination, siaccPagination]);

  const formatIndonesianDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("id-ID", { month: "long" }).format(
      date
    );
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedTime = `${hours % 12 || 12}.${minutes
      .toString()
      .padStart(2, "0")}.${seconds.toString().padStart(2, "0")} ${amOrPm}`;
    return `${day} ${month} ${year}, ${formattedTime}`;
  };

  return (
    <div className="page-content pb-0">
      <Container fluid>
        <Row>
          <Col md="3">
            <Card style={{ height: "82vh", overflow: "auto" }}>
              <SidebarNotification title="semua notifikasi" />
            </Card>
          </Col>
          <Col md="9">
            <Card style={{ height: "82vh" }}>
              <SimpleBar style={{ maxHeight: "82vh" }}>
                <div className="p-4">
                  <p className="fs-18 fw-semibold mb-0">Semua Notifikasi</p>
                  <p className="text-muted fs-14 mb-4">
                    Menampilkan semua pemberitahuan
                  </p>
                  <Row>
                    <Col>
                      {dashboardData &&
                        dashboardData.map((item, index) => (
                          <div key={index}>
                            <div className="d-flex">
                              <p className="text-muted fs-12 mb-0">
                                {item.title}
                              </p>
                              <p className="text-muted fs-12 mb-0 ms-auto">
                                {formatIndonesianDateTime(item.timestamp)}
                              </p>
                            </div>
                            <p className="fs-14">{item.body}</p>
                            <hr />
                          </div>
                        ))}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {siaccData &&
                        siaccData.map((item, index) => (
                          <div key={index}>
                            <div className="d-flex">
                              <p className="text-muted fs-12 mb-0">
                                {item.notification.title}
                              </p>
                              <p className="text-muted fs-12 mb-0 ms-auto">
                                {formatIndonesianDateTime(item.created_at)}
                              </p>
                            </div>
                            <p className="fs-14">{item.notification.body}</p>
                            <hr />
                          </div>
                        ))}
                    </Col>
                  </Row>
                </div>
              </SimpleBar>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Notification;
