import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  ModalHeader,
} from "reactstrap";
import Carousel from 'react-elastic-carousel'
import { useGetWidget } from "../../../../helpers/widget_helper";

import BoundWidget from "../../BoundWidget";

import SimpleBar from "simplebar-react";
import CardSimpleCol from "./card-simple-col";
import CardSimpleRow from "./card-simple-row";

// bagian simple card

const RankingSimple = ({ source, widgetName }) => {
  const { loading, data, failed, getSource } = useGetWidget({ source });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  function rewriteRanking(ranking) {
    if (typeof ranking === "string") {
      var checktype = ranking.search("/");
      if (checktype > 0) {
        return parseInt(ranking.split("/")[0]);
      } else {
        return parseInt(ranking);
      }
    } else {
      return ranking;
    }
  }

  let newData = [];
  if (data) {
    data.map((res, index) => {
      data[index]["ranking"] = rewriteRanking(res.ranking);
    });
    newData = data;
    newData.sort(function (a, b) {
      return a.ranking - b.ranking;
    });
  }
	const carouselRef = useRef()
  const breakPoints = [
    { width: 343, itemsToShow: 1, itemsToScroll: 1, itemPadding: [0,5]},
    { width: 550, itemsToShow: 3},
    { width: 850, itemsToShow: 3 },
	]
  let resetTimeout;
  return (
    <BoundWidget
      loading={loading}
      data={data}
      failed={failed}
      onReload={getSource}
    >
      {newData && (
        <>
          {/* <Row
            className="me-0 d-flex align-items-stretch"
            style={{ maxHeight: "29em" }}
          >
            {newData.slice(0, 3).map((item, index) => {
              return (
                <Col
                  key={item.id}
                  className="pe-0 cursor-pointer"
                  onClick={toggleModal}
                >
                  <CardSimpleCol rank={index + 1} data={item}></CardSimpleCol>
                </Col>
              );
            })}
          </Row> */}
          <div className="d-flex gap-1 justify-content-end">
					<Button color="primary" size="sm"><i className="ri-arrow-left-s-line" onClick={() => carouselRef.current.slidePrev()}></i></Button>
					<Button color="primary" size="sm"><i className="ri-arrow-right-s-line" onClick={() =>{ carouselRef.current.slideNext(); console.log(carouselRef.current)}}></i></Button>
					</div>
          <Carousel 
            ref={carouselRef} 
            itemsToScroll={1} 
            itemsToShow={3} 
            enableAutoPlay={true}  
            pagination={false} 
            showArrows={false} 
            breakPoints={breakPoints}  
            itemPadding={[0, 20]}
            autoPlaySpeed={3000}
            onNextEnd={({ index }) => {
              if(carouselRef.current.state.pages.length === newData.length){
               if (index+1 === Math.ceil((newData.length))) {
                 resetTimeout = setTimeout(() => {
                    carouselRef.current.goTo(0)
                }, 3000) // same time
                 return 
              }
                return 

              }
              if (index + 3 === Math.ceil((newData.length))) {
                resetTimeout = setTimeout(() => {
                   carouselRef.current.goTo(0)
               }, 3000) // same time
                return 
             }
            }}>
          {newData.map((item, index) => {
              return (
                <Col
                  key={item.id}
                  className="pe-0 cursor-pointer pt-4 gap-4"
                  onClick={toggleModal}
                >
                  <CardSimpleCol onClick={toggleModal} rank={index + 1} data={item}></CardSimpleCol>
                </Col>
              );
            })}
          </Carousel>
          <Modal
            size="lg"
            isOpen={isModalOpen}
            toggle={toggleModal}
            className="modal-dialog modal-dialog-centered"
          >
            <ModalHeader>{widgetName}</ModalHeader>
            <ModalBody>
              
              <SimpleBar
                style={{
                  maxHeight: "76vh",
                  overflowX: "hidden",
                  overflow: "auto",
                }}
              >
                {/* Menggunakan komponen Ranking sebagai isi modal */}
                <Row className="me-0">
                  {newData.slice(0, 3).map((item, index) => (
                    <Col key={item.id} className="pe-0" md={4}>
                      <CardSimpleCol
                        rank={index + 1}
                        data={item}
                        title="modal"
                      ></CardSimpleCol>
                    </Col>
                  ))}
                </Row>
                <Row>
                  {newData.slice(3).map((item, index) => (
                    <Col key={item.id} xs={12}>
                      <CardSimpleRow
                        rank={index + 4}
                        data={item}
                      ></CardSimpleRow>
                    </Col>
                  ))}
                </Row>
              </SimpleBar>
            </ModalBody>
            <ModalFooter>
              <Button outline onClick={toggleModal}>
                Tutup
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </BoundWidget>
  );
};
// bagian simple card

export default RankingSimple;
