import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { img_error } from "../../../../helpers/default_profile";
import DateTimeFormat from "../../../../helpers/time_parser";
import "./index.scss"

const NewsDetail = (props) => {
  const { dateTime,getTimeAgo } = DateTimeFormat;
  const { show, setShow, data } = props;

  const getLevel = (level) => {
    switch (level) {
      case "national":
        return "Nasional";
      case "local":
        return "Lokal";
      default:
        return level;
    }
  };

  return (
    <Modal isOpen={show} toggle={() => setShow(!show)} size="lg">
      <ModalHeader toggle={() => setShow(!show)}></ModalHeader>
      <ModalBody>
        <div className="d-flex flex-column justify-content-start">
          <div className="d-flex ">
            <img
              src={data?.photo_author_url}
              onError={(e) => img_error(e)}
              alt=""
              height="30px"
              width="30px"
              className="rounded rounded-circle "
            />

            <div className="align-self-center ms-2">
              <p className="fs-12 mb-0 fw-semibold">{data.author} - {data.workunit}</p>
              <p className="fs-10 mb-0 text-muted"><span>{data.date && `${getTimeAgo(data.date.substr(0,10))} pukul ${data.date.substr(11,5)}`} </span> - {data.place}</p>
            </div>
          </div>
          <span className="fw-bold mt-3" style={{ height: "3em" }}>
            {data.title}
          </span>

          <div
            style={{
              background: "#606061",
              borderRadius: "10px",
              textAlign: "center",
              padding: "7px",
            }}
          >
            <img
              src={data.image_url}
              className="rounded my-2"
              style={{
                width: "500px",
                height: "380px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </div>
          {/* <div>
            <Button color="primary" className="my-2">
               Trending #{data.trending_seq} ({dateTime(data.trending_date)}){" "} 
            </Button>
          </div> */}
          <p className="fs-10 mt-2">{data.description}</p>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <span className="mdi mdi-comment-multiple-outline fs-10">
              <span className="ms-1">{data.total_comment}</span>
            </span>
            <span className="mdi mdi-eye-outline fs-10 ms-2">
              <span className="ms-1">{data.total_views}</span>
            </span>
          </div>
          <span className="mdi mdi-earth fs-10">
            <span className="ms-1">{getLevel(data.level)}</span>
          </span>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default NewsDetail;
