import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import Select                                 from "react-select";
import { read, utils }                        from "xlsx";
import { FilePond, registerPlugin }           from "react-filepond";
import FilePondPluginFileValidateType         from "filepond-plugin-file-validate-type";
import DataTable                              from "react-data-table-component";
import CustomToast                            from "./CustomToast";
import { getDataTypeGroup, postImportData }   from "../../helpers/widget_helper";
import gisXlsx                                from "../../assets/data/gis.xlsx";
import singleDataXlsx                         from "../../assets/data/single-data.xlsx";
import multipleDataXlsx                       from "../../assets/data/multiple-data.xlsx";
import newsXlsx                               from "../../assets/data/news.xlsx";
import tableXlsx                              from "../../assets/data/table.xlsx";

import "filepond/dist/filepond.min.css";
import "./Import.css";

registerPlugin(FilePondPluginFileValidateType);

const EXCLUDE_DATA_TYPE = ["RANKING"];
const EXAMPLE_DATA      = [
  {
    name : "gis",
    file : gisXlsx,
  },
  {
    name : "single-data",
    file : singleDataXlsx,
  },
  {
    name : "multiple-data",
    file : multipleDataXlsx,
  },
  {
    name : "news",
    file : newsXlsx,
  },
  {
    name : "table",
    file : tableXlsx,
  },
];

function upperToDash(str) {
  return str
    .split(" ")
    .map((str) => str.toLowerCase())
    .join("-");
}

const Import = () => {
  const [modal, setModal]                       = useState(false);
  const [tab, setTab]                           = useState();
  const [file, setFile]                         = useState();
  const [dataName, setDataName]                 = useState("");
  const [dataType, setDataType]                 = useState();
  const [dataTypes, setDataTypes]               = useState([]);
  const [files, setFiles]                       = useState([]);
  const [preview, setPreview]                   = useState();
  const [nestedModalTitle, setNestedModalTitle] = useState("");
  const [nestedModal, setNestedModal]           = useState(false);
  const [closeAll, setCloseAll]                 = useState(false);
  const [isLoading, setIsLoading]               = useState(false);
  const [isLoadingExample, setIsLoadingExample] = useState(false);
  const filepond                                = useRef();

  const toggle       = () => {
    setModal(!modal);
    setDataName("");
    setDataType("");
    setFiles([]);
  };
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  const toggleAll    = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };

  useEffect(() => {
    getDataTypeGroup().then(
      (data) =>
        data &&
        setDataTypes(
          data
            .filter((item) => !EXCLUDE_DATA_TYPE.includes(item.name))
            .map((item) => ({ label: upperToDash(item.name), value: item.id }))
        )
    );
  }, []);
  useEffect(() => {
    if (nestedModal) return;
    setFile();
    setPreview();
  }, [nestedModal]);

  function handleDataName(e) {
    setDataName(e.target.value);
  }
  function handleDataType(options) {
    setDataType(options);
  }
  function onAddFile(err, file) {
    const item = document.querySelector(
      `#filepond--item-${file.id} .filepond--file`
    );
    const btn  = createBtn();
    if (err?.main == "File is of invalid type") {
      const text = item.querySelector(`.filepond--file-info`);

      text.style.marginLeft = "0";
      btn.style.display     = "none";
    } else {
      btn.addEventListener(
        "click",
        (e) => {
          readFile(file);
        },
        { capture: true }
      );
    }
    item.append(btn);
    // item
    //   .querySelector(".filepond--action-process-item")
    //   .addEventListener("click", () => moveBtn(file), { capture: true });
    // item
    //   .querySelector(".filepond--action-retry-item-processing")
    //   .addEventListener("click", () => moveBtn(file), { capture: true });
    return true;
  }
  function readFile(file) {
    const reader = new FileReader();

    reader.addEventListener("load", (e) => {
      const preview  = {};
      const workbook = read(e.target.result, {
        type: "binary",
      });
      workbook.SheetNames.forEach(function (name) {
        preview[name] = utils.sheet_to_row_object_array(workbook.Sheets[name]);
      });
      setFile(file);
      if (workbook.SheetNames.length) {
        setTab(0);
      } else {
        setTab();
      }
      setPreview(preview);
      setNestedModalTitle(`Preview ${file.filename}`);
      toggleNested();
    });
    reader.readAsBinaryString(file.source);
  }
  async function handleImportAll() {
    setIsLoading(true);
    const payload = {
      file      : files[0].source,
      name      : dataName,
      type_id   : dataType.value,
      type_name : dataType.label,
    };
    try {
      await postImportData(payload);
      toggle();
      CustomToast("success", "Berhasil melakukan import!");
    } catch (error) {
      CustomToast("danger", "Terjadi Kesalahan!");
    } finally {
      setIsLoading(false);
    }
  }
  async function handleClickShowExample() {
    const preview     = {};
    const exampleData = EXAMPLE_DATA.find(
      (item) => item.name == dataType.label
    );
    setIsLoadingExample(true);
    const res      = await fetch(exampleData.file);
    const workbook = read(await res.arrayBuffer(), {
      type: "buffer",
    });
    setIsLoadingExample(false);
    workbook.SheetNames.forEach(function (name) {
      preview[name] = utils.sheet_to_row_object_array(workbook.Sheets[name]);
    });
    if (workbook.SheetNames.length) {
      setTab(0);
    } else {
      setTab();
    }
    setPreview(preview);
    setNestedModalTitle(`Example ${exampleData.name}`);
    toggleNested();
  }
  function createBtn() {
    const btn = document.createElement("button");

    btn.className = "filepond--file-action-button filepond--action-view-item";
    btn.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 18px; height: 18px;">
      <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    </svg>`;
    return btn;
  }
  function getBtn(file) {
    return document.querySelector(
      `#filepond--item-${file.id} .filepond--action-view-item`
    );
  }
  function moveBtn(file) {
    const btn      = getBtn(file);
    btn.style.left = ".5625em";
  }
  function moveBackBtn(file) {
    const btn      = getBtn(file);
    btn.style.left = "2.9625em";
  }
  function Preview({ data }) {
    if (!data) return <></>;
    const columns = Object.keys(data[0]).map((name) => ({
      name,
      selector: (row) => row[name],
    }));
    return <DataTable columns={columns} data={data}></DataTable>;
  }

  return (
    <>
      <Button size="sm" color="primary" onClick={toggle}>
        <i className="bx bxs-cloud-upload me-1"></i> Import Data
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Import Data</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Nama Data</Label>
            <Input
              id="name"
              name="data_name"
              value={dataName}
              onChange={handleDataName}
            />
          </FormGroup>
          <FormGroup>
            <Label for="type">Tipe Data</Label>
            <Select
              id="type"
              name="datatype_group_id"
              value={dataType}
              options={dataTypes}
              onChange={handleDataType}
            />
          </FormGroup>
          {dataType &&
            EXAMPLE_DATA.some((item) => item.name == dataType.label) && (
              <Button
                color="primary"
                outline
                block
                type="button"
                className="mb-3"
                disabled={isLoadingExample}
                onClick={handleClickShowExample}
              >
                {isLoadingExample ? (
                  <div className="d-flex gap-2 w-100 justify-content-center">
                    <Spinner size="sm">Loading...</Spinner>
                    <span>Memuat</span>
                  </div>
                ) : (
                  <span>Lihat Contoh</span>
                )}
              </Button>
            )}
          <FilePond
            ref={filepond}
            name="data_file"
            files={files}
            onupdatefiles={setFiles}
            onaddfile={onAddFile}
            onprocessfileabort={(file) => {
              moveBackBtn(file);
            }}
            onerror={(err, file, status) => {
              if (status.main == "Error during upload") {
                moveBackBtn(file);
              } else if (status.main == "File is of invalid type") {
                //
              }
            }}
            allowReorder={false}
            allowMultiple={false}
            allowProcess={false}
            instantUpload={false}
            chunkSize={4000000}
            server="/api"
            acceptedFileTypes={[
              // "text/csv",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              // ".csv",
              "application/vnd.ms-excel",
            ]}
            labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
          />
          <Modal
            scrollable
            size="xl"
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggle : undefined}
          >
            <ModalHeader>{nestedModalTitle}</ModalHeader>
            <ModalBody>
              {preview && (
                <>
                  <Nav tabs>
                    {Object.keys(preview).map((key, index) => (
                      <NavItem key={"import_nav_" + index}>
                        <NavLink
                          className={tab === index ? "active" : ""}
                          onClick={() => setTab(index)}
                        >
                          {key}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={tab}>
                    {Object.keys(preview).map((key, index) => (
                      <TabPane key={"import_tab_" + index} tabId={index}>
                        <Preview data={preview[key]}></Preview>
                      </TabPane>
                    ))}
                  </TabContent>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={toggleNested}>
                Tutup
              </Button>
            </ModalFooter>
          </Modal>
          <div className="hstack gap-2 justify-content-center mt-4">
            <Button color="light" onClick={() => setModal(false)}>
              Batal
            </Button>
            <Button
              color="primary"
              disabled={
                !dataName.trim() || !dataType || !files.length || isLoading
              }
              onClick={handleImportAll}
            >
              {isLoading ? (
                <div className="d-flex gap-2 w-100 justify-content-center">
                  <Spinner size="sm">Loading...</Spinner>
                  <span>Memuat</span>
                </div>
              ) : (
                <span>Import</span>
              )}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Import;
