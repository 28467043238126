import { Button, Col, Row } from "reactstrap";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import NewsItem from "./news-item";
import Carousel from 'react-elastic-carousel'
import { useRef } from "react";

const News = ({ source, datas }) => {
	let { loading, data, failed, getSource } = useGetWidget({ source });
	if (datas?.length > 0) {
		data = datas
		failed = false
	}
	// data = [
	// 	{
	// 		photo_author_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		author: "Teguh",
	// 		workunit: "Pimpinan",
	// 		title: "Berita Hari Ini Terbaru",
	// 		image_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		level: "local",
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempor et orci id commodo. Morbi sodales ex lacus, at convallis odio dictum vel. Nunc at fringilla mauris. Proin eget ultricies mauris. Vestibulum aliquam, sapien in pretium dapibus, purus magna ornare ante, ut cursus metus sapien sit amet ex. Suspendisse fringilla eros sit amet tortor consectetur, nec dictum risus pulvinar. Quisque efficitur eget odio et placerat. Quisque ante tortor, varius nec aliquet a, suscipit at ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non odio metus. Integer imperdiet tristique augue quis dictum. Cras vel feugiat ante, eu consectetur diam.",
	// 		total_comment: 20,
	// 		total_view: 20,
	// 	},
	// 	{
	// 		photo_author_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		author: "Teguh",
	// 		workunit: "Pimpinan",
	// 		title: "Berita Hari Ini Terbaru",
	// 		image_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		level: "local",
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempor et orci id commodo. Morbi sodales ex lacus, at convallis odio dictum vel. Nunc at fringilla mauris. Proin eget ultricies mauris. Vestibulum aliquam, sapien in pretium dapibus, purus magna ornare ante, ut cursus metus sapien sit amet ex. Suspendisse fringilla eros sit amet tortor consectetur, nec dictum risus pulvinar. Quisque efficitur eget odio et placerat. Quisque ante tortor, varius nec aliquet a, suscipit at ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non odio metus. Integer imperdiet tristique augue quis dictum. Cras vel feugiat ante, eu consectetur diam.",
	// 		total_comment: 20,
	// 		total_view: 20,
	// 	},
	// 	{
	// 		photo_author_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		author: "Teguh",
	// 		workunit: "Pimpinan",
	// 		title: "Berita Hari Ini Terbaru",
	// 		image_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		level: "local",
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempor et orci id commodo. Morbi sodales ex lacus, at convallis odio dictum vel. Nunc at fringilla mauris. Proin eget ultricies mauris. Vestibulum aliquam, sapien in pretium dapibus, purus magna ornare ante, ut cursus metus sapien sit amet ex. Suspendisse fringilla eros sit amet tortor consectetur, nec dictum risus pulvinar. Quisque efficitur eget odio et placerat. Quisque ante tortor, varius nec aliquet a, suscipit at ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non odio metus. Integer imperdiet tristique augue quis dictum. Cras vel feugiat ante, eu consectetur diam.",
	// 		total_comment: 20,
	// 		total_view: 20,
	// 	},
	// 	{
	// 		photo_author_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		author: "Teguh",
	// 		workunit: "Pimpinan",
	// 		title: "Berita Hari Ini Terbaru",
	// 		image_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		level: "local",
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempor et orci id commodo. Morbi sodales ex lacus, at convallis odio dictum vel. Nunc at fringilla mauris. Proin eget ultricies mauris. Vestibulum aliquam, sapien in pretium dapibus, purus magna ornare ante, ut cursus metus sapien sit amet ex. Suspendisse fringilla eros sit amet tortor consectetur, nec dictum risus pulvinar. Quisque efficitur eget odio et placerat. Quisque ante tortor, varius nec aliquet a, suscipit at ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non odio metus. Integer imperdiet tristique augue quis dictum. Cras vel feugiat ante, eu consectetur diam.",
	// 		total_comment: 20,
	// 		total_view: 20,
	// 	},
	// 	{
	// 		photo_author_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		author: "Teguh",
	// 		workunit: "Pimpinan",
	// 		title: "Berita Hari Ini Terbaru",
	// 		image_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		level: "local",
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempor et orci id commodo. Morbi sodales ex lacus, at convallis odio dictum vel. Nunc at fringilla mauris. Proin eget ultricies mauris. Vestibulum aliquam, sapien in pretium dapibus, purus magna ornare ante, ut cursus metus sapien sit amet ex. Suspendisse fringilla eros sit amet tortor consectetur, nec dictum risus pulvinar. Quisque efficitur eget odio et placerat. Quisque ante tortor, varius nec aliquet a, suscipit at ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non odio metus. Integer imperdiet tristique augue quis dictum. Cras vel feugiat ante, eu consectetur diam.",
	// 		total_comment: 20,
	// 		total_view: 20,
	// 	},
	// 	{
	// 		photo_author_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		author: "Teguh",
	// 		workunit: "Pimpinan",
	// 		title: "Berita Hari Ini Terbaru",
	// 		image_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		level: "local",
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempor et orci id commodo. Morbi sodales ex lacus, at convallis odio dictum vel. Nunc at fringilla mauris. Proin eget ultricies mauris. Vestibulum aliquam, sapien in pretium dapibus, purus magna ornare ante, ut cursus metus sapien sit amet ex. Suspendisse fringilla eros sit amet tortor consectetur, nec dictum risus pulvinar. Quisque efficitur eget odio et placerat. Quisque ante tortor, varius nec aliquet a, suscipit at ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non odio metus. Integer imperdiet tristique augue quis dictum. Cras vel feugiat ante, eu consectetur diam.",
	// 		total_comment: 20,
	// 		total_view: 20,
	// 	},
	// 	{
	// 		photo_author_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		author: "Teguh",
	// 		workunit: "Pimpinan",
	// 		title: "Berita Hari Ini Terbaru",
	// 		image_url: "https://images.unsplash.com/photo-1532781914607-2031eca2f00d?ixlib=rb-0.3.5&amp;q=80&amp;fm=jpg&amp;crop=entropy&amp;cs=tinysrgb&amp;w=1080&amp;fit=max&amp;ixid=eyJhcHBfaWQiOjMyMDc0fQ&amp;s=7c625ea379640da3ef2e24f20df7ce8d",
	// 		level: "local",
	// 		description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis tempor et orci id commodo. Morbi sodales ex lacus, at convallis odio dictum vel. Nunc at fringilla mauris. Proin eget ultricies mauris. Vestibulum aliquam, sapien in pretium dapibus, purus magna ornare ante, ut cursus metus sapien sit amet ex. Suspendisse fringilla eros sit amet tortor consectetur, nec dictum risus pulvinar. Quisque efficitur eget odio et placerat. Quisque ante tortor, varius nec aliquet a, suscipit at ligula. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent non odio metus. Integer imperdiet tristique augue quis dictum. Cras vel feugiat ante, eu consectetur diam.",
	// 		total_comment: 20,
	// 		total_view: 20,
	// 	},
	// ]
	const carouselRef = useRef()
	const breakPoints = [
		{ width: 550, itemsToShow: 2, itemsToScroll: 2},
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 5 },
        { width: 1750, itemsToShow: 6 },
	]
	let resetTimeout
	return (
		<BoundWidget
			loading={loading}
			data={data}
			failed={failed}
			onReload={getSource}
		>
			{data && (
				// <Row xs={12} style={{ flexWrap: "nowrap", overflowX: "overlay" }}>
				// 	{data.map(item => (
				// 		<Col key={"news_item_" + item.id} sx={3} className="p-1">
				// 			<NewsItem data={item}></NewsItem>
				// 		</Col>
				// 	))}
				// </Row>
				<div style={{width: "100%"}}>
					<div className="d-flex gap-1 justify-content-end ">
					<Button color="primary" size="sm"><i className="ri-arrow-left-s-line" onClick={() => carouselRef.current.slidePrev()}></i></Button>
					<Button color="primary" size="sm"><i className="ri-arrow-right-s-line" onClick={() => carouselRef.current.slideNext()}></i></Button>
					</div>
					<Carousel ref={carouselRef} itemsToScroll={3} itemsToShow={3} autoPlaySpeed={3000} enableAutoPlay={true} pagination={false} showArrows={false} breakPoints={breakPoints} onChange={(index) => {
							console.log(index)
							console.log(data.length)
						}}>
						{data.map((item, idx) => (
							<Col key={"news_item_" + idx} sx={3} className="p-1">
								<NewsItem data={item}></NewsItem>
							</Col>
						))}
					</Carousel>

				</div>



			)}
		</BoundWidget>
	);
};


export default News