import { Button, Card, Spinner } from "reactstrap";

const Header = ({ toDashboard, toggleOpenModalSave, queue, dashboards }) => {
  return (
    <Card className="p-3 mb-0">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="fs-5 fw-semibold lh-base mb-0">Tambah Grafik Baru</h1>
        <div className="d-flex align-content-center" style={{ gap: "8px" }}>
          <Button
            size="sm"
            color="primary"
            outline
            onClick={() => toDashboard()}
          >
            Batal
          </Button>
          <Button
            size="sm"
            color="primary"
            disabled={!queue.length || dashboards.isUpdate}
            onClick={toggleOpenModalSave}
          >
            {dashboards.isUpdate ? (
              <div className="d-flex gap-2 w-100 justify-content-center">
                <Spinner size="sm">Loading...</Spinner>
                <span>Memuat</span>
              </div>
            ) : (
              <span>Simpan</span>
            )}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default Header;
