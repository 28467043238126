import React, { useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Label,
  Spinner,
} from "reactstrap";

import html2canvas from "html2canvas";
import * as XLSX from "xlsx";
import CustomToast from "../CustomToast";
import ExportTable from "../export-table";
import ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getWidget } from "../../../helpers/widget_helper";
import ExportTablePDF from "../export-table/export-table-pdf";
import jsPDF from "jspdf";
import ExportTableExcel from "../export-table/export-table-excel";
import "jspdf-autotable";
import { autoTable } from "jspdf-autotable";
import html2pdf from "html2pdf.js/dist/html2pdf.min";

const Export = ({ idContent }) => {
  const [modal, setModal] = useState(false);
  const dataChart = useRef([]);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setTitle(null);
  };

  const dashboards = useSelector((state) => state.Dashboards);

  const [title, setTitle] = useState(null)

  // tipe file
  const [tipeFile, setTipeFile] = useState(null);
  const handleTipeFile = (event) => {
    setTipeFile(event.target.value);
  };

  // ukuran file
	const [ukuran, setUkuran] = useState(null);
	const handleUkuran = event => {
		setUkuran(event.target.value);
	};


  const handleExportPDF = async () => {
    setLoading(true);
    let urls = [];

    dashboards.current.widget.map(function (data, index) {
      urls.push(getWidget(data));
    });

    axios
      .all(urls)
      .then((res) => {
        dataChart.current = res;

        const pdfString = ReactDOMServer.renderToString(
          <ExportTablePDF dashboards={dashboards} data={dataChart.current} />
        );

        let size = null;
        let margin = null
          if (ukuran === "a4") {
            size = [210, 297];
            margin = [20, 30, 30, 30]
          } else if (ukuran === "letter") {
            size = [215.9, 297];
            margin = [20, 30, 30, 0]
          } else if (ukuran === "legal") {
            size = [215.9, 355.6];
            margin = [20, 30, 30, 10]
          }


        html2pdf()
          .from(pdfString)
          .set({
            jsPDF:        { format:size},
            pageSplit: true,
            margin: margin,
          })
          .toPdf()
          .get("pdf")
          .save(title)
          .then(function (pdf) {
            // window.open(pdf.output("bloburl"), "_blank");
            setLoading(false);
            setModal(!modal);
            setTitle("")
          });
      })
      .catch((err) => console.log(err));
  };

  const handleExportExcel = () => {
    var wb = XLSX.utils.table_to_book(document.getElementById("table-to-xls"));
    var nameFile = title ?? "sample";
    XLSX.writeFile(wb, nameFile + ".xlsx", {
      bookType: "xlsx",
      type: "buffer",
    });
    return false;
  };

  const handleSubmit = async () => {
    try {
      //   CustomToast("success", "Sedang Mengexport!");
      if (tipeFile === "pdf") {
        await handleExportPDF();
      } else if (tipeFile === "excel") {
        await handleExportExcel();
      }

      //
      //   setModal(!modal);
    } catch (error) {
      console.log(error);
      CustomToast("danger", "Dashboard gagal diexport!");
    }
  };

  const handleModalClose = ()=> {
    setTitle(null)
    setTipeFile(null)
    setUkuran(null)
  }

  return (
    <>
      <span
        className="mdi mdi-arrow-top-right-bold-box-outline fs-4 px-2 cursor-pointer"
        onClick={toggle}
      ></span>
      <Modal isOpen={modal} toggle={toggle} onClosed={handleModalClose}>
        <ModalHeader toggle={toggle}>Export Dashboard</ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Label for="tipeFile">Judul</Label>
            <Input
              id="tipeFile"
              name="tipeFile"
              type="text"
              placeholder="Masukkan Judul"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="mb-3">
            <Label for="tipeFile">Tipe File</Label>
            <Input
              id="tipeFile"
              name="tipeFile"
              type="select"
              onChange={handleTipeFile}
            >
              <option disabled selected hidden>
                Pilih Tipe File
              </option>
              <option value="pdf">Export ke PDF</option>
              <option value="excel">Export ke Excel</option>
            </Input>
          </div>

          {/* {tipeFile == "excel" && (
            <div className="mb-3">
              <Label for="judul">Nama File</Label>
              <Input
                id="judul"
                name="judul"
                placeholder="Masukkan Judul"
                type="text"
                value={judul}
                onChange={(e) => setJudul(e.target.value)}
              />
            </div>
          )} */}

          {tipeFile == "pdf" && (
            <div className="mb-3">
            <Label for="ukuran">Ukuran</Label>
            <Input
              id="ukuran"
              name="tipeFile"
              type="select"
              onChange={handleUkuran}
            >
              <option disabled selected hidden>
									Pilih Ukuran
								</option>
								<option value="letter">US Letter (21,59 cm x 27,94 cm)</option>
								<option value="legal">US Legal (21,59 cm x 35,56 cm)</option>
								<option value="a4">A4 (20,99 cm x 29,7 cm)</option>
            </Input>
          </div>
          )}

          <ExportTableExcel dashboards={dashboards} data={dataChart} />
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          {!loading ? (
            <>
              <Button color="secondary" outline onClick={toggle}>
                Batal
              </Button>

              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={tipeFile === null || title === null || (tipeFile === "pdf" && ukuran === null)}
              >
                Export
              </Button>
            </>
          ) : (
            <Spinner size="md" color="#fff" />
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Export;
