import classNames from "classnames";
import { useEffect, useState } from "react";
import { getWidget } from "../../../../helpers/widget_helper";
import "../index.scss";

const ExportTableGIS = ({ source, name, data, type }) => {
  const [datas, setDatas] = useState(data ?? null);
  useEffect(() => {
    if (!data) {
      getWidget({ source }).then((res) => {
        setDatas(res);
      });
    }
  }, []);

  return (
    <div
      className={classNames({
        "d-flex justify-content-center": !data,
      })}
    >
    {datas?.length > 0 && (
      <table
        className={classNames({
          "table table-hover table-striped table-bordered w-75 mb-4": !data,
          "table-pdf": data,
        })}
      >
        <thead>
          {
            (type == "excel" && (
              <tr>
                <th className="fw-bold text-center" colSpan={3}>
                  &nbsp;
                </th>
              </tr>
            ))
          }
          <tr>
            <th className="fw-bold text-center" colSpan={3}>
              {name}
            </th>
          </tr>
          <tr>
            <th className="fw-bold text-center">No</th>
            <th className="fw-bold text-center">Data</th>
            <th className="fw-bold text-center">Jumlah</th>
          </tr>
        </thead>

        <tbody>
          {datas &&
            datas.map(function (item, i) {
              return (
                <tr key={i}>
                  <td className="text-center fw-bold">{i + 1}</td>
                  <td className="text-center">{item.label}</td>
                  <td className="text-center">{item.value}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    )}
    </div>
  );
};

export default ExportTableGIS;
