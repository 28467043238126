import { createSlice } from "@reduxjs/toolkit";
import {
  addGroupMember,
  getMsgByRoom,
  getRoomArchive,
  getRoomChat,
  markAsRead,
  markAsUnread,
  removeGroupMember,
  setAdminGroup,
  uploadAttachment,
} from "./thunk";
import { WEBSOCKET_SIACC } from "../../helpers/url_helper";
import user_info from "../../helpers/user_info";

export const initialState = {
  chatBoxOpen: false,
  roomList: [],
  roomArchiveList: [],
  chatActive: null,
  showGroupDetail: false,
  chatSocket: null,
  isLoading: false,
  attachmentId: "",
};

const ChatSlice = createSlice({
  name: "chat",
  initialState: initialState,
  reducers: {
    clearChatActive: (state, { payload }) => {
      state.chatActive = null;
    },
    openChatBox: (state, { payload }) => {
      if (!payload) {
        state.chatActive = null;
      }
      state.chatBoxOpen = payload;
    },
    setShowGroupDetail: (state, { payload }) => {
      state.showGroupDetail = !state.showGroupDetail;
    },

    appendNewChat: (state, { payload }) => {
      state.chatActive = payload;
    },
    setArchiveRoom: (state, { payload }) => {
      state.roomArchiveList = payload;
    },
    setRoomList: (state, { payload }) => {
      state.roomList = payload;
    },
    setChatActive: (state, { payload }) => {
      state.chatActive = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoomChat.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.roomList = payload;
      })
      .addCase(getRoomArchive.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.roomArchiveList = payload;
      })
      .addCase(getMsgByRoom.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.chatActive = payload;
        state.isLoading = false;
      })
      .addCase(getMsgByRoom.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(markAsUnread.fulfilled, (state, { payload }) => {
        return;
      })
      .addCase(markAsRead.fulfilled, (state, { payload }) => {
        return;
      })
      .addCase(setAdminGroup.fulfilled, (state, { payload }) => {
        state.chatActive = payload;
      })
      .addCase(removeGroupMember.fulfilled, (state, { payload }) => {
        state.chatActive = payload;
      })
      .addCase(addGroupMember.fulfilled, (state, { payload }) => {
        state.chatActive = payload;
      });
  },
});

export const {
  openChatBox,
  setShowGroupDetail,
  connectChatSocket,
  appendNewChat,
  clearChatActive,
  attachmentId,
  setArchiveRoom,
  setRoomList,
  setChatActive,
} = ChatSlice.actions;
export default ChatSlice.reducer;
