import { createSlice } from "@reduxjs/toolkit";
import { getEmployeBiodata } from "./thunk";

export const initialState = {
	message: null,
	loading: true,
	data: null,
	success: false,
};

const employeSlice = createSlice({
	name: "Employee Biodata",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getEmployeBiodata.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					state.data = payload;
					state.success = true;
					state.loading = false;
					state.message = "Sukses Menampilkan Data";
				} else {
					state.data = null;
					state.success = false;
					state.loading = false;
					state.message = "Gagal Menampilkan Data";
				}
			});
	},
});


export default employeSlice.reducer;
