import { Button, Spinner } from "reactstrap";
import imageEmpty from "../../assets/images/table/box.png"

function BoundWidget(props) {
  return (
    <>
      {props.loading && (
        <div
          className="d-flex gap-2 w-100 justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Spinner>Loading...</Spinner>
        </div>
      )}

      {props.failed && (
        <div className="d-flex justify-content-center align-items-center flex-column" style={{ height:"400px"}}>
          <img src={imageEmpty} width="120px" className="mb-1" />
          <h4 className="fw-bolder">Data Tidak Ditemukan</h4>
        </div>
      )}
      {props.children}
    </>
  );
}

export default BoundWidget;
