import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import "./index.scss";

const LandingPage = () => {
  const navigate = useNavigate();
  const announcement = useSelector((state) => state.Announcement);
  
  useEffect(() => {
    // console.log(announcement.landingPageData.attachments[0].url);
  }, []);

  return (
    <div className="wrapper-landing-page">
      <div className="w-100">
        <div className="d-flex justfify-content-between align-items-center h-10vh p-2">
          <div className="w-90">
            <h1 className="text-center">Informasi Pusat</h1>
          </div>
          <div
            className="cursor-pointer d-flex justify-content-end w-10"
            onClick={() => {
              Cookies.set("landingpage", true);
              navigate("/");
            }}
          >
            <i className="mdi mdi-close fs-1"></i>
          </div>
        </div>
        <div className="d-flex justify-content-center h-90vh p-5">
          {announcement && (
            <img src={announcement.landingPageData.attachments[0].url}></img>
          )}  
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
