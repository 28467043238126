

export const PERIODS = [
    {
      name  : "Mingguan",
      value : "weekly",
    },
    {
      name  : "Bulanan",
      value : "monthly",
    },
    {
      name  : "Semester",
      value : "semester",
    },
    {
      name  : "Tahunan",
      value : "yearly",
    },
    {
      name  : "Custom",
      value : "custom",
    },
    {
      name  : "Harian",
      value : "daily",
    },
  ];

export default PERIODS