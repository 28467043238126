import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  TabPane,
  TabContent,
  FormGroup,
  Label,
  Input,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Badge,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { clearState, removeEndpoint, removeWidget, setEndpoint, setPeriode, setSelectedWidget, setSource, setTabName } from "../../slices/widget/reducer";
import {
  addDashboardWidget,
  getDashboards,
  getDashboardsWidget,
  getDataSources,
  getMenuDashboards,
  getWidgetsByDatatype,
} from "../../slices/thunks";
import { selectWidgetSource } from "../../helpers/widget_helper";

import Widget from "../../Components/Common/Widget";
import moment from "moment/moment";
import ModalSave from "./modalSave";
import WIDGETS from "./widgetData";
import PERIODS from "./periodData";
import Header from "./header";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

// styles
import "./index.css";
import SimpleBar from "simplebar-react";
import { setDashboard } from "../../slices/dashboards/reducer";

function AddWidget(props) {
  // global states
  const dashboards = useSelector((state) => state.Dashboards);
  const stateWidget = useSelector((state) => state.Widget);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()

  // states
  const [tab, setTab] = useState(1);
  const [openDataSource, setOpenDataSource] = useState("");
  const [openModalSave, setOpenModalSave] = useState(false);
  const [dataModel, setDataModel] = useState();
  const [widget, setWidget] = useState({});
  const [widgets, setWidgets] = useState([]);
  const [layouts, setLayouts] = useState([]);
  const [period, setPeriod] = useState();
  const [periods, setPeriods] = useState(PERIODS);
  const [title, setTitle] = useState("");
  const [preview, setPreview] = useState();
  const [queue, setQueue] = useState([]);
  const [layoutQueue, setLayoutQueue] = useState([])

  // use effect
  // useEffect(() => {
  //   if (dashboards.isInitial) {
  //     dispatch(getDashboards());
  //   }
  // }, [dashboards.isInitial]);

  // initial get data state datasource (sumber data)
  useEffect(() => {
    dispatch(getDataSources());
    dispatch(clearState())
  }, []);

  useEffect(() => {
    if(searchParams.get("workunit_level")){
      console.log(searchParams.get("workunit_level"))
      if (dashboards.isInitial || dashboards.isUpdate || searchParams.get("workunit_level")) {
        fetchData();
      }
    }
   
  }, [dashboards.isInitial, dashboards.isUpdate, searchParams.get("workunit_level")]);

  // useEffect(() => {
  //   if(searchParams.get("dashboard")){
  //     // dispatch(setDashboard(searchParams.get("dashboard")))
  //     console.log(dashboards)
  //   }
  // })

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

  const fetchData = useCallback(async () => {
    dispatch(getMenuDashboards());
    await sleep(2000);
    dispatch(
      getMenuDashboards({ default: true, workunitLevel: searchParams.get("workunit_level"), currentPathDefault: searchParams.get("dashboard") })
    );
  });

  useEffect(() => {
    if (dashboards.isInitial || dashboards.isUpdate) {
      dispatch(getMenuDashboards());
    }
  }, [dashboards.isInitial, dashboards.isUpdate]);

  useEffect(() => {
    if (stateWidget.dataSources.length && !openDataSource) {
      toggleOpenDataSource("1");
    }
  }, [stateWidget.dataSources]);

  useEffect(() => {
    if (dataModel == []) return
    if (stateWidget.endpoint) {

      const periods = [];
      if (dataModel?.is_weekly == 1) {
        periods.push(PERIODS[0]);
      }
      if (dataModel?.is_monthly == 1) {
        periods.push(PERIODS[1]);
      }
      if (dataModel?.is_semester == 1) {
        periods.push(PERIODS[2]);
      }
      if (dataModel?.is_yearly == 1) {
        periods.push(PERIODS[3]);
      }
      if (dataModel?.is_custom == 1) {
        periods.push(PERIODS[4]);
      }

      if (dataModel?.is_daily == 1) {
        periods.push(PERIODS[5])
      }

      if (periods.length === 0) {
        periods.push(PERIODS[3]);
      }

      dispatch(getWidgetsByDatatype({ id: dataModel?.datatype_group.id, eid: dataModel?.id, name: dataModel?.name, periods }));
      // })

    }
  }, [stateWidget.endpoint]);

  // end use effect

  // open modal save
  function toggleOpenModalSave() {
    setOpenModalSave(!openModalSave);
  }

  function toggleOpenDataSource(id) {
    if (openDataSource == id) {
      setOpenDataSource("");
    } else {
      setOpenDataSource(id);
      dispatch(setSource(stateWidget.dataSources[id - 1].id));
      setDataModel();
      setWidget();
      setTitle("");
      setPreview();
    }
  }
  const [dataModels, setDataModels] = useState([])
  function handleDataModel(e, value) {
    if (e.target.checked) {
      setDataModel(value);
      setDataModels([...dataModels, value]);
      dispatch(setEndpoint(value.id));
      if (!periods.length) {
        setPeriod({
          name: "Tahunan",
          value: "yearly",
        });
      }
    } else {
      setDataModel();
      setDataModels(dataModels.filter(model => model.id !== value.id))
      dispatch(removeEndpoint(value.id))
      dispatch(removeWidget(value.id))
      setPeriod();
    }
    setWidget();
    setTitle("");

    setPreview();
  }

  function changeWidget(type, endpoint_id, widget) {
    dispatch(setSelectedWidget({ endpoint_id, widget }))
  }

  function changePeriod(endpoint_id, period) {
    // console.log(endpoint_id,value);
    dispatch(setPeriode({ endpoint_id, period }))
  }

  function checkForm(){
    let validate = []
    stateWidget.selectedWidget.map(wdg => {
      if(!Object.keys(wdg).includes("type") || !Object.keys(wdg).includes("period")){
        validate.push("pass")
      }
    })
    return (validate.length === 0) ? true : false
  }

  function handleCreate() {    
    setQueue([createWidget(), ...queue]);
    const widgets = [];
    const layouts = [];
    for (const widget of [createWidget(), ...queue]) {
      widgets.push(widget);
      layouts.push(createLayout(widget, widgets));
    }

    setWidgets(widgets)
    setLayouts(layouts)

    setTab(1);
    setDataModel();
    setDataModels([]);
    setWidget();
    setTitle("");
    setPeriod();
    setPreview();
    dispatch(clearState())
  }

  // saving data after previewed
  function handleSave() {
    console.log(dashboards)
    dispatch(
      addDashboardWidget({
        widget: widgets,
        layout: layouts,
      })
    ).then((res) => {
      if(dashboards.isDefault){
        return navigate("/management-default-dashboard")
      }
      toDashboard(dashboards.currentPath);
    });
  }

  function queueShift(value) {
    setQueue(oldQueue => {
      return oldQueue.filter(item => item.key !== value.key)
    })
  }

  // function for redirect to dashboard page
  function toDashboard(path = "/") {
    navigate(path);
  }

  function createWidget() {
    let tabs = []
    for (let i = 1; i < stateWidget.widgets.length; i++) {
      tabs.push({
        tab_name: stateWidget.widgets[i].tab_name || "Tab" + (i + 1),
        endpoint_id: stateWidget.selectedWidget[i].endpoint_id,
        type: stateWidget.selectedWidget[i].type,
        periods: stateWidget.widgets[i]?.periods?.map(period => period.value),
        source: selectWidgetSource({
          datatype: stateWidget.endpoint[i].datatype_group.name,
          endpointId: stateWidget.endpoint[i].id,
          period: stateWidget.selectedWidget[i].period,
        })
      })
    }
    return {
      key: Math.random().toString(36).slice(2, 7),
      endpoint_id: stateWidget.selectedWidget[0].endpoint_id,
      type: stateWidget.selectedWidget[0].type,
      periods: stateWidget.widgets[0]?.periods?.map(period => period.value),
      source: selectWidgetSource({
        datatype: stateWidget.endpoint[0].datatype_group.name,
        endpointId: stateWidget.endpoint[0].id,
        period: stateWidget.selectedWidget[0].period,
      }),
      name: title,
      tab_name: stateWidget.widgets[0].tab_name || "Tab 1",
      tabs: tabs
    };
  }

  // crea
  function createLayout(widget, widgets) {
    // let w = stateWidget.widgetSource.find(function (el) {
    //   return el.name === widget.type
    // })
    return {
      i: widget.key,
      x: (dashboards.current.layout.length + widgets.length) % 2 ? 0 : stateWidget?.selectedWidget[0]?.minW,
      y: (dashboards.current.layout.length + widgets.length * 100),
      w: stateWidget.selectedWidget[0]?.minW,
      h: stateWidget.selectedWidget[0]?.minH,
      minH: stateWidget.selectedWidget[0]?.minH,
      minW: stateWidget.selectedWidget[0]?.minW
    };
  }

  const [widgetTab, setWidgetTab] = useState(1)
  
  return (
    <>
    {/* {props?.isDefault && (
      <Navbar headerClass={"topbar"} isChatLayout={true} />
    )} */}
    <Container fluid className="page-content">
      <Row className="g-4">
        <Col xs={12}>
          {/* Header card for title page and submit button */}
          <Header
            dashboards={dashboards}
            queue={queue}
            toDashboard={toDashboard}
            toggleOpenModalSave={toggleOpenModalSave}
          />
        </Col>
        <Col xs={8}>
          {/* preview chart  */}
          <Row className="g-2">
            {[preview, ...queue]
              .filter((item) => item)
              .map((item) => (
                <Card key={"queue_" + item.key}>
                  <CardHeader style={{ paddingBottom: "0px" }}>
                    <div className="align-items-start d-flex">
                      <h4 className="card-title mb-0 flex-grow-1">{item.name}</h4>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => queueShift(item)}
                      >
                        Hapus
                      </button>
                    </div>
                    {item.tabs && (
                      <>
                        <PerfectScrollbar handlers={["drag-thumb"]}
                          className="d-flex align-content-center"
                          style={{ height: "50px", gap: "8px", padding: "7px" }}
                        >
                          <Badge
                            style={widgetTab === 1 ? { height: "max-content", fontSize: "1rem", cursor: "pointer" } : { height: "max-content", fontSize: "1rem", cursor: "pointer", color: "gray" }}
                            className="fw-semibold"
                            color={widgetTab != 1 ? "link" : "primary"}
                            outline={widgetTab != 1}
                            onClick={() => setWidgetTab(1)}
                          >
                            {item.tab_name}
                          </Badge>
                          {item.tabs.map((t, idx) => (
                            <Badge key={idx}
                              style={widgetTab === idx + 2 ? { height: "max-content", fontSize: "1rem", cursor: "pointer" } : { height: "max-content", fontSize: "1rem", cursor: "pointer", color: "gray" }}
                              className="fw-semibold"
                              color={widgetTab != idx + 2 ? "link" : "primary"}
                              outline={widgetTab != idx + 2}
                              onClick={() => setWidgetTab(idx + 2)}
                            >
                              {t.tab_name}
                            </Badge>
                          ))}
                        </PerfectScrollbar>
                      </>
                    )}
                  </CardHeader>
                  {/* <CardBody> */}
                  {/* {item.tab_name}
                    <Widget widget={item} /> */}
                  {item.tabs ? (
                    <>
                      <TabContent activeTab={widgetTab}>
                        <TabPane tabId={1}>
                          <SimpleBar style={{ height: "100%", overflow: "auto" }}>
                            <CardBody
                            // className={classNames({ "p-0": (item.type == "SIMPLE NEWS") })}
                            >
                              <div className="mx-n3">
                                <SimpleBar className="px-3">
                                  <Widget widget={item}></Widget>
                                </SimpleBar>
                              </div>
                            </CardBody>
                          </SimpleBar>
                        </TabPane>
                        {item.tabs?.map((w, i) => (
                          <TabPane tabId={i + 2}>
                            <SimpleBar style={{ height: "100%", overflow: "auto" }}>
                              <CardBody
                              // className={classNames({ "p-0": (widget.type == "SIMPLE NEWS") })}
                              >
                                <div className="mx-n3">
                                  <SimpleBar className="px-3">
                                    <Widget widget={w}></Widget>
                                  </SimpleBar>
                                </div>
                              </CardBody>
                            </SimpleBar>
                          </TabPane>
                        ))}
                      </TabContent>
                    </>
                  ) : (
                    <SimpleBar style={{ height: "100%", overflow: "auto" }}>
                      <CardBody className={classNames({ "p-0": (widget.type == "SIMPLE NEWS") })}>
                        <div className="mx-n3">
                          <SimpleBar className="px-3">
                            <Widget widget={widget}></Widget>
                          </SimpleBar>
                        </div>
                      </CardBody>
                    </SimpleBar>
                  )}
                  {/* </CardBody> */}
                </Card>
              ))}
          </Row>
        </Col>
        <Col xs={4}>
          <Card className="p-3">
            <Row className="g-2">
              <Col xs={12}>
                <div
                  className="d-flex align-content-center"
                  style={{ gap: "8px" }}
                >
                  <Button
                    className="fw-semibold rounded-pill"
                    color={tab != 1 ? "link" : "primary"}
                    outline={tab != 1}
                    onClick={() => setTab(1)}
                  >
                    Sumber Data
                  </Button>
                  <Button
                    className="fw-semibold rounded-pill"
                    color={tab != 2 ? "link" : "primary"}
                    outline={tab != 2}
                    onClick={() => setTab(2)}
                  >
                    Grafik
                  </Button>
                </div>
              </Col>
              <Col xs={12}>
                <TabContent activeTab={tab}>
                  <TabPane tabId={1}>
                    <Row className="g-2">
                      <Col xs={12}>
                        <Accordion
                          flush
                          open={openDataSource}
                          toggle={toggleOpenDataSource}
                        >
                          {stateWidget.dataSources.map((item, index) => (
                            <AccordionItem key={"dataSources_" + index}>
                              <AccordionHeader targetId={index + 1 + ""}>
                                {item.name}
                              </AccordionHeader>
                              <AccordionBody accordionId={index + 1 + ""}>
                                <Row>
                                  {item.endpoint.map((item, index) => (
                                    <Col key={"endpoint_" + index} xs={12}>
                                      <FormGroup check>
                                        <Input
                                          id={item.id}
                                          name={item.name}
                                          type="checkbox"
                                          onChange={(e) =>
                                            handleDataModel(e, item)
                                          }
                                          checked={dataModels?.map(data => { return data.id }).includes(item.id)}
                                        />
                                        <Label check for={item.id}>
                                          {item.name}
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  ))}
                                </Row>
                              </AccordionBody>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId={2}>
                    <Row className="g-2">
                      {
                        <Col xs={12}>
                          <Row className="g-6">
                            {stateWidget.widgets.map((item, idx) => (
                              <div key={item.endpoint_id} style={{ borderBottom: "solid 2px #dddddd", backgroundColor: "#f3f3f3", padding: "14px", borderRadius: "10px", marginBottom: "10px" }}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                  <Label size="lg">{item.endpoint_name}</Label>
                                  {stateWidget.widgets.length > 1 && (
                                    <span style={{ padding: "10px" }}>
                                      <Label>Tab Name</Label>
                                      <Input value={item.tab_name} onChange={(e) => dispatch(setTabName({ endpoint_id: item.endpoint_id, tab_name: e.target.value }))} />
                                    </span>
                                  )}
                                </div>
                                <Row style={{ padding: "10px" }}>
                                  {item.widgets.map((w, index) => (
                                    <Col
                                      key={"widgets_" + w.name}
                                      xs={5}
                                      md={4}
                                      xl={3}
                                      style={{
                                        display: "grid",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <>
                                        <img
                                          src={WIDGETS.find((i) => { return (w.name == i.type) })?.icon}
                                          alt={WIDGETS.find((i) => { return (w.name == i.type) })?.name}
                                          className={
                                            "widget w-100" +
                                            (WIDGETS.find((i) => { return (w.name == i.type) })?.type === stateWidget.selectedWidget[idx]?.type
                                              ? " active"
                                              : "")
                                          }
                                          style={{ height: "78px" }}
                                          onClick={() => changeWidget(WIDGETS.find((i) => { return (w.name == i.type) }).type, item.endpoint_id, w)}
                                        />
                                        <h5 className="fs-12 text-center mt-2">
                                          {WIDGETS.find((i) => { return (w.name == i.type) })?.name}
                                        </h5>
                                        {/* <span>{w.name}</span> */}
                                      </>
                                    </Col>
                                  ))}
                                  <FormGroup tag="fieldset">
                                    {item.periods.length ? (
                                      <Label for="period">Perode Grafik</Label>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.periods.map((p, key) => (
                                      <FormGroup key={key} check>
                                        <Input
                                          id={p?.name + idx}
                                          name={p?.name + idx}
                                          type="radio"
                                          checked={p.value == stateWidget.selectedWidget[idx]?.period?.value}
                                          // disabled={!dataModel}
                                          onChange={(e) => changePeriod(item.endpoint_id, p)}
                                        />
                                        <Label
                                          className="fw-normal"
                                          check
                                          for={p?.name + idx}
                                        >
                                          {p?.name}
                                        </Label>
                                      </FormGroup>
                                    ))}
                                  </FormGroup>
                                </Row>
                              </div>
                            ))}
                          </Row>
                        </Col>
                      }
                      {
                        <>
                          <Col xs={12}>
                            <FormGroup className="">
                              <Label for="title">Judul</Label>
                              <Input
                                id="title"
                                name="title"
                                value={title}
                                placeholder="masukkan judul grafik"
                                onChange={(e) => setTitle(e.target.value)}
                              // disabled={!dataModel}
                              />
                            </FormGroup>

                            <Button
                              color="primary"
                              disabled={!checkForm() || !title}
                              onClick={handleCreate}
                            >
                              Tambah
                            </Button>
                          </Col>
                        </>
                      }
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* modal confirm save create widgets */}
      <ModalSave
        handleSave={handleSave}
        openModalSave={openModalSave}
        toggleOpenModalSave={toggleOpenModalSave}
      />
    </Container>
    </>
  );
}

export default AddWidget;
