import { APIClient } from "../../helpers/api_helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHAT_BASE, LANDINGPAGE_SIACC } from "../../helpers/url_helper";

const api = new APIClient();

export const getAnnouncement = createAsyncThunk(
  "landing-page/get",
  async () => {
    try {
      const response = await api.get(`${LANDINGPAGE_SIACC}`);

      return response.data.result;
    } catch (error) {
      return null;
    }
  }
);
