import { Col, Row } from "reactstrap";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import NewsItem from "./news-item";


const ListNews = ({ source, datas }) => {
	let { loading, data, failed, getSource } = useGetWidget({ source });
	if(datas?.length > 0){
		data = datas
		failed = false
	}
	return (
		<BoundWidget
			loading={loading}
			data={data}
			failed={failed}
			onReload={getSource}
		>
			{data && (
				<Row xs={12} >
					{data.map(item => (
                        <>
						<Col key={"news_item_" + item.id} sx={1} className="p-1">
							<NewsItem data={item}></NewsItem>
						</Col>
                        </>
					))}

				</Row>
			)}
		</BoundWidget>
	);
};


export default ListNews