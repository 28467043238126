import { useEffect }                            from "react";
import Widget                                   from "./Widget";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const WidgetPreview = (props) => {

  return (
    <Row>
      {props.widgetList.map((widget, idx) => (
        <Col sm={6} key={idx}>
          <Card>
            <CardHeader className="align-items-start d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{widget.name}</h4>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => props.removeWidgetPreview(widget.id)}
              >
                Remove
              </button>
            </CardHeader>
            <CardBody>
              <Widget widget={widget} />
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default WidgetPreview;
