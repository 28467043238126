import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import avatar2 from "../../assets/images/default-user.png";
import { useDispatch, useSelector } from "react-redux";
import { openChatBox, setShowGroupDetail } from "../../slices/chat/reducer";
import styles from "./ChatBox.module.css";
import {
  getMsgByRoom,
  getRoomArchive,
  getRoomChat,
  markAsRead,
} from "../../slices/thunks";
import DateTimeFormat from "../../helpers/time_parser";
import ChatArchive from "./ChatArchive";
import SearchInput from "../../Components/Common/search-input";
import MoreButton from "./MoreButton";
import set_profile, { img_error } from "../../helpers/default_profile";

const ChatList = (props) => {
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.Chat);
  useEffect(() => {
    dispatch(getRoomChat());
  }, []);

  const handleClick = (id, e) => {
    if (props.isShare) return;
    dispatch(markAsRead({ id }));

    dispatch(getRoomChat());
    dispatch(getMsgByRoom(id));
    props.closeDropdown(false);
    dispatch(openChatBox(true));
  };

  const [showLeft, setShowLeft] = useState(false);

  const toggleShowLeft = () => {
    setShowLeft(!showLeft);
  };

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchSubmit = (searchText) => {
    if (searchText.trim() === "") {
      setIsSearching(false);
      setSearchResults([]);
    } else {
      const filteredResults = chat.roomList.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setIsSearching(true);
      setSearchResults(filteredResults);
    }
  };

  return (
    <Fragment>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <ChatArchive
          show={showLeft}
          setShowLeft={setShowLeft}
          height="height: calc(100vh - 306px) !important"
          classList="chat-list"
        />
        <Row className="align-items-center">
          <Col>
            {!props.isShare && <h4 className="m-0 fs-20"> Komunikasi </h4>}
          </Col>
          <div
            className="col-auto"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            {props.isDropdown && (
              <>
                <Link to="/chat">
                  <i className={` ri-fullscreen-fill fs-20 me-1`}></i>
                </Link>
                <UncontrolledDropdown className="align-self-center message-box-drop">
                  <DropdownToggle tag="a" role="button">
                    <i className=" ri-edit-box-line fs-20"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => props.personal()}>
                      Komunikasi Pribadi
                    </DropdownItem>
                    <DropdownItem onClick={() => props.group()}>
                      Komunikasi Group
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )}

            {props.isFull && (
              <>
                <Link to="/" onClick={() => dispatch(openChatBox(false))}>
                  <i className={` ri-home-4-line fs-20 me-1`}></i>
                </Link>
                <Link onClick={() => toggleShowLeft()}>
                  <i className=" ri-archive-line fs-20 me-1" />
                </Link>
                <UncontrolledDropdown className="align-self-center message-box-drop">
                  <DropdownToggle tag="a" role="button">
                    <i className=" ri-edit-box-line fs-20"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => props.personal()}>
                      Komunikasi Pribadi
                    </DropdownItem>
                    <DropdownItem onClick={() => props.group()}>
                      Komunikasi Group
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )}

            {props.isPopup && (
              <i
                className={`ri-close-line fs-20 me-1`}
                role="button"
                onClick={() => dispatch(openChatBox(false))}
              ></i>
            )}
          </div>
        </Row>
        <SearchInput submit={(searchText) => handleSearchSubmit(searchText)} />
        <SimpleBar
          style={{ height: props.height }}
          className={`${styles[props.classList]} pe-2 mt-3`}
        >
          {(isSearching ? searchResults : chat.roomList).map((items, index) => (
            <div
              key={index}
              className={`
                            text-reset 
                            notification-item 
                            d-block dropdown-item 
                            position-relative mt-2 
                            ${
                              chat.chatActive?.id === items.id &&
                              styles.chat_active
                            }
                            ${
                              !props.isShare &&
                              (items.is_un_read ||
                                items.un_read_message !== 0) &&
                              styles.is_unread
                            }
                        `}
              onClick={(e) => handleClick(items.id, e)}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={set_profile(items.avatar)}
                    onError={(e) => img_error(e)}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-1">
                    <Link to="#" className="stretched-link">
                      <h6
                        className={`${
                          items.is_un_read || items.un_read_message !== 0
                            ? "fw-bold"
                            : "fw-semibold"
                        } mt-0 mb-1 fs-13`}
                      >
                        {items.name}
                      </h6>
                    </Link>
                    <div
                      className={`${
                        items.is_un_read || items.un_read_message !== 0
                          ? "fw-bold"
                          : "fw-semibold"
                      } fs-13 text-muted`}
                    >
                      {!props.isShare && (
                        <p className="mb-1">
                          {items.last_message.substring(0, 15)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  {!props.isShare && (
                    <div className="d-flex flex-column align-items-end">
                      <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                        <span>{DateTimeFormat.getTimeAgo(items.time)}</span>
                      </p>
                      {items.un_read_message !== 0 && (
                        <Badge color="success">{items.un_read_message}</Badge>
                      )}
                    </div>
                  )}
                  {props.isFull && (
                    <MoreButton type={items.type} items={items} />
                  )}
                  {props.isShare && (
                    <Input
                      type="checkbox"
                      name={items.id}
                      className="ms-auto align-self-center p-2 me-2"
                      onChange={(e) => props.checkRoom(items.id, e)}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </SimpleBar>
      </div>
    </Fragment>
  );
};

export default ChatList;
