import { createAsyncThunk } from "@reduxjs/toolkit"

import { APIClient } from "../../helpers/api_helper"
import { DATA_COLLECTOR_BASE, WIDGET_SYNC_BASE } from "../../helpers/url_helper"

const api = new APIClient()

export const getDataSources = createAsyncThunk(
	"widget/datasource",
	async () => {
		try {
			const response = await api.get(`${DATA_COLLECTOR_BASE}/datasource/`)
			return response.data
		} catch (error) {
			return null;
		}
	}
)

export const getWidgetsByDatatype = createAsyncThunk(
	"widget/getByDatatype",
	async (payload) => {
		if (payload.eid === undefined) return
		try {
			const response = await api.get(
				`${DATA_COLLECTOR_BASE}/widget/${payload.id}/datatype-group?eid=${payload.eid}`
			)
			let res = {
				endpoint_name: payload.name,
				endpoint_id: payload.eid,
				periods: payload.periods,
				widgets: response.data
			}
			return res
		} catch (error) {
			return null;
		}
	}
)

export const putWidgetLabel = createAsyncThunk(
	"widget/updateLabel",
	async (payload, { getState }) => {
		const state = getState().Dashboards;

		try {
			let url = state.isDefault 
				? `${WIDGET_SYNC_BASE}/sync/default-dashboard/${payload.dashboard_id}/${payload.widget_id}/widget?update_label=true` 
				: `${WIDGET_SYNC_BASE}/sync/dashboard/${payload.dashboard_id}/${payload.widget_id}/widget?update_label=true`
			const response = await api.put(url, payload)
			return response.data
		} catch (error) {
			return null
		}
	}
)