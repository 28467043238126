import { Card } from "reactstrap";
import set_profile, { img_error } from "../../../../helpers/default_profile";
import Rank1 from "../../../../assets/images/peringkat/1.svg";
import Rank2 from "../../../../assets/images/peringkat/2.svg";
import Rank3 from "../../../../assets/images/peringkat/3.svg";
import { useEffect, useState } from "react";

const RankingCol = ({
  rank,
  data: {
    id,
    endpoint_id,
    photo_url,
    name,
    workunit,
    badge_image1,
    badge_name1,
    badge_value1,
    badge_image2,
    badge_name2,
    badge_value2,
    badge_image3,
    badge_name3,
    badge_value3,
    performances,
  },
}) => {
  const [rankImage, setRankImage] = useState(null);
  useEffect(() => {
    if (rank === 1) {
      setRankImage(Rank1);
    } else if (rank === 2) {
      setRankImage(Rank2);
    } else if (rank === 3) {
      setRankImage(Rank3);
    }
  }, [rank]);

  return (
    <>
      <Card
        className="border p-2 d-flex justify-content-between"
        style={{ minHeight: "100%" }}
      >
        <span
          className="rounded-circle mx-auto"
          style={{
            width: "70px",
            height: "70px",
            overflow: "hidden",
          }}
        >
          <img
            src={set_profile(photo_url || "")}
            onError={(e) => img_error(e)}
            alt={name}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </span>
        <div className="d-flex justify-content-center align-items-center mb-2">
          {/* Tampilkan gambar sesuai dengan rank */}
          {rankImage && (
            <img
              src={rankImage}
              alt={`rank${rank}`}
              width="50px"
              height="60px"
            />
          )}
        </div>
        <div className="text-center">
          <p className="fw-semibold mb-0 fs-12">{name}</p>
          <p className="fs-10 text-muted">{workunit}</p>
        </div>
        <p className="mb-2 fs-10 text-center">
          Aktifitas Berita (7 hr Terakhir)
        </p>
        {/* <img src={LineChart} width="100%" /> */}
        <div className="d-flex justify-content-evenly">
          <div className="text-center">
            <span
              style={{ minHeight: "30px", height: "30px", display: "block" }}
            >
              {badge_image1 && (
                <img
                  src={badge_image1}
                  alt=""
                  style={{ objectFit: "fill", height: "30px", width: "100%" }}
                />
              )}
            </span>
            <p className="mb-0 mt-1 fs-11 fw-semibold">{badge_value1}</p>
            <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
              {badge_name1}
            </p>
          </div>
          <div className="text-center">
            <span
              style={{ minHeight: "30px", height: "30px", display: "block" }}
            >
              {badge_image2 && (
                <img
                  src={badge_image2}
                  alt=""
                  style={{ objectFit: "fill", height: "30px", width: "100%" }}
                />
              )}
            </span>
            <p className="mb-0 mt-1 fs-11 fw-semibold">{badge_value2}</p>
            <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
              {badge_name2}
            </p>
          </div>
          <div className="text-center">
            <span
              style={{ minHeight: "30px", height: "30px", display: "block" }}
            >
              {badge_image3 && (
                <img
                  src={badge_image3}
                  alt=""
                  style={{ objectFit: "fill", height: "30px", width: "100%" }}
                />
              )}
            </span>
            <p className="mb-0 mt-1 fs-11 fw-semibold">{badge_value3}</p>
            <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
              {badge_name3}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
};

export default RankingCol;
