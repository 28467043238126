import { v4 as uuidv4 } from 'uuid';
import { Button, Col, FormGroup, Input, Label, ListGroup, ListGroupItem, Row } from "reactstrap"
import "./endpoint-child.css"
import { useEffect, useRef, useState } from "react"
import { APIClient } from "../../helpers/api_helper"
import { DATA_COLLECTOR_BASE } from "../../helpers/url_helper"
import { Plus, Trash2 } from 'react-feather';

const EndpointChild = ({ child, setChild }) => {
    const api = new APIClient()
    const [endpointLists, setEndpointLists] = useState([])

    const getEndpoint = async () => {
        const res = await api.get(DATA_COLLECTOR_BASE + "/endpoints/?list=true")
        setEndpointLists(res.data)
    }
    const getWidget = async (datatype_group, endpoint_id) => {
        const res = await api.get(DATA_COLLECTOR_BASE + "/widget/" + datatype_group + "/datatype-group?eid=" + endpoint_id)
        return res.data
    }

    useEffect(() => {
        getEndpoint()
    }, [])

    // const child = [
    //     {
    //         "name": "Berita Masuk",
    //         "widget_type": "NEWS",
    //         "endpoint_id": "723ed5f1-b51b-4d18-bbf6-8ce9b46e54d0"
    //     },
    //     {
    //         "name": "Berita Masuk",
    //         "widget_type": "NEWS",
    //         "endpoint_id": "723ed5f1-b51b-4d18-bbf6-8ce9b46e54d0"
    //     },
    //     {
    //         "name": "Berita Masuk",
    //         "widget_type": "NEWS",
    //         "endpoint_id": "723ed5f1-b51b-4d18-bbf6-8ce9b46e54d0"
    //     },
    //     {
    //         "name": "Berita Masuk",
    //         "widget_type": "NEWS",
    //         "endpoint_id": "723ed5f1-b51b-4d18-bbf6-8ce9b46e54d0"
    //     }
    // ]
    const handleChangeEndpoint = async (eid, idx) => {
        // console.log(child[id])
        // setChild({...child, {child[id]: {}}})
        let currentIndex = child.findIndex(chd => chd.id === idx)
        let selectedEndpoint = endpointLists.find(endpoint => endpoint.id === eid)
        let widgets = await getWidget(selectedEndpoint.datatype_group.id, selectedEndpoint.id)
        let updated = { ...child[currentIndex], name: selectedEndpoint.name, endpoint_id: eid, widget_type: widgets[0].name, widget_lists: widgets }
        let newChild = [...child]
        newChild[currentIndex] = updated
        setChild(newChild)
        // console.log(child)
    }

    const handleChangeWidget = (e, idx) => {

        let currentIndex = child.findIndex(chd => chd.id === idx)
        let selectedEndpoint = endpointLists.find(endpoint => endpoint.name === e.target.value)
        let updated = { ...child[currentIndex], widget_type: e.target.value }
        let newChild = [...child]
        newChild[currentIndex] = updated
        setChild(newChild)
        console.log(selectedEndpoint)
        // let updated = { ...child[currentIndex], {} }
    }

    const addChild = () => {
        if (child[child.length - 1]?.endpoint_id === "" || child[child.length - 1]?.widget_type === "") return
        setChild([...child, {
            id: uuidv4(),
            name: "",
            widget_type: "",
            endpoint_id: "",
            widget_lists: []
        }])
        console.log(child)
    }
    const removeChild = (id) => {
        setChild(
            child.filter(c => c.id !== id)
        )
    }

    if (child.length === 0) {
        return (
            <div style={{ minHeight: "77vh" }}>
                <div className='empty-endpoint'>
                    <h2>Endpoint ini tidak memiliki endpoint child</h2>
                    <Button onClick={addChild} size='sm' color='primary'><Plus /> Tambah Endpoint</Button>
                </div>
            </div>
        )
    }

    return (
        <>
            <ul className='endpoint-child'>
                {child.map((c, i) => {
                    return (
                        <li style={{ marginLeft: i * 20 + "px" }} className='endpoint-child-list' key={i}>
                            <div className="d-flex gap-2 align-items-end">
                                <FormGroup>
                                    <Label>Nama Endpoint</Label>
                                    <Input type="select" style={{width: 500 - (i*20) +"px"}} value={c.endpoint_id} onChange={(e) => handleChangeEndpoint(e.target.value, c.id)}>
                                        <option value="default" disabled>
                                            Pilih Sumber Data
                                        </option>
                                        {endpointLists.map((ep, idx) => (
                                            <option value={ep.id} key={idx}>
                                                {ep.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                {c?.widget_lists?.length !== 0 && (
                                    <FormGroup>
                                        <Label>Widget</Label>
                                        <Input type="select" style={{width: "250px"}} value={c.widget_type} onChange={(e) => handleChangeWidget(e, c.id)}>
                                            <option value="" disabled>
                                                Pilih Endpoint
                                            </option>
                                            {c?.widget_lists?.map((w, idx) => (
                                                <option key={idx} value={w.name}>
                                                    {w.name}
                                                </option>
                                            ))}

                                        </Input>
                                    </FormGroup>
                                )}
                                {i + 1 == child.length && (
                                    <div className='mb-3 gap-2 d-flex'>
                                        <Button onClick={addChild} size='sm' color='primary' disabled={child[child.length - 1].endpoint_id === "" || child[child.length - 1].widget_type === ""}><Plus /></Button>
                                        <Button onClick={() => removeChild(c.id)} size='sm' color='danger'><Trash2 /></Button>
                                    </div>
                                )}
                            </div>
                        </li>
                    )
                })}

            </ul>
        </>
    )
}

export default EndpointChild