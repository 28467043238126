import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Row, Spinner, Container, Modal, ModalBody } from "reactstrap";

import { useNavigate } from "react-router-dom";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useSelector, useDispatch } from "react-redux";

import {
  getDashboards,
  getDashboardsWidget,
  getMenuDashboards,
  putDashboards,
} from "../../slices/thunks";
import { resetState, setDashboard } from "../../slices/dashboards/reducer";

import Import from "../../Components/Common/Import";
import ExportShare from "../../Components/Common/ExportShare";
import GabungkanDashboard from "../../Components/Common/GabungkanDashboard";
import TambahGrafik from "../../Components/Common/TambahGrafik";
import WidgetCard from "../../Components/Common/WidgetCard";
const LazyWidgetCard = React.lazy(() =>
  import("../../Components/Common/WidgetCard")
);

import { isAdmin } from "../../helpers/user_info";

import "./dashboard.css";
import "./index.scss";

import WidgetEmpty from "./widget-empty";
import WidgetLoading from "./loading";
import DashboardEmpty from "./dashboard-empty";
import RunningText from "../../Components/Common/charts/running-text";
import Banner from "../banner";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboards = useSelector((state) => state.Dashboards);

  useEffect(() => {
    if(dashboards.isDefault){
      dispatch(resetState())
    }
    navigate(dashboards.currentPath);
  }, [dashboards.currentPath]);

  useEffect(() => {
    if (dashboards.isInitial || dashboards.isUpdate) {
      dispatch(getMenuDashboards());
    }
  }, [dashboards.isInitial, dashboards.isUpdate]);

  useEffect(() => {
    if (dashboards?.current) {
      dispatch(
        getDashboardsWidget({ _id: dashboards?.current?._id, default: false })
      );
    }
  }, [dashboards.isInitialWidget]);

  const layoutChange = (layout, layouts, widgets) => {
    // console.log(layouts)
    // if (isAdmin) return;
    if (
      dashboards.current.layout.every((item, index) =>
        _.isEqual(
          {
            h: item.h,
            w: item.w,
            x: item.x,
            y: item.y,
            minH: item.minH,
            minW: item.minW,
          },
          {
            h: layouts.lg[index].h,
            w: layouts.lg[index].w,
            x: layouts.lg[index].x,
            y: layouts.lg[index].y,
            minH: layouts.lg[index].minH,
            minW: layouts.lg[index].minW,
          }
        )
      )
    )
      return;

    let updated_layout = [];
    dashboards.current.layout.map((lay, idx) => {
      if (lay.i === layout[idx].i) {
        updated_layout.push({
          _id: lay._id,
          i: layouts.lg[idx].i,
          x: layouts.lg[idx].x,
          y: layouts.lg[idx].y,
          w: layouts.lg[idx].w,
          h: layouts.lg[idx].h,
          minW: layouts.lg[idx].minW,
          minH: layouts.lg[idx].minH,
        });
      }
    });

    let dashboard = { ...dashboards.current };
    dashboard.layout = updated_layout;
    dispatch(putDashboards(dashboard));
  };

  document.title = dashboards.current?.label
    ? dashboards.current.label + " | Dashboard Executive"
    : "Dashboard Executive";

  return (
    <React.Fragment>
      <Banner />
      {/* <div>
        <PrintPreview />
      </div> */}
      <div className="page-content">
        <Container fluid>
          {!dashboards.isEmptyDashboard && (
            <div className="d-flex gap-3 justify-content-end print-hidden">
              <TambahGrafik></TambahGrafik>
              <Import></Import>
              <GabungkanDashboard></GabungkanDashboard>
              <ExportShare dashboardId={dashboards?.current?._id} />
            </div>
          )}

          {!dashboards.isEmptyDashboard &&
            !dashboards.isLoading &&
            !dashboards.isLoadingWidget &&
            dashboards?.current?.widget?.map((widget, key) => {
              return (
                widget.type === "RUNNING TEXT" && (
                  <RunningText widget={widget} />
                )
              );
            })}

          {dashboards.isLoadingWidget && <WidgetLoading />}

          {dashboards.isEmptyDashboard &&
          !dashboards.isLoading &&
          !dashboards.isLoadingWidget ? (
            <DashboardEmpty />
          ) : (
            dashboards.isEmptyWidget && <WidgetEmpty />
          )}

          {!dashboards.isLoading &&
            !dashboards.isLoadingWidget &&
            dashboards.current && (
              <Row>
                <ResponsiveGridLayout
                  layouts={{
                    lg: dashboards.current.layout,
                  }}
                  draggableHandle="._drag_area"
                  breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                  cols={{ lg: 8, md: 8, sm: 2, xs: 2, xxs: 1 }}
                  rowHeight={47}
                  width={1000}
                  isResizable={true}
                  onLayoutChange={(layout, layouts) =>
                    layoutChange(layout, layouts, dashboards.current.widget)
                  }
                >
                  {dashboards.current.widget.map((widget, key) => (
                    <div key={widget.key}>
                      <Suspense>
                        <LazyWidgetCard widget={widget}></LazyWidgetCard>
                      </Suspense>
                    </div>
                  ))}
                </ResponsiveGridLayout>
              </Row>
            )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
