import React, {
  useEffect,
  Suspense,
  useState,
  useRef,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Container, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import {
  deleteDashboard,
  getDashboardsWidget,
  getMenuDashboards,
  putDashboards,
} from "../../slices/thunks";
import { Responsive, WidthProvider } from "react-grid-layout";
import SelectDashboards from "./select-dashboards";
import { useNavigate, createSearchParams } from "react-router-dom";
import Header from "../../Layouts/Header";

const ResponsiveGridLayout = WidthProvider(Responsive);

const LazyWidgetCard = React.lazy(() =>
  import("../../Components/Common/WidgetCard")
);

const ManagementDefaultDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const dashboards = useSelector((state) => state.Dashboards);

  const [workunitLevel, setWorkUnitLevel] = useState("1");
  const [modalDelete, setModalDelete] = useState(false)
  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  const sliderRef = useRef(null);
  const scrollAmount = 100;

  useEffect(() => {
    if (dashboards.isInitial || dashboards.isUpdate || workunitLevel) {
      fetchData();
    }
  }, [dashboards.isInitial, dashboards.isUpdate, workunitLevel]);

  const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

  const fetchData = useCallback(async () => {
    dispatch(getMenuDashboards());
    await sleep(2000);
    dispatch(
      getMenuDashboards({ default: true, workunitLevel: workunitLevel })
    );
  });

  const layoutChange = (layout, layouts, widgets) => {
    console.log(layouts)
    // if (isAdmin) return;
    if (
      dashboards?.current?.layout?.every((item, index) =>
        _.isEqual(
          {
            h: item.h,
            w: item.w,
            x: item.x,
            y: item.y,
            minH: item.minH,
            minW: item.minW,
          },
          {
            h: layouts.lg[index].h,
            w: layouts.lg[index].w,
            x: layouts.lg[index].x,
            y: layouts.lg[index].y,
            minH: layouts.lg[index].minH,
            minW: layouts.lg[index].minW,
          }
        )
      )
    )
      return;

    let updated_layout = [];
    dashboards?.current?.layout?.map((lay, idx) => {
      console.log(lay);
      if (lay.i === layout[idx].i) {
        updated_layout.push({
          _id: lay._id,
          i: layouts.lg[idx].i,
          x: layouts.lg[idx].x,
          y: layouts.lg[idx].y,
          w: layouts.lg[idx].w,
          h: layouts.lg[idx].h,
          minW: layouts.lg[idx].minW,
          minH: layouts.lg[idx].minH,
        });
      }
    });

    let dashboard = { ...dashboards.current };
    dashboard.layout = updated_layout;
    if(dashboard._id == undefined) return
    dispatch(putDashboards(dashboard));
  };

  const handleWorkUnitChange = (workunit_level) => {
    navigate({
      // pathname: "management-default-dashboard",
      search: createSearchParams({
        workunit_level: workunit_level
      }).toString()
    })
    setWorkUnitLevel(workunit_level)
  }

  const handleDelete = () => {
    console.log(dashboards.current._id)
    dispatch(deleteDashboard(dashboards.current._id))
    toggleDelete()
  }

  const handleAddWidgetClick = () => {
    navigate({
      pathname: "add-widget",
      search: createSearchParams({
        workunit_level: workunitLevel,
        dashboard: dashboards.currentPathDefault
      }).toString()
    })
  }

  return (
    <>

      {/* <Header headerClass={"topbar"} isChatLayout={true} /> */}
      <Container fluid className="main-content" style={{ marginLeft: 0 }}>
        <div className="page-content">
          {/* header 1 (buttons) */}
          <div className="d-flex justify-content-between">
            <div className="w-25">
              <Input
                type="select"
                value={workunitLevel}
                onChange={(e) => handleWorkUnitChange(e.target.value)}
              >
                <option disabled value="">
                  Pilih Level
                </option>
                <option value="1">Kejaksaan Agung</option>
                <option value="2">Kejaksaan Tinggi</option>
                <option value="3">Kejaksaan Negeri </option>
                <option value="4">Cabang Kejaksaan Negeri </option>
              </Input>
            </div>

            <SelectDashboards dashboards={dashboards} />

            <div className="d-flex gap-2">
              <Button size="sm" color="primary" disabled={dashboards.defaultDashboards == null} onClick={() => handleAddWidgetClick()}>
                <i className="bx bx-plus "></i> Tambah Grafik
              </Button>
              <Button size="sm" color="danger" disabled={dashboards.defaultDashboards == null} onClick={toggleDelete}>Hapus Dashboard ini</Button>
            </div>
          </div>
          <div>
            {!dashboards.isLoading && dashboards.current && (
              <>
                <Row>
                  <ResponsiveGridLayout
                    layouts={{
                      lg: dashboards?.current?.layout,
                    }}
                    draggableHandle="._drag_area"
                    breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                    cols={{ lg: 8, md: 8, sm: 2, xs: 2, xxs: 1 }}
                    rowHeight={40}
                    width={1000}
                    isResizable={true}
                    onLayoutChange={(layout, layouts) =>
                      layoutChange(layout, layouts, dashboards?.current?.widget)
                    }
                  >
                    {dashboards?.current?.widget?.map((widget, key) => (
                      <div key={widget.key}>
                        <Suspense>
                          <LazyWidgetCard widget={widget}></LazyWidgetCard>
                        </Suspense>
                      </div>
                    ))}
                  </ResponsiveGridLayout>
                </Row>
              </>
            )}
          </div>
        </div>
      </Container>
      <Modal isOpen={modalDelete}>
        <ModalHeader toggle={toggleDelete}>Konfirmasi</ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          Apakah Anda yakin akan menghapus dashboard ini?
        </ModalBody>
        <ModalFooter className="gap-2 justify-content-center">
          <Button color="primary" outline onClick={toggleDelete}>
            Tidak
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Ya
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ManagementDefaultDashboard;
