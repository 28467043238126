import React from "react";

import ChartBar from "./charts/bar";
import ChartPie from "./charts/pie";
import ChartDoughnut from "./charts/doughnut";
import ChartLine from "./charts/line";
import ChartBarVertical from "./charts/bar-vertical";
import ChartStackedBar from "./charts/stacked-bar";
import ChartArea from "./charts/area";
import News from "./charts/news";
import Ranking from "./charts/ranking";
import Table from "./charts/table";
import Gis from "./charts/gis";
import ChartBubble from "./charts/bubble";
import RankingSimple from "./charts/ranking-simple";
import ChartPresentage from "./charts/card-persentage";
import SimpleNews from "./charts/news/SimpleNews";
import RunningText from "./charts/running-text";
import ListNews from "./charts/list-news";
import GISList from "./charts/gis-list";
import UserList from "./charts/user-list";

const Widget = ({ widget: { type, source, datas, name, period } }) => {
  switch (type) {
    case "GIS":
      return <Gis source={source}></Gis>;
    case "USER LIST":
      return <UserList source={source} datas={datas} period={period}></UserList>;
    case "GIS LIST":
      return <GISList source={source} datas={datas} period={period}></GISList>;
    case "NEWS":
      return <News source={source} datas={datas}></News>;
    case "SIMPLE NEWS":
      return <SimpleNews source={source}></SimpleNews>;
    case "LIST NEWS":
      return <ListNews source={source}  datas={datas}></ListNews>;
    case "RANKING":
      return <Ranking source={source} widgetName={name}></Ranking>;
    case "TABLE":
      return <Table source={source}></Table>;
    case "BAR":
      return <ChartBar source={source}></ChartBar>;
    case "BAR MULTIPLE DATA":
      return <ChartBar source={source}></ChartBar>;
    case "STACKEDBAR":
      return <ChartStackedBar source={source}></ChartStackedBar>;
    case "STACKED BAR MULTIPLE DATA":
      return <ChartStackedBar source={source}></ChartStackedBar>;
    case "LINE":
      return <ChartLine source={source}></ChartLine>;
    case "LINE MULTIPLE DATA":
      return <ChartLine source={source}></ChartLine>;
    case "AREA":
      return <ChartArea source={source}></ChartArea>;
    case "AREA MULTIPLE DATA":
      return <ChartArea source={source}></ChartArea>;
    case "PIE":
      return <ChartPie source={source}></ChartPie>;
    case "DOUGHNUT":
      return <ChartDoughnut source={source}></ChartDoughnut>;
    case "BUBBLE":
      return <ChartBubble source={source}></ChartBubble>;
    case "RANKING SIMPLE":
      return <RankingSimple source={source} widgetName={name}></RankingSimple>;
    case "CARD":
      return <ChartPresentage source={source}></ChartPresentage>;
    case "BAR VERTICAL":
      return <ChartBarVertical source={source}></ChartBarVertical>;
    case "RUNNING TEXT":
      return <RunningText source={source}></RunningText>;
    default:
      return <>Unknown {type} Widget</>;
  }
};

export default Widget;
