import { APIClient } from "../../helpers/api_helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { CHAT_BASE } from "../../helpers/url_helper";

const api = new APIClient();

export const getBroadcast = createAsyncThunk("broadcast/get", async () => {
  try {
    const response = await api.get(`${CHAT_BASE}/room/broadcast`);
    return response.data;
  } catch (error) {
    return null;
  }
});

export const postAttachment = createAsyncThunk(
  "broadcast/sendAttachment",
  async (payload) => {
    try {
      const response = await api.create(`${CHAT_BASE}/attachment`, payload);

      return response.data;
    } catch (error) {
      return null;
    }
  }
);

export const postBroadcast = createAsyncThunk(
  "broadcast/sendBroadcast",
  async (payload) => {
    try {
      const response = await api.create(`${CHAT_BASE}/room/broadcast`, payload);

      return response.data;
    } catch (error) {
      return null;
    }
  }
);
