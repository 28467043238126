import CustomToast from "../../Components/Common/CustomToast";
import {
  getDashboards,
  addDashboardWidget,
  deleteDashboardWidget,
  createDashboard,
  deleteDashboard,
  putDashboards,
  getMenuDashboards,
  getDashboardsWidget,
} from "./thunk";
import { createSlice, current } from "@reduxjs/toolkit";

export const initialState = {
  current: null,
  currentPath: "",
  prevPath: "",
  isEmptyDashboard: true,
  isEmptyWidget: true,
  isInitial: true,
  isUpdate: false,
  isLoading: true,
  map: {},
  isInitialWidget: false,
  isLoadingWidget: false,
  // for default dashboard
  isDefault: false,
  workunitLevel: null,
  currentPathDefault: "",
  defaultDashboards: null,
};

const DashboardsSlice = createSlice({
  name: "dashboards",
  initialState: initialState,
  reducers: {

    resetState: (state, {payload}) => {
      state.current = null,
      state.currentPath = "",
      state.prevPath = "",
      state.isEmptyDashboard = true,
      state.isEmptyWidget = true,
      state.isInitial = true,
      state.isUpdate = false,
      state.isLoading = true,
      state.map = {},
      state.isInitialWidget = false,
      state.isLoadingWidget = false,
      // for default dashboard
      state.isDefault = false,
      state.workunitLevel = null,
      state.currentPathDefault = "",
      state.defaultDashboards = null
    },

    setDashboard: (state, { payload }) => {
      if(state.isDefault){
        let dashboard = selectDashboardDefault(current(state), payload)
        state.current = dashboard
        state.currentPathDefault = payload
        state.isInitialWidget = true;
        state.isLoadingWidget = true;
        return
      }
      if (!current(state).isEmptyDashboard) {
        let dashboard = selectDashboard(current(state), payload);

        if (!dashboard) {
          dashboard = selectDashboard(
            current(state),
            current(state.map[Object.keys(state.map)[0]].path)
          );
        }

        // set data active
        state.current = dashboard;
        // set path active
        state.currentPath = payload;

        state.isInitialWidget = true;
        state.isLoadingWidget = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteDashboard.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.current = null;
        state.currentPath = "/";
        state.isUpdate = true;
        CustomToast("success", "berhasil menghapus dashboard")
      })
      .addCase(createDashboard.fulfilled, (state, { payload }) => {

        if (!payload) return;
        state.current = null;
        state.currentPath = payload.path;
        state.isUpdate = true;
      })
      .addCase(getMenuDashboards.fulfilled, (state, { payload }) => {
        if (!payload) return;
        let pathActive = "/" + window.location.pathname.split("/")[1];
        
        if (payload[Object.keys(payload)[0]]?.workunit_level) {
          let defaults = {...payload}
          delete defaults.default
          delete defaults.workunitLevel
          // payload.default = undefined
          // payload.workunitLevel = undefined
          state.defaultDashboards = defaults;
        } else {
          state.defaultDashboards = null;
          if(!state.isDefault){
            state.map = payload;
          }
          
        }

        state.isEmptyDashboard = !Object.keys(payload).length;
        if (Object.keys(payload).length) {
          if (pathActive == "/") {
            state.current = payload[Object.keys(payload)[0]];
            state.currentPath = payload[Object.keys(payload)[0]].path;
            state.isDefault = false;
            state.workunitLevel = null;
          } else if (pathActive == "/management-default-dashboard") {
            state.isDefault = true;
            if (payload[Object.keys(payload)[0]]?.workunit_level) {
              if(payload?.currentPathDefault){
                state.current = selectDashboardDefault(current(state), payload.currentPathDefault)
                state.currentPathDefault = payload.currentPathDefault
                state.workunitLevel =
                  payload[Object.keys(payload)[0]].workunit_level;
              }else{
                state.current = payload[Object.keys(payload)[0]];
                state.currentPathDefault = payload[Object.keys(payload)[0]].path;
                state.isDefault = true;
                state.workunitLevel =
                  payload[Object.keys(payload)[0]].workunit_level;
              }
            }else{
              state.current = {}
            }
          } else {
            let dashboard = selectDashboard(current(state), pathActive);
            if (dashboard) {
              state.current = dashboard;
              state.currentPath = dashboard.path;
            } else {
              state.current = payload[Object.keys(payload)[0]];
              state.currentPath = payload[Object.keys(payload)[0]].path;
            }
          }
          state.isInitialWidget = true;
          state.isLoadingWidget = true;
        }
        state.isInitial = false;
        state.isUpdate = false;
        state.isLoading = false;
      })
      .addCase(getDashboardsWidget.fulfilled, (state, { payload }) => {
        if (!payload) return;
        if (!state.current) return;
        
        state.current.layout = payload.layout;
        state.current.widget = payload.widget;

        state.isLoadingWidget = false;
        state.isInitialWidget = false;

        state.isEmptyWidget = !state.current.widget.length;

        
        
      })
      .addCase(putDashboards.fulfilled, (state, { payload }) => {
        if (!payload) return;
        // state.isUpdate = true;
      })
      .addCase(addDashboardWidget.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.isUpdate = true;
        state.isInitialWidget = true;
      })
      .addCase(deleteDashboardWidget.fulfilled, (state, { payload }) => {
        if (!payload) return;
        state.isUpdate = true;
        state.isInitialWidget = true;
      });
  },
});

export function selectDashboard(state, path) {
  return Object.values(state.map).find((item) => item.path == path);
}
export function selectDashboardDefault(state, path){
  return Object.values(state.defaultDashboards).find((item) => item.path == path)
}

export const { setDashboard, resetState } = DashboardsSlice.actions;
export default DashboardsSlice.reducer;
