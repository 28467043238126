import React, { Fragment }         from "react";
import { toast }                   from "react-toastify";
import { AlertTriangle, Check, X } from "react-feather";
import { ToastBody, ToastHeader }  from "reactstrap";

const titleDefault = type => {
	switch (type) {
		case "success":
			return "Berhasil!";
		case "danger":
			return "Terjadi Kesalahan";
		case "warning":
			return "Perhatian";
	}
};

const iconDefault = type => {
	switch (type) {
		case "success":
			return <Check size={16} />;
		case "danger":
			return <X size={16} />;
		case "warning":
			return <AlertTriangle size={16} />;
	}
};

const Toast = ({ type, title, description }) => {
	return (
		<Fragment>
			<ToastHeader
				className={`text-${type} mb-3`}
				color={`${type}`}
				icon={iconDefault(type)}
			>
				{title ?? titleDefault(type)}
			</ToastHeader>
			<ToastBody>
				<span aria-label="toast-text">{description}</span>
			</ToastBody>
		</Fragment>
	);
};

const CustomToast = (type, description, title) =>
	toast(<Toast type={type} title={title} description={description} />, {
		hideProgressBar: false,
	});

export default CustomToast;
