import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardsWidget, getMenuDashboards } from "../../../slices/thunks";
import ExportTableSingleData from "./single-data";
import ExportTableMultipleData from "./multiple-data";
import ExportTableData from "./table";
import "./index.scss";
import ExportTableNews from "./news";
import ExportTableRanking from "./ranking";
import ExportTableGIS from "./gis";
import { useParams } from "react-router-dom";

const ExportTable = () => {
  const dashboards = useSelector((state) => state.Dashboards);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (dashboards.isInitial || dashboards.isUpdate) {
      dispatch(getMenuDashboards());
    }
  }, [dashboards.isInitial, dashboards.isUpdate]);

  useEffect(() => {
    dispatch(getDashboardsWidget({ _id: params.id }));
  }, [dashboards.isInitialWidget]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.print();
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="p-3">
      {dashboards?.current?.widget?.map((widget) => {
        let source = widget.source;
        let data_type = source.split("/")[2];

        switch (data_type) {
          case "single-data":
            return <ExportTableSingleData source={source} name={widget.name} />;
          case "multiple-data":
            return (
              <ExportTableMultipleData source={source} name={widget.name} />
            );
          case "table":
            return <ExportTableData source={source} name={widget.name} />;
          case "news":
            return <ExportTableNews source={source} name={widget.name} />;
          case "ranking":
            return <ExportTableRanking source={source} name={widget.name} />;
          case "gis":
            return <ExportTableGIS source={source} name={widget.name} />;
        }
      })}
    </div>
  );
};

export default ExportTable;
