import React from "react";
import "./Switch.css";

const SwitchButton = ({ isToggled, onToggle, title }) => {
	return (
		<>
			<div className="d-flex align-self-center">
				<label className="switch">
					<input type="checkbox" checked={isToggled} onChange={onToggle}/>
					<div className="sliders"></div>
				</label>
				<p className="align-self-center ms-2 fs-16 text-muted mb-2">{title}</p>
			</div>
		</>
	);
};

export default SwitchButton;
