import { useDispatch, useSelector } from "react-redux";
import { WEBSOCKET_SIACC } from "../helpers/url_helper";
import user_info from "../helpers/user_info";
import { appendNewChat, openChatBox, setArchiveRoom, setChatActive, setRoomList } from "../slices/chat/reducer";
import { getMsgByRoom, getRoomArchive, getRoomChat } from "../slices/thunks";
import CustomToast from "../Components/Common/CustomToast";
import Cookies from "js-cookie";

const { createContext, useState } = require("react");

export const SocketContext = createContext(null)

const SocketContextProvider = ({ children }) => {
    const [chatSocket, setChatSocket] = useState(null)
    const chat = useSelector(state => state.Chat)
    const dispatch = useDispatch()
    const [token, setToken] = useState(Cookies.get('siaccToken') ? JSON.parse(Cookies.get('siaccToken')) : null)

    const connectChatSocket = roomId => {
        if(chatSocket != null){
            chatSocket.close();
        }
        const websocket = new WebSocket(`${WEBSOCKET_SIACC}/ws/${roomId ? roomId : user_info()?.uuid}/chat`);
        setChatSocket(websocket);
    };

    if (chatSocket != null) {
        chatSocket.onopen = function (e) {
            console.log('connect chat');
        };

        chatSocket.onmessage = function (event) {
            let res = JSON.parse(event.data);

        
            if (res.status == 201 || res.status == 200) {
                switch (res.type) {
                    case "communication-room-create":
                        if (res.status == 201) {
                            
                            CustomToast("success", 'Room berhasil dibuat');
                        } else {
                            CustomToast("success", 'Room berhasil diupdate.');
                        }
                        dispatch(getRoomChat())
                        dispatch(getMsgByRoom(res.data.id))
                        dispatch(openChatBox(true))
                        console.log(res)
                        break;
                    case "communication-message-create":
                        let chatActive = JSON.parse(JSON.stringify(chat.chatActive));
                        let chatActiveMsg = []
                        if(res.data.content_type === "dashboard") return CustomToast("success", "Berhasil share dashboard")
                        if (chatActive.messages === undefined) {
                            chatActiveMsg = []
                        } else {
                            chatActiveMsg = [...chatActive.messages]
                        }
                        chatActiveMsg.push(res.data)
                        chatActive.messages = chatActiveMsg
                        dispatch(appendNewChat(chatActive))
                        dispatch(getRoomChat())
                        break;
                    case "communication-room-archive":
                        dispatch(getRoomChat())
                        dispatch(getRoomArchive())
                        let room = JSON.parse(JSON.stringify(chat.roomList))
                        if(room.length === 1){
                            dispatch(setRoomList([]))
                        }
                        break;
                    case "communication-room-un-archive":
                        // dispatch(getRoomArchive())
                        dispatch(getRoomChat())
                        dispatch(getRoomArchive())
                        let archiveRoom = JSON.parse(JSON.stringify(chat.roomArchiveList))
                        if(archiveRoom.length === 1){
                            dispatch(setArchiveRoom([]))
                        }
                        break;
                    case "communication-message-revoke-for-me":
                        console.log(res.data.id)
                        let chatActives = JSON.parse(JSON.stringify(chat.chatActive));
                        let chatActiveMsgs = []
                        let filtered
                        // console.log(chat.chatActive)
                        if (chatActives.messages === undefined) {
                            chatActiveMsgs = []
                        } else {
                            chatActiveMsgs = [...chatActives.messages]
                        }
                        filtered = chatActiveMsgs.filter(msg => {return msg.id !== res.data.id})
                        chatActives.messages = filtered
                        console.log(chatActives)
                        dispatch(appendNewChat(chatActives))
                        break;

                    case "communication-message-revoke-for-all":
                        console.log(res.data.id)
                        let chatActivesl = JSON.parse(JSON.stringify(chat.chatActive));
                        let chatActiveMsgsl = []
                        let filteredl
                        // console.log(chat.chatActive)
                        if (chatActivesl.messages === undefined) {
                            chatActiveMsgsl = []
                        } else {
                            chatActiveMsgsl = [...chatActivesl.messages]
                        }
                        filteredl = chatActiveMsgsl.filter(msg => {return msg.id !== res.data.id})
                        chatActivesl.messages = filteredl
                        console.log(chatActivesl)
                        dispatch(appendNewChat(chatActivesl))
                        break;
                }
            }
        }
         // on close chat socket
         chatSocket.onclose = function (e) {
            console.log('reconnect chat socket');
            setTimeout(() => {
                connectChatSocket();
            }, 1000)
        };

        chatSocket.onerror = function (error) {
            console.log(error);
        };
    }

    const revokeMe = (id) => {
        if (chatSocket != null) {
            let val = {
                type: "communication-message-revoke-for-me",
                is_secure: true,
                token,
                payload: {
                    id: id
                }
            }

            // console.log(val)
            chatSocket.send(JSON.stringify(val));
        }
    }
    const revokeAll = (id) => {
        if (chatSocket != null) {
            let val = {
                type: "communication-message-revoke-for-all",
                is_secure: true,
                token,
                payload: {
                    id: id
                }
            }

            chatSocket.send(JSON.stringify(val));
        }
    }

    const archiveMsg = id => {
        if (chatSocket != null) {
            let val = {
                type: "communication-room-archive",
                is_secure: true,
                token: JSON.parse(Cookies.get('siaccToken')),
                payload: {
                    id: id,
                }
            };
            chatSocket.send(JSON.stringify(val));
        }
    };

    const unArchiveMsg = id => {
        if(chatSocket != null){
			let val = {
                type: "communication-room-un-archive",
                is_secure: true,
                token,
                payload: {
                    id: id,
                }
            };
            chatSocket.send(JSON.stringify(val));
        }
    }

    const endMsg = (id) => {
        if (chatSocket != null) {
            let val = {
                type: "communication-room-visible",
                is_secure: true,
                token: JSON.parse(Cookies.get('siaccToken')),
                payload: {
                    id: id,
                }
            };

            chatSocket.send(JSON.stringify(val));
        }
    }

    const sendMessage = (text, attachment_id, parent_id) => {
        if (chatSocket != null) {
            let val = {
                type: "communication-message-create",
                is_secure: true,
                token,
                payload: {
                    room_id: chat.chatActive.id,
                    content_type: "text",
                    content: text,
                    attachment_id,
                    parent_id
                }
            }
            chatSocket.send(JSON.stringify(val))
        }
    }
    const sendDashboard = (text, room) => {
        if (chatSocket != null) {
            let val = {
                type: "communication-message-create",
                is_secure: true,
                token,
                payload: {
                    room_id: room,
                    content_type: "dashboard",
                    content: text,
                }
            }
            chatSocket.send(JSON.stringify(val))
        }
    }

    const groupRoom = (groupName, user) => {
        if (user.length <= 1) {
			CustomToast("danger", "Jumlah kontak harus lebih dari 1.");
		} else if (groupName == "") {
			CustomToast("danger", "Nama Group Tidak Boleh Kosong");
		} else {
			let val = {
				type: "communication-room-create",
				is_secure: true,
				token,
				payload: {
					type: "group",
					name: groupName,
					member_id: [...user, user_info().uuid]
				}
			};
			chatSocket.send(JSON.stringify(val));
        }
    }

    const personalRoom = (user) => {
        if (chatSocket != null) {
			let val = {
				type: "communication-room-create",
				is_secure: true,
				token,
				payload: {
					type: "personal",
					name: "",
					member_id: [user, user_info().uuid],
				}
			};
			chatSocket.send(JSON.stringify(val))
		}
    }




    return (
        <SocketContext.Provider value={
            {
                chatSocket,
                setChatSocket,
                connectChatSocket,
                revokeMe, revokeAll, archiveMsg, endMsg, sendMessage, unArchiveMsg, groupRoom, personalRoom, sendDashboard
            }}>
            {children}
        </SocketContext.Provider>
    )
}

export default SocketContextProvider