import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-multilevel-dropdown";
import SimpleBar from "simplebar-react";
import {
  Card,
  CardBody,
  CardHeader,
  Popover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Badge,
  TabContent,
  TabPane,
} from "reactstrap";
const WidgetLazy = React.lazy(() => import("../../Components/Common/Widget" ))

import {
  deleteDashboardWidget,
  putDashboards,
  putWidgetLabel,
} from "../../slices/thunks";
import moment from "moment/moment";
import _ from "lodash";
//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

import "../../pages/Dashboard/index.scss";
import classNames from "classnames";

const WidgetCard = (props) => {
  const dispatch = useDispatch();
  const dashboards = useSelector((state) => state.Dashboards);
  const [widget, setWidget] = useState({});
  const labelRef = useRef()
  const [tab, setTab] = useState(1)

  useEffect(() => {
    setWidget(props.widget);
  }, [dispatch]);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const [editParam, setEditPram] = useState({
    dashboard_id: dashboards.current._id,
    widget_id: "",
    name: ""
  })

  function updatePeriod(period, widget) {
    let w = { ...widget };
    let split = widget.source.split("=");
    split[split?.length - 1] = period;
    w.source = split.join("=");

    let dashboard = JSON.parse(JSON.stringify(dashboards.current));
    dashboards.current.widget.map((wdg, idx) => {
      if (wdg._id !== w._id) return;
      dashboard.widget[idx].source = w.source;
    });
    dispatch(putDashboards(dashboard)).then((res) => {
      setWidget(res.payload.widget.find((item) => item._id === w._id));
    });
  }

  // State to track popover visibility for each widget
  const [popoverState, setPopoverState] = useState({});

  // Function to toggle popover visibility for a specific widget
  const togglePopover = (widgetId) => {
    setPopoverState((prevState) => ({
      ...prevState,
      [widgetId]: !prevState[widgetId],
    }));
  };

  const changeTab = (tabNum) => {
    setTab(tabNum)
  }

  const [count, setCount] = useState(0);

 
    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setCount(count + 1);
            if(widget.type == "RANKING SIMPLE" || widget.type == "NEWS"){
              var totalTabs = widget?.tabs?.length;
              if(totalTabs){
                
                if(tab == totalTabs+1){
                  setTab(1)
                }else{
                  setTab(tab+1)
                }
              }
            }
            
            
        }, 30000);
 
        //Clearing the interval
        return () => clearInterval(interval);
    }, [count]);

  if (widget.type !== "RUNNING TEXT") {
    return (
      <>
        <Card className="mb-0 widget-card">
          <CardHeader style={{paddingBottom:"0px"}}>
            <>
            <div className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1" ref={labelRef}>{widget.name}</h4>

              {!props.isShare && (
                <div className="flex-shrink-0">
                  <ul className="list-inline card-toolbar-menu d-flex align-items-center mb-0">
                    {popoverState[widget._id] && (
                      <Popover
                        placement="left"
                        isOpen={popoverState[widget._id]}
                        target={`Popover-${widget._id}`}
                        toggle={() => togglePopover(widget._id)}
                        style={{ zIndex: 10 }}
                      >
                        <PopoverHeader>Last Update</PopoverHeader>
                        <PopoverBody>
                          <div className="text-center mb-2">
                            {moment(widget.updated_at)
                              .locale("id")
                              .format("D MMMM YYYY")}
                          </div>
                          <div className="text-center">
                            {moment(widget.updated_at).locale("id").format("HH:mm")}
                          </div>
                        </PopoverBody>
                      </Popover>
                    )}
                    <li
                      className="list-inline-item cursor-pointer"
                      style={{ padding: "12px 16px" }}
                      id={`Popover-${widget._id}`}
                      onClick={() => togglePopover(widget._id)}
                    >
                      <i
                        className="mdi mdi-clock-outline align-middle"
                        style={{ fontSize: "20px", color: "red" }}
                      ></i>
                    </li>
                    <li className="list-inline-item _drag_area">
                      <i className="mdi mdi-arrow-all align-middle"></i>
                    </li>
                    <li className="list-inline-item">
                      <Dropdown
                        title=""
                        buttonClassName="text-muted dropdown-btn bg-transparent fw-normal ri-more-fill fs-4"
                      >
                        {widget.periods?.length !== 0 && (
                          <Dropdown.Item
                            className="d-flex"
                            style={{ width: "100%" }}
                          >
                            <i className="ri-arrow-left-s-line me-1"></i>
                            Filter
                            <Dropdown.Submenu
                              position="left"
                              style={{ width: "100%" }}
                              wrapperClassName="w-full"
                              buttonClassName="align-middle bg-transparent fw-light fs-5 flex-fill justify-content-start p-0"
                            >
                              {widget.periods?.map((period, key) => (
                                <Dropdown.Item
                                isActive={widget?.source?.split("=")[widget?.source?.split("=").length -1] === period}
                                  key={key}
                                  onClick={() => updatePeriod(period, widget)}
                                >
                                  {period === "daily" && "Harian"}
                                  {period === "weekly" && "Mingguan"}
                                  {period === "monthly" && "Bulanan"}
                                  {period === "yearly" && "Tahunan"}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Submenu>
                          </Dropdown.Item>
                        )}

                        <Dropdown.Item
                          onClick={() => {
                            setModal(true)
                            setEditPram({ ...editParam, widget_id: widget._id, name: labelRef.current.innerText })
                          }}
                        >
                          <span>
                            <i className="align-middle ri-edit-line me-1"></i>
                            Edit Label
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            dispatch(deleteDashboardWidget({ widget }));
                          }}
                        >
                          <span>
                            <i className="align-middle ri-delete-bin-line me-1"></i>
                            Hapus Grafik
                          </span>
                        </Dropdown.Item>
                      </Dropdown>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {widget.tabs ? (
              <PerfectScrollbar handlers={["drag-thumb"]}
                className="d-flex align-content-center"
                style={{ height: "50px", gap: "8px", padding: "7px"}}
              >
                 <Badge
                    style={tab === 1 ? { height: "max-content", fontSize: "1rem", cursor: "pointer" } : { height: "max-content", fontSize: "1rem", cursor: "pointer", color: "gray" }}
                    className="fw-semibold"
                    color={tab != 1 ? "link" : "primary"}
                    outline={tab == 1}
                    onClick={() => changeTab(1)}
                  >
                    {widget.tab_name}
                  </Badge>
                {widget.tabs.map((t, idx) => (
                  <Badge key={idx}
                    style={tab === idx +2 ? { height: "max-content", fontSize: "1rem", cursor: "pointer" } : { height: "max-content", fontSize: "1rem", cursor: "pointer", color: "gray" }}
                    className="fw-semibold"
                    color={tab != idx + 2 ? "link" : "primary"}
                    outline={tab == idx + 2}
                    onClick={() => changeTab(idx + 2)}
                  >
                  {t.tab_name}
                  </Badge>
                ))}
                </PerfectScrollbar>
              ):(
                <div style={{height:"50px"}}></div>
              )}
            </>
          </CardHeader>
          {widget.tabs ? (
            <>
              <TabContent activeTab={tab}>
                <TabPane tabId={1}>
                  <SimpleBar style={{ height: "100%", overflow: "auto" }}>
                    <CardBody className={classNames({ "p-0": (widget.type == "SIMPLE NEWS") })}>
                      <div className="mx-n3">
                        <SimpleBar className="px-3">
                          <WidgetLazy widget={{...widget, name: `${widget.name} - ${widget.tab_name}`}}></WidgetLazy>
                        </SimpleBar>
                      </div>
                    </CardBody>
                  </SimpleBar>
                </TabPane>
                {widget.tabs?.map((w, i) => (
                  <TabPane tabId={i + 2}>
                    <SimpleBar style={{ height: "100%", overflow: "auto" }}>
                      <CardBody className={classNames({ "p-0": (widget.type == "SIMPLE NEWS") })}>
                        <div className="mx-n3">
                          <SimpleBar className="px-3">
                            <WidgetLazy widget={{...w, name: `${widget.name} - ${w.tab_name}`}}></WidgetLazy>
                          </SimpleBar>
                        </div>
                      </CardBody>
                    </SimpleBar>
                  </TabPane>
                ))}
              </TabContent>
            </>
          ):(
            <SimpleBar style={{ height: "100%", overflow: "auto" }}>
              <CardBody className={classNames({ "p-0": (widget.type == "SIMPLE NEWS") })}>
                <div className="mx-n3">
                  <SimpleBar className="px-3">
                    <WidgetLazy widget={widget}></WidgetLazy>
                  </SimpleBar>
                </div>
              </CardBody>
            </SimpleBar>
          )}
        </Card>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader className="modal-title" toggle={toggle}>
            Edit Label
          </ModalHeader>

          <ModalBody className="text-center">
            <div className="p-4">
              <Input
                type="text"
                placeholder="Label"
                value={editParam.name}
                onChange={(e) => setEditPram({ ...editParam, name: e.target.value })}
              />
            </div>
            <div className="hstack gap-2 justify-content-center">
              <Button
                color="light"
                onClick={() =>
                  toggle()
                }
              >
                Batal
              </Button>

              <Button
                color="primary"
                onClick={() => {
                  dispatch(putWidgetLabel(editParam)).then(res => {
                    labelRef.current.innerText = editParam.name
                    setEditPram({ ...editParam, name: "", widget_id: "" })
                    toggle()
                  })
                }}
              >
                Edit
              </Button>

            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }

};

export default WidgetCard;
