import Marquee from "react-fast-marquee";
import "./index.scss";
import Logo from "../../../../assets/images/logo/logo-kejaksaan.png";
import { APIClient } from "../../../../helpers/api_helper";
import { API_BASEURL } from "../../../../helpers/url_helper";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { deleteDashboardWidget } from "../../../../slices/thunks";
import ModalDelete from "./modalDelete";
import NewsDetail from "../news/news-detail";

const api = new APIClient();

const RunningText = (widget) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(["Running Text", "Running Text"]);
  const [openModalSave, setOpenModalSave] = useState(false);
  const [modalNewsDetail,setModalNewsDetail] = useState(false);
  const [dataSelected,setDataSelected] = useState(null);


  function toggleOpenModalSave() {
    setOpenModalSave(!openModalSave);
  }

  useEffect(() => {
    api.get(API_BASEURL + widget?.widget?.source).then((res) => {
      setData(res.data);
    });
  }, []);

  const confirmDelete = () => {
    toggleOpenModalSave();
  };

  function handleSave() {
    dispatch(deleteDashboardWidget(widget));

    toggleOpenModalSave();
  }

  return (
    <>
      <div style={{position: "sticky", top: "35px", zIndex: 996, background: "#f3f3f9"}}>
        {/* <span className="hapus">X</span> */}
        <div className="d-flex justify-content-end">
          <i
            className="ri-close-line fs-20 me-1 hapus"
            role="button"
            onClick={() => confirmDelete()}
          ></i>
        </div>
        <Marquee
          pauseOnHover={true}
          gradient={true}
          gradientColor="#f3f3f9"
          className="rt-wrapper"
        >
          {data.length > 0 &&
            data.map((d, key) => (
              <span className="d-flex" key={key}>
                <img className="img" src={Logo} />
                <h4
                  className="text cursor-pointer"
                  onClick={() => {
                    setDataSelected(d);
                    setModalNewsDetail(!modalNewsDetail)
                  }}
                >
                  {d.title}
                </h4>
              </span>
            ))}
                <img className="img" src={Logo} />
        </Marquee>
      </div>

    {dataSelected &&
      <NewsDetail
        show={modalNewsDetail}
        setShow={() => setModalNewsDetail(!modalNewsDetail)}
        data={dataSelected}
      />
    }

      <ModalDelete
        handleSave={handleSave}
        openModalSave={openModalSave}
        toggleOpenModalSave={toggleOpenModalSave}
      />
    </>
  );
};

export default RunningText;
