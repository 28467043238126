import { useEffect, useState } from "react";
import { getWidget } from "../../../../helpers/widget_helper";
import classNames from "classnames";

const ExportTableData = ({ source, name, data, type }) => {
  const [datas, setDatas] = useState(data ?? null);
  useEffect(() => {
    if (!data) {
      getWidget({ source }).then((res) => {
        setDatas(res);
      });
    }
  }, []);
  return (
    <div
      className={classNames({
        "d-flex justify-content-center": !data,
      })}
    >
    {datas?.length > 0 && (
      <table
        className={classNames({
          "table table-hover table-striped table-bordered w-75 mb-4": !data,
          "table-pdf": data,
        })}
      >
        <thead>
          {type == "excel" && (
            <tr>
              <td
                className="fw-bold text-center"
                colSpan={datas?.headers.length}
              >
                &nbsp;
              </td>
            </tr>
          )}
          <tr>
            <th className="fw-bold text-center" colSpan={datas?.headers.length}>
              {name}
            </th>
          </tr>
          <tr>
            {datas?.headers.map((header, i) => (
              <th key={i}>{header.label}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {datas?.body.map((data, i) => (
            <tr key={i}>
              {datas?.headers.map((header, j) => (
                <th key={j}>{data[header.key]}</th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )}
    </div>
  );
};

export default ExportTableData;
