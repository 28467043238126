import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getMessaging, onMessage } from "firebase/messaging";

import moment from "moment/moment";
import classnames from "classnames";
import "moment/locale/id";

import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import {
  getBroadcast,
  getMsgByRoom,
  getNotifDashboard,
  getNotifSiacc,
  markAsRead,
  updateNotifDashboard,
  updateNotifSiacc,
} from "../../slices/thunks";
import user_info from "../../helpers/user_info";
import { setNotifSiacc } from "../../slices/notifications/siacc/reducer";
import CustomTableEmptyData from "./custom-table/empty";
import { setNotifDashboard } from "../../slices/notifications/dashboard/reducer";
import { openChatBox } from "../../slices/chat/reducer";
import { CHAT_BASE } from "../../helpers/url_helper";
import Skeleton from "react-loading-skeleton";
import CardFile from "./card-file/CardFile";
import CarouselAttachment from "./card-carousel";
import { APIClient } from "../../helpers/api_helper";

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const api = new APIClient();

  const processAttachment = (data, type) => {
    let attachment_ = [];

    if (data != null && data.length > 0) {
      data.map((data) => data.type === type && attachment_.push(data));
    }

    return attachment_;
  };

  const dashboardNotification = useSelector(
    (state) => state.NotificationDashboard
  );
  const siaccNotification = useSelector((state) => state.NotificationSiacc);
  const broadcast = useSelector((state) => state.Broadcast);

  const { data: dashboardData, pagination: dashboardPagination } =
    dashboardNotification;
  const { data: siaccData, pagination: siaccPagination } = siaccNotification;
  const { data: broadcastData } = broadcast;

  const [isBroadcast, setIsBroadcast] = useState(false);
  const [modal, setModal] = useState(false);
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [dataSelected, setDataSelected] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const toggleBroadcast = () => {
    setIsBroadcast(!isBroadcast);
  };

  const getDetail = (content) => {
    api
      .get(`${CHAT_BASE}/messages/${content}`)
      .then((res) => {
        setDataSelected(res.data.messages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      if (dashboardPagination && dashboardPagination.has_next) {
        const nextDashboardPage = dashboardPagination.current_page + 1;
        dispatch(getNotifDashboard(user_info().uuid_user, nextDashboardPage));
      }

      if (siaccPagination && siaccPagination.has_next) {
        const nextSiaccPage = siaccPagination.current_page + 1;
        dispatch(getNotifSiacc(user_info().uuid, nextSiaccPage));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dashboardPagination, siaccPagination]);

  // initiate firebase messaging
  // const msg = initFirebaseMessaging()
  onMessage(getMessaging, function (payload) {
    console.log(payload);
  });

  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const calculateCountdown = (timestamp) => {
    const targetTime = moment(timestamp).utc().format();
    const now = moment().utc().format();
    const diff = moment.duration(moment(now).diff(moment(targetTime)));

    if (diff.asSeconds() < 60) {
      return `${Math.floor(diff.asSeconds())} detik yang lalu`;
    } else if (diff.asMinutes() < 60) {
      return `${Math.floor(diff.asMinutes())} menit yang lalu`;
    } else if (diff.asHours() < 24) {
      return `${Math.floor(diff.asHours())} jam yang lalu`;
    } else {
      return `${Math.floor(diff.asDays())} hari yang lalu`;
    }
  };

  const unreadMessage = [
    ...(siaccData || []).map((item) => ({ ...item, is_read: item.is_read })),
    ...(Array.isArray(dashboardData) ? dashboardData : []),
  ].filter((item) => item.is_read === false).length;

  const handleReadNotifSiacc = (
    uuid,
    notification_id,
    kind,
    content,
    title,
    body,
    created_at
  ) => {
    const payload = {
      uuid: uuid,
      notification_id: notification_id,
    };
    dispatch(updateNotifSiacc(payload));

    // Set status notifikasi yang dipilih sebagai sudah dibaca
    const updatedSiaccData = siaccData.map((item) =>
      item.notification_id === notification_id
        ? { ...item, is_read: true }
        : item
    );

    // Update data notifikasi di Redux store dengan data yang sudah diupdate
    dispatch(setNotifSiacc(updatedSiaccData));
    dispatch(getNotifSiacc(user_info().uuid));

    if (kind === "new_message") {
  
      dispatch(markAsRead({ content }));
      dispatch(getMsgByRoom(content));
      dispatch(openChatBox(true));
      toggleNotificationDropdown();
    }
    if (kind === "new_broadcast") {
     
      toggle();
      toggleBroadcast();
      setTitle(title);
      setBody(body);
      setCreatedAt(created_at);
      getDetail(content);
    }
  };

  const handleReadNotifDashboard = (_id) => {
    dispatch(updateNotifDashboard(_id));

    // Toggle status is_read untuk notifikasi yang diklik
    const updatedDashboardData = dashboardData.map((item) =>
      item._id === _id ? { ...item, is_read: true } : item
    );

    // Update data notifikasi di Redux store dengan data yang sudah diupdate
    dispatch(setNotifDashboard(updatedDashboardData));
    dispatch(getNotifDashboard(user_info().uuid_user));
  };

  const handleReadAllNotifSiacc = () => {
    // Filter out notifications with is_read === true
    const unreadSiaccData = siaccData.filter((item) => !item.is_read);

    // Update each unread notification
    unreadSiaccData.forEach((item) => {
      const payload = {
        uuid: item.uuid,
        notification_id: item.notification_id,
      };
      dispatch(updateNotifSiacc(payload));
    });

    // Set status of unread notifications as already read
    const updatedSiaccData = siaccData.map((item) => ({
      ...item,
      is_read: true,
    }));

    // Update data notifikasi di Redux store dengan data yang sudah diupdate
    dispatch(setNotifSiacc(updatedSiaccData));
    dispatch(getNotifSiacc(user_info().uuid));
  };

  const handleReadAllNotifDashboard = () => {
    // Filter out notifications with is_read === true
    const unreadDashboardData = dashboardData.filter((item) => !item.is_read);

    // Update each unread notification
    unreadDashboardData.forEach((item) => {
      dispatch(updateNotifDashboard(item._id));
    });

    // Set status of unread notifications as already read
    const updatedDashboardData = dashboardData.map((item) => ({
      ...item,
      is_read: true,
    }));

    // Update data notifikasi di Redux store dengan data yang sudah diupdate
    dispatch(setNotifDashboard(updatedDashboardData));
    dispatch(getNotifDashboard(user_info().uuid_user));
  };

  useEffect(() => {
    if (!dashboardData || dashboardData.length === 0) {
      dispatch(getNotifDashboard(user_info().uuid_user));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!siaccData || siaccData.length === 0) {
      dispatch(getNotifSiacc(user_info().uuid));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBroadcast());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          style={{ color: "#FFFFFF" }}
        >
          <i className="bx bx-bell fs-22"></i>
          {unreadMessage !== 0 ? (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
              {unreadMessage}
              <span className="visually-hidden">unread messages</span>
            </span>
          ) : null}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notifikasi{" "}
                  </h6>
                </Col>
                <div
                  className="col-auto dropdown-tabs"
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <Link
                    to="/notifications"
                    onClick={toggleNotificationDropdown}
                  >
                    <i
                      className="bx bx-exit-fullscreen"
                      style={{
                        fontSize: "23px",
                        color: "#FFFFFF",
                        cursor: "pointer",
                      }}
                    ></i>
                  </Link>
                </div>
              </Row>
            </div>

            <div className="px-2 pt-2">
              <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    SIACC{" "}
                    {siaccData &&
                    siaccData.length > 0 &&
                    siaccData.some((item) => !item.is_read)
                      ? `(${siaccData.length})`
                      : ""}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    Dashboard Executive{" "}
                    {dashboardData &&
                    dashboardData.length > 0 &&
                    dashboardData.some((item) => !item.is_read)
                      ? `(${
                          dashboardData.filter((item) => !item.is_read).length
                        })`
                      : ""}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {siaccData && siaccData.length > 0 ? (
                  siaccData.map((items, index) => (
                    <div
                      key={index}
                      className={`text-reset notification-item d-block dropdown-item position-relative ${
                        items.is_read === false ? "active" : undefined
                      }`}
                      onClick={() =>
                        handleReadNotifSiacc(
                          items.uuid,
                          items.notification_id,
                          items.notification.kind,
                          items.notification.content,
                          items.notification.title,
                          items.notification.body,
                          items.notification.created_at
                        )
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="bg-primary d-flex justify-content-center align-items-center me-2"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                        >
                          <i className="bx bx-bell fs-22"></i>
                        </div>
                        <div className="flex-1">
                          <Link to="#" className="stretched-link">
                            <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                              {items.notification.title}
                            </h6>
                          </Link>
                          <div className="fs-13 text-muted">
                            <p className="mb-1">{items.notification.body.substring(0, 20)}</p>
                          </div>
                          <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span>
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              {calculateCountdown(items.created_at)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <CustomTableEmptyData />
                )}
              </SimpleBar>
              {siaccData && siaccData.length > 0 ? (
                <div className="text-center mb-2">
                  <hr />
                  <div
                    className="fw-semibold cursor-pointer"
                    onClick={handleReadAllNotifSiacc}
                  >
                    Tandai Semua Sudah Dibaca
                  </div>
                </div>
              ) : null}
            </TabPane>

            <TabPane tabId="2" className="py-2 ps-2">
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                {dashboardData &&
                dashboardData.some((item) => !item.is_read) ? (
                  dashboardData.map((items, index) => (
                    <div
                      key={index}
                      className={`text-reset notification-item d-block dropdown-item position-relative ${
                        items.is_read === false ? "active" : undefined
                      }`}
                      onClick={() => handleReadNotifDashboard(items._id)}
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="bg-primary d-flex justify-content-center align-items-center me-2"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            color: "white",
                          }}
                        >
                          <i className="bx bx-bell fs-22"></i>
                        </div>

                        <div className="flex-1">
                          <Link to="#" className="stretched-link">
                            <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                              {items.title}
                            </h6>
                          </Link>
                          <div className="fs-13 text-muted">
                            <p className="mb-1">{items.body}</p>
                          </div>
                          <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span>
                              <i className="mdi mdi-clock-outline"></i>{" "}
                              {calculateCountdown(items.timestamp)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <CustomTableEmptyData />
                )}
              </SimpleBar>
              {dashboardData && dashboardData.some((item) => !item.is_read) ? (
                <div className="text-center mb-2">
                  <hr />
                  <div
                    className="fw-semibold cursor-pointer"
                    onClick={handleReadAllNotifDashboard}
                  >
                    Tandai Semua Sudah Dibaca
                  </div>
                </div>
              ) : null}
            </TabPane>
          </TabContent>
        </DropdownMenu>
      </Dropdown>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="modal-title" toggle={toggle}>
          Detail Pesan Siaran
        </ModalHeader>
        <ModalBody>
          <div className="p-2">
            <Card
              style={{
                borderBottom: "none",
                boxShadow: "none",
                margin: 0,
              }}
            >
              <CardHeader
                className="align-items-center d-flex"
                style={{
                  borderBottom: "none",
                  boxShadow: "none",
                }}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 56 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-3"
                >
                  <rect width="56" height="56" rx="28" fill="#D0D8D2" />
                  <path
                    d="M27.7938 14.8988C30.99 14.8461 34.0954 15.9616 36.5275 18.036C38.9596 20.1104 40.551 23.0011 41.0031 26.1655C41.4553 29.3299 40.737 32.5506 38.9832 35.2231C37.2294 37.8956 34.5606 39.8361 31.4776 40.6806C28.536 41.4864 25.4102 41.2415 22.6359 39.9963L16.4178 41.1045C15.9417 41.1893 15.4576 41.0055 15.1577 40.6261C14.8579 40.2467 14.791 39.7331 14.9836 39.2896L16.8657 34.9551C15.3172 32.4661 14.6426 29.5229 14.9629 26.5946C15.3106 23.417 16.8059 20.4755 19.1683 18.3221C21.5306 16.1687 24.5977 14.9514 27.7938 14.8988Z"
                    fill="#44624D"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.3266 22.6704C23.6958 22.3012 24.1966 22.0938 24.7188 22.0938C25.2409 22.0938 25.7417 22.3012 26.1109 22.6704C26.4801 23.0396 26.6875 23.5404 26.6875 24.0625V25.375C26.6875 25.8971 26.4801 26.3979 26.1109 26.7671C25.9781 26.8999 25.8283 27.0117 25.6667 27.1005C25.9245 27.8413 26.3475 28.5221 26.9127 29.0873C27.4779 29.6525 28.1587 30.0755 28.8995 30.3333C28.9883 30.1717 29.1001 30.0219 29.2329 29.8891C29.6021 29.5199 30.1029 29.3125 30.625 29.3125H31.9375C32.4596 29.3125 32.9604 29.5199 33.3296 29.8891C33.6988 30.2583 33.9062 30.7591 33.9062 31.2812C33.9062 31.8034 33.6988 32.3042 33.3296 32.6734C32.9604 33.0426 32.4596 33.25 31.9375 33.25H30.625C28.5364 33.25 26.5334 32.4203 25.0565 30.9435C23.5797 29.4666 22.75 27.4636 22.75 25.375V24.0625C22.75 23.5404 22.9574 23.0396 23.3266 22.6704Z"
                    fill="#D0D8D2"
                  />
                </svg>
                <div>
                  <h4 className="card-title mb-0 flex-grow-1">Pesan Siaran!</h4>
                  <p className="m-auto">
                    {moment(createdAt).locale("id").format("D MMMM YYYY")}
                  </p>
                </div>
              </CardHeader>
              <CardBody>
                <h5 className="mt-3 fw-bold">{title}</h5>
                {dataSelected != null ? (
                  dataSelected.length > 0 ? (
                    dataSelected.map((data) => (
                      <>
                        <p className="mt-3">{data.content}</p>

                        {/* image */}
                        {data.attachment != null &&
                        data.attachment.length > 0 ? (
                          <CarouselAttachment
                            images={processAttachment(
                              data.attachment,
                              "Image"
                            ).map((data) => ({
                              attachment: data.content,
                              Name: data.name,
                            }))}
                          />
                        ) : null}
                        {data.attachment != null &&
                        data.attachment.length > 0 ? (
                          <CardFile
                            item={processAttachment(
                              data.attachment,
                              "Document"
                            ).map((data) => ({
                              attachment: data.content,
                              Name: data.name,
                            }))}
                          />
                        ) : null}
                      </>
                    ))
                  ) : null
                ) : (
                  <Skeleton height={20} count={1} />
                )}
                <Button
                  style={{ float: "right" }}
                  color="primary"
                  onClick={toggle}
                >
                  Ok
                </Button>
              </CardBody>
            </Card>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default NotificationDropdown;
