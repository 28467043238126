import { ListGroup, ListGroupItem } from "reactstrap";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import LogoKejaksaan from "../../../../assets/images/logo/logo-kejaksaan.png"
import set_profile, { img_error } from "../../../../helpers/default_profile";

const UserList = ({ source, datas }) => {
    let { loading, data, failed, getSource } = useGetWidget({ source });
    if (datas?.length > 0) {
        data = datas
        failed = false
    }
    
    return (
        <BoundWidget
            loading={loading}
            data={data}
            failed={failed}
            onReload={getSource}
        >
            {data && (
                <ListGroup  style={{width: "100%"}}>
                    {data.map((dt, key) => (
                        <ListGroupItem  className="d-flex gap-4 align-items-center">
                            <img 
                                style={{width: "40px", height: "40px"}} 
                                src={set_profile(dt.photo_url || "")}
                                onError={(e) => img_error(e)} />
                                <div>
                                    <h5 style={{marginBottom: "2px"}}>{dt.name}</h5>
                                    <small style={{color: "#919191"}}>{dt.workunit}</small>
                                </div>
                        </ListGroupItem>
                    ))}
                </ListGroup>
            )}
        </BoundWidget>
    )
}

export default UserList