import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ModalReload = (props) => {
  const { show, onClose, dataReload } = props;
  return (
    <>
      <Modal isOpen={show} toggle={onClose} size="lg">
        <ModalHeader toggle={onClose}>Data Response</ModalHeader>
        <ModalBody>
          <table className="table w-75 mt-4">
            <tr>
              <th className="p-2">Sumber Data</th>
              <td>:</td>
              <td>{dataReload?.endpoint?.datasource.name}</td>
            </tr>
            <tr>
              <th className="p-2">Nama Endpoint</th>
              <td>:</td>
              <td>{dataReload?.endpoint?.name}</td>
            </tr>
            <tr>
              <th className="p-2">Path</th>
              <td>:</td>
              <td>{dataReload?.endpoint?.path}</td>
            </tr>
          </table>

          {dataReload?.response?.YEARLY && (
            <>
              <h5>Yearly</h5>
              <Card className="bg-light">
                <CardBody
                  style={{
                    height: "220px",
                    overflow: "auto",
                  }}
                >
                  <pre
                    dangerouslySetInnerHTML={{
                      __html: JSON.stringify(
                        dataReload?.response?.YEARLY,
                        null,
                        2
                      ),
                    }}
                  />
                </CardBody>
              </Card>
            </>
          )}

          {dataReload?.response?.MONTHLY && (
            <>
              <h5>Monthly</h5>
              <Card className="bg-light">
                <CardBody
                  style={{
                    height: "220px",
                    overflow: "auto",
                  }}
                >
                  <pre
                    dangerouslySetInnerHTML={{
                      __html: JSON.stringify(
                        dataReload?.response?.MONTHLY,
                        null,
                        2
                      ),
                    }}
                  />
                </CardBody>
              </Card>
            </>
          )}

          {dataReload?.response?.WEEKLY && (
            <>
              <h5>Weekly</h5>
              <Card className="bg-light">
                <CardBody
                  style={{
                    height: "220px",
                    overflow: "auto",
                  }}
                >
                  <pre
                    dangerouslySetInnerHTML={{
                      __html: JSON.stringify(
                        dataReload?.response?.WEEKLY,
                        null,
                        2
                      ),
                    }}
                  />
                </CardBody>
              </Card>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => onClose(!show)}>
            Tutup
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalReload;
