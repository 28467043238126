module.exports = {
  google: {
    API_KEY   : "",
    CLIENT_ID : "",
    SECRET    : "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "",
  }
};
