import { useEffect, useState } from "react";
import { APIClient } from "./api_helper";
import {
  API_BASEURL,
  DATA_COLLECTOR_BASE,
  WIDGET_SYNC_BASE,
} from "./url_helper";

const api = new APIClient();
export async function getWidget({ source }) {
  try {
    const response = await api.get(`${API_BASEURL}${source}`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function getDataTypeGroup() {
  try {
    const response = await api.get(`${DATA_COLLECTOR_BASE}/datatype-group/`);
    return response.data;
  } catch (error) {
    return null;
  }
}

export async function postImportData({ file, name, type_id, type_name }) {
  try {
    const form = new FormData();

    form.set("data_file", file);
    form.set("data_name", name);
    form.set("datatype_group_id", type_id);
    form.set("datatype_group_name", type_name);

    const response = await api.create(`${WIDGET_SYNC_BASE}/import/file`, form);
    return response.data;
  } catch (error) {
    return null;
  }
}

export function useGetWidget({ source, params }) {
  const [data, setData] = useState(null);
  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getSource();
  }, []);

  useEffect(() => {
    getSource();
  }, [source]);

  async function getSource(
    { cached, cacheTimeout, forced } = {
      cached: true,
      cacheTimeout: 60 * 1e3,
      forced: false,
    }
  ) {
    if(source === undefined){ 
      setLoading(false) 
      setFailed(true)
      return
    }
    try {
      setLoading(true);
      const url = `${API_BASEURL}${source}`;
      
      let body = null;
      if (!forced && cached) {
        body = await cacheRequest(url, source, {
          key: "Widget",
          timeout: cacheTimeout,
        });
        
      }
      if (!body) {
        let response

        if(typeof(source) === "object"){
          response = await api.create(url, source[1])
          console.log("POST")
        }else{
          response = await api.get(url);
          console.log("GETT")
        }
        console.log(url)
        body = response.data;
        console.log(body)
      }

      const data = Array.isArray(body)
        ? body.length
          ? body
          : null
        : "data" in body
        ? body.data
          ? body
          : null
        : body;



      setData(data);
      setFailed(!data);
    } catch (error) {
      console.log(error)
      setData(null);
      setFailed(true);
    } finally {
      setLoading(false);
    }
  }

  return { loading, data, failed, getSource, setter: { setData, setFailed } };
}

export function selectWidgetSource({ datatype, endpointId, period }) {
  let source = "";
  switch (datatype) {
    case "SINGLE DATA":
      source = `/data-collector/single-data/${endpointId}/endpoint?output=chart&period=${period.value}`;
      break;
    case "MULTIPLE DATA":
      source = `/data-collector/multiple-data/${endpointId}/endpoint?output=chart&period=${period.value}`;
      break;
    case "GIS":
      source = `/data-collector/gis/${endpointId}/endpoint?period=${period.value}`;
      break;
    case "NEWS":
      source = `/data-collector/news/${endpointId}/endpoint?period=${period.value}`;
      break;
    case "SIMPLE NEWS":
      source = `/data-collector/news/${endpointId}/endpoint?period=${period.value}`;
      break;
    case "RANKING":
      source = `/data-collector/ranking/${endpointId}/endpoint?period=${period.value}`;
      break;
    case "TABLE":
      source = `/data-collector/table/${endpointId}/endpoint?period=${period.value}`;
      break;
    default:
      break;
  }
  if (period.value == "custom") {
    source += `&start_date=${period.start}&end_date=${period.end}`;
  }
  return source;
}

async function cacheRequest(
  url, source,
  { key, timeout } = {
    key: "cache",
    timeout: 5e3,
  }
) {
  let metadata = [];
  try {
    metadata = JSON.parse(localStorage.getItem(key)) ?? [];
  } catch (error) {
    return null;
  }
  const index = metadata.findIndex((item) => item.url == url);
  if (index != -1) {
    const data = metadata[index];
    if (data.timeout > Date.now()) {
      return data.body;
    } else {
      metadata.splice(index, 1);
    }
  }
  let response
  if(typeof(source) === "object"){
    console.log(url)
    response = await api.create(url, source[1]);
  }else{
    response = await api.get(url);
  }

  metadata.push({
    url,
    body: response.data,
    timeout: Date.now() + timeout,
  });
  localStorage.setItem(key, JSON.stringify(metadata));
  return response.data;
}
