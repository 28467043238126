import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const Banner = () => {
  const [show, setShow] = useState(false);
  const announcement = useSelector((state) => state.Announcement);
  var banner = Cookies.get("banner");

  useEffect(() => {
    if (announcement.bannerData) {
      if (banner == "false") {
        setShow(true);
      }
    }
  }, []);
  return (
    <Modal isOpen={show} centered={true}>
      <ModalHeader
        toggle={() => {
          Cookies.set("banner", true);
          setShow(!show);
        }}
      >
        Informasi Pusat
      </ModalHeader>
      <ModalBody>
        {announcement.bannerData && (
          <div
            style={{
              background: "#606061",
              borderRadius: "10px",
              textAlign: "center",
              padding: "7px",
            }}
          >
            <img
              src={announcement?.bannerData?.attachments[0]?.url}
              className="rounded my-2"
              style={{
                width: "400px",
                height: "380px",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default Banner;
