import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ModalBody,
  Modal,
  ModalHeader,
  Button,
} from "reactstrap";

const AdminMenuDropdown = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.Profile);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          style={{ color: "#FFFFFF" }}
        >
          <i className=" bx bx-cog fs-22"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" right>
          {/* <h6 className="dropdown-header">Welcome {userName}!</h6> */}
          <>
            <DropdownItem className="px-1 py-1">
              <Link
                to={process.env.PUBLIC_URL + "/sumber-data"}
                className="dropdown-item"
                onClick={() => dispatch(openChatBox(false))}
              >
                <i className="mdi mdi-server-security text-muted fs-13 align-middle me-2"></i>
                <span className="align-middle fs-13">Sumber Data</span>
              </Link>
            </DropdownItem>
            <DropdownItem className="px-1 py-1">
              <Link
                to={process.env.PUBLIC_URL + "/endpoint"}
                className="dropdown-item"
                onClick={() => dispatch(openChatBox(false))}
              >
                <i className="mdi mdi-history text-muted fs-13 align-middle me-2"></i>
                <span className="align-middle fs-13">Endpoint</span>
              </Link>
            </DropdownItem>
            <DropdownItem className="px-1 py-1">
              <Link
                to={process.env.PUBLIC_URL + "/management-default-dashboard"}
                className="dropdown-item"
                onClick={() => dispatch(openChatBox(false))}
              >
                <i className="mdi mdi-table-edit text-muted fs-13 align-middle me-2"></i>
                <span className="align-middle fs-13">
                  Management Default Dashboard
                </span>
              </Link>
            </DropdownItem>
          </>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default AdminMenuDropdown;
