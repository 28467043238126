import { getNotifSiacc, updateNotifSiacc } from "./thunk";

import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
	message: null,
	loading: true,
	data: null,
	success: false,
};

const notificationSiaccSlice = createSlice({
	name: "notification siacc",
	initialState,
	reducers: {
		setNotifSiacc: (state, action) => {
			state.data = action.payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getNotifSiacc.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					state.data = payload;
					state.success = true;
					state.loading = false;
					state.message = "Sukses Menampilkan Data";
				} else {
					state.data = null;
					state.success = false;
					state.loading = false;
					state.message = "Gagal Menampilkan Data";
				}
			})
			.addCase(updateNotifSiacc.fulfilled, (state, { payload }) => {
				state.loading = false;
				if (payload) {
					state.data = payload;
					state.success = true;
					state.loading = false;
					state.message = "Sukses Memperbarui Data";
				} else {
					state.data = null;
					state.success = false;
					state.loading = false;
					state.message = "Gagal Memperbarui Data";
				}
			});
	},
});

export const { setNotifSiacc } = notificationSiaccSlice.actions;

export default notificationSiaccSlice.reducer;
