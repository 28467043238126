import Cookies from "js-cookie";
import { APIClient } from "./api_helper"
import { API_BASEURL } from "./url_helper";
import user_info from "../helpers/user_info";

export function widgetDetailParams({type,endpointId,paramChild, condition}){
    // console.log(type)
    switch(type){
        case "work_unit_ids":
            return WorkUnitIDS(endpointId,paramChild, condition);
        case "work_unit_id":
            return WorkUnitID(endpointId,paramChild, condition);
        case "work_unit_id_by_login" :
            return WorkUnitIDByLogin(endpointId, condition);
        case "work_unit_id_and_work_unit_ids":
            return WorkUnitIDAndWorkUnitIDS(endpointId, paramChild, condition)
    }
}

export async function WorkUnitIDS(endpointId,paramChild, condition){
    let api = new APIClient();
    const res = await api.get(API_BASEURL+"/data-collector/workunit/"+paramChild)
    const workunit_level_id = res.data.workunit.workunit_level_id.toString()

    let params = {
        "workunit_id": paramChild,
        "endpoint_root_id" : endpointId,
        "workunit_level_id" : workunit_level_id,
        "additional_parameter" :{
            "work_unit_ids" : res.data.children,
            "condition": condition
        }
    }

    return params
}

export async function WorkUnitID(endpointId,paramChild, condition){
    let api = new APIClient();
    const res = await api.get(API_BASEURL+"/data-collector/workunit/"+paramChild)
    const workunit_level_id = res.data.workunit.workunit_level_id.toString()

    let params = {
        "workunit_id": paramChild,
        "endpoint_root_id" : endpointId,
        "workunit_level_id" : workunit_level_id,
        "additional_parameter" :{
            "work_unit_id" : parseInt(paramChild),
            "condition": condition
        }
    }

    return params
}

export async function WorkUnitIDByLogin(endpointId, condition){
    let api = new APIClient();
    var work_unit_id = user_info().workunit_id
    const res = await api.get(API_BASEURL+"/data-collector/workunit/"+work_unit_id)
    const workunit_level_id = res.data.workunit.workunit_level_id.toString()
    
    let params = {
        "workunit_id": work_unit_id.toString(),
        "endpoint_root_id" : endpointId,
        "workunit_level_id" : workunit_level_id,
        "additional_parameter" :{
            "work_unit_id" : work_unit_id,
            "condition": condition
        }
    }


    return params
}

export async function WorkUnitIDAndWorkUnitIDS(endpointId,paramChild, condition){
    let api = new APIClient();
    const res = await api.get(API_BASEURL+"/data-collector/workunit/"+paramChild)
    const workunit_level_id = res.data.workunit.workunit_level_id.toString()

    let params = {
        "workunit_id": paramChild,
        "endpoint_root_id" : endpointId,
        "workunit_level_id" : workunit_level_id,
        "additional_parameter" :{
            "work_unit_ids" : [...res.data.children, parseInt(paramChild) ],
            "condition": condition
        }
    }

    return params
}