import React, { Fragment, useEffect, useState } from "react";

import { Card, Col, Row, Container, Button } from "reactstrap";

import SidebarProfile from "./SidebarProfile";
import SwitchButton from "../../Components/Common/SwitchButton";

import { APIClient } from "../../helpers/api_helper";

import _ from "lodash";
import { WIDGET_SYNC_BASE } from "../../helpers/url_helper";
import SimpleBar from "simplebar-react";
import user_info from "../../helpers/user_info";
import CustomToast from "../../Components/Common/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { getMenuDashboards } from "../../slices/thunks";

const api = new APIClient();

const Notifikasi = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	
	const dispatch = useDispatch();
	const dashboards = useSelector(state => state.Dashboards);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (dashboards.isInitial || dashboards.isUpdate) {
			// dispatch(getDashboards());
			dispatch(getMenuDashboards());
		}
	}, [dashboards.isInitial, dashboards.isUpdate]);

	const fetchData = () => {
		api
			.get(WIDGET_SYNC_BASE + "/sync/dashboard?widget_only=true")
			.then(response => {
				setData(response.data.result.widgets);
				setLoading(false);
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	const updateData = (dashboard_id, _id, notification) => {
		const body = {
			notification: notification,
		};

		api
			.put(
				WIDGET_SYNC_BASE + `/sync/dashboard/${dashboard_id}/${_id}/widget`,
				body
			)
			.then(response => {
				setLoading(false);
				CustomToast("success", "Berhasil Mengatur Notifikasi!");
			})
			.catch(error => {
				console.error(error);
				setLoading(false);
			});
	};

	const handleToggle = (dashboard_id, _id) => {
		const updatedData = data.map(item => {
			if (item._id === _id) {
				const newNotification = !item.notification; // Mendapatkan nilai terbaru notifikasi
				updateData(dashboard_id, _id, newNotification); // Panggil updateData dengan nilai terbaru notifikasi
				return {
					...item,
					notification: newNotification,
				};
			}
			return item;
		});

		setData(updatedData);
	};

	return (
		<React.Fragment>
			<div className="page-content pb-0">
				<Container fluid>
					<Row>
						<Col md="3">
							<SidebarProfile title="notifikasi" />
						</Col>
						<Col md="9">
							<Card style={{ height: "82vh", overflow: "auto" }}>
								<SimpleBar style={{ height: "82vh" }}>
									<div className="p-4">
										<p className="fs-18 fw-semibold mb-0">Notifikasi</p>
										<p className="text-muted fs-14 mb-4">
											Kelola informasi yang akan ditampilkan pada notifikasi
											Anda
										</p>

										<Row>
											<Col md={12}>
												{loading ? (
													<p>Loading...</p>
												) : (
													data.map(value => (
														<div
															key={value._id}
															className="d-flex justify-content-between"
														>
															<div>
																<p className="fs-18 fw-semibold mb-0">
																	{value.name}
																</p>
																<p className="text-muted">
																	Merupakan widgets dari {value.dashboard_name}
																</p>
															</div>
															<div>
																<SwitchButton
																	title={value.notification ? "ON" : "OFF"}
																	isToggled={value.notification}
																	onToggle={() =>
																		handleToggle(value.dashboard_id, value._id)
																	}
																/>
															</div>
														</div>
													))
												)}
											</Col>
										</Row>
									</div>
								</SimpleBar>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Notifikasi;
