import { useState } from "react";
import { Line } from "react-chartjs-2"

const RangkingPerformance = ({performances}) => {
    // const [data, setData] = useState([])


    const options = {
        responsive: true,
        tension: 0.4,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
            position: "bottom",
          },
          title: {
            display: false,
          },
        },
        scales: {
            // to remove the labels
            
            x: {
              ticks: {
                display: false,
                beginAtZero: true,
              },
        
              // to remove the x-axis grid
              grid: {
                drawBorder: false,
                display: false,
              },
            },
            // to remove the y-axis labels
            y: {
              ticks: {
                display: false,
                beginAtZero: true,
              },
              // to remove the y-axis grid
              grid: {
                drawBorder: false,
                display: false,
              },
            },
          },
      };

      const labels = []
      const datas = []
      performances?.map((item) => {
        labels.push(item.label)
        datas.push(item.value)
      })

      const data = {
        labels,
        datasets: [
          {
            // label: 'Dataset 1',
            fill: true,
            data: datas,
            borderColor: 'rgb(23, 97, 56)',
            // backgroundColor: 'transparent'
            backgroundColor: (context) => {
                if(!context.chart.chartArea){
                    return
                }
                const {ctx} = context.chart;
                const gradient = ctx.createLinearGradient(0, 0, 0, 85);
                gradient.addColorStop(0, "rgba(23, 97, 56, 0.2)");
                gradient.addColorStop(1, "rgba(20,174,50,0)");
                return gradient;
            },
          }
        ],
      };

    return <div style={{height:"70px", width:"100%"}}>
        <Line options={options} data={data} />
    </div>
}

export default RangkingPerformance