import ExportTableMultipleData from "./multiple-data";
import ExportTableSingleData from "./single-data";
import ExportTableData from "./table";
import "./index.scss";
import ExportTableRanking from "./ranking";
import ExportTableNews from "./news";
import ExportTableGIS from "./gis";

const ExportTablePDF = ({ dashboards, data }) => {
  return (
    <div id="wrapper">
      {dashboards?.current?.widget?.map((widget, index) => {
        let source = widget.source;
        let data_type = source.split("/")[2];
        
        switch (data_type) {
          case "single-data":
            return (
              <ExportTableSingleData
                source={source}
                data={data[index]}
                name={widget.name}
              />
            );
          case "multiple-data":
            return (
              <ExportTableMultipleData
                source={source}
                data={data[index]}
                name={widget.name}
              />
            );
          case "table":
            return (
              <ExportTableData
                source={source}
                data={data[index]}
                name={widget.name}
              />
            );
          case "ranking":
            return (
              <ExportTableRanking
                source={source}
                data={data[index]}
                name={widget.name}
              />
            );
          case "news":
            return (
              <ExportTableNews
                source={source}
                data={data[index]}
                name={widget.name}
              />
            );
          case "gis":
            return (
              <ExportTableGIS
                source={source}
                data={data[index]}
                name={widget.name}
              />
            );
        }
       
      })}
    </div>
  );
};

export default ExportTablePDF;
