import axios       from "axios"
import { api }     from "../config"
import CustomToast from "../Components/Common/CustomToast"
import user_info, { isAdmin }   from "./user_info"
import { API_SIACC } from "./url_helper"
import Cookies from "js-cookie"

const accessControlOrigin = ".sysdev.id, .underdev.team, localhost:3000, .mapbox.com, localhost:3001, localhost:9000"

// axios default config
axios.defaults.baseURL                                    		= api.API_URL
axios.defaults.headers.post["Content-Type"]               		= "application/json; charset=utf-8"
axios.defaults.headers.common["X-User-Id"]                		= user_info()?.uuid_user
axios.defaults.headers.common["X-User-Role"]              		= user_info()?.user_group[0].title
axios.defaults.headers.common["X-User-Detail-Id"]  		  		= user_info()?.id
axios.defaults.headers.common["X-User-Workunit-Level-Id"] 		= isAdmin ? 1 :` ${user_info()?.workunit_level_id}`
axios.defaults.headers.common["X-User-Workunit-Child-Id"] 		= `${user_info()?.child_id}`

axios.defaults.headers.common["X-User-Workunit-ID"] 			= isAdmin ? 1 : `${user_info()?.workunit_id}`

axios.defaults.headers.common["Access-Control-Allow-Origin"] 	= accessControlOrigin


// auth token
const token = Cookies.get("token") ? JSON.parse(Cookies.get("token")) : null
const siaccToken = Cookies.get("siaccToken") ? JSON.parse(Cookies.get("siaccToken")) : null
// if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token

axios.defaults.headers.common["X-SIACC-Token"] = siaccToken

// intercepting to capture errors
axios.interceptors.response.use(
	function (response) {
		let status = response.data.status
		switch(status){
			case 401:
				CustomToast("danger", "Sesi telah berakhir, login kembali")
				Cookies.remove("authUser")
				Cookies.remove("token")
				Cookies.remove("siaccToken")
				window.location.replace('/login')
				break
		}
		return response.data ? response.data : response
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		let message
		// console.log(error.response)
		switch (error.response.status) {
			case 500:
				message = "Internal Server Error"
				console.log(500)
				break
			case 401:
				message = "Invalid credentials"
				CustomToast("danger", "Sesi telah berakhir, login kembali")
				Cookies.remove("authUser")
				Cookies.remove("token")
				Cookies.remove("siaccToken")
				window.location.replace('/login')
				break
			case 404:
				message = "Sorry! the data you are looking for could not be found"
				break
			default:
				message = error.message || error
		}

		return Promise.reject(message)
	}
)
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
	
	// axios.defaults.headers.common["Authorization"] = "Bearer " + token
	axios.defaults.headers.common["X-User-Id"]     = user_info()?.uuid_user
	axios.defaults.headers.common["X-User-Role"]   = user_info()?.user_group[0].title
}

class APIClient {
	/**
   * Fetches data from given url
   */

	//  get = (url, params) => {
	//   return axios.get(url, params);
	// };
	get = (url, params) => {
		getToken(url);
		

		let response

		let paramKeys = []

		if (params) {
			Object.keys(params).map(key => {
				if (params[key]) {
					paramKeys.push(key + "=" + params[key])
					return paramKeys
				}
			})

			const queryString = paramKeys && paramKeys.length ? paramKeys.join("&") : ""
			response          = axios.get(`${url}?${queryString}`, params)
		} else {
			response = axios.get(`${url}`, params)
		}

		return response
	}

	/**
   * post given data to url
   */
	create = (url, data) => {
		getToken(url);
		return axios.post(url, data)
	}

	createMultipart = (url, data) => {	
		getToken(url);	
		return axios.post(url, data, {
			headers: {
				'Content-Type': 'multipart/form-data', // Set the content type for multipart requests
			},
		});
	};

	/**
   * Updates data
   */
	patch = (url, data) => {
		getToken(url);
		return axios.patch(url, data)
	}

	put = (url, data) => {
		getToken(url);
		return axios.put(url, data)
	}


	/**
   * Delete
   */
	delete = (url, config) => {
		getToken(url);
		return axios.delete(url, { ...config })
	}
}

const getToken = (url) => {
	let detectBaseUrl = url.search(API_SIACC);
		
		if(detectBaseUrl != -1) {
			axios.defaults.headers.common["Authorization"] = "Bearer " + siaccToken
		}else{
			axios.defaults.headers.common["Authorization"] = "Bearer " + token
		}
}
const getLoggedinUser = () => {
	const user = Cookies.get("authUser") 
	if (!user) {
		return null
	} else {
		return JSON.parse(user)
	}
}

export { APIClient, setAuthorization, getLoggedinUser }