import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "!mapbox-gl";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";
import logo from "../../../../assets/images/logo/logo-kejaksaan.png";
import ModalDetail from "../../detail-modal";
import { APIClient } from "../../../../helpers/api_helper";
import { API_BASEURL } from "../../../../helpers/url_helper";

const Maps = (props) => {
  const api = new APIClient();
  const ref = useRef();
  useEffect(() => {
    ref.current.id = "map_" + Math.random().toString(36).slice(2, 7);

    // mapboxgl.accessToken = process.env.NODE_ENV || process.env.NODE_ENV !== "production" ?
    //   process.env.REACT_APP_MAPBOX_API : window._env_.REACT_APP_API_MAPBOX;
    const REACT_APP_API_MAPBOX =
      "pk.eyJ1IjoiemVwaHlyZm4iLCJhIjoiY2trNzI5bWN1MDlubDJ1cW94Z3hicm9qdCJ9.HPNjaaL1I5rkMdSg1AJf5g";
    mapboxgl.accessToken = REACT_APP_API_MAPBOX;

    const map = new mapboxgl.Map({
      container: ref.current.id,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [113.921327, -0.789275],
      zoom: 3.2,
    });
    ref.map = map;
    map.addControl(new mapboxgl.FullscreenControl());
    map.addControl(new mapboxgl.NavigationControl());

    window.map = map;
    window.openDetail = async function openDetail(
      endpoint_child,
      source,
      type,
      param_child,
      endpoint_root_id,
      childType
    ) {
      await props.toggleOpenModalSave({
        endpoint_child,
        source,
        type,
        param_child,
        endpoint_root_id,
        childType,
      });
    };
  }, []);
  useEffect(() => {
    if (!ref.map) return;
    for (const item of props.data ?? []) {
      const div = document.createElement("div");
      const marker = document.createElement("div");
      const tail = document.createElement("div");
      const ctn = document.createElement("div");
      const notif = document.createElement("span");

      marker.className = "maps_marker";
      ctn.className = "maps_marker_ctn";
      ctn.textContent = item.value;
      tail.className = "maps_marker_tail";
      if (item.is_update) {
        ctn.className = "maps_marker_ctn";
        notif.className = "maps_marker_red";
      } else {
        ctn.className = "maps_marker_ctn";
      }
      ctn.textContent = item.value;
      tail.className = "maps_marker_tail";

      marker.append(ctn, tail, notif);
      div.append(marker);

      const button = (item) =>
        item.endpoint_child !== ""
          ? `<button type="button" class="btn btn-primary btn-sm" onclick="openDetail('${item.endpoint_child}', '${item.source}','${item.type}','${item.param_child}', '${item.endpoint.id}','${item.endpoint_child_type}')">Lihat Detail</button>`
          : ``;

      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
        `<div class="text-center p-2" style="width:200px">
          <img src="${logo}" width="60px" class="mb-2">
          <h6> ${item.label}</h6> 
          <h5> ${item.value}</h5>
          ${button(item)}
        </div>`
      );

      new mapboxgl.Marker(div)
        .setLngLat([+item.longitude, +item.latitude])
        .setPopup(popup)
        .addTo(ref.map);
    }
  }, [props.data]);
  return (
    <div ref={ref} id="map" style={{ width: "100%", height: "380px" }}></div>
  );
};

const Gis = ({ source, params }) => {
  const { loading, data, failed, getSource } = useGetWidget({ source, params });
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [child, setChild] = useState();
  function toggleOpenModalSave(child_data) {
    setChild(child_data);
    setOpenModalDetail(!openModalDetail);
  }

  return (
    <>
      <BoundWidget
        loading={loading}
        data={data}
        failed={failed}
        onReload={getSource}
      >
        {data && (
          <Maps toggleOpenModalSave={toggleOpenModalSave} data={data}></Maps>
        )}
      </BoundWidget>

      <ModalDetail
        openModalDetail={openModalDetail}
        toggleOpenModalDetail={toggleOpenModalSave}
        child={{ ...child, parentSource: source, period: source.split("=")[1] }}
      />
    </>
  );
};

export default Gis;
