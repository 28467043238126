import { useEffect, useRef, useState } from "react";
import { Bar, Doughnut, Pie, getElementAtEvent } from "react-chartjs-2";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from "chart.js/auto";
import random_color from "../../../../helpers/random_color";
import ModalDetail from "../../detail-modal";

const ChartDoughnut = ({ source, params }) => {
  const { loading, data, failed, getSource } = useGetWidget({ source, params });

  const [rcd, setRcd] = useState({
    data: null,
    datasets: [],
    ignoreColor: false,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: function (context) {
          return context.dataset.data[context.dataIndex] >= 1;
        },
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: Math.round,
        padding: 6
      },
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  useEffect(() => {
    if (data) {
      if (data.datasets) {
        setRcd({
          labels: data.labels,
          datasets: data.datasets,
        });
      } else {
        let color = random_color(data.background_color)
        setRcd({
          labels: data.labels,
          datasets: [
            {
              label: data.label,
              data: data.data,
              backgroundColor: color,
              borderColor: color,
            },
          ],
        });
      }
    }
  }, [data]);

  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [child, setChild] = useState()
  function toggleOpenModalSave(child_data, label) {
    setChild({
      period: source?.split("=")[2],
      source: data.source,
      enpoint_root_id: data.endpoint_id,
      param_child: child_data?.toString(),
      childType: data.endpoint_child_type,
      type: data.type,
      condition: label
    })
    setOpenModalDetail(!openModalDetail);
  }
  const chartRef = useRef();
  const handleChartClick = async (e, source) => {
    if (getElementAtEvent(chartRef.current, e).length === 0 || data.endpoint_child.length === 0) return
    
    const index = getElementAtEvent(chartRef.current, e)[0].index;
    const label = chartRef.current.data.labels[index]
    toggleOpenModalSave(_, label)
    // await props.toggleOpenModalSave({endpoint_child, source, type, param_child, endpoint_root_id,childType})
  }

  return (
    <>
      <BoundWidget
        loading={loading}
        data={data}
        failed={failed}
        onReload={getSource}
      >
        {data && (
          <div style={{ height: "380px" }}>
            <Doughnut
              ref={chartRef} role="button"
              onClick={(e) => handleChartClick(e, source)} options={options} data={rcd} plugins={[ChartDataLabels]} />
          </div>
        )}
      </BoundWidget>
      <ModalDetail
        toggleOpenModalDetail={toggleOpenModalSave}
        openModalDetail={openModalDetail}
        child={child} // child: {type, data}
      />
    </>
  );
};

export default ChartDoughnut;
