import { APIClient } from "../../helpers/api_helper";
import { EMPLOYE_SIACC } from "../../helpers/url_helper";
import { createAsyncThunk } from "@reduxjs/toolkit";

const api = new APIClient();

export const getEmployeBiodata = createAsyncThunk(
  "employe/get",
  async (payload) => {
    try {
      const response = await api.get(
        EMPLOYE_SIACC + `/employee`,
        payload
      );
      return response.data.employee;
    } catch (error) {
      return null;
    }
  }
);
