import React, { useState, useEffect } from "react";
import {
	Container,
	Row,
	Col,
	Card,
	Alert,
	CardBody,
	Button,
	Label,
	Input,
	Form,
	FormGroup,
	FormText,
	FormFeedback,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Spinner,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import SidebarProfile from "./SidebarProfile";
import { loadProfile } from "../../slices/auth/profile/reducer";
import {
	getDashboards,
	getMenuDashboards,
	patchProfile,
	patchProfilePhoto,
} from "../../slices/thunks";
import _ from "lodash";
import LogoKejasaan from "../../assets/images/logo/logo-kejaksaan.png";

const schema = Yup.object({
	name: Yup.string().required("Please Enter Your Name"),
	email: Yup.string()
		.email("must be a valid email")
		.required("Please Enter Your Email")
		.matches(/@kejaksaan\.go\.id$/, "Gunakan Email Kejaksaan"),
	phone_number: Yup.string().required("Please Enter Your Phone Number"),
});

const UserProfile = () => {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.Profile);
	const dashboards = useSelector(state => state.Dashboards);
	const [state, setState] = useState({});
	const [inputToChange, setInputToChange] = useState("");
	const [modalInput, setModalInput] = useState(false);
	const [modalConfirm, setModalConfirm] = useState(false);

	const formik = useFormik({
		initialValues: profile.data,
		validationSchema: schema,
		onSubmit: values => {
			console.log(values);
		},
	});
	const toggleModalInput = () => setModalInput(!modalInput);
	const toggleModalConfirm = () => setModalConfirm(!modalConfirm);

	const [photo, setPhoto] = useState(null); // Menambahkan state untuk gambar yang dipilih
	const [imageBlob, setImageBlob] = useState(null); // Menambahkan state untuk Blob gambar
	const [imageError, setImageError] = useState(false);
	const [imagePreview, setImagePreview] = useState(null); // Menambahkan state untuk menampilkan preview gambar
	const [imgB64, setImgB64] = useState(null)

	useEffect(() => {
		
		if (photo) {
			// Menggunakan FileReader untuk membuat Blob dari gambar
			const reader = new FileReader();
			reader.readAsArrayBuffer(photo);
			reader.onload = function () {
				const blob = new Blob([reader.result], { type: photo.type });
				setImageBlob(blob);

				// Mengatur preview gambar
				const url = URL.createObjectURL(blob);
				setImagePreview(url);
			};
		}
	}, [photo]);

	function handleBrowseFile() {
		const elm = document.getElementById("input_file");
		let b64str = ""
		elm.addEventListener(
			"change",
			event => {
				if (!elm.files[0]) return;
				const file = elm.files[0];
				let reader = new FileReader()
				reader.onload = () => {
					b64str = reader.result
					.replace("data:", "")
                    .replace(/^.+,/, "")
					setImgB64("data:image/png;base64,"+b64str)
				}
				reader.readAsDataURL(file)
				setPhoto(file);
				if (window.profile_photo) {
					URL.revokeObjectURL(window.profile_photo);
				}
				window.profile_photo = URL.createObjectURL(file);
			},
			{ once: true }
		);
		elm.click();
	}

	const handleImageError = () => {
		setImageError(true);
	};
	const defaultImageSrc = LogoKejasaan;
	const imageSrc =
		imageError || !profile.data.photo ? defaultImageSrc : profile.data.photo;

	// useEffect(() => {
	// 	console.log(profile);
	// }, [profile]);

	useEffect(() => {
		dispatch(loadProfile());
	}, []);

	useEffect(() => {
		setState(profile.data);
	}, [profile.data]);

	useEffect(() => {
		if (dashboards.isInitial || dashboards.isUpdate) {

			dispatch(getMenuDashboards());
		}
	}, [dashboards.isInitial, dashboards.isUpdate]);

	function handleChange() {
		toggleModalInput();
		setInputToChange("");
		setState(
			Object.assign({}, state, {
				[inputToChange]: formik.values[inputToChange],
			})
		);
	}

	function handleInput(value) {
		setInputToChange(value);
		toggleModalInput();
	}

	async function handleSave() {
		toggleModalConfirm();
		const updatedProfile = {
			...state,
			email: formik.values.email || state.email,
			phone_number: formik.values.phone_number || state.phone_number,
		};

		if (!_.isEqual(updatedProfile, profile.data)) {
			console.log(updatedProfile);
			dispatch(patchProfile(updatedProfile));
		}

		if (photo) {
			const payload = new FormData();
			payload.append("uuid", state.uuid);
			payload.append("photo[]", photo);
			payload.append("imgB64", imgB64);
			dispatch(patchProfilePhoto(payload));
			
		}
	}

	document.title = "Profile | Dashboard Executive";

	return (
		<React.Fragment>
			<div className="page-content pb-0">
				<Container fluid>
					<Row>
						<Col md="3">
							<SidebarProfile title="profile" />
						</Col>
						<Col md="9">
							<Card style={{ height: "82vh", overflow: "auto" }}>
								<div className="p-4">
									<p className="fs-18 fw-semibold mb-0">Profil Saya</p>
									<p className="text-muted fs-14 mb-4">
										Kelola informasi profil Anda untuk mengontrol, melindungi
										dan mengamankan akun
									</p>

									<Row>
										<Col md="8">
											<div className="mb-3">
												<p className="mb-1 fs-14">Nama Lengkap</p>
												<p className="fs-16">{state.name}</p>
											</div>
											<div className="mb-3">
												<p className="mb-1 fs-14">Username</p>
												<p className="fs-16">{state.name}</p>
											</div>
											<div className="mb-3">
												<div className="d-flex">
													<div className="me-3">
														<p className="mb-1 fs-14">Email</p>
														<p className="fs-16 mb-1">{state.email}</p>
													</div>
													<div className="align-self-center">
														<Button
															color="primary"
															outline
															onClick={() => handleInput("email")}
														>
															Ubah
														</Button>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<div className="d-flex">
													<div className="me-3">
														<p className="mb-1 fs-14">Nomor Telepon</p>
														<p className="fs-16 mb-1">
															{state.phone_number
																? state.phone_number
																: "Nomor kosong"}
														</p>
													</div>
													<div className="align-self-center">
														<Button
															color="primary"
															outline
															onClick={() => handleInput("phone_number")}
														>
															Ubah
														</Button>
													</div>
												</div>
											</div>
											<div className="mb-3">
												<p className="mb-1 fs-14">Wilayah</p>
												<p className="fs-16">{state.workunit_level}</p>
											</div>
											<div className="mb-3">
												<p className="mb-1 fs-14">Posisi</p>
												<p className="fs-16">{state.position}</p>
											</div>
										</Col>
										<Col md={4}>
											<div className="text-center">
												<img
													src={imagePreview || imageSrc} // Menggunakan imagePreview jika ada, jika tidak, gunakan imageSrc
													alt="photo"
													onError={handleImageError}
													className="rounded"
													width={160}
													style={{ objectFit: "cover" }}
												/>
												<Button
													outline
													color="primary"
													className="d-block mx-auto mt-4 mb-2 fw-semibold"
													onClick={handleBrowseFile}
												>
													Pilih Gambar
												</Button>
												<input
													id="input_file"
													type="file"
													accept="image/*"
													style={{ display: "none" }}
												/>
												<div className="mt-3">
													<p className="text-muted fs-14 mb-2">
														Ukuran gambar: maks. 1 MB
													</p>
													<p className="text-muted fs-14">
														Format gambar: .JPEG, .PNG
													</p>
												</div>
											</div>
										</Col>
										<div>
											<Button
												color="primary"
												disabled={profile.isLoading}
												onClick={() => setModalConfirm(true)}
											>
												{profile.isLoading ? (
													<div className="d-flex gap-2 w-100 justify-content-center">
														<Spinner size="sm">Loading...</Spinner>
														<span>Memuat</span>
													</div>
												) : (
													<span>Simpan</span>
												)}
											</Button>
										</div>
									</Row>
								</div>
							</Card>
						</Col>
					</Row>
					<Modal isOpen={modalInput} toggle={toggleModalInput}>
						<ModalHeader toggle={toggleModalInput}>
							Ubah {inputToChange == "email" ? "Email" : "Nomor Telepon"}
						</ModalHeader>
						<ModalBody>
							<Form>
								{inputToChange == "email" && (
									<FormGroup>
										<Label for="email">Alamat Email</Label>
										<Input
											id="email"
											name="email"
											type="email"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											placeholder="Masukkan Alamat Email Baru"
											invalid={
												formik.touched.email && formik.errors.email
													? true
													: false
											}
										/>
										<FormFeedback
											invalid={formik.touched.email && formik.errors.email}
										>
											{formik.errors.email}
										</FormFeedback>
									</FormGroup>
								)}
								{inputToChange == "phone_number" && (
									<FormGroup>
										<Label for="phone_number">Nomor Telepon</Label>
										<Input
											id="phone_number"
											name="phone_number"
											type="tel"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											placeholder="Masukkan Nomor Telepon Baru"
											invalid={!!formik.errors.phone_number}
										/>
										<FormFeedback invalid={formik.errors.phone_number}>
											{formik.errors.phone_number}
										</FormFeedback>
									</FormGroup>
								)}
							</Form>
						</ModalBody>
						<ModalFooter className="gap-2 justify-content-center">
							<Button color="secondary" onClick={toggleModalInput}>
								Batal
							</Button>
							<Button
								color="primary"
								disabled={!!formik.errors[inputToChange]}
								onClick={handleChange}
							>
								Ganti
							</Button>
						</ModalFooter>
					</Modal>
					<Modal isOpen={modalConfirm} toggle={toggleModalConfirm}>
						<ModalHeader toggle={toggleModalConfirm}>
							Konfirmasi Perubahan
						</ModalHeader>
						<ModalBody>Apakah anda yakin ingin menyimpan perubahan?</ModalBody>
						<ModalFooter className="gap-2 justify-content-center">
							<Button color="secondary" onClick={toggleModalConfirm}>
								Batal
							</Button>
							<Button color="primary" onClick={handleSave}>
								Ya
							</Button>
						</ModalFooter>
					</Modal>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default UserProfile;
