import { Fragment, useContext, useEffect, useState } from "react";
import styles from "./ChatBox.module.css";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Header from "../../Layouts/Header";
import ChatBody from "./ChatBody";
import ChatList from "./ChatList";
import ChatKosongImg from "../../assets/images/chat-kosong.png";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../context/SocketContext";
import SelectSingleUser from "../../Components/Common/select-single-user";
import { getEmployeBiodata, getRoomChat } from "../../slices/thunks";
import SelectMultipleUser from "../../Components/Common/select-multiple-user";

const Chat = () => {
  const chat = useSelector((state) => state.Chat);
  const dispatch = useDispatch();

  const { connectChatSocket, personalRoom, groupRoom } =
    useContext(SocketContext);

  const [modalPersonal, setModalPersonal] = useState(false);
  const [modalGroup, setModalGroup] = useState(false);
  const [groupName, setGroupName] = useState("");

  useEffect(() => {
    connectChatSocket();
    dispatch(getEmployeBiodata());
  }, []);

  const createPersonalRoom = (user) => {
    personalRoom(user);
    setModalPersonal(!modalPersonal);
  };

  const createGroupRoom = (user) => {
    groupRoom(groupName, user);
    setModalGroup(!modalGroup);
  };

  return (
    <Fragment>
      <SelectSingleUser
        show={modalPersonal}
        setShow={() => setModalPersonal(!modalPersonal)}
        title="Komunikasi Baru"
        size="md"
        onSubmit={(user) => createPersonalRoom(user)}
      />

      <SelectMultipleUser
        show={modalGroup}
        setShow={() => setModalGroup(!modalGroup)}
        title="Komunikasi Group"
        size="md"
        onSubmit={(users) => createGroupRoom(users)}
      >
        <FormGroup>
          <Label for="nama_group">Nama Group</Label>
          <Input
            id="nama_group"
            name="nama_group"
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </FormGroup>
      </SelectMultipleUser>

      <Header headerClass={styles.topbar} isChatLayout={true} />
      <div className="main-content" style={{ marginLeft: 0 }}>
        <div className="page-content" style={{ height: "100vh" }}>
          <Row style={{ height: "100%" }}>
            <Col md={4}>
              <Card style={{ padding: "20px" }}>
                <ChatList
                  height="height: calc(100vh - 306px) !important;"
                  classList="chat-list"
                  isFull={true}
                  personal={() => setModalPersonal(!modalPersonal)}
                  group={() => setModalGroup(!modalGroup)}
                  closeDropdown={()=>{}}

                />
              </Card>
            </Col>
            <Col md={8}>
              <Card style={{ overflow: "hidden" }}>
                {chat.chatActive == null ? (
                  <div
                    className={styles.empty_conversation}
                    style={{
                      // backgroundImage: `url(${ChatKosongImg})`,
                      // backgroundImage: "linear-gradient(to top, #0ba360 0%, #3cba92 100%)",
                      // backgroundImage: "linear-gradient(-20deg, #00cdac 0%, #8ddad5 100%)",
                      backgroundImage:
                        "linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%)",
                      // backgroundImage: "linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)",
                      width: "100%",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      className="bg-white rounded-circle p-4 mx-auto"
                      style={{ width: "80px", height: "80px" }}
                    >
                      <i
                        className="mdi mdi-message-outline fs-1"
                        style={{ marginTop: "-8px", display: "inline-block" }}
                      ></i>
                    </span>
                    <Button
                      className="bg-white border-0 px-3 mt-3 text-dark"
                      style={{
                        borderRadius: "24px",
                        boxShadow: "0 2px 3px rgba(56, 65, 74, 0.15)",
                      }}
                    >
                      Mulai Percakapan
                    </Button>
                  </div>
                ) : (
                  <ChatBody isFull={true} />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default Chat;
