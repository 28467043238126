import React                       from "react";
import ReactDOM                    from "react-dom/client";
import App                         from "./App";
import reportWebVitals             from "./reportWebVitals";
import { BrowserRouter }           from "react-router-dom";
import { Provider }                from "react-redux";
import { configureStore }          from "@reduxjs/toolkit";
import rootReducer, { middleware } from "./slices";
import { ToastContainer }          from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import { initFirebaseMessaging }   from "./helpers/firebase_helper";

const store = configureStore({
  reducer  : rootReducer,
  devTools : true,
  middleware,
});


const root = ReactDOM.createRoot(document.getElementById("root"));

initFirebaseMessaging()

root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
        <ToastContainer newestOnTop />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
