import React, { useEffect, useState } from 'react';

import { Card, CardBody, CardHeader } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";
import styles from './ChatBox.module.css'

import ChatBody from './ChatBody';
import { useDispatch, useSelector } from 'react-redux';
import ChatList from './ChatList';
import Skeleton from 'react-loading-skeleton';

const ChatBox = (props) => {
    const dispatch = useDispatch()
    const chat = useSelector(state => state.Chat)

    if (!chat.chatBoxOpen) return
    return (

        <Card className={`${styles.chat_box_container} shadow-lg `}>
            {chat.isLoading ? (
                <Skeleton
                    style={{
                        height: "300px",
                        width: "100%",
                        padding: "10px",
                        flexDirection: "row !important",
                    }}
                ></Skeleton>

            ) : chat.chatActive !== null ? (<ChatBody />) : (
                <div className='p-3'>
                    <ChatList closeDropdown={()=>{}}
                        height="300px" isPopup={true} />
                </div>

            )}


        </Card>
    );
};

export default withRouter(ChatBox);
