import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import { Button, Card, CardBody, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import "./select-dashboard.scss";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDashboard } from "../../slices/thunks";
import { snakeCase } from "lodash";
import { useSearchParams } from "react-router-dom";
import CustomToast from "../../Components/Common/CustomToast";
import { X } from "react-feather";
import { setDashboard } from "../../slices/dashboards/reducer";

const SelectDashboards = ({ dashboards }) => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [modalAddDefaultDash, setModalAddDefaultDash] = useState(false)
  const [dashboardName, setDashboardName] = useState("")
  const toggleModal = () => {
    setDashboardName("")
    setModalAddDefaultDash(!modalAddDefaultDash)
  }
  const handleDashboardName = (str) => {
    if (/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(str))
      return CustomToast("danger", "Karakter inputan tidak valid");
    setDashboardName(str.trimStart());
  };
  // console.log()
  const submitDashboard = () => {
    if(dashboardName === "") {
      return CustomToast("danger", "Nama dashboard tidak boleh kosong!")
    }
    if(dashboards.defaultDashboards !== null){
      if(Object.keys(dashboards?.defaultDashboards).some(d => d === dashboardName.toLocaleLowerCase())){
        return CustomToast("danger", "Nama dashboard sudah tersedia!")
      }
      dispatch(createDashboard({
        label: dashboardName,
        icon: "ri-dashboard-2-line",
        path: "/" + snakeCase(dashboardName.toLowerCase()),
        layout: [],
        widget: [],
        workunit_level: searchParams.get("workunit_level") == null ? "1" : searchParams.get("workunit_level")
      }))
      toggleModal()
      return
    }
    dispatch(createDashboard({
      label: dashboardName,
      icon: "ri-dashboard-2-line",
      path: "/" + snakeCase(dashboardName.toLowerCase()),
      layout: [],
      widget: [],
      workunit_level: searchParams.get("workunit_level") == null ? "1" : searchParams.get("workunit_level")
    }))
    toggleModal()
  }

  const changeDashboard = (path) => {
    // console.log(path)
    console.log(dashboards)
    dispatch(setDashboard(path))
  }

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center "
        style={{ width: "40%" }}
      >
        {/* {!dashboards.isLoading && (
        <Button
          color="primary"
          className="nav-btn rounded-circle fs-4"
          onClick={() => {
            const container = sliderRef.current;
            container.scrollLeft -= scrollAmount; // Scroll left by the specified amount
          }}
        >
          <i className="mdi mdi-chevron-left"></i>
        </Button>
      )} */}

        <PerfectScrollbar
          handlers={["drag-thumb"]}
          className="d-flex align-content-center"
          style={{ height: "50px", gap: "8px", padding: "7px" }}
        >
          {!dashboards.isLoading ? (
            Object.keys(dashboards.defaultDashboards ?? {}).map((item, key) => {
              return (
                <Button
                  color="primary"
                  onClick={() => changeDashboard(dashboards.defaultDashboards[item].path)}
                  outline={
                    dashboards.currentPathDefault ==
                      dashboards.defaultDashboards[item].path
                      ? false
                      : true
                  }
                >
                  {dashboards.defaultDashboards[item].label}
                </Button>
              );
            })
          ) : (
            <Skeleton
              style={{
                height: "40px",
                width: "100%",
                marginTop: "10px",
              }}
            />
          )}
          {!dashboards.isLoading && (
            <Button color="primary" outline={true} onClick={toggleModal}>
              <i className="mdi mdi-plus"></i>
            </Button>
          )}
        </PerfectScrollbar>

        {/* {!dashboards.isLoading && (
        <Button
          color="primary"
          className="nav-btn rounded-circle fs-4"
          onClick={() => {
            const container = sliderRef.current;
            container.scrollLeft -= scrollAmount; // Scroll left by the specified amount
          }}
        >
          <i className="mdi mdi-chevron-right"></i>
        </Button>
      )} */}
      </div>
      <Modal isOpen={modalAddDefaultDash} toggle={toggleModal}>
        <ModalHeader className="modal-title" toggle={toggleModal}>
          Tambah Dashboard Default
        </ModalHeader>
        <ModalBody>
          <Input
            type="text"
            placeholder="Nama Dashboard"
            className="mb-4"
            value={dashboardName}
            onChange={(e) => handleDashboardName(e.target.value)}
          />
          <div className="hstack gap-2 justify-content-center">
            <Button
              color="light"
              onClick={() =>
                toggleModal()
              }
            >
              Batal
            </Button>
            <Button
              color="primary"
              onClick={submitDashboard}
            >
              Tambah
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SelectDashboards;
