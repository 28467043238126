import React, { useState, useEffect }  from "react";

import {
  Label,
  Input,
  FormFeedback,
  Card,
  Col,
  Row,
  Container,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

// Formik Validation
import * as Yup                        from "yup";
import { useFormik }                   from "formik";

//redux
import { useSelector, useDispatch }    from "react-redux";

import SidebarProfile                  from "./SidebarProfile";
import { patchProfile, getDashboards, patchPassword, getMenuDashboards } from "../../slices/thunks";

import { loadProfile }                 from "../../slices/auth/profile/reducer";

const schema = Yup.object({
  password_current : Yup.string().required("Please Enter Your Current Password"),
  password_new     : Yup.string().required("Please Enter Your New Password"),
  password_confirm : Yup.string()
    .oneOf([Yup.ref("password_new")], "Password Must Match With New Password")
    .required("Please Enter Your Confirmation Password"),
});

const UbahPassword = () => {
  const dispatch                        = useDispatch();
  const dashboards                      = useSelector((state) => state.Dashboards);
  const profile                         = useSelector((state) => state.Profile);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [password, setPassword]         = useState({
    current : false,
    new     : false,
    confirm : false,
  });
  const formik                          = useFormik({
    initialValues    : {},
    validationSchema : schema,
    onSubmit         : (values) => {
      console.log(values);
    },
  });
  const toggleModalConfirm              = () => setModalConfirm(!modalConfirm);

  useEffect(() => {
    dispatch(loadProfile());
  }, []);

  useEffect(() => {
    // if (dashboards.isInitial || dashboards.isUpdate) {
      dispatch(getMenuDashboards());
      // dispatch(getDashboards());
    // }
  }, []);

  function handleSave() {
    dispatch(
      patchPassword({
        username: profile.data.username,
        password: formik.values.password_new
      })
    );
    toggleModalConfirm();
  }
  return (
    <React.Fragment>
      <div className="page-content pb-0">
        <Container fluid>
          <Row>
            <Col md="3">
              <SidebarProfile title="ubah password" />
            </Col>
            <Col md="9">
              <Card style={{ height: "82vh", overflow:"auto" }}>
                <div className="p-4">
                  <p className="fs-18 fw-semibold mb-0">Ubah Password</p>
                  <p className="text-muted fs-14 mb-4">
                    Untuk keamanan akun Anda, mohon untuk tidak menyebarkan
                    password Anda ke orang lain.
                  </p>

                  <Row>
                    <Col md={4}>
                      <div className="mb-3">
                        <Label
                          htmlFor="password_current"
                          className="form-label"
                        >
                          Password Saat Ini
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="password_current"
                            name="password_current"
                            value={formik.values.password_current || ""}
                            type={password.current ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Masukkan Password Saat Ini"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.password_current &&
                              formik.errors.password_current
                                ? true
                                : false
                            }
                          />
                          {formik.touched.password_current &&
                          formik.errors.password_current ? (
                            <FormFeedback type="invalid">
                              {formik.errors.password_current}
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() =>
                              setPassword({
                                ...password,
                                current: !password.current,
                              })
                            }
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="password_new" className="form-label">
                          Password Baru
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="password_new"
                            name="password_new"
                            value={formik.values.password_new || ""}
                            type={password.new ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Masukkan Password Baru"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.password_new &&
                              formik.errors.password_new
                                ? true
                                : false
                            }
                          />
                          {formik.touched.password_new &&
                          formik.errors.password_new ? (
                            <FormFeedback type="invalid">
                              {formik.errors.password_new}
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() =>
                              setPassword({
                                ...password,
                                new: !password.new,
                              })
                            }
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label
                          htmlFor="password_confirm"
                          className="form-label"
                        >
                          Password Konfirmasi
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <Input
                            id="password_confirm"
                            name="password_confirm"
                            value={formik.values.password_confirm || ""}
                            type={password.confirm ? "text" : "password"}
                            className="form-control pe-5"
                            placeholder="Masukkan Password Konfirmasi"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.password_confirm &&
                              formik.errors.password_confirm
                                ? true
                                : false
                            }
                          />
                          {formik.touched.password_confirm &&
                          formik.errors.password_confirm ? (
                            <FormFeedback type="invalid">
                              {formik.errors.password_confirm}
                            </FormFeedback>
                          ) : null}
                          <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                            type="button"
                            id="password-addon"
                            onClick={() =>
                              setPassword({
                                ...password,
                                confirm: !password.confirm,
                              })
                            }
                          >
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <Button
                        color="primary"
                        disabled={profile.isLoading}
                        onClick={toggleModalConfirm}
                      >
                        {profile.isLoading ? (
                          <div className="d-flex gap-2 w-100 justify-content-center">
                            <Spinner size="sm">Loading...</Spinner>
                            <span>Memuat</span>
                          </div>
                        ) : (
                          <span>Simpan</span>
                        )}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={modalConfirm} toggle={toggleModalConfirm}>
            <ModalHeader toggle={toggleModalConfirm}>
              Konfirmasi Perubahan
            </ModalHeader>
            <ModalBody>Apakah anda yakin ingin menyimpan perubahan?</ModalBody>
            <ModalFooter className="gap-2 justify-content-center">
              <Button color="secondary" onClick={toggleModalConfirm}>
                Batal
              </Button>
              <Button color="primary" onClick={handleSave}>
                Ya
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UbahPassword;
