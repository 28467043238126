import { Fragment, useEffect, useRef, useState } from "react";
import { Edit2, Plus, Trash2 } from "react-feather";
import { Button, Col, Row } from "reactstrap";
import CustomTable from "../../Components/Common/custom-table";
import ActionButton from "../../Components/Common/action-button";
import { APIClient } from "../../helpers/api_helper";
import { DATA_COLLECTOR_BASE } from "../../helpers/url_helper";
import headerTable from "./header-table";
import CreateEndpoint from "./create";
import Swal from "sweetalert2";
import CustomToast from "../../Components/Common/CustomToast";
import EditEndpoint from "./edit";
import { RotateCcw } from "feather-icons-react/build/IconComponents";
import ModalReload from "./modal-reload";
import { useDispatch, useSelector } from "react-redux";
import { getMenuDashboards } from "../../slices/thunks";
import { useNavigate } from "react-router-dom";
import { isAdmin } from "../../helpers/user_info";

const Endpoint = () => {
  // states
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataPagination, setDataPagination] = useState({});
  const [showCreate, setShowCreate] = useState(false);
  const [dataSelected, setDataSelected] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataEndpoint, setDataEndpoint] = useState([]);
  const [dataType, setDataType] = useState([]);
  const [dataReload, setDataReload] = useState(null);
  const [showReload, setShowReload] = useState(false);

  
	const dispatch = useDispatch();
	const dashboards = useSelector(state => state.Dashboards);

  // refs
  const params = useRef({
    keyword: "",
    page: 1,
  });

  // custom table
  const {
    CustomTableHeader,
    CustomTableBody,
    CustomTableSearch,
    CustomTablePaginate,
    CustomTableLoading,
    CustomTableEmptyData,
  } = CustomTable;

  // func http
  const api = new APIClient();

  // get data
  const getData = () => {
    setLoading(true);

    api.get(DATA_COLLECTOR_BASE + "/endpoints/", params.current).then(
      (res) => {
        console.log(res.data)
        setDatas(res.data ?? []);
        setDataPagination(res.pagination);
        setLoading(false);
      },
      (err) => {
        CustomToast("danger", "terjadi kesalahan saat mengambil data");
      }
    );
  };

  const getDataSource = () => {
    api
      .get(DATA_COLLECTOR_BASE + "/datasource/?list=true")
      .then((response) => {
        setDataSource(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getDataType = () => {
    api
      .get(DATA_COLLECTOR_BASE + "/datatype-group/?list=true")
      .then((response) => {
        setDataType(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const reloadData = (id) => {
    api
      .get(DATA_COLLECTOR_BASE + "/endpoints/" + id + "/refresh")
      .then((response) => {
        // CustomToast("success", "Berhasil reload data endpoint");
        setDataReload(response.data);
        setShowReload(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // function call sweet alert and handle delete endpoint
  const handleDeleteEndpoint = (id) => {
    Swal.fire({
      title: "Konfirmasi",
      text: "Yakin ingin menghapus data endpoint?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal",
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(DATA_COLLECTOR_BASE + "/endpoints/" + id)
          .then((res) => {
            CustomToast("success", "Berhasil menghapus data endpoint");
            getDataSource();
            getData();
            getDataType();
          })
          .catch((err) => {
            console.error(err);
            CustomToast("danger", "Gagal menghapus data endpoint");
          });
      }
    });
  };

  
	useEffect(() => {
		if (dashboards.isInitial || dashboards.isUpdate) {

			dispatch(getMenuDashboards());
		}
	}, [dashboards.isInitial, dashboards.isUpdate]);
  
	const navigate = useNavigate()

  // useEffect
  useEffect(() => {
    if(!isAdmin){
      return navigate("/")
    }
    getData();
    getDataSource();
    getDataType();
  }, []);

  return (
    <Fragment>
      {/* modal reload */}
      <ModalReload
        show={showReload}
        onClose={() => setShowReload(!showReload)}
        dataReload={dataReload}
      />

      {/* modal Create */}
      <CreateEndpoint
        show={showCreate}
        onClose={() => setShowCreate(!showCreate)}
        getData={getData}
        dataType={dataType}
        dataSource={dataSource}
        getDataSource={getDataSource}
      />
      <EditEndpoint
        show={showEdit}
        onClose={() => setShowEdit(!showEdit)}
        data={dataSelected}
        endpoint_id={dataSelected?.id}
        dataSource={dataSource}
        dataType={dataType}
        getData={getData}
      />
      <div style={{ marginTop: "8%" }} className="px-4">
        <Row className="mb-1">
          <Col>
            {/* Header 1 */}
            <Row className="mb-1">
              <Col>
                <Button
                  color="primary"
                  size="sm"
                  outline
                  onClick={() => {
                    setDataSelected(null);
                    setShowCreate(!showCreate);
                  }}
                  disabled={false}
                >
                  <Plus size={14} />
                </Button>
              </Col>
              <Col md={5}>
                <CustomTableSearch
                  title="Cari Endpoint"
                  onSearch={(e) => {
                    params.current.keyword = e;
                    params.current.page = 1;
                    getData();
                  }}
                />
              </Col>
            </Row>
            {/* Header 2 */}
            <div className="d-flex justify-content-end align-items-center">
              <CustomTablePaginate
                total={dataPagination?.total_data}
                limit={10}
                currentPage={dataPagination?.page}
                next={dataPagination?.has_next}
                prev={dataPagination?.has_prev}
                onClick={(value) => {
                  if (value == "next") {
                    params.current.page = params.current.page + 1;
                  } else {
                    params.current.page = params.current.page - 1;
                  }
                  getData();
                }}
              />
            </div>

            {/* table */}
            <CustomTableHeader data={headerTable} />
            {!loading ? (
              datas.length > 0 ? (
                datas.map((item, index) => (
                  <CustomTableBody key={index}>
                    <Col md="1">{index + 1}</Col>
                    <Col md="4">{item.name}</Col>
                    <Col md="3">{item.datasource.name}</Col>
                    <Col md="2">
                      {item.scheduled_mode ? (
                        <>
                          <ActionButton
                            id="btn-edit"
                            title="Ubah"
                            onClick={() => {
                              reloadData(item.id);
                            }}
                          >
                            <RotateCcw size={20} />
                          </ActionButton>

                          <ActionButton
                            id="btn-edit"
                            title="Ubah"
                            onClick={() => {
                              setDataSelected(item);
                              setShowEdit(!showEdit);
                            }}
                          >
                            <Edit2 size={20} />
                          </ActionButton>
                        </>
                      ) : null}

                      <ActionButton
                        id="btn-delete"
                        title="Hapus"
                        onClick={() => handleDeleteEndpoint(item.id)}
                      >
                        <Trash2 size={20} />
                      </ActionButton>
                    </Col>
                  </CustomTableBody>
                ))
              ) : (
                <CustomTableEmptyData />
              )
            ) : (
              <CustomTableLoading />
            )}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Endpoint;
