import React, { useState }                                 from "react";
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";

import { Link, useNavigate }                               from "react-router-dom";

import { useSelector, useDispatch }                        from "react-redux";
import { addDashboardWidget }                              from "../../slices/dashboards/thunk";

import WidgetAdd                                           from "./WidgetAdd";
import WidgetPreview                                       from "./WidgetPreview";

const TambahGrafik = () => {
  const navigate = useNavigate();
  function toPageAddWidget() {
    navigate("/add-widget");
  }
  return (
    <>
      <Button size="sm" color="primary" onClick={toPageAddWidget}>
        <i className="bx bx-plus me-1"></i> Tambah Grafik
      </Button>
    </>
  );
};

export default TambahGrafik;
