import React, { useState }          from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Input,
	Row,
	Col,
} from "reactstrap";
import Select                       from "react-select";
import {
	createDashboard,
	deleteDashboard,
	getDashboardsWidget,
} from "../../slices/dashboards/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import Swal                         from "sweetalert2";
import user_info                    from "../../helpers/user_info";
import CustomToast from "./CustomToast";

const GabungkanDashboard = () => {
	const dashboards = useSelector(state => state.Dashboards);
	const dispatch   = useDispatch();

	const [dashboardName, setDashboardName]   = useState("");
	const [selectedSingle, setSelectedSingle] = useState(null);
	const [modal, setModal]                   = useState(false);
	const [modalType, setModalType]           = useState("");
	const toggle                              = type => {
		setModal(!modal);
		setModalType(type);
		setDashboardName("");
	};

	function handleSelectSingle(e) {
		setSelectedSingle(e);
	}

	const snakeCase = string => {
		return string

			.split(/ |\B(?=[A-Z])/)
			.map(word => word.toLowerCase())
			.join("-");
	};

	const handleDashboardName = (str) => {
		if (/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(str))
			return CustomToast("danger", "Karakter inputan tidak valid");
		setDashboardName(str.trimStart());
	};

	const submitAddDeleteDashboard = async () => {
		switch (modalType) {
			case "add":
				if (dashboardName === "")
					return CustomToast("danger", "Nama dashboard tidak boleh kosong");
				dispatch(
					createDashboard({
						label  : dashboardName,
						icon   : "ri-dashboard-2-line",
						path   : "/" + snakeCase(dashboardName.toLowerCase()),
						layout : [],
						widget : [],
					})
				);
				toggle("");
				setDashboardName(null);
				break;
			case "delete":
				dispatch(deleteDashboard(selectedSingle.id));
				setSelectedSingle(null);
				toggle("");
				break;
			case "merge":
				if (dashboardName === "")
					return CustomToast("danger", "Nama dashboard tidak boleh kosong");
				if(Object.keys(dashboards.map).some(d => d === dashboardName.toLowerCase())){
					return CustomToast("danger", `Dashboard dengan nama ${dashboardName} sudah tersedia`)
					}
				let data = {
					label   : dashboardName,
					icon    : "ri-dashboard-2-line",
					path    : "/" + snakeCase(dashboardName),
					layout  : [],
					widget  : [],
					user_id : user_info().uuid_user
				};

				if (selectedSingle === null)
					return Swal.fire({
						text : "Pilih Dashboard 2",
						icon : "warning",
					});

				let dashboard_1 = window.location.pathname.substring(1);
				let dashboard_2 = snakeCase(selectedSingle.label.toLowerCase());

				if (dashboard_1 === dashboard_2)
					return Swal.fire({
						text : "Tidak dapat menggabungkan sesama dashboard!",
						icon : "error",
					});

				let d1_id = dashboards.map[removeDash(dashboard_1)]._id
				let d2_id = dashboards.map[removeDash(dashboard_2)]._id

				await dispatch(getDashboardsWidget({_id: d1_id})).then(result => {
					data.layout.push(...result.payload.layout);
					data.widget.push(...result.payload.widget);
				})

				await dispatch(getDashboardsWidget({_id: d2_id})).then(result => {
					data.layout.push(...result.payload.layout);
					data.widget.push(...result.payload.widget);
				})
				
				dispatch(createDashboard(data));
				setSelectedSingle(null);
				toggle("");
			default:
				break;
		}
	};

	const removeDash = str => {
		return str.replace(/-/g, " ");
	};

	const SingleOptions = Object.keys(dashboards.map).map(item => ({
		// value: item,
		// label: menuItems[item].label,
		id    : dashboards.map[item]._id,
		// const SingleOptions = Object.keys(dashboards.map).map((item) => ({
		value : dashboards.map[item].path,
		label : dashboards.map[item].label,
	}));
	return (
		<>
			<Button size="sm" color="primary" onClick={() => toggle("merge")}>
				<i className="bx bx-git-merge me-1"></i> Gabungkan Dashboard
			</Button>
			<Modal isOpen={modal} toggle={toggle}>
				<ModalHeader className="modal-title" toggle={toggle}>
					{modalType === "merge" && "Gabungkan Dashboard"}
				</ModalHeader>
				<ModalBody className="text-center">
					<div className="p-4">
						{modalType === "merge" && (
							<>
								<Input
									type="text"
									placeholder="Nama Dashboard"
									value={dashboardName}
									onChange={e => handleDashboardName(e.target.value)}
								/>
								<Row className="mt-2">
									<Col sm={6} xs={12}>
										<div>
											<Input
												type="text"
												className="form-control"
												defaultValue={window.location.pathname.substring(1)}
												disabled
											/>
										</div>
									</Col>
									<Col sm={6} xs={12}>
										<Select
											placeholder="Pilih Dashboard"
											value={selectedSingle}
											onChange={e => {
												handleSelectSingle(e);
											}}
											options={SingleOptions}
										/>
									</Col>
								</Row>
							</>
						)}
					</div>
					<div className="hstack gap-2 justify-content-center">
						<Button
							color="light"
							onClick={() => setModal(false) && setDashboardName("")}
						>
							Batal
						</Button>
						<Button
							onClick={() => submitAddDeleteDashboard()}
							color="primary"
							disabled={dashboardName === ""}
						>
							Gabungkan
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default GabungkanDashboard;
