import { useDispatch, useSelector } from "react-redux";
import styles from "./ChatBox.module.css";
import SearchInput from "../../Components/Common/search-input";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import avatar2 from "../../assets/images/default-user.png";
import { Fragment, useEffect, useState } from "react";
import { setShowGroupDetail } from "../../slices/chat/reducer";
import {
  addGroupMember,
  removeGroupMember,
  setAdminGroup,
} from "../../slices/thunks";
import user_info from "../../helpers/user_info";
import SimpleBar from "simplebar-react";
import SelectMultipleUser from "../../Components/Common/select-multiple-user/";
import SelectSingleUser from "../../Components/Common/select-single-user";

const GroupDetails = () => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.Chat);

  const toggle = () => {
    setModal(!modal);
  };

  const setAdmin = (id) => {
    dispatch(
      setAdminGroup({
        id: chat.chatActive.id,
        admins_id: [id],
      })
    );
  };
  const removeUser = (id) => {
    dispatch(
      removeGroupMember({
        id: chat.chatActive.id,
        member_id: [id],
      })
    );
  };
  const addMember = (member) => {
    dispatch(
      addGroupMember({
        id: chat.chatActive.id,
        member_id: [member],
      })
    );
  };

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchSubmit = (searchText) => {
    if (searchText.trim() === "") {
      setIsSearching(false);
      setSearchResults([]);
    } else {
      const filteredResults = chat.chatActive.member.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setIsSearching(true);
      setSearchResults(filteredResults);
    }
  };

  return (
    <Fragment>
      <SelectSingleUser
        show={modal}
        setShow={toggle}
        title="Tambah Angota"
        size="md"
        onSubmit={(user) => addMember(user)}
      />
      <div
        className={`${styles.chat_right_side} ${
          chat.showGroupDetail && styles.chat_right_side_show
        }`}
      >
        <div className="d-flex align-items-center justify-content-between border-bottom pb-3">
          <h4 className="m-0 fs-20">Informasi Group</h4>
          <i
            className=" ri-close-fill fs-20"
            role="button"
            onClick={() => dispatch(setShowGroupDetail())}
          ></i>
        </div>
        <div className="mt-3">
          <h5 className="fs-11">{`${chat.chatActive?.member_id.length} ANGGOTA GROUP`}</h5>
          <SearchInput
            submit={(searchText) => handleSearchSubmit(searchText)}
          />
          <Button color="primary" className="my-2 w-100" onClick={toggle}>
            Tambah Anggota
          </Button>
          <SimpleBar style={{ maxHeight: "300px" }}>
            {(isSearching ? searchResults : chat.chatActive?.member || []).map(
              (member, id) => (
                <div
                  key={id}
                  className="d-flex p-2 align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={member.avatar === "" ? avatar2 : member.avatar}
                      className="me-3 rounded-circle avatar-xs"
                      alt="user-pic"
                    />
                    <span>
                      <h6 className="m-0">{member.name}</h6>
                      {chat.chatActive?.admins_id.includes(member.uuid) && (
                        <p className="m-0 fs-8 text-danger">Admin</p>
                      )}
                    </span>
                  </div>

                  {chat.chatActive?.admins_id.includes(user_info().uuid) && (
                    <UncontrolledDropdown
                      className="align-self-center message-box-drop"
                      style={{ zIndex: "10" }}
                    >
                      <DropdownToggle
                        tag="a"
                        role="button"
                        style={{ zIndex: "10" }}
                      >
                        <i className=" ri-more-2-fill fs-20"></i>
                      </DropdownToggle>
                      <DropdownMenu style={{ zIndex: "10" }}>
                        {!chat.chatActive?.admins_id.includes(member.uuid) && (
                          <DropdownItem
                            style={{ zIndex: "10" }}
                            onClick={() => setAdmin(member.uuid)}
                            className="d-flex align-items-center gap-2"
                          >
                            {" "}
                            <i className="ri-user-follow-line fs-20"></i>{" "}
                            Jadikan Admin
                          </DropdownItem>
                        )}
                        <DropdownItem
                          style={{ zIndex: "10" }}
                          onClick={() => removeUser(member.uuid)}
                          className="d-flex align-items-center gap-2"
                        >
                          <i className=" ri-user-unfollow-line fs-20"></i>
                          Keluarkan Dari Group
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </div>
              )
            )}
          </SimpleBar>
        </div>
      </div>
      {/* <Modal isOpen={modal} toggle={toggle} centered>
				<ModalHeader className="modal-title" toggle={toggle}>
					Tambah Anggota
				</ModalHeader>
				<ModalBody>
					<SelectMultipleUser onSubmit={addMember} />
				</ModalBody>
			</Modal> */}
    </Fragment>
  );
};

export default GroupDetails;
