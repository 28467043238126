import _ from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { selectDashboard } from "./reducer";

import { APIClient } from "../../helpers/api_helper";
import { WIDGET_SYNC_BASE } from "../../helpers/url_helper";

import CustomToast from "../../Components/Common/CustomToast";
import { useSelector } from "react-redux";

const api = new APIClient();

export const createDashboard = createAsyncThunk(
  "dashboard/create",
  async (payload) => {
    try {

      const url = payload?.workunit_level ? `${WIDGET_SYNC_BASE}/sync/default-dashboard` : `${WIDGET_SYNC_BASE}/sync/dashboard`
      const response = await api.create(
        url,
        payload
      );
      CustomToast("success", "Berhasil menambah dashboard!");
      return response.data.result;
    } catch (err) {
      CustomToast("danger", "Gagal menambah dashboard!");
      return err;
    }
  }
);

export const deleteDashboard = createAsyncThunk(
  "dashboard/delete",
  async (payload, {getState}) => {
    const state = getState().Dashboards
    try {
      let url = state.isDefault ? "/sync/default-dashboard/" + payload : "/sync/dashboard/" + payload
      return await api.delete(WIDGET_SYNC_BASE + url);
    } catch (err) {
      CustomToast("danger", "Gagal menghapus dashboard!");
      return err;
    }
  }
);

export const getDashboards = createAsyncThunk("dashboards/get", async () => {
  try {
    const response = await api.get(WIDGET_SYNC_BASE + "/sync/dashboard");
    return response.data.result;
  } catch (error) {
    CustomToast("danger", "Gagal menampilkan dashboard!");
    return null;
  }
});

export const getMenuDashboards = createAsyncThunk(
  "menuDashboards/get",
  async (params) => {
    try {
      var url = params
        ? `${WIDGET_SYNC_BASE}/sync/default-dashboard?menu_only=true&workunit_level=${params.workunitLevel}`
        : `${WIDGET_SYNC_BASE}/sync/dashboard?menu_only=true`;
      const response = await api.get(url);

      return {...response.data.result, ...params};
    } catch (error) {
      CustomToast("danger", "Gagal menampilkan menu");
      return null;
    }
  }
);

export const getDashboardsWidget = createAsyncThunk(
  "dashboardsWidget/get",
  async (payload) => {
    try {
      var url = payload.default
        ? `${WIDGET_SYNC_BASE}/sync/default-dashboard/${payload._id}`
        : `${WIDGET_SYNC_BASE}/sync/dashboard/${payload._id}`;

      const response = await api.get(url);

      return response.data.result;
    } catch (error) {
      // CustomToast("danger", "Gagal menampilkan Widget!");
      return null;
    }
  }
);

export const putDashboards = createAsyncThunk(
  "dashboard/put",
  async (payload, { getState }) => {
    const state = getState().Dashboards;
    var url = state.isDefault
        ? `${WIDGET_SYNC_BASE}/sync/default-dashboard/${payload._id}`
        : `${WIDGET_SYNC_BASE}/sync/dashboard/${payload._id}`;
    try {
      const response = await api.put(url,
        payload
      );
      return response.data.result;
    } catch (err) {
      CustomToast("danger", "Gagal mengupdate dashboard!");
      return null;
    }
  }
);

export const addDashboardWidget = createAsyncThunk(
  "dashboards/widget/add",
  async ({ widget, layout, dashboard: path }, { getState }) => {
    const state = getState().Dashboards;
    const dashboard = state.current;
    const payload = _.cloneDeep(dashboard);

    payload.layout.push(...layout);
    payload.widget.push(...widget);
    var url = state.isDefault
        ? `${WIDGET_SYNC_BASE}/sync/default-dashboard/${dashboard._id}`
        : `${WIDGET_SYNC_BASE}/sync/dashboard/${dashboard._id}`;

    try {
      const response = await api.put(url,payload);
      CustomToast("success", "Berhasil menambah dashboard widget!");
      return response.data;
    } catch (error) {
      CustomToast("danger", "Gagal menambah dashboard widget!");
      return null;
    }
  }
);

export const deleteDashboardWidget = createAsyncThunk(
  "dashboards/widget/delete",
  async ({ widget }, { getState }) => {
    const state = getState().Dashboards;
    const dashboard = state.current;
    const payload = JSON.parse(JSON.stringify(dashboard));

    payload.layout = payload.layout.filter((item) => item.i != widget.key);
    payload.widget = payload.widget.filter((item) => item.key != widget.key);

    try {
      let url = state.isDefault ? `${WIDGET_SYNC_BASE}/sync/default-dashboard/${dashboard._id}` : `${WIDGET_SYNC_BASE}/sync/dashboard/${dashboard._id}`
      const response = await api.put(
        url,
        payload
      );
      CustomToast("success", "Berhasil menghapus dashboard widget!");
      return response.data;
    } catch (error) {
      CustomToast("danger", "Gagal menghapus dashboard widget!");
      return null;
    }
  }
);
