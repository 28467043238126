import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";

import moment from "moment";
import CreateBroadcast from "./CreateBroadcast";
import ShowBroadcast from "./ShowBroadcast";
import { useDispatch, useSelector } from "react-redux";
import { getBroadcast } from "../../../slices/thunks";
import CustomTableEmptyData from "../custom-table/empty";
import SearchInput from "../search-input";
import { APIClient } from "../../../helpers/api_helper";
import { CHAT_BASE } from "../../../helpers/url_helper";

const Broadcast = () => {
  const dispatch = useDispatch();
  const broadcast = useSelector((state) => state.Broadcast);
  const { data: broadcastData, unread } = broadcast;
  const api = new APIClient();

  useEffect(() => {
    dispatch(getBroadcast());
  }, [dispatch]);

  const [isBroadcast, setIsBroadcast] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [data, setData] = useState();
  const [dataSelected, setDataSelected] = useState(null);

  const toggle = (type) => {
    setModal(!modal);
    setModalType(type);
  };

  const toggleBroadcast = () => {
    setIsBroadcast(!isBroadcast);
  };

  const readBroadcast = (item) => {
    if (!item.is_read) {
      api
        .create(`${CHAT_BASE}/room/read`, {
          id: item.id,
        })
        .then((res) => {
          dispatch(getBroadcast());
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getDetail = (item) => {
    api
      .get(`${CHAT_BASE}/messages/${item.id}`)
      .then((res) => {
        setDataSelected(res.data.messages);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClick = (item) => {
    setData(item);
    readBroadcast(item);
    toggle("show");
  };

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchSubmit = (searchText) => {
    if (searchText.trim() === "") {
      setIsSearching(false);
      setSearchResults([]);
    } else {
      const filteredResults = broadcastData.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setIsSearching(true);
      setSearchResults(filteredResults);
    }
  };

  return (
    <>
      <React.Fragment>
        <Dropdown
          isOpen={isBroadcast}
          toggle={toggleBroadcast}
          className="topbar-head-dropdown ms-1 header-item"
        >
          <DropdownToggle
            tag="button"
            type="button"
            className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            style={{ color: "#FFFFFF" }}
          >
            <span className="mdi mdi-access-point fs-22">
              {unread > 0 ? (
                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                  {unread}
                  <span className="visually-hidden">unread messages</span>
                </span>
              ) : (
                <div></div>
              )}
            </span>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
            <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-13"> Pesan Siaran </h6>
                </Col>
                <span
                  role="button"
                  className="col-auto"
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                  onClick={() => toggle("create")}
                >
                  <i className="bx bx-edit fs-20"></i>
                </span>
              </Row>
              <SearchInput
                submit={(searchText) => handleSearchSubmit(searchText)}
              />
              <SimpleBar style={{ maxHeight: "300px" }} className="pe-2 mt-3">
                {(isSearching ? searchResults : broadcastData).length > 0 ? (
                  (isSearching ? searchResults : broadcastData).map(
                    (items, index) => (
                      <div
                        key={index}
                        className={`
      text-reset 
      notification-item 
      d-block dropdown-item position-relative 
      ${items.is_read == false ? "active" : undefined}
    `}
                        onClick={() => handleClick(items)}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            {items.option !== "whatsapp" ? (
                              <div
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  color: "white",
                                  borderRadius: "50%",
                                }}
                                className="bg-primary d-flex justify-content-center align-items-center me-3"
                              >
                                <span className="mdi mdi-access-point fs-22"></span>
                              </div>
                            ) : (
                              <svg
                                width="40"
                                height="40"
                                viewBox="0 0 56 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="me-3"
                              >
                                <rect
                                  width="56"
                                  height="56"
                                  rx="28"
                                  fill="#D0D8D2"
                                />
                                <path
                                  d="M27.7938 14.8988C30.99 14.8461 34.0954 15.9616 36.5275 18.036C38.9596 20.1104 40.551 23.0011 41.0031 26.1655C41.4553 29.3299 40.737 32.5506 38.9832 35.2231C37.2294 37.8956 34.5606 39.8361 31.4776 40.6806C28.536 41.4864 25.4102 41.2415 22.6359 39.9963L16.4178 41.1045C15.9417 41.1893 15.4576 41.0055 15.1577 40.6261C14.8579 40.2467 14.791 39.7331 14.9836 39.2896L16.8657 34.9551C15.3172 32.4661 14.6426 29.5229 14.9629 26.5946C15.3106 23.417 16.8059 20.4755 19.1683 18.3221C21.5306 16.1687 24.5977 14.9514 27.7938 14.8988Z"
                                  fill="#44624D"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M23.3266 22.6704C23.6958 22.3012 24.1966 22.0938 24.7188 22.0938C25.2409 22.0938 25.7417 22.3012 26.1109 22.6704C26.4801 23.0396 26.6875 23.5404 26.6875 24.0625V25.375C26.6875 25.8971 26.4801 26.3979 26.1109 26.7671C25.9781 26.8999 25.8283 27.0117 25.6667 27.1005C25.9245 27.8413 26.3475 28.5221 26.9127 29.0873C27.4779 29.6525 28.1587 30.0755 28.8995 30.3333C28.9883 30.1717 29.1001 30.0219 29.2329 29.8891C29.6021 29.5199 30.1029 29.3125 30.625 29.3125H31.9375C32.4596 29.3125 32.9604 29.5199 33.3296 29.8891C33.6988 30.2583 33.9062 30.7591 33.9062 31.2812C33.9062 31.8034 33.6988 32.3042 33.3296 32.6734C32.9604 33.0426 32.4596 33.25 31.9375 33.25H30.625C28.5364 33.25 26.5334 32.4203 25.0565 30.9435C23.5797 29.4666 22.75 27.4636 22.75 25.375V24.0625C22.75 23.5404 22.9574 23.0396 23.3266 22.6704Z"
                                  fill="#D0D8D2"
                                />
                              </svg>
                            )}
                            <div className="flex-1">
                              <Link to="#" className="stretched-link">
                                <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                                  {items.name}
                                </h6>
                              </Link>
                              <div
                                className="fs-13 text-muted"
                                style={{ overflowWrap: "anywhere" }}
                              >
                                <p className="mb-1">{items.last_message}</p>
                              </div>
                            </div>
                          </div>
                          <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                            <span>{moment(items.time).format("HH:mm")}</span>
                          </p>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <CustomTableEmptyData />
                )}
              </SimpleBar>
            </div>
          </DropdownMenu>
        </Dropdown>
        <Modal
          isOpen={modal}
          toggle={toggle}
          centered
          size={modalType === "show" && "md"}
          onOpened={() => {
            if (modalType === "show") {
              getDetail(data);
            }
          }}
        >
          <ModalHeader className="modal-title" toggle={toggle}>
            {modalType === "create" && "Pesan Siaran"}
            {modalType === "show" && "Detail Pesan Siaran"}
          </ModalHeader>
          <ModalBody>
            <div className="p-2">
              {modalType === "create" && <CreateBroadcast onClose={toggle} />}
              {modalType === "show" && (
                <ShowBroadcast
                  data={data}
                  dataSelected={dataSelected}
                  toggle={toggle}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default Broadcast;
