import { Card, Col, Row } from "reactstrap";
import set_profile, { img_error } from "../../../../helpers/default_profile";

const RankingRow = ({
  rank,
  data: {
    id,
    endpoint_id,
    photo_url,
    name,
    workunit,
    badge_image1,
    badge_name1,
    badge_value1,
    badge_image2,
    badge_name2,
    badge_value2,
    badge_image3,
    badge_name3,
    badge_value3,
    performances,
  },
}) => {
  return (
    <>
      <Card className="border p-3" style={{ minWidth: "140px", marginBottom:"0.5rem" }}>
        <Row>
          <Col md={1} className="align-self-center pe-0">
            <p className="mb-0 fw-semibold">{rank}</p>
          </Col>
          <Col md={7} className="d-flex">
            <span 
                className="rounded me-2 d-block" style={{width:"30px", height:"30px", overflow:"hidden"}}>
              <img
                src={set_profile(photo_url || "")}
                onError={e => img_error(e)} style={{height:"100%", width:"100%"}}
              />
            </span>
            <div className="align-self-center">
              <p className="fw-semibold mb-0 fs-12">{name}</p>
              <p className="fs-10 text-muted mb-0">{workunit}</p>
            </div>
          </Col>
          {/* <Col md={2}>
                          <img src={LineChart} alt="" width="100%" />
                      </Col> */}
          <Col md={4} className="d-flex justify-content-center gap-3">
            <div className="text-center">
              <p className="mb-0 mt-1 fs-12 fw-semibold">{badge_value1}</p>
              <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
                {badge_name1}
              </p>
            </div>
            <div className="text-center">
              <p className="mb-0 mt-1 fs-12 fw-semibold">{badge_value2}</p>
              <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
                {badge_name2}
              </p>
            </div>
            <div className="text-center">
              <p className="mb-0 mt-1 fs-12 fw-semibold">{badge_value3}</p>
              <p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
                {badge_name3}
              </p>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default RankingRow;
