import { useEffect, useRef, useState } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import random_color from "../../../../helpers/random_color";
import ModalDetail from "../../detail-modal";
import { Input } from "reactstrap";

const ChartBar = ({ source, params }) => {
  const { loading, data, failed, getSource } = useGetWidget({ source, params });
  const [showValue, setShowValue] = useState(true)

  const [rcd, setRcd] = useState({
    data: null,
    datasets: [],
    ignoreColor: false,
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: function (context) {
          return showValue ? context.dataset.data[context.dataIndex] >= 1 : false;
        },
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: "white",
        font: {
          weight: "bold",
        },
        formatter: Math.round,
        padding: 6,
      },

      legend: {
        display: false,
        position: "bottom",
        onClick(e, legendItem, legend) {
          const ci = legend.chart;
          ci.toggleDataVisibility(legendItem.index);
          ci.update();
        },
        labels: {
          generateLabels: (chart) => {
            const labels = chart.data.labels;
            const dataset = chart.data.datasets[0];
  
            return labels.map((label, index) => ({
              text: label,
              hidden: !chart.getDataVisibility(index),
              fillStyle: dataset.backgroundColor[index],
              index,
            }));
          }
        }
      },

      title: {
        display: false,
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
      },
    },
  };

  useEffect(() => {
    if (data) {
      if (data.datasets) {
        setRcd({
          labels: data.labels,
          datasets: data.datasets,
        });
      } else {
        setRcd({
          labels: data.labels,
          datasets: [
            {
              // label: data.label,
              data: data.data,
              backgroundColor: random_color(data.background_color),
              borderColor: random_color(data.border_color),
            },
          ],
        });
      }
    }
  }, [data]);

  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [child, setChild] = useState();
  function toggleOpenModalSave(child_data, label) {
    setChild({
      period: source?.split("=")[2],
      source: data.source,
      enpoint_root_id: data.endpoint_id,
      param_child: child_data?.toString(),
      childType: data.endpoint_child_type,
      type: data.type,
      condition: label
    })
    setOpenModalDetail(!openModalDetail);
  }
  const chartRef = useRef();
  const handleChartClick = (e, source) => {
    if (getElementAtEvent(chartRef.current, e).length === 0 || data.endpoint_child.length === 0) return
    const index = getElementAtEvent(chartRef.current, e)[0].index;
    const label = chartRef.current.data.labels[index]
    // console.log(label)
    toggleOpenModalSave(_, label)
  }

  return (
    <>
      <BoundWidget
        loading={loading}
        data={data}
        failed={failed}
        onReload={getSource}
      >
        {data && (
          <>
          <div style={{ height: "380px" }}>
            <Bar
              ref={chartRef}
              onClick={(e) => handleChartClick(e, source)}
              options={options}
              data={rcd}
              plugins={[ChartDataLabels]} 
            />{" "}
          </div>
          <div>
          <Input
            className="cursor-pointer"
            type="checkbox"
            checked={showValue}
            onChange={() => setShowValue(!showValue)}
          />
          <span className="ms-2">Tampilkan Nilai</span>
        </div>
        </>
        )}
      </BoundWidget>
      <ModalDetail
        toggleOpenModalDetail={toggleOpenModalSave}
        openModalDetail={openModalDetail}
        child={child} // child: {type, data}
      />
    </>
  );
};

export default ChartBar;
