import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	ModalBody,
	Modal,
	ModalHeader,
	Button,
} from "reactstrap";
import LogoKejasaan from "../../assets/images/logo/logo-kejaksaan.png";

import { loadProfile } from "../../slices/auth/profile/reducer";
import user_info, { isAdmin } from "../../helpers/user_info";
import ApkDev from "../../assets/apk/app-s-dev.apk";
import Apk from "../../assets/apk/app-s-beta.apk";
import { openChatBox } from "../../slices/chat/reducer";
import set_profile, { img_error } from "../../helpers/default_profile";

const ProfileDropdown = () => {
	const dispatch = useDispatch();
	const profile = useSelector(state => state.Profile);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => {
		setIsModalOpen(prevState => !prevState);
	};

	// download apk
	const downloadApkDev = () => {
		const link = document.createElement("a");
		link.href = ApkDev;
		link.setAttribute("download", "app-s-dev.apk");
		link.click();
	};
	const downloadApkBeta = () => {
		const link = document.createElement("a");
		link.href = Apk;
		link.setAttribute("download", "app-s-beta.apk");
		link.click();
	};

	useEffect(() => {
		dispatch(loadProfile());
	}, []);

	//Dropdown Toggle
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown);
	};

	return (
		<React.Fragment>
			<Dropdown
				isOpen={isProfileDropdown}
				toggle={toggleProfileDropdown}
				className="ms-sm-3 header-item topbar-user"
				style={{ backgroundColor: "#176138" }}
			>
				<DropdownToggle tag="button" type="button" className="btn">
					<span className="d-flex align-items-center">
						<span className="text-end me-xl-3">
							{/* <span
								className="d-none d-xl-block ms-1 fs-12 user-name-sub-text"
								style={{ color: "#ffffffb0" }}
							>
								{profile.data.position}
							</span> */}
							<span
								className="d-none d-xl-inline-block ms-1 fs-14 fw-bold user-name-text"
								style={{ color: "#FFFFFF" }}
							>
								{profile.data.name}
							</span>
						</span>
						<img
							className="rounded-circle header-profile-user"
							src={set_profile((profile.data.photo || ""))}
							alt="profile_pic"
							onError={(e) => img_error(e)}
						/>
					</span>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end" right>
					{/* <h6 className="dropdown-header">Welcome {userName}!</h6> */}
					<DropdownItem className="px-1 py-1">
						<Link
							to={process.env.PUBLIC_URL + "/profile"}
							className="dropdown-item"
							onClick={() => dispatch(openChatBox(false))}
						>
							<i className="mdi mdi-account-circle-outline text-muted fs-13 align-middle me-2"></i>
							<span className="align-middle fs-13">Akun Saya</span>
						</Link>
					</DropdownItem>
					{isAdmin !== "admin" ? (
						<>
							<DropdownItem className="px-1 py-1">
								<Link
									to={process.env.PUBLIC_URL + "/notifikasi"}
									className="dropdown-item"
									onClick={() => dispatch(openChatBox(false))}
								>
									<i className="mdi mdi-bell-outline text-muted fs-13 align-middle me-2"></i>
									<span className="align-middle fs-13">Atur Notifikasi</span>
								</Link>
							</DropdownItem>
						</>
					) : null}
					<DropdownItem className="px-1 py-1">
						<Link
							to={process.env.PUBLIC_URL + "/logupdate"}
							className="dropdown-item"
							onClick={() => dispatch(openChatBox(false))}
						>
							<i className="mdi mdi-history text-muted fs-13 align-middle me-2"></i>
							<span className="align-middle fs-13">Log Update Database</span>
						</Link>
					</DropdownItem>

					<DropdownItem className="px-1 py-1">
						<Link to="#" className="dropdown-item" onClick={toggleModal}>
							<i className="mdi mdi-tray-arrow-down text-muted fs-13 align-middle me-2"></i>
							<span className="align-middle fs-13">Download APK</span>
						</Link>
						<Modal
							isOpen={isModalOpen}
							toggle={toggleModal}
							className="modal-dialog modal-dialog-centered"
						>
							<ModalHeader toggle={toggleModal}></ModalHeader>
							<ModalBody className="pt-0">
								<div className="text-center">
									<p className="fw-semibold fs-16 mb-2">
										Aplikasi Mobile Dashboard Executive
									</p>
									<p>
										Download dan Install Aplikasi Mobile Dashboard Executive
									</p>
								</div>
								<div className="d-flex justify-content-between gap-2 text-white">
									<div>
										<div className="p-3 bg-dark w-100  text-center">
											<img
												src={LogoKejasaan}
												width="50%"
												alt="logo kejaksaan"
												className="mb-3"
											/>
											<p className="fs-16 fw-semibold mb-1">Full Version </p>
											<p>Minimal Android 7 Version </p>
											<p className="mb-0 fw-light">V1.1 .apk</p>
										</div>
										<Button
											onClick={downloadApkDev}
											className="w-100 rounded-0"
										>
											Download
										</Button>
									</div>
									<div>
										<div className="p-3 bg-primary w-100  text-center">
											<img
												src={LogoKejasaan}
												width="50%"
												alt="logo kejaksaan"
												className="mb-3"
											/>
											<p className="fs-16 fw-semibold mb-1">Beta Version </p>
											<p>Minimal Android 5 Version </p>
											<p className="mb-0 fw-light">V1.1 .apk</p>
										</div>
										<Button
											onClick={downloadApkBeta}
											className="w-100 rounded-0"
										>
											Download
										</Button>
									</div>
								</div>
							</ModalBody>
						</Modal>
					</DropdownItem>
					<div className="px-md-4">
						<div className="dropdown-divider"></div>
					</div>
					<DropdownItem className="px-1 py-1">
						<Link
						onClick={() => dispatch(openChatBox(false))}
							to={process.env.PUBLIC_URL + "/logout"}
							className="dropdown-item"
						>
							<i className="mdi mdi-logout text-danger fs-13 align-middle me-2"></i>{" "}
							<span
								className="align-middle text-danger fs-13"
								data-key="t-logout"
							>
								Logout
							</span>
						</Link>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	);
};

export default ProfileDropdown;
