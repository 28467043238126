import React, { useState } from "react";
import axios from "axios";

import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
} from "reactstrap";
import CustomToast from "../../Components/Common/CustomToast";
import { APIClient } from "../../helpers/api_helper";
import { DATA_COLLECTOR_BASE } from "../../helpers/url_helper";
import SubmitButton from "../../Components/Common/submit-button";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatasourceSchema from "./validation";

const api = new APIClient();

const CreateSumberData = (props) => {
  // props
  const { show, onClose, getData } = props;

  const [loading, setLoading] = useState(false);


  const [authType, setAuthType] = useState("");


  // ** Hooks Form
  const {
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(DatasourceSchema),
  });

  const onSubmit = (dataForm) => {
    setLoading(true)

    // encode
    const encode = dataForm['username'] + ":" + dataForm['password'];
    const encodedCredentials = window.btoa(encode);

    dataForm['auth_type'] = authType;
    dataForm['bearer_keyword'] = authType == "bearer" ? "bearer" : "";
    dataForm['token'] =  authType == "bearer" ? dataForm['token'] : encodedCredentials;


    api
      .create(DATA_COLLECTOR_BASE + "/datasource/", dataForm)
      .then((response) => {
        
        CustomToast("success", "Berhasil Menambah Sumber Data!");
        setLoading(false)
        onClose()
        getData();
        
        
      })
      .catch((error) => {
        setLoading(false)
        CustomToast("danger", "Gagal Menambah Sumber Data!");
      });
  };


  return (
    <>
      <Modal isOpen={show} toggle={onClose} centered={true}>
        <ModalHeader toggle={onClose}>Tambah Sumber Data</ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <FormGroup>
              <Label for="name">Nama Sumber Data</Label>
              <Controller
                id="name"
                name="name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Masukkan Nama Sumber Data"
                    type="text"
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Label for="base_url">Base URL</Label>
              <Controller
                id="base_url"
                name="base_url"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Masukkan Base URL"
                    type="text"
                    onChange={(e, value) => {
                      field.onChange(
                        e.target.value.startsWith("https://")
                          ? e.target.value
                          : "https://" + e.target.value
                      );
                    }}
                  />
                )}
              />
            </FormGroup>
            <FormGroup>
              <Label for="dataMethod">Type</Label>
              <Controller
                id="method"
                name="method"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    onChange={(e, value) => {
                      setAuthType(
                        e.target.value == "bearer" ? "bearer" : "basic"
                      );
                      field.onChange(e.target.value);
                    }}
                    type="select"
                  >
                    <option disabled value="" selected>
                      Pilih Method
                    </option>
                    <option value="bearer">Bearer Token</option>
                    <option value="basic">Basic Auth</option>
                  </Input>
                )}
              />
            </FormGroup>
            {authType === "bearer" ? (
              <>
                <FormGroup>
                  <Label for="name">Token</Label>
                  <Controller
                    id="token"
                    name="token"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Masukkan Token"
                        type="text"
                      />
                    )}
                  />
                </FormGroup>
              </>
            ) : null}
            {authType === "basic" ? (
              <>
                <FormGroup>
                  <Label for="name">Username</Label>
                  <Controller
                    id="username"
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Masukkan Username"
                        type="text"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Password</Label>
                  <Controller
                    id="password"
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Masukkan Password"
                        type="text"
                      />
                    )}
                  />
                </FormGroup>
              </>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClose}>
              Batal
            </Button>

            <SubmitButton loading={loading} title="Simpan" />
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CreateSumberData;
