import { useEffect, useRef, useState } from "react";
import { Line, getElementAtEvent } from "react-chartjs-2";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import random_color from "../../../../helpers/random_color";
import { Input } from "reactstrap";
import ModalDetail from "../../detail-modal";

const ChartArea = ({ source, params }) => {
  const { loading, data, failed, getSource } = useGetWidget({ source, params });

  const [rcd, setRcd] = useState({
    data: null,
    datasets: [],
    ignoreColor: false,
  });

  const [showValue, setShowValue] = useState(true)

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: function (context) {
          return showValue ? context.dataset.data[context.dataIndex] >= 1 : false;
        },
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderRadius: 4,
        color: 'white',
        font: {
          weight: 'bold'
        },
        formatter: Math.round,
        padding: 6
      },
      legend: {
        display: true,
        position: "bottom",
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
        },
      },
    },
  };

  const convert = (dataChart) => {
    let newData = [];

    dataChart.map((chart, i) => {

      newData.push({
        fill: "start",
        pointRadius: 3,
        label: chart.label,
        data: chart.data,
        borderColor: chart.border_color,
        backgroundColor: chart.background_color,
        lineTension: 0.5,
      });
    });

    return newData;
  };

  useEffect(() => {
    if (data) {
      if (data.datasets) {
        setRcd({
          labels: data.labels,
          datasets: convert(data.datasets),
        });
      } else {
        let color = random_color(data.background_color)
        setRcd({
          labels: data.labels,
          datasets: [
            {
              fill: "start",
              label: data.label,
              data: data.data,
              pointRadius: 3,
              backgroundColor: color,
              borderColor: color,
              lineTension: 0.5,
            },
          ],
        });
      }
    }
  }, [data]);

  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [child, setChild] = useState()
  function toggleOpenModalSave(child_data, label) {
    setChild({
      period: source?.split("=")[2],
      source: data.source,
      enpoint_root_id: data.endpoint_id,
      param_child: child_data?.toString(),
      childType: data.endpoint_child_type,
      type: data.type,
      condition: label
    })
    setOpenModalDetail(!openModalDetail);
  }
  const chartRef = useRef();
  const handleChartClick = async (e, source) => {
    if (getElementAtEvent(chartRef.current, e).length === 0 || data.endpoint_child.length === 0) return
    const index = getElementAtEvent(chartRef.current, e)[0].index;
    const label = chartRef.current.data.labels[index]
    console.log(label)
    toggleOpenModalSave(_, label)
  }

  return (
    <>
      <BoundWidget
        loading={loading}
        data={data}
        failed={failed}
        onReload={getSource}
      >
        {data && (
          <>
            <div style={{ height: "380px" }}>
              <Line
                role="button"
                ref={chartRef}
                onClick={(e) => handleChartClick(e, source)} 
                options={options} 
                data={rcd} 
                plugins={[ChartDataLabels]} />{" "}
            </div>

            <div>
              <Input
                className="cursor-pointer"
                type="checkbox"
                checked={showValue}
                onChange={() => setShowValue(!showValue)}
              />
              <span className="ms-2">Tampilkan Nilai</span>
            </div>
          </>
        )}
      </BoundWidget>
      <ModalDetail
        toggleOpenModalDetail={toggleOpenModalSave}
        openModalDetail={openModalDetail}
        child={{...child, period: source.split("=")[1]}} // child: {type, data}
      />
    </>
  );
};

export default ChartArea;
