import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Col,
	Container,
	Input,
	Row,
	Button,
	Form,
	FormFeedback,
	Alert,
	Spinner,
} from "reactstrap";
import * as Yup                       from "yup";
import { useFormik }                  from "formik";

import ParticlesAuth                  from "../AuthenticationInner/ParticlesAuth";
import withRouter                     from "../../Components/Common/withRouter";

// redux
import { useSelector, useDispatch }   from "react-redux";
import { loginUser, resetLoginFlag }  from "../../slices/thunks";

// assets
import BgLogin                        from "../../assets/images/bg-login.png";
import Otp                            from "../../Components/Common/Otp";
import user_info, { isAdmin } from "../../helpers/user_info";
import { useNavigate } from "react-router-dom";

const Login = props => {
	const dispatch                           = useDispatch();
	const { user, error, loading, errorMsg } = useSelector(state => ({
		user     : state.Account.user,
		error    : state.Login.error,
		loading  : state.Login.loading,
		errorMsg : state.Login.errorMsg,
	}));

	const [userLogin, setUserLogin]       = useState({});
	const [passwordShow, setPasswordShow] = useState(false);
	const [showOTP, setShowOTP]           = useState(false);

	useEffect(() => {
		if (user && user) {
			setUserLogin({
				email    : user.user.username,
				password : user.user.confirm_password,
			});
		}
	}, [user]);

	const navigate = useNavigate()
	useEffect(() => {
		if( user_info() ){
		// 	if(isAdmin){
		// 		return navigate("/sumber-data")
		// 	}else{
		// 		return navigate("/")
		// 	}
		return navigate("/")
		}
	},[])

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			username : userLogin.username || "",
			password : userLogin.password || "",
		},

		validationSchema: Yup.object({
			username : Yup.string().required("Nama pengguna belum diisi"),
			password : Yup.string().required("Kata sandi belum diisi"),
		}),

		onSubmit: values => {
			dispatch(loginUser(values, props.router.navigate, setShowOTP, null));
		},
	});

	useEffect(() => {
		if (errorMsg) {
			setTimeout(() => {
				dispatch(resetLoginFlag());
			}, 3000);
		}
	}, [dispatch, errorMsg]);

	document.title = "Masuk | Dashboard Executive";
	return (
		<React.Fragment>
			<ParticlesAuth>
				<div
					className="auth-page-content d-grid"
					style={{
						backgroundColor : "#176138",
						placeItems      : "center",
						backgroundImage : `url(${BgLogin})`,
						height          : "100vh",
						width           : "100%",
						objectFit       : "cover",
						backgroundSize  : "cover",
					}}
				>
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={4}>
								<Card className="mt-4">
									<CardBody className="px-3 py-4">
										<div className="text-center mt-2">
											<h3 className="fw-semibold">Selamat Datang!</h3>
											<p className="text-muted">
												Beberapa langkah lagi untuk memulai.
											</p>
										</div>
										{error && error ? (
											<Alert color="danger"> {error} </Alert>
										) : null}
										<div className="p-2 mt-4">
											<Form
												onSubmit={e => {
													e.preventDefault();
													validation.handleSubmit();
													return false;
												}}
												action="#"
											>
												<div className="mb-3">
													{/* <Label htmlFor="email" className="form-label">Email</Label> */}
													<Input
														name="username"
														className="form-control"
														placeholder="Masukkan Username"
														type="text"
														onChange={validation.handleChange}
														onBlur={validation.handleBlur}
														value={validation.values.username || ""}
														invalid={
															validation.touched.username &&
															validation.errors.username
																? true
																: false
														}
													/>
													{validation.touched.username &&
													validation.errors.username ? (
														<FormFeedback type="invalid">
															{validation.errors.username}
														</FormFeedback>
													) : null}
												</div>

												<div className="mb-3">
													<div className="float-end">
														{/* <Link to="/forgot-password" className="text-muted">Forgot password?</Link> */}
													</div>
													{/* <Label className="form-label" htmlFor="password-input">Password</Label> */}
													<div className="position-relative auth-pass-inputgroup mb-3">
														<Input
															name="password"
															value={validation.values.password || ""}
															type={passwordShow ? "text" : "password"}
															className="form-control pe-5"
															placeholder="Masukkan Password"
															onChange={validation.handleChange}
															onBlur={validation.handleBlur}
															invalid={
																validation.touched.password &&
																validation.errors.password
																	? true
																	: false
															}
														/>
														{validation.touched.password &&
														validation.errors.password ? (
															<FormFeedback type="invalid">
																{validation.errors.password}
															</FormFeedback>
														) : null}
														<button
															className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
															type="button"
															id="password-addon"
															onClick={() => setPasswordShow(!passwordShow)}
														>
															<i className="ri-eye-fill align-middle"></i>
														</button>
													</div>
												</div>
												<div className="mt-4">
													<Button
														color="success"
														disabled={error ? null : loading ? true : false}
														className="btn w-100 border-0"
														style={{ backgroundColor: "#176138" }}
														type="submit"
													>
														{loading ? (
															<div className="d-flex gap-2 w-100 justify-content-center">
																<Spinner size="sm">Loading...</Spinner>
																<span>Memuat</span>
															</div>
														) : (
															<span>Masuk</span>
														)}
													</Button>
												</div>
											</Form>
											{/* modal otp */}
											{showOTP && (
												<Otp
													user={{
														username : validation.values.username,
														password : validation.values.password
													}}
													router={props.router}
													onSubmit={e => {
														e.preventDefault();
														validation.handleSubmit();
														return false;
													}}
												/>
											)}
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
					<footer className="footer">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="text-end">
										<p className="mb-0 text-white">
											© 2023 All Rights Reserved
										</p>
									</div>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</ParticlesAuth>
		</React.Fragment>
	);
};

export default withRouter(Login);
