import React, { useState, useEffect } from "react";
import { Table as ReactstrapTable } from "reactstrap";
import { getWidget, useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget";

export const Table = ({ source }) => {
	const { loading, data, failed, getSource } = useGetWidget({ source });
	const [header, setHeader] = useState([]);
	const [body, setBody] = useState([]);

	useEffect(() => {
		if (!data || !data.body) return;
		let columns = [];
		setBody(data.body);
		data.headers.map((h, i) => {
			columns.push(h.label.toUpperCase());
		});
		setHeader(columns);
	}, [data]);

	return (
		<BoundWidget
			loading={loading}
			data={data}
			failed={failed}
			onReload={getSource}
		>
			{data && (
				<ReactstrapTable striped bordered responsive>
					<thead>
						<tr>
							{header.map((col, index) => (
								<th key={index}>{col}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{body.map((row, rowIndex) => (
							<tr key={rowIndex}>
								{data.headers.map((h, colIndex) => (
									<td key={colIndex} onClick={() => console.log(row)}>{row[h.key]}</td>
								))}
							</tr>
						))}
					</tbody>
				</ReactstrapTable>
			)}
		</BoundWidget>
	);
};

export default Table;
