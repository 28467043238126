import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import ChatBubble from "./ChatBubble";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import avatar2 from "../../assets/images/default-user.png";
import styles from "./ChatBox.module.css";
import {
  Badge,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { openChatBox, setShowGroupDetail } from "../../slices/chat/reducer";
import GroupDetails from "./GroupDetails";
import MoreButton from "./MoreButton";
import SoundMessage from "../../Components/Common/sound-message";
import { SocketContext } from "../../context/SocketContext";
import { getMsgByRoom, uploadAttachment } from "../../slices/thunks";
import user_info from "../../helpers/user_info";
import set_profile, { img_error } from "../../helpers/default_profile";

const ChatBody = (props) => {
  const [text, setText] = useState("");
  const [messageBox, setMessageBox] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [modal, setModal] = useState(false);
  const [replyContent, setReplyContent] = useState(null);

  const toggle = () => {
    setModal(!modal);
  };

  const chat = useSelector((state) => state.Chat);
  const dispatch = useDispatch();
  const { sendMessage, connectChatSocket } = useContext(SocketContext);

  useEffect(() => {
    connectChatSocket(chat.chatActive.id);
  }, []);

  useLayoutEffect(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  }, [messageBox, chat.chatActive.messages]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    let data = new FormData();
    data.append("attachment[]", file);

    dispatch(uploadAttachment(data)).then((result) => {
      console.log(result);
      sendMessage("", [result.payload[0].id]);
    });
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    setSelectedVideo(file);
    let data = new FormData();
    data.append("attachment[]", file);

    dispatch(uploadAttachment(data)).then((result) => {
      console.log(result);
      sendMessage("", [result.payload[0].id]);
    });
  };

  useEffect(() => {
    if (selectedImage) {
      // Call onSendMessage when an image is selected
      onSendMessage();
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedVideo) {
      // Call onSendMessage when an image is selected
      onSendMessage();
    }
  }, [selectedVideo]);

  const onSendMessage = () => {
    if (selectedImage) {
      setSelectedImage(null);
    } else if (selectedVideo) {
      setSelectedVideo(null);
    } else if (text.trim() !== "") {
      console.log("tess");
    }
  };

  const sendChat = (e) => {
    if (e.key === "Enter") {
      sendMessage(text, [], replyContent?.id);
      setText("");
      setReplyContent(null);
    }
  };

  const submitAudio = (audio) => {
    const rekaman = new File([audio], "rekaman.ogg", {
      type: audio.type,
    });
    let data = new FormData();
    data.append("attachment[]", rekaman);
    dispatch(uploadAttachment(data)).then((result) => {
      sendMessage("", [result.payload[0].id]);
    });
  };

  const endMsg = (id) => {
    console.log(id);
  };

  const archiveMsg = (id) => {
    console.log(id);
  };

  const markUnread = (id) => {
    console.log(id);
  };

  const groupDetail = () => {
    dispatch(setShowGroupDetail());
  };

  const openAudioRec = () => {
    toggle();
  };
  const [lastSender, setLastSender] = useState(null);
  useEffect(() => {
    if (chat.chatActive?.messages && chat.chatActive.messages.length > 0) {
      setLastSender(
        chat.chatActive.messages[chat.chatActive.messages.length - 1].sender_id
      );
    }
  }, [chat.chatActive?.messages]);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      {chat.chatActive?.type === "group" && props.isFull && <GroupDetails />}
      <CardHeader className="align-items-center d-flex">
        <img
          src={
            chat.chatActive.type === "group"
              ? set_profile(chat.chatActive.logo)
              : set_profile(chat.chatActive?.member[0].avatar)
          }
          className="me-3 rounded-circle avatar-xs"
          alt="user-pic"
          onError={(e) => img_error(e)}
        />
        <h4 className="card-title mb-0 flex-grow-1">{chat.chatActive?.name}</h4>
        <div className="flex-shrink-0">
          {props.isFull ? (
            <MoreButton
              groupDetail={groupDetail}
              endMsg={endMsg}
              archiveMsg={archiveMsg}
              markUnread={markUnread}
              items={chat?.chatActive}
            />
          ) : (
            <button
              type="button"
              className="btn-close float-end fs-11"
              aria-label="Close"
              onClick={() => dispatch(openChatBox(false))}
            ></button>
          )}
        </div>
      </CardHeader>
      <CardBody className="p-0 position-relative">
        <div id="users-chat">
          <PerfectScrollbar
            className={`${styles.chat_body} chat-conversation p-3`}
            id="chat-conversation"
            style={{ height: "407px !important" }}
            containerRef={(ref) => setMessageBox(ref)}
          >
            <ul
              className="list-unstyled chat-conversation-list chat-sm"
              id="users-conversation"
            >
              {chat.chatActive?.messages ? (
                chat.chatActive.messages.map((item, key) => (
                  <ChatBubble
                    item={item}
                    key={key}
                    setReplyContent={setReplyContent}
                    messageBox={messageBox}
                    isLast={key === chat.chatActive.messages.length - 1}
                    showAvatar={
                      key === 0 ||
                      chat.chatActive.messages[key - 1]?.sender_id !==
                        item.sender_id
                    }
                  />
                ))
              ) : (
                <p className="text-center">No messages yet.</p>
              )}
            </ul>
          </PerfectScrollbar>
        </div>
        {replyContent !== null && (
          <div
            className="position-absolute start-0 end-0 p-3 d-flex justify-content-between"
            style={{
              background: "rgb(41 41 41 / 69%",
              bottom: "13%",
              color: "white",
            }}
          >
            <span>
              <h6 style={{ color: "#68ff68 " }}>{replyContent.sender.name}</h6>
              {replyContent.attachment !== null
                ? replyContent.attachment[0].name
                : replyContent.content}
            </span>
            <i
              className=" ri-close-line me-2 text-white fs-20 align-bottom"
              role="button"
              onClick={() => setReplyContent(null)}
            ></i>
          </div>
        )}
        <div className="border-top border-top-dashed relative">
          <div className="row g-2 mx-3 mt-2 mb-3">
            <div className="col-auto">
              <label
                htmlFor="fileInput"
                className="btn p-2 m-0"
                style={{ cursor: "pointer" }}
              >
                <i className="ri-image-line fs-5"></i>
              </label>
              <input
                type="file"
                id="fileInput"
                accept=".jpg,.jpeg,.png, video/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <button type="button" onClick={openAudioRec} className="btn p-2">
                <i className=" ri-mic-line fs-5"></i>
              </button>
              <label
                htmlFor="videoInput"
                className="btn p-2 m-0"
                style={{ cursor: "pointer" }}
              >
                <i className="ri-file-line fs-5"></i>
              </label>
              <input
                type="file"
                id="videoInput"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                style={{ display: "none" }}
                onChange={handleVideoChange}
              />
            </div>
            <div className="col">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control border-light bg-light"
                  placeholder="Enter Message..."
                  value={text}
                  onKeyDown={sendChat}
                  onChange={(e) => setText(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="modal-title" toggle={toggle}>
          Kirim Pesan Suara
        </ModalHeader>
        <ModalBody>
          <SoundMessage submitAudio={submitAudio} toggle={toggle} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChatBody;
