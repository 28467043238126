import { createSlice }                     from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import CustomToast                         from "../../../Components/Common/CustomToast";
import { patchProfile, patchProfilePhoto } from "./thunk";

export const initialState = {
  isUpdate  : false,
  isLoading : false,
  isInit    : false,
  data      : {
    name           : "",
    email          : "",
    phone_number   : "",
    workunit_level : "",
    position       : "",
  },
};

const ProfileSlice = createSlice({
  name         : "Profile",
  initialState : initialState,
  reducers     : {
    loadProfile: (state, action) => {
      const raw = Cookies.get("authUser");
      try {
        state.data   = JSON.parse(raw);
        state.isInit = false;
      } catch (error) {
        console.error(error);
        CustomToast("danger", "Gagal memuat user");
      }
    },
    initProfile: (state, action) => {
      if (!state.isInit) return;
      state.data   = action.payload;
      state.isInit = false;
    },
    updateProfile: (state, action) => {
      if (state.isUpdate) return;
      state.data     = Object.assign(state.data, action.payload);
      state.isUpdate = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(patchProfile.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isUpdate  = true;
      })
      .addCase(patchProfile.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isUpdate  = false;
        state.data      = payload;
        Cookies.set("authUser", JSON.stringify(state.data));
      })
      .addCase(patchProfile.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isUpdate  = false;
      })
      .addCase(patchProfilePhoto.pending, (state, { payload }) => {
        state.isLoading = true;
        state.isUpdate  = true;
      })
      .addCase(patchProfilePhoto.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isUpdate  = false;
    
        Cookies.set("authUser", JSON.stringify({...state.data, photo: payload.photo}));
        window.location.reload()
      })
      .addCase(patchProfilePhoto.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isUpdate  = false;
      });
  },
});

export const { loadProfile, initProfile, updateProfile } = ProfileSlice.actions;

export default ProfileSlice.reducer;
