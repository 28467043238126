import { Fragment } from "react"
import { Button, Spinner } from "reactstrap"


const SubmitButton = props => {
    // props
    const {
        className,
        style,
        loading,
        title,
        isBlock,
        onClick
    } = props

    // content
    return (
        <Fragment>
            <Button
                type="submit"
                color="primary"
                disabled={loading}
                className={className}
                onClick={onClick}
                block={isBlock ?? false}
                style={style}>

                {!loading ?
                    title :
                    <Spinner
                        color="success"
                        size="sm" />}
            </Button>
        </Fragment>
    )
}



export default SubmitButton