import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from "reactstrap";
import ChatList from "../../pages/Chat/ChatList";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRoomChat } from "../../slices/thunks";
import SelectSingleUser from "./select-single-user";
import { SocketContext } from "../../context/SocketContext";
import SelectMultipleUser from "./select-multiple-user";

const Message = () => {
  // states
  const [isMessage, setIsMessage] = useState(false);
  const [chatCount, setChatCount] = useState(0);
  const [modalPersonal, setModalPersonal] = useState(false);
  const [modalGroup, setModalGroup] = useState(false);
  const [groupName, setGroupName] = useState("");
  // global States
  const chat = useSelector((state) => state.Chat);
  const dispatch = useDispatch();

  const { personalRoom, groupRoom } = useContext(SocketContext);

  //   use effect
  useEffect(() => {
    dispatch(getRoomChat());
  }, []);

  useEffect(() => {
    let count = chat.roomList.reduce((acc, obj) => {
      return acc + obj.un_read_message;
    }, 0);
    setChatCount(count);
  }, [chat.roomList]);

  //   functions
  const toggleMessage = () => {
    setIsMessage(!isMessage);
  };

  const createPersonalRoom = (user) => {
    personalRoom(user);
    setModalPersonal(!modalPersonal);
  };

  const createGroupRoom = (user) => {
    groupRoom(groupName, user);
    setModalGroup(!modalGroup);
  };

  return (
    <React.Fragment>
      <SelectSingleUser
        show={modalPersonal}
        setShow={() => setModalPersonal(!modalPersonal)}
        title="Komunikasi Baru"
        size="md"
        onSubmit={(user) => createPersonalRoom(user)}
      />

      <SelectMultipleUser
        show={modalGroup}
        setShow={() => setModalGroup(!modalGroup)}
        title="Komunikasi Group"
        size="md"
        onSubmit={(users) => createGroupRoom(users)}
      >
        <FormGroup>
          <Label for="nama_group">Nama Group</Label>
          <Input
            id="nama_group"
            name="nama_group"
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </FormGroup>
      </SelectMultipleUser>

      <Dropdown
        isOpen={isMessage}
        toggle={toggleMessage}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
          style={{ color: "#FFFFFF" }}
        >
          <i className="bx bx-message-alt-detail fs-22"></i>
          {chatCount !== 0 && (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
              {chatCount}
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <ChatList
              height="300px"
              // icon="bx bx-exit-fullscreen"
              // link="/chat"
              isDropdown={true}
              closeDropdown={setIsMessage}
              personal={() => {
                setModalPersonal(!modalPersonal);
              }}
              group={() => {
                setModalGroup(!modalGroup);
              }}
            />
            <hr />
            <div className="text-center">
              <Link to="/chat">Lihat Semua</Link>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default Message;
