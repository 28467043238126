//Include Both Helper File with needed methods
import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
  loginStart,
  otpSuccess,
} from "./reducer";
import { APIClient, setAuthorization } from "../../../helpers/api_helper";
import { AUTH_BASE } from "../../../helpers/url_helper";
import CustomToast from "../../../Components/Common/CustomToast";
import Cookies from "js-cookie";

const api = new APIClient();

export const loginUser = (user, history, showOtp, otp) => async (dispatch) => {
  try {
    dispatch(loginStart());

    let response =
      otp !== null
        ? api.create(`${AUTH_BASE}/login?otp=${otp}`, user)
        : api.create(`${AUTH_BASE}/login`, user);

    let data = await response;

    if (!data.is_error) {
      if (data.data.result.otp) {
        showOtp(true);
        dispatch(otpSuccess(data));
      } else if (data.data.result.biodata && data.data.result.token) {
        const authUser = data.data.result.biodata || {};
        const token = data.data.result.token || "";
        const siaccToken = data.data.result.siacc_token || "";

        authUser.username = user.username;

        Cookies.set("authUser", JSON.stringify(authUser));
        Cookies.set("token", JSON.stringify(token));
        Cookies.set("siaccToken", JSON.stringify(siaccToken));

        // setAuthorization(token);

        dispatch(loginSuccess(data));
        // CustomToast("success", "Login berhasil!");
        // setTimeout(() => {
        window.location.replace("/splash");
        // }, 3000);
        // history("/splash");
      }
    }
  } catch (error) {
    dispatch(apiError(error));
    CustomToast("danger", "Login gagal!");
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    Cookies.remove("authUser");
    Cookies.remove("token");

    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};
