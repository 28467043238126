import React, { useContext, useEffect, useState } from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Button,
	ModalFooter,
} from "reactstrap";
import ChatList from "../../../pages/Chat/ChatList";
import { SocketContext } from "../../../context/SocketContext";
import { useSelector } from "react-redux";

const Share = () => {
	const [showModal1, setShowModal1] = useState(false);
	const [roomSelected, setRoomSelected] = useState([])
    const { sendDashboard } = useContext(SocketContext);
	const dashboards = useSelector(state => state.Dashboards)

	const toggleModal1 = () => {
		setRoomSelected([])
		setShowModal1(!showModal1)
	};

	const handleClick = () => {
		let text = window.location.host+"/share/"+dashboards.current._id
		roomSelected.map(room => {
			sendDashboard(text, room)
		})
		toggleModal1()
	};

	const checkRoom = (id, e) => {
		if (e.target.checked) {
			setRoomSelected([...roomSelected, id])
		} else {
			setRoomSelected((prev) => prev.filter(room => room !== id))
		}
	}

	return (
		<>
			<span
				className="mdi mdi-link-variant fs-4 px-2 cursor-pointer"
				onClick={toggleModal1}
			></span>
			<Modal isOpen={showModal1} toggle={toggleModal1}>
				<ModalHeader toggle={toggleModal1}>Share Analitik</ModalHeader>
				<ModalBody>
					<ChatList closeDropdown={() => { }} isShare={true} checkRoom={checkRoom}
						height="300px" />
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={handleClick}
						disabled={!roomSelected || roomSelected.length === 0}
					>
						Share
					</Button>
				</ModalFooter>
			</Modal>


		</>
	);
};

export default Share;
