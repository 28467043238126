import { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import SubmitButton from "../../Components/Common/submit-button";
import { yupResolver } from "@hookform/resolvers/yup";
import EndpointSchema from "./validation";
import { Plus, Trash2 } from "react-feather";
import { APIClient } from "../../helpers/api_helper";
import { DATA_COLLECTOR_BASE } from "../../helpers/url_helper";
import CustomToast from "../../Components/Common/CustomToast";
import DynamicInput from "./dynamic-input";
import EndpointTabs from "./tabs";
import EndpointChild from "./endpoint-child";

const EditEndpoint = (props) => {
  // props
  const { show, onClose, data, getData, dataSource, dataType, endpoint_id } =
    props;

  // states
  const [loading, setLoading] = useState(false);
  const [bodyPost, setBodyPost] = useState(false);
  const [scheduledMode, setScheduledMode] = useState(1);
  const [filterWorkunit, setFilterWorkunit] = useState(0);
  const [directCall, setDirectCall] = useState(1);
  const [keys, setKeys] = useState([]);
  const [dataTypeName, setDataTypeName] = useState("");
  const [iterateType, setIterateType] = useState("");
  const [tab, setTab] = useState(1);
  const [child, setChild] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const [periods, setPeriods] = useState({
    is_daily: 0,
    is_weekly: 0,
    is_monthly: 0,
    is_yearly: 0,
    is_custom: 0,
  });

  const [periodsForm, setPeriodsForm] = useState({
    is_daily: [],
    is_weekly: [],
    is_monthly: [],
    is_yearly: [],
    is_custom: [],
  });

  // ** Hooks Form
  const {
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(EndpointSchema),
  });

  const api = new APIClient();

  /**
   * handlePeriodsChange evaluate the periods checkbox input whenever
   * the value change. It set the periods state, add or remove form object
   * from periodsForm state.
   *
   * @param {boolean} checked
   * @param {string} name
   * @returns
   *
   * */
  const handlePeriodsChange = (checked, name) => {
    setPeriods((prev) => ({
      ...prev,
      [name]: checked ? 1 : 0,
    }));

    if (checked) {
      const form = { key: "", value: "" };
      let current = periodsForm[name];

      if (current === null) {
        current = [];
        current.push(form);
      } else {
        current.push(form);
      }

      setPeriodsForm((prev) => ({
        ...prev,
        [name]: current,
      }));

      return;
    }

    setPeriodsForm((prev) => ({
      ...prev,
      [name]: [],
    }));
    return;
  };

  /**
   * handlePeriodsFormAction is function for action button for each
   * form input of periods.
   *
   * @param {string} name name of periodsForm
   * @param {number} index current index of iteration
   * @param {string} state state of button
   * @returns
   *
   * */
  const handlePeriodsFormAction = (name, index, state) => {
    const form = { key: "", value: "" };
    const current = periodsForm[name];

    if (state === "add") {
      current.push(form);
    }

    if (state === "remove") {
      current.splice(index, 1);
    }

    setPeriodsForm((prev) => ({
      ...prev,
      [name]: current,
    }));
  };

  const handleInputChange = (index, field, value) => {
    const updatedKeys = [...keys];
    updatedKeys[index] = {
      ...updatedKeys[index],
      [field]: value,
    };
    setKeys(updatedKeys);
  };

  const getDataTypeKeys = (id) => {
    api
      .get(DATA_COLLECTOR_BASE + "/datatype-group/?list=true")
      .then((response) => {
        const data = response.data;

        const selectedId = id;
        const selectedDataType = data.find((item) => item.id === selectedId);

        if (selectedDataType) {
          const fields = selectedDataType.fields.map((field) => ({
            id: field.id,
            key: "",
            key_type: field.field_type,
            value_data: field.field_name,
          }));
          setKeys(fields);
          setDataTypeName(selectedDataType.name);
        } else {
          console.info("Tidak ada data dengan id yang dipilih");
        }
      })
      .catch((error) => {
        CustomToast("danger", error);
      });
  };

  const handlePeriodsFormInput = (name, key, index, value) => {
    const current = periodsForm[name];
    current[index][key] = value;

    setPeriodsForm((prev) => ({
      ...prev,
      [name]: current,
    }));
  };

  const objToArray = (obj) => {
    var newArr = [];
    if (obj != undefined) {
      Object.keys(obj).forEach(function (item, index) {
        newArr.push({ key: item, value: obj[item] });
      });
    }

    return newArr;
  };

  /** parseAdditionalParam parse an array of object into map object.
   * It set the key from the array's key and value from array's value
   * then return the result.
   *
   * @param {Array<Map<String, String>>} data
   * @returns {Map<String,String>}
   *
   * */
  const parseAdditionalParam = (data) => {
    let ap = {};
    for (const d of data) {
      ap[d.key] = d.value;
    }

    return ap;
  };

  const onSubmit = (dataForm) => {
    dataForm["body_request"] = JSON.parse(dataForm["body_request"]);
    dataForm["scheduled_mode"] = scheduledMode;
    dataForm["scheduling_time"] = parseInt(dataForm["scheduling_time"]);
    dataForm["filter_workunit"] = filterWorkunit;
    dataForm["direct_call"] = directCall;
    dataForm["keys"] = keys;
    dataForm["is_daily"] = periods["is_daily"];
    dataForm["is_weekly"] = periods["is_weekly"];
    dataForm["is_monthly"] = periods["is_monthly"];
    dataForm["is_yearly"] = periods["is_yearly"];
    dataForm["is_custom"] = periods["is_custom"];

    dataForm["endpoint_scheduler"] = {
      is_daily: periods["is_daily"] === 1 ? true : false,
      is_weekly: periods["is_weekly"] === 1 ? true : false,
      is_yearly:
        iterateType === "object" || iterateType === "key-value"
          ? true
          : periods["is_yearly"] === 1
          ? true
          : false,
      is_monthly: periods["is_monthly"] === 1 ? true : false,
      is_custom: false,

      data: {
        daily_period_data: !periodsForm["is_daily"].length
          ? null
          : {
              additional_parameter: parseAdditionalParam(
                periodsForm["is_daily"]
              ),
            },
        weekly_period_data: !periodsForm["is_weekly"].length
          ? null
          : {
              additional_parameter: parseAdditionalParam(
                periodsForm["is_weekly"]
              ),
            },
        yearly_period_data: !periodsForm["is_yearly"].length
          ? null
          : {
              additional_parameter: parseAdditionalParam(
                periodsForm["is_yearly"]
              ),
            },
        custom_period_data: !periodsForm["is_custom"].length
          ? null
          : {
              additional_parameter: parseAdditionalParam(
                periodsForm["is_custom"]
              ),
            },
        monthly_period_data: !periodsForm["is_monthly"].length
          ? null
          : {
              additional_parameter: parseAdditionalParam(
                periodsForm["is_monthly"]
              ),
            },
      },
    };
    dataForm["endpoint_child"] = [...child];

    api
      .put(DATA_COLLECTOR_BASE + "/endpoints/" + data?.id, dataForm)
      .then((res) => {
        CustomToast("success", "Data Endpoint berhasil diubah!");
        getData();
        onClose();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Tidak dapat menambahkan data endpoint", error);
        CustomToast("danger", "Data Endpoint gagal diubah!");
        setLoading(false);
      });
  };

  const addRow = () => {
    let newKeys = [
      ...keys,
      {
        id: uuidv4(),
        key: "",
        key_type: "default",
        value_data: "",
      },
    ];
    setKeys(newKeys);
  };

  const removeRow = (id) => {
    let filtered = keys.filter((k) => {
      return k.id !== id;
    });
    setKeys(filtered);
  };

  const getEndpointDetail = async (eid) => {
    setIsloading(true);

    let { data } = await api.get(DATA_COLLECTOR_BASE + "/endpoints/" + eid);
    if (data) {
      setValue("datasource_id", data?.datasource?.id);
      setValue("name", data?.name);
      setValue("method", data?.method.toLowerCase());
      if (data?.method.toLowerCase() == "post") {
        setBodyPost(true);
        setValue("body_request", JSON.stringify(data?.body_request));
      }
      setValue("path", data?.path);
      setValue("filter_workunit_type", data?.filter_workunit_type);
      setValue("iterate_field", data?.iterate_field);
      setValue("iterate_type", data?.iterate_type);
      setValue("iterate_type", data?.iterate_type);
      setValue("unique_id", data?.unique_id);
      setValue("pagination_param_key", data?.pagination_param_key);
      setValue("total_page_key", data?.total_page_key);
      setValue("get_data_type", data?.get_data_type);
      setScheduledMode(data?.scheduled_mode);
      setFilterWorkunit(data?.filter_workunit);
      setDirectCall(data?.direct_call);
      setValue(
        "scheduling_time",
        data?.scheduling_time ? data?.scheduling_time / 3600 : 0
      );
      setValue("datatype_group_id", data?.datatype_group?.id);
      setKeys(data?.keys);

      setPeriods({
        is_daily: data?.is_daily,
        is_weekly: data?.is_weekly,
        is_monthly: data?.is_monthly,
        is_yearly: data?.is_yearly,
        is_custom: data?.is_custom,
      });

      setPeriodsForm({
        is_daily: objToArray(
          data?.endpoint_scheduler.data.daily_period_data.additional_parameter
        ),
        is_weekly: objToArray(
          data?.endpoint_scheduler.data.weekly_period_data.additional_parameter
        ),
        is_monthly: objToArray(
          data?.endpoint_scheduler.data.monthly_period_data.additional_parameter
        ),
        is_yearly: objToArray(
          data?.endpoint_scheduler.data.yearly_period_data.additional_parameter
        ),
        is_custom: objToArray(
          data?.endpoint_scheduler.data.custom_period_data.additional_parameter
        ),
      });
      setChild(data.endpoint_child);
      setIsloading(false);
    }
  };

  const clear = () => {
    setValue("");
    setBodyPost(false);
    setScheduledMode([]);
    setFilterWorkunit("");
    setDirectCall("");
    setKeys([]);
    setPeriods({});
    setPeriodsForm({});
    setChild([]);
    setTab(1);
  };

  return (
    <>
      <Modal
        isOpen={show}
        toggle={onClose}
        fullscreen
        onOpened={() => {
          getEndpointDetail(endpoint_id);
        }}
        onClosed={clear}
      >
        {/*  */}
        <ModalHeader toggle={onClose}>Ubah Endpoint</ModalHeader>

        <ModalBody>
          {isLoading ? (
            <Spinner
              size={"2xl"}
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* <EndpointTabs tab1={<Tab1 />} tab={tab} setTab={setTab} child={child} setChild={setChild} tab2={<EndpointChild child={child} setChild={setChild} />} /> */}

              <Row className="g-2">
                <Col xs={12}>
                  <div
                    className="d-flex align-content-center"
                    style={{ gap: "8px" }}
                  >
                    <Button
                      className="fw-semibold rounded-pill"
                      color={tab != 1 ? "link" : "primary"}
                      outline={tab != 1}
                      onClick={() => setTab(1)}
                    >
                      Endpoint Data
                    </Button>
                    <Button
                      className="fw-semibold rounded-pill"
                      color={tab != 2 ? "link" : "primary"}
                      outline={tab != 2}
                      onClick={() => setTab(2)}
                    >
                      Endpoint Child
                    </Button>
                  </div>
                </Col>
                <Col xs={12}>
                  <TabContent activeTab={tab}>
                    <TabPane tabId={1}>
                      <Row>
                        <Col md={4}>
                          <FormGroup>
                            <Label for="datasource_id">Sumber Data</Label>
                            <Controller
                              id="datasource_id"
                              name="datasource_id"
                              control={control}
                              render={({ field }) => (
                                <Input type="select" {...field}>
                                  {dataSource && dataSource.length > 0 ? (
                                    dataSource.map((item) => (
                                      <option value={item.id} key={item.id}>
                                        {item.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">No data available</option>
                                  )}
                                </Input>
                              )}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="name">Nama Widget</Label>
                            <Controller
                              id="name"
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Masukkan Nama Widget"
                                  type="text"
                                />
                              )}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="dataMethod">Method</Label>
                            <Controller
                              id="method"
                              name="method"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  onChange={(e, value) => {
                                    setBodyPost(
                                      e.target.value == "get" ? false : true
                                    );
                                    field.onChange(e.target.value);
                                  }}
                                  type="select"
                                >
                                  <option disabled value="">
                                    Pilih Method
                                  </option>
                                  <option value="get">Get</option>
                                  <option value="post">Post</option>
                                </Input>
                              )}
                            />
                          </FormGroup>
                          {data?.method?.toLowerCase() !== "get" && (
                            <FormGroup>
                              <Label for="dataBody">Body</Label>
                              <Controller
                                id="body_request"
                                name="body_request"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    placeholder="Masukkan Body"
                                    type="textarea"
                                    rows="5"
                                  />
                                )}
                              />
                            </FormGroup>
                          )}
                          <FormGroup>
                            <Label for="path">Path</Label>
                            <Controller
                              id="path"
                              name="path"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  onChange={(e, value) => {
                                    field.onChange(
                                      e.target.value.startsWith("/")
                                        ? e.target.value
                                        : "/" + e.target.value
                                    );
                                  }}
                                  placeholder="Masukkan path"
                                  type="text"
                                />
                              )}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="iterate_field">Iterate Field</Label>
                            <Controller
                              id="iterate_field"
                              name="iterate_field"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Masukkan Nama Widget"
                                  type="text"
                                />
                              )}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="iterate_type">Iterate Type</Label>
                            <Controller
                              id="iterate_type"
                              name="iterate_type"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Masukkan Iterate Type"
                                  type="select"
                                >
                                  <option disabled value="">
                                    Pilih Tipe Iterasi
                                  </option>
                                  <option value="array">Array</option>
                                  <option value="object">Object</option>
                                  <option value="key-value">Key Value</option>
                                </Input>
                              )}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="name">Unique Id</Label>
                            <Controller
                              id="unique_id"
                              name="unique_id"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Masukkan Unique Id"
                                  type="text"
                                />
                              )}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="pagination_param_key">
                              Pagination Param Key
                            </Label>
                            <Controller
                              id="pagination_param_key"
                              name="pagination_param_key"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Masukkan Pagination Param Key"
                                  type="text"
                                />
                              )}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="total_page_key">Total Page Key</Label>
                            <Controller
                              id="total_page_key"
                              name="total_page_key"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  placeholder="Masukkan Total Page Key"
                                  type="text"
                                />
                              )}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="get_data_type">Get Data Type</Label>
                            <Controller
                              id="get_data_type"
                              name="get_data_type"
                              control={control}
                              render={({ field }) => (
                                <Input type="select" {...field}>
                                  <option value="list">List</option>
                                  <option value="pagination">Pagination</option>
                                </Input>
                              )}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Input
                              type="checkbox"
                              id="scheduled_mode"
                              name="scheduled_mode"
                              checked={scheduledMode === 1}
                              onChange={(e) =>
                                setScheduledMode(e.target.checked ? 1 : 0)
                              }
                            />{" "}
                            <Label check for="scheduled_mode" className="">
                              Ambil data secara berkala (Jam)
                            </Label>
                            {scheduledMode === 1 && (
                              <Controller
                                id="scheduling_time"
                                name="scheduling_time"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    className="mt-2"
                                    placeholder="Masukkan Data berdasarkan Jam"
                                    type="number"
                                  />
                                )}
                              />
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="checkbox"
                              id="filter_workunit"
                              name="filter_workunit"
                              checked={filterWorkunit === 1}
                              onChange={(e) =>
                                setFilterWorkunit(e.target.checked ? 1 : 0)
                              }
                            />{" "}
                            <Label check for="filter_workunit" className="">
                              Filter Workunit
                            </Label>
                          </FormGroup>
                          <FormGroup>
                            <Input
                              type="checkbox"
                              id="direct_call"
                              name="direct_call"
                              checked={directCall === 1}
                              onChange={(e) =>
                                setDirectCall(e.target.checked ? 1 : 0)
                              }
                            />{" "}
                            <Label check for="direct_call" className="">
                              Akses Langsung
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label for="filter_workunit_type">
                              Filter Workunit Type
                            </Label>
                            <Controller
                              id="filter_workunit_type"
                              name="filter_workunit_type"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  onChange={(e, value) => {
                                    field.onChange(e.target.value);
                                  }}
                                  type="select"
                                >
                                  <option value="" disabled hidden>
                                    Pilih Filter Workunit Type
                                  </option>
                                  <option value="work_unit_ids">
                                    work_unit_ids
                                  </option>
                                  <option value="work_unit_id">
                                    work_unit_id
                                  </option>
                                  <option value="work_unit_id_by_login">
                                    work_unit_id_by_login
                                  </option>
                                  <option value="work_unit_id_and_work_unit_ids">
                                    work_unit_id_and_work_unit_ids
                                  </option>
                                </Input>
                              )}
                            />
                          </FormGroup>

                          <h5 className="mb-3">Periode Endpoint</h5>
                          {Object.keys(periods).map((period, index) => (
                            <div key={"periods" + index} className="mb-3">
                              <FormGroup check>
                                <Label for={period} check>
                                  <Input
                                    id={period}
                                    name={period}
                                    type="checkbox"
                                    checked={periods[period] === 1}
                                    onChange={(e) =>
                                      handlePeriodsChange(
                                        e.target.checked,
                                        period
                                      )
                                    }
                                  ></Input>

                                  {period === "is_daily"
                                    ? "Harian"
                                    : period === "is_weekly"
                                    ? "Mingguan"
                                    : period === "is_monthly"
                                    ? "Bulanan"
                                    : period === "is_yearly"
                                    ? "Tahunan"
                                    : "Custom"}
                                </Label>
                              </FormGroup>
                              {periods[period] === 1 && (
                                <div>
                                  {periodsForm[period].length
                                    ? periodsForm[period].map((p, i) => (
                                        <Row className="mt-2" key={i}>
                                          <Col md={4}>
                                            <Input
                                              id=""
                                              name={`key`}
                                              placeholder="Key"
                                              onChange={(e) =>
                                                handlePeriodsFormInput(
                                                  period,
                                                  "key",
                                                  i,
                                                  e.target.value
                                                )
                                              }
                                              value={p.key}
                                            ></Input>
                                          </Col>
                                          <Col md={5}>
                                            <Input
                                              id=""
                                              name={`value`}
                                              placeholder="Value"
                                              onChange={(e) =>
                                                handlePeriodsFormInput(
                                                  period,
                                                  "value",
                                                  i,
                                                  e.target.value
                                                )
                                              }
                                              value={p.value}
                                            ></Input>
                                          </Col>
                                          <Col>
                                            <Button
                                              size="sm"
                                              color="success"
                                              onClick={() =>
                                                handlePeriodsFormAction(
                                                  period,
                                                  i,
                                                  "add"
                                                )
                                              }
                                            >
                                              <Plus />
                                            </Button>
                                          </Col>
                                          {i >= 1 && (
                                            <Col>
                                              <Button
                                                size="sm"
                                                color="danger"
                                                onClick={() =>
                                                  handlePeriodsFormAction(
                                                    period,
                                                    i,
                                                    "remove"
                                                  )
                                                }
                                              >
                                                <Trash2 />
                                              </Button>
                                            </Col>
                                          )}
                                        </Row>
                                      ))
                                    : ""}
                                </div>
                              )}
                            </div>
                          ))}
                        </Col>
                        <Col md={8}>
                          <FormGroup>
                            <Label for="type_data">Type Data</Label>
                            <Controller
                              id="datatype_group_id"
                              name="datatype_group_id"
                              control={control}
                              render={({ field }) => (
                                <Input
                                  {...field}
                                  type="select"
                                  disabled
                                  onChange={(e) => {
                                    getDataTypeKeys(e.target.value);
                                    field.onChange(e.target.value);
                                  }}
                                >
                                  <option value="" disabled hidden>
                                    Pilih Type Data
                                  </option>
                                  {dataType && dataType.length > 0 ? (
                                    dataType.map((item) => (
                                      <option value={item.id} key={item.id}>
                                        {item.name}
                                      </option>
                                    ))
                                  ) : (
                                    <option value="">No data available</option>
                                  )}
                                </Input>
                              )}
                            />
                          </FormGroup>
                          {keys?.map((item, index) => (
                            
                            <Row key={item.id}>
                              <Col
                                md={
                                  (dataTypeName ||
                                    data?.datatype_group.name) !== "TABLE"
                                    ? 4
                                    : 3
                                }
                              >
                                <FormGroup>
                                  <Label for={item.value_data}>
                                    Label{dataTypeName}
                                  </Label>
                                  <Input
                                    id={item.value_data}
                                    disabled={
                                      (dataTypeName ||
                                        data?.datatype_group.name) !== "TABLE"
                                        ? true
                                        : false
                                    }
                                    name={item.value_data}
                                    type="text"
                                    placeholder={item.value_data}
                                    value={item.value_data}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "value_data",
                                        e.target.value
                                      )
                                    }
                                  />
                                </FormGroup>
                              </Col>

                              <Col
                                md={
                                  (dataTypeName ||
                                    data?.datatype_group.name) !== "TABLE"
                                    ? 4
                                    : 3
                                }
                              >
                                <FormGroup>
                                  <Label for={item.field_type}>Type</Label>
                                  <Input
                                    id={item.field_type}
                                    name={item.field_type}
                                    type="select"
                                    disabled={
                                      (dataTypeName ||
                                        data?.datatype_group.name) === "TABLE"
                                        ? true
                                        : false
                                    }
                                    value={item.key_type}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "key_type",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="default" defaultValue>
                                      Default
                                    </option>
                                    <option value="outter_key">
                                      Outer Key
                                    </option>
                                    <option value="default_value">
                                      Default Value
                                    </option>
                                    <option value="array">Array</option>
                                    <option value="ranking_performance">
                                      Ranking Performance
                                    </option>
                                    <option value="chainable">Chainable</option>
                                    <option value="unused">Unused</option>
                                  </Input>
                                </FormGroup>
                              </Col>

                              <Col
                                md={
                                  (dataTypeName ||
                                    data?.datatype_group.name) !== "TABLE"
                                    ? 4
                                    : 3
                                }
                              >
                                {item.value_data === "widget_type" ? (
                                  <FormGroup>
                                    <Label for={item.key}>Value Data</Label>
                                    <Input
                                      id={item.key}
                                      placeholder={"Masukkan Value Data"}
                                      name={item.key}
                                      type="select"
                                      value={item.key}
                                      onChange={(e) => {
                                        handleInputChange(
                                          index,
                                          "key",
                                          e.target.value
                                        );
                                      }}
                                    >
                                      <option disabled value="">
                                      Pilih Tipe Widget
                                      </option>
                                      <option value="percentage">Percentage</option>
                                      <option value="chart">Chart</option>
                                      <option value="performance">Performance</option>
                                      <option value="user">User</option>
                                      </Input>
                                  </FormGroup>
                                ) : (
                                  <FormGroup>
                                    <Label for={item.key}>Value Data</Label>
                                    <Input
                                      id={item.key}
                                      placeholder={"Masukkan Value Data"}
                                      name={item.key}
                                      type="text"
                                      value={item.key}
                                      onChange={(e) => {
                                        handleInputChange(
                                          index,
                                          "key",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </FormGroup>

                                )}
                              </Col>

                              {data?.datatype_group.name === "TABLE" && (
                                <Col md={3} className="align-self-center">
                                  <div className="d-flex gap-2">
                                    <Button
                                      color="primary"
                                      size="sm"
                                      onClick={() => {
                                        addRow();
                                      }}
                                    >
                                      <Plus />
                                    </Button>
                                    {index !== 0 && (
                                      <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                          removeRow(item.id);
                                        }}
                                      >
                                        <Trash2 />
                                      </Button>
                                    )}
                                  </div>
                                </Col>
                              )}
                            </Row>
                          ))}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId={2}>
                      <EndpointChild child={child} setChild={setChild} />
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
              <ModalFooter>
                <Button color="secondary" onClick={onClose}>
                  Batal
                </Button>

                <SubmitButton loading={loading} title="Ubah" />
              </ModalFooter>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditEndpoint;
