import PropTypes                    from "prop-types";
import React, { useEffect }         from "react";
import { Navigate }                 from "react-router-dom";

import { logoutUser }               from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter                   from "../../Components/Common/withRouter";

const Logout = (props) => {
  const dispatch = useDispatch();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
     window.location.href = "/";
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};


export default withRouter(Logout);