import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import Swal from "sweetalert2";
import Select from "react-select";
import "./layout.css"

//Import Components
import VerticalLayout from "./VerticalLayouts";
import withRouter from "../Components/Common/withRouter";

import user_info, { isAdmin } from "../helpers/user_info";
import { createDashboard, deleteDashboard } from "../slices/dashboards/thunk";
import AdminMenu from "./AdminMenu";
import CustomToast from "../Components/Common/CustomToast";
import LogoSiacc from "../assets/images/logo/logosiacc.png";

const Sidebar = ({ layoutType }) => {
  const dashboards = useSelector((state) => state.Dashboards);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const toggle = (type) => {
    setModal(!modal);
    setModalType(type);
  };

  window.onscroll = function () {
    scrollFunction();
  };

  const scrollFunction = () => {
    const element = document.getElementById("back-to-top");
    if (element) {
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const [dashboardName, setDashboardName] = useState("");
  const [selectedSingle, setSelectedSingle] = useState(null);
  const [toggleDelete, setToggleDelete] = useState(false)

  function handleSelectSingle(e) {
    setSelectedSingle(e);
  }

  const snakeCase = (string) => {
    return string

      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join("-");
  };

  const handleDashboardName = (str) => {
    if (/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(str))
      return CustomToast("danger", "Karakter inputan tidak valid");
    setDashboardName(str.trimStart());
  };

  const submitAddDeleteDashboard = () => {
    switch (modalType) {
      case "add":
        if (dashboardName === "")
          return CustomToast("danger", "Nama dashboard tidak boleh kosong");
        // dashboards.map.some(d => d.label.toLowerCase() === dashboardName.toLowerCase)
        if(Object.keys(dashboards.map).some(d => d === dashboardName.toLowerCase())){
          return CustomToast("danger", `Dashboard dengan nama ${dashboardName} sudah tersedia`)
        }
        dispatch(
          createDashboard({
            label: dashboardName,
            icon: "ri-dashboard-2-line",
            path: "/" + snakeCase(dashboardName.toLowerCase()),
            layout: [],
            widget: [],
            user_id: user_info().uuid_user,
          })
        );
        toggle("");
        setDashboardName(null);
        break;
      
      default:
        break;
    }
  };

  const SingleOptions = Object.keys(dashboards.map).map((item) => ({
    // value: item,
    // label: menuItems[item].label,
    id: dashboards.map[item]._id,
    // const SingleOptions = Object.keys(dashboards.map).map((item) => ({
    value: dashboards.map[item].path,
    label: dashboards.map[item].label,
  }));

  const { leftSidebarSize } = useSelector((state) => ({
    leftSidebarSize: state.Layout.leftSidebarSize,
  }));

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  const addEventListenerOnSmHoverMenu = () => {
    // add listener Sidebar Hover icon on change layout from setting
    if (
      document.documentElement.getAttribute("data-sidebar-size") === "sm-hover"
    ) {
      document.documentElement.setAttribute(
        "data-sidebar-size",
        "sm-hover-active"
      );
    } else if (
      document.documentElement.getAttribute("data-sidebar-size") ===
      "sm-hover-active"
    ) {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    } else {
      document.documentElement.setAttribute("data-sidebar-size", "sm-hover");
    }
  };

  return (
    <React.Fragment>
      <div
        className="app-menu navbar-menu"
        style={{ height: "100vh", position: "fixed", backgroundColor: "#FFFFFF", borderRight: "#FFFFFF" }}
      >
        <div className="navbar-brand-box" style={{ backgroundColor: "#176138" }}>
          <Link to="/" className="logo logo-light" style={{ lineHeight: "66px" }}>
            <span className="logo-sm">
              <span style={{ color: "#fff", fontSize: "18pt" }}>DE</span>
            </span>
            <span className="logo-lg">
              <span style={{ color: "#fff", fontSize: "18pt" }}>
                <img src={LogoSiacc} width="40" className="me-2"></img>Dashboard</span>
            </span>
          </Link>
          <button
            onClick={addEventListenerOnSmHoverMenu}
            type="button"
            className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
            id="vertical-hover"
          >
            <i className="ri-record-circle-line"></i>
          </button>
        </div>
        <React.Fragment>
          <SimpleBar id="scrollbar" style={{ height: leftSidebarSize == "sm" ? "90%" : "80%" }}>
            <Container fluid>
              <div id="two-column-menu"></div>
              <ul className="custom-nav navbar-nav" id="navbar-nav" style={{ padding: leftSidebarSize == "sm" ? "5px" : "15px" }}>
                {<VerticalLayout toggleDelete={toggleDelete} setToggleDelete={setToggleDelete} layoutType={layoutType} />}
              </ul>
            </Container>
          </SimpleBar>
          <div className="sidebar-background"></div>
        </React.Fragment>
        <div
          className="px-3"
          style={{ marginTop: leftSidebarSize == "sm" ? "35px" : "25px" }}
        >

          {layoutType === "vertical" && !dashboards.isEmptyDashboard ? (
            <>
              {leftSidebarSize == "sm" ? (
                <Button
                  className="btn btn-danger mb-2 w-100"
                  onClick={() => setToggleDelete(!toggleDelete)}
                >
                  <span
                    className="bx bx-trash d-flex"
                    style={{ fontSize: "18px", justifyContent: "center" }}
                  ></span>
                </Button>
              ) : (
                <Button
                  className="btn btn-danger mb-2 w-100"
                  onClick={() => setToggleDelete(!toggleDelete)}
                >
                  {toggleDelete ? "Batalkan" : "Hapus Dashboard"}
                </Button>
              )}
            </>
          ) : null}

          {layoutType === "vertical" &&
            (!toggleDelete) ? (
            <>
              {leftSidebarSize == "sm" ? (
                <Button
                  className="btn bg-primary mb-2 w-100"
                  onClick={() => toggle("add")}
                >
                  <span
                    className="bx bx-list-plus d-flex"
                    style={{ fontSize: "18px", justifyContent: "center" }}
                  ></span>
                </Button>
              ) : (
                <Button
                  className="btn bg-primary mb-2 w-100"
                  onClick={() => toggle("add")}
                >
                  Tambah Dashboard
                </Button>
              )}
            </>
          ) : null}
        </div>
      </div>
      <div className="vertical-overlay"></div>
      {/* modal body */}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader className="modal-title" toggle={toggle}>
          {modalType === "add" && "Tambah Dashboard"}
          {modalType === "delete" && "Hapus Dashboard"}
          {modalType === "merge" && "Gabungkan Dashboard"}
        </ModalHeader>

        <ModalBody className="text-center">
          <div className="p-4">
            {modalType === "add" && (
              <Input
                type="text"
                placeholder="Nama Dashboard"
                value={dashboardName}
                onChange={(e) => handleDashboardName(e.target.value)}
              />
            )}
            {modalType === "delete" && (
              <Select
                placeholder="Pilih Dashboard"
                value={selectedSingle}
                onChange={(e) => {
                  handleSelectSingle(e);
                }}
                options={SingleOptions}
              />
            )}
          </div>
          <div className="hstack gap-2 justify-content-center">
            <Button
              color="light"
              onClick={() =>
                (setModal(false) && dashboardName("")) ||
                setSelectedSingle(null)
              }
            >
              Batal
            </Button>
            {modalType === "add" && (
              <Button
                onClick={() => submitAddDeleteDashboard()}
                color="primary"
                disabled={dashboardName === ""}
              >
                Tambah
              </Button>
            )}
            {modalType === "delete" && (
              <Button
                onClick={() => submitAddDeleteDashboard()}
                className="btn btn-danger"
                disabled={!selectedSingle}
              >
                Hapus
              </Button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(Sidebar);
