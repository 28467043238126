import { useState } from "react";
import { useGetWidget } from "../../../../helpers/widget_helper";

import BoundWidget from "../../BoundWidget";
import ModalDetail from "../../detail-modal";

const ChartPresentage = ({ source }) => {
  const { loading, data, failed, getSource } = useGetWidget({ source });

  // STATES
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [child, setChild] = useState();

  function toggleOpenModalChild(child_data) {
    if(data.endpoint_child === "") return
    setChild({
      period: data.period,
      source: data.source,
      enpoint_root_id: data.endpoint_id,
      param_child: data.param_child.toString(),
      childType: data.endpoint_child_type,
      type: data.type,  
    });

    setOpenModalDetail(!openModalDetail);
  }

  return (
    <>
      <BoundWidget
        loading={loading}
        data={data}
        failed={failed}
        onReload={getSource}
      >
        {data && (
          <div className="d-flex flex-column justify-content-center align-items-center" role={data.endpoint_child !== "" && "button"} onClick={toggleOpenModalChild}>
            <p className="mb-0" style={{ fontSize: "16px" }}>
              {data.label}
            </p>
            <div className="d-flex justify-content-center">
              <p style={{ fontSize: "36px", fontWeight: "700" }}>
                {data?.data && data?.data[0]}
                {/* {data?.data[0]} */}
              </p>
              <div>
                {data.percentage[0] != 0 ? (
                  data?.is_up[0] == true ? (
                    <div
                      className="align-self-center p-1 rounded d-flex ms-2"
                      style={{ backgroundColor: "#E8FFEA", color: "#00B42A" }}
                    >
                      <i className="bx bx-up-arrow-alt align-self-center"></i>
                      <span> {data.percentage ?? 0}%</span>
                    </div>
                  ) : (
                    <div
                      className="align-self-center p-1 rounded d-flex ms-2"
                      style={{ backgroundColor: "#FCECE8", color: "#EE5253" }}
                    >
                      <i className="bx bx-down-arrow-alt align-self-center"></i>
                      <span> {data?.percentage[0] ?? 0}%</span>
                    </div>
                  )
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        )}
      </BoundWidget>
      <ModalDetail
        toggleOpenModalDetail={toggleOpenModalChild}
        openModalDetail={openModalDetail}
        child={{ ...child, period: source.split("=")[2] }} // child: {type, data}
      />
    </>
  );
};

export default ChartPresentage;
