import React, { useEffect, useState } from "react";
import { useDispatch, useSelector }   from "react-redux";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Col,
	FormGroup,
	Input,
	FormFeedback,
	Form,
} from "reactstrap";
import { APIClient }                  from "../../helpers/api_helper";
import { loginUser }                  from "../../slices/thunks";

const api = new APIClient()

const Otp = ({ user, router, onSubmit }) => {

	const dispatch = useDispatch()

	const [modal, setModal] = useState(true);
	const toggle            = () => setModal(!modal);

	const dataOtp  = useSelector(state => state.Login);
	const email    = dataOtp.user.email;
	const phone    = dataOtp.user.phone_number;
	const otp_send = dataOtp.user.otp_send;

	const hiddenEmail = email.replace(/(?<=.{3}).(?=[^@]*?@)/g, "*");
	const hiddenPhone = phone.replace(/\d(?=\d{4})/g, "*");

	const [otp, setOtp]         = useState("");
	const [isValid, setIsValid] = useState(true);

	const handleChange = e => {
		const { value } = e.target;
		setOtp(value);
		setIsValid(value.length >= 6);
	};

	const handleFormSubmit = async e => {
		e.preventDefault()
		dispatch(loginUser(user, router.navigate, null, otp))
	};

	// countdown
	const [countdown, setCountdown] = useState(0);

	useEffect(() => {
		let timer;
		if (countdown > 0) {
			timer = setTimeout(() => {
				setCountdown(prevCountdown => prevCountdown - 1);
			}, 1000);
		}
		return () => {
			clearTimeout(timer);
		};
	}, [countdown]);

	const handleClick = e => {
		e.preventDefault();
		if (countdown === 0) {
			onSubmit(e); // Call the onSubmit function and pass the event object
			setCountdown(60); // Start the countdown
		}
	};

	return (
		<div className="mt-2">
			<Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered">
				<ModalHeader toggle={toggle}>Otentikasi Akun Data</ModalHeader>
				<Form onSubmit={handleFormSubmit}>
					<ModalBody>
						<p className="fs-14 text-center mb-0">
							Melindungi data anda adalah prioritas kami, Harap konfirmasi akun
							anda dengan memasukkan kode otorisasi yang dikirim ke
						</p>
						<p className="fw-semibold text-center">{otp_send === "wa" ? hiddenPhone : hiddenEmail}</p>
						<FormGroup row className="mx-auto justify-content-center">
							<Col sm={6}>
								<Input
									name="kode"
									type="number"
									value={otp}
									onChange={handleChange}
									invalid={!isValid}
								/>
								{!isValid && (
									<FormFeedback color="danger" className="text-center">
										OTP minimal 6 karakter
									</FormFeedback>
								)}
							</Col>
						</FormGroup>
						<p className="text-center fs-14 mb-0">
							Mungkin butuh beberapa menit untuk menerima kode anda
						</p>
					</ModalBody>
					<ModalFooter>
						<p className="me-auto fs-14 align-self-start">
							Belum menerima kode?{" "}
							<a
								onClick={handleClick}
								hidden={countdown > 0}
								className="fw-semibold cursor-pointer"
							>
								Kirim Kode Baru
							</a>
							{countdown > 0 && (
								<p className="me-auto">
									Tunggu{" "}
									<span className="fw-semibold text-danger">{countdown}</span>{" "}
									detik sebelum mengirim kode lagi
								</p>
							)}
						</p>
						<Button color="primary" type="submit">
							Kirim
						</Button>
					</ModalFooter>
				</Form>
			</Modal>
		</div>
	);
};

export default Otp;
