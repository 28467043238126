import { getBroadcast, postAttachment, postBroadcast } from "./thunk";
import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    data: [],
    unread : 0
};

const broadcast = createSlice({
    name: "brodcast",
    initialState,
    reducers: {
        setBroadcast: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(getBroadcast.fulfilled, (state, { payload }) => {
                if (!payload) return
                state.data = payload
                state.unread = payload.filter((item) => item.is_read === false).length
            })
            .addCase(postAttachment.fulfilled, (state, { payload }) => {
                return
            })
            .addCase(postBroadcast.fulfilled, (state, { payload }) => {
                return
            })
    },
});

export const { setBroadcast } = broadcast.actions;

export default broadcast.reducer;
