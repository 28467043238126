import BoundWidget from "../../BoundWidget";
import { useGetWidget } from "../../../../helpers/widget_helper";
import { useEffect, useRef, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bubble, getElementAtEvent, getDatasetAtEvent } from "react-chartjs-2";
import random_color from "../../../../helpers/random_color";
import ModalDetail from "../../detail-modal";
import classNames from "classnames";
// ChartJS.register();
// convert form data API to list array
function apiToList(data) {
  let newData = [];
  data?.labels?.map(function (item, i) {
    newData.push({
      title: data.labels[i],
      subtitle: data.data[i],
      background_color: data.background_color[i],
    });
  });

  return newData;
}

export const ChartBubble = ({ source }) => {
  // states
  const { loading, data, failed, getSource } = useGetWidget({ source });
  const [dataChart, setDataChart] = useState([]);
  const [dataColor, setDataColor] = useState([]);

  // data static
  let datastatic = [
    {
      x: 150,
      y: 150,
      r: 50,
      order: "1",
      font: "14",
    },
    {
      x: 63,
      y: 85,
      r: 30,
      font: "12",
    },
    {
      x: 170,
      y: 70,
      r: 30,
      order: "3",
      font: "12",
    },
    {
      x: 255,
      y: 100,
      r: 25,
      order: "4",
      font: "10",
    },
    {
      x: 270,
      y: 150,
      r: 19,
      order: "5",
      font: "8",
    },
    {
      x: 255,
      y: 200,
      r: 25,
      order: "6",
      font: "10",
    },
    {
      x: 170,
      y: 225,
      r: 25,
      order: "7",
      font: "10",
    },
    {
      x: 80,
      y: 215,
      r: 25,
      order: "8",
      font: "10",
    },
    {
      x: 35,
      y: 175,
      r: 20,
      order: "9",
      font: "9",
    },
    {
      x: 32,
      y: 130,
      r: 18,
      order: "10",
      font: "9",
    },
    {
      x: 95,
      y: 40,
      r: 20,
      order: "11",
      font: "9",
    },
    {
      x: 255,
      y: 50,
      r: 20,
      order: "12",
      font: "9",
    },
    {
      x: 245,
      y: 245,
      r: 20,
      order: "13",
      font: "9",
    },
    {
      x: 110,
      y: 260,
      r: 20,
      order: "14",
      font: "9",
    },
    {
      x: 180,
      y: 280,
      r: 20,
      order: "15",
      font: "9",
    },

    // Add more data as needed
  ];

  // use effect
  useEffect(() => {
    if (!data) return;

    // parsing data api to list array object
    let datas = apiToList(data);

    // sorting data by value DESC
    const sortedDataDesc = [...datas]; // Buat salinan array asli untuk menghindari perubahan array asli
    sortedDataDesc.sort((a, b) => b.subtitle - a.subtitle);

    let newData = [];
    let newColor = [];

    // creating data to bubble chart data
    sortedDataDesc.map(function (item, i) {
      let objectAPI = {
        title: item.title,
        subtitle: item.subtitle,
        color: item.background_color,
      };

      newColor.push(
        item.background_color != ""
          ? item.background_color
          : random_color([""])[0]
      );
      // merging Object
      newData.push({ ...datastatic[i], ...objectAPI });
    });

    setDataColor(newColor);
    // set state data chart
    setDataChart(newData);
  }, [data]);

  const textCenterRevenue = {
    id: "textCenter",
    afterDatasetsDraw: (chart) => {
      const { ctx, data } = chart;
      data.datasets.map(function (dataset, i) {
        var meta = chart.getDatasetMeta(i);
        if (!meta.hidden) {
          meta.data.map(function (data, i) {
            ctx.save();
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";

            ctx.font = `bold ` + data.$context.raw.font + `px sans-serif`;
            ctx.fillStyle = "white";
            let title =
              data.$context.raw.title + " " + data.$context.raw.subtitle;
            let radius = data.$context.raw.r;
            let t = title.split(" ");
            if (t.length > 3) {
              t = [t[0], t[1] + "..", t[t.length - 1]];
            }
            for (let i = 0; i < t.length; i++) {
              ctx.fillText(
                t[i],
                data.x,
                (t.length > 3 ? data.y - 25 : data.y - 8) + i * radius * 0.4
              );
            }
          });
        }
      });
    },
  };

  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            // if (label) {
            //     label = ': ';
            // }
            // if (context.parsed.y !== null) {
            //     label = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
            // }
            return context.raw.title;
          },
        },
      },
    },
    scales: {
      y: {
        min: 0,
        max: 300,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        min: 0,
        max: 300,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
    },
  };

  const datas = {
    datasets: [
      {
        label: "Red dataset",
        data: dataChart,
        backgroundColor: dataColor,
      },
    ],
  };

  // STATES
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [child, setChild] = useState();

  function toggleOpenModalChild(child_data) {
    setChild({
      period: data.period,
      source: data.source,
      enpoint_root_id: data.endpoint_id,
      param_child: child_data.toString(),
      childType: data.endpoint_child_type,
      type: data.type,
    });

    setOpenModalDetail(!openModalDetail);
  }
  const chartRef = useRef();
  const handleChartClick = async (e, source, data) => {
    if (
      getElementAtEvent(chartRef.current, e).length === 0 ||
      data.endpoint_child.length === 0
    )
      return;
    const index = getElementAtEvent(chartRef.current, e);
    const label = index[0].element.$context.raw.title
    const dataIndex = data?.labels?.findIndex(lb => lb === label)
    toggleOpenModalChild(data?.param_child[dataIndex]);
  };
  
  return (
    <>
      <BoundWidget
        loading={loading}
        data={data}
        failed={failed}
        onReload={getSource}
      >
        {data && (
          <Bubble
            ref={chartRef}
            onClick={(e) => handleChartClick(e, source, data)}
            options={options}
            data={datas}
            height={400}
            width={250}
            className={classNames({
              "cursor-pointer": data.endpoint_child.length,
            })}
            plugins={[textCenterRevenue]}
          />
        )}
      </BoundWidget>
      <ModalDetail
        toggleOpenModalDetail={toggleOpenModalChild}
        openModalDetail={openModalDetail}
        child={{ ...child, period: source.split("=")[2] }} // child: {type, data}
      />
    </>
  );
};

export default ChartBubble;
