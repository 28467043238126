import Skeleton from "react-loading-skeleton";
import { Col, Row } from "reactstrap";

const WidgetLoading = () => {
  return (
    <>
      <Row>
        {[...Array(3)].map(() => (
          <Col>
            <Skeleton
              style={{
                height: "250px",
                width: "100%",
                marginTop: "30px",
                flexDirection: "row !important",
              }}
            ></Skeleton>
          </Col>
        ))}
      </Row>
      <Row>
        {[...Array(2)].map(() => (
          <Col>
            <Skeleton
              style={{
                height: "250px",
                width: "100%",
                marginTop: "30px",
                flexDirection: "row !important",
              }}
            ></Skeleton>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default WidgetLoading;
