import { combineReducers } from "redux";

import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";

// API Key
import APIKeyReducer from "./apiKey/reducer";

// Dashboard
import WidgetReducer from "./widget/reducer";
import DashboardsReducer from "./dashboards/reducer";

//Chat
import ChatReducer from "./chat/reducer";

// notif
import NotificationReducerSiacc from "./notifications/siacc/reducer";
import NotificationReducerDashboard from "./notifications/dashboard/reducer";

// employe
import EmployeBiodataReducer from "./employeBiodata/reducer";

// broadcast
import BroadcastReducer from "./broadcast/reducer";

// announcement
import AnnounccementReducer from "./announcement/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,

  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  ForgetPassword: ForgetPasswordReducer,

  APIKey: APIKeyReducer,

  Widget: WidgetReducer,
  Dashboards: DashboardsReducer,

  Chat: ChatReducer,

  NotificationSiacc: NotificationReducerSiacc,
  NotificationDashboard: NotificationReducerDashboard,

  EmployeBiodata: EmployeBiodataReducer,

  Broadcast: BroadcastReducer,

  Announcement: AnnounccementReducer,
});

export const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ["profile/photo/patch"],
    },
  });

export default rootReducer;
