import LogoKejasaan from '../assets/images/logo/logo-kejaksaan.png'

const set_profile = (profile) => {
    // console.log(profile + " " +name)
    // console.log(profile === ""? true : false)
    // if(profile !== ""){
    //     checkImage(profile)
    // }
    const imgSrc =
        profile === "" ? LogoKejasaan : profile;
    return imgSrc
}

export const img_error = (e) => {
    return e.target.src = LogoKejasaan
}

export default set_profile