import { useState } from "react"

const SearchInput = (props) => {
    const [text, setText] = useState("")
    return (
        <div className="form-group mt-2 mb-2">
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search ..."
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <button className="btn btn-primary" type="submit" onClick={() => props.submit(text)}>
                    <i className="mdi mdi-magnify"></i>
                </button>
            </div>
        </div>
    )
}

export default SearchInput