
import LineChart from "../../assets/images/chart/clean/line.svg";
import AreaChart from "../../assets/images/chart/clean/area.svg";
import BarChart from "../../assets/images/chart/clean/bar.svg";
import BubbleChart from "../../assets/images/chart/clean/bubble.svg";
import DonutChart from "../../assets/images/chart/clean/donut.svg";
import MapsChart from "../../assets/images/chart/clean/peta.svg";
import CardChart from "../../assets/images/chart/clean/card.svg";
import PieChart from "../../assets/images/chart/clean/pie.svg";
import ColumnChart from "../../assets/images/chart/clean/column.svg";
import ComputerChart from "../../assets/images/chart/clean/computer.svg";
import TableChart from "../../assets/images/chart/clean/table.svg";
import SimpleCard from "../../assets/images/chart/clean/simplecard.svg";
import ChartPresentage from "../../assets/images/chart/clean/persentase.svg";
import BarVertical from "../../assets/images/chart/clean/barvertical.svg";
import StackedBar from "../../assets/images/chart/clean/stacked.svg";
import RunningText from "../../assets/images/chart/clean/runningtext.svg";
import List from "../../assets/images/chart/clean/list.svg";
import { Bar } from "recharts";

export const WIDGETS = [
  {
    icon: LineChart,
    name: "Line",
    type: "LINE",
  },
  {
    icon: AreaChart,
    name: "Area",
    type: "AREA",
  },
  {
    icon: BarChart,
    name: "Bar",
    type: "BAR",
  },
  {
    icon: BubbleChart,
    name: "Bubble",
    type: "BUBBLE",
  },
  {
    icon: DonutChart,
    name: "Donut",
    type: "DOUGHNUT",
  },
  {
    icon: MapsChart,
    name: "Maps",
    type: "GIS",
  },
  {
    icon: CardChart,
    name: "News",
    type: "NEWS",
  },
  {
    icon: CardChart,
    name: "Simple News",
    type: "SIMPLE NEWS",
  },
  {
    icon: CardChart,
    name: "List News",
    type: "LIST NEWS",
  },
  {
    icon: CardChart,
    name: "Ranking",
    type: "RANKING",
  },
  {
    icon: PieChart,
    name: "Pie",
    type: "PIE",
  },
  {
    icon: ColumnChart,
    name: "Column",
    type: "COLUMN",
  },
  {
    icon: ComputerChart,
    name: "Computer",
    type: "COMPUTER",
  },
  {
    icon: TableChart,
    name: "Table",
    type: "TABLE",
  },
  {
    icon: SimpleCard,
    name: "Simple Card",
    type: "RANKING SIMPLE",
  },
  {
    icon: ChartPresentage,
    name: "Presentage",
    type: "CARD",
  },
  {
    icon: BarVertical,
    name: "Bar Vertical",
    type: "BAR VERTICAL",
  },
  {
    icon: BarChart,
    name: "Bar",
    type: "BAR MULTIPLE DATA",
  },
  {
    icon: LineChart,
    name: "Line",
    type: "LINE MULTIPLE DATA",
  },
  {
    icon: AreaChart,
    name: "Area",
    type: "AREA MULTIPLE DATA",
  },
  {
    icon: StackedBar,
    name: "Stacked Bar",
    type: "STACKED BAR MULTIPLE DATA",
  },
  {
    icon: RunningText,
    name: "Running Text",
    type: "RUNNING TEXT",
  },
  {
    icon: List,
    name: "Gis List",
    type: "GIS LIST",
  },
];

export default WIDGETS 