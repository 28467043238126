import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import { useGetWidget } from "../../../../helpers/widget_helper";
import BoundWidget from "../../BoundWidget"
import ModalDetail from "../../detail-modal";
import LogoKejaksaan from "../../../../assets/images/logo/logo-kejaksaan.png"
import { useState } from "react";

const GISList = ({ source, params, datas, period }) => {
    console.log(source)
    let { loading, data, failed, getSource } = useGetWidget({ source });
	if(datas?.length > 0){
		data = datas
		failed = false
	}
    const [openModalDetail, setOpenModalDetail] = useState(false);
    const [child, setChild] = useState();
    const toggleModalDetail = (data) => {
        console.log(data)
        setChild({
            childType: data?.endpoint_child_type,
            endpoint_child: data?.endpoint_child,
            endpoint_root_id: data?.endpoint?.id,
            param_child: data?.param_child,
            source: data?.source,
            type: data?.type
        })
        setOpenModalDetail(!openModalDetail)
    }
    return (
        <>
            <BoundWidget
                loading={loading}
                data={data}
                failed={failed}
                onReload={getSource}
            >
                {data && (
                    <ListGroup style={{width: "100%"}}>
                        {data.map((dt, key) => (
                            <ListGroupItem
                                className="d-flex justify-content-between align-items-center"
                                onClick={() => toggleModalDetail(dt)}
                                key={key} role="button" >
                                    <div>
                                <img style={{width: "30px", height: "30px"}} src={LogoKejaksaan} /> {dt.label}

                                    </div>
                                <Badge color="primary" pill>
                                    {dt.value}
                                </Badge>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                )}
            </BoundWidget>
            <ModalDetail
                openModalDetail={openModalDetail}
                toggleOpenModalDetail={toggleModalDetail}
                child={{ ...child, period: source ? source.split("=")[1] : period }}
            />
        </>
    )
}

export default GISList