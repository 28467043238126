import Skeleton from "react-loading-skeleton";

const LoadingMenus = () => {
  return <>
        <Skeleton count={3} 
        style={{
            height: "40px",
            width: "100%",
            marginTop: "10px",
            flexDirection: "row !important",
          }}/>
  </>;
};

export default LoadingMenus;
