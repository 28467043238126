import { createSlice }                          from "@reduxjs/toolkit"
import CustomToast from "../../Components/Common/CustomToast"
import { getDataSources, getWidgetsByDatatype, putWidgetLabel } from "./thunk"

export const initialState = {
	dataSources : [],
	source      : null,
	endpoint    : [],
	widgets     : [],
	widgetSource : [],
	selectedWidget : []
}

const widgetSlice = createSlice({
	name     : "widget",
	initialState,
	reducers : {
		setSource: (state, { payload }) => {
			const source = state.dataSources.find((item) => item.id == payload)
			if (source) {
				state.source = source
			}
		},
		setEndpoint: (state, { payload }) => {
			const endpoint = state.source.endpoint.find((item) => item.id == payload)
			if (endpoint) {
				state.endpoint = [...state.endpoint, endpoint]
				state.selectedWidget = [...state.selectedWidget, {endpoint_id: endpoint.id}]
			}
		},
		removeWidget: (state, {payload}) => {
			if(!payload) return
			state.widgets = state.widgets.filter(data => data.endpoint_id !== payload)
		},
		removeEndpoint: (state, {payload}) => {
			if(!payload) return
			state.endpoint = state.endpoint.filter(data => data.id !== payload)
			state.selectedWidget = state.endpoint.filter(data => data !== payload)
		},
		setTabName: (state, {payload}) => {
			if(!payload) return
			state.widgets = state.widgets.map(widget => {
				if(widget.endpoint_id === payload.endpoint_id){
					widget.tab_name = payload.tab_name
				}
				return widget
			})
		},
		setSelectedWidget: (state, {payload}) => {
			if(!payload) return
			state.selectedWidget = state.selectedWidget.map(sWidget => {
				if(sWidget.endpoint_id === payload.endpoint_id){
					sWidget.type = payload?.widget?.name
					sWidget.id = payload?.widget?.id
					sWidget.minH = payload?.widget?.minH
					sWidget.minW = payload?.widget?.minW
				}
				return sWidget
			})
		},
		setPeriode: (state, {payload}) => {
			if(!payload) return
			state.selectedWidget = state.selectedWidget.map(sWidget => {
				if(sWidget.endpoint_id === payload.endpoint_id){
					sWidget.period = payload.period
				}
				return sWidget
			})
		},
		clearState: (state, {payload}) => {
			state.endpoint    = [],
			state.widgets     = [],
			state.widgetSource = [],
			state.selectedWidget = []
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getDataSources.fulfilled, (state, { payload }) => {
				if (!payload) return
				state.dataSources = payload
			})
			.addCase(getWidgetsByDatatype.fulfilled, (state, { payload }) => {
				if (!payload) return
				state.widgets = [...state.widgets, {...payload, tab_name: ""}]
			})
			.addCase(putWidgetLabel.fulfilled, (state, {payload}) => {
				if(!payload) return
				CustomToast("success", "Berhasil Mengubah Label!");
			})

	},
})


export const { setSource, setEndpoint, removeWidget, removeEndpoint, setSelectedWidget, setTabName, clearState, setPeriode } = widgetSlice.actions
export default widgetSlice.reducer
