import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Card, Col, Row, Container } from "reactstrap";
import SidebarProfile from "./SidebarProfile";
import CustomToast from "../../Components/Common/CustomToast";

import { APIClient } from "../../helpers/api_helper";
import { LOG_BASE } from "../../helpers/url_helper";
import { getMenuDashboards } from "../../slices/thunks";
import SimpleBar from "simplebar-react";

const api = new APIClient();

const LogUpdate = () => {
	const [loading, setLoading] = useState(true);
	const [logAdmin, setLogAdmin] = useState([]);
	const [logUser, setLogUser] = useState([]);
	const dispatch = useDispatch();

	const profile = useSelector(state => state.Profile);
	const uuid = profile.data?.uuid_user;
	const title = profile.data?.user_group?.[0]?.title || "";

	const getLogUser = async () => {
		try {
			const res = await api.get(LOG_BASE + "/log-update/" + uuid + "/user");
			setLogUser(res.data.result);
			setLoading(false);
		} catch (err) {
			CustomToast("danger", "Gagal memuat log update");
			setLoading(false);
		}
	};

	const getLogAdmin = async () => {
		try {
			const res = await api.get(LOG_BASE + "/log-update/admin");
			setLogAdmin(res.data.result);
			setLoading(false);
		} catch (err) {
			CustomToast("danger", "Gagal memuat log update");
			setLoading(false);
		}
	};

	useEffect(() => {
		if (uuid && title) {
			if (title === "admin") {
				getLogAdmin();
			} else {
				getLogUser();
			}
			dispatch(getMenuDashboards());
		}
	}, [uuid, title]);

	return (
		<React.Fragment>
			<div className="page-content pb-0">
				<Container fluid>
					<Row>
						<Col md="3">
							<SidebarProfile title="logupdate" />
						</Col>
						<Col md="9">
							<Card style={{ height: "82vh", overflow: "auto" }}>
								<SimpleBar style={{ height: "82vh", overflow: "auto" }}>
									<div className="p-4">
										<p className="fs-18 fw-semibold mb-0">
											Log Update Database
										</p>
										<p className="text-muted fs-14 mb-4">
											Menampilkan informasi log update database
										</p>

										{/* log user */}
										{logUser && title !== "admin" ? (
											logUser.map(item => (
												<Row key={item._id}>
													<Col>
														<div className="d-flex">
															<p className="text-muted fs-12 mb-0">
																{item.dashboard}
															</p>
															<p className="text-muted fs-12 mb-0 ms-auto">
																{new Date(item.updated_at).toLocaleString()}
															</p>
														</div>
														<p className="fs-14">{item.name}</p>
														<hr />
													</Col>
												</Row>
											))
										) : loading ? (
											<p>Loading...</p>
										) : null}

										{/* log admin */}
										{logAdmin && title === "admin" ? (
											logAdmin.map(item => (
												<Row key={item._id}>
													<Col>
														<div className="d-flex">
															<p className="text-muted fs-12 mb-0">
																{item.dashboard}
															</p>
															<p className="text-muted fs-12 mb-0 ms-auto">
																{new Date(item.updated_at).toLocaleString()}
															</p>
														</div>
														<p className="fs-14 mb-1">{item.name}</p>
														<p className="fs-12 text-danger">
															Pesan: {item.error}
														</p>
														<hr />
													</Col>
												</Row>
											))
										) : loading ? (
											<p>Loading...</p>
										) : null}
									</div>
								</SimpleBar>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default LogUpdate;
