import { Card, Col, Row } from "reactstrap";

import LineChart from "../../../../assets/images/LineChart.png";
import RangkingPerformance from "./rangking-line-chart";
import set_profile, { img_error } from "../../../../helpers/default_profile";

const CardSimpleRow = ({
	rank,
	data: {
		photo_url,
		name,
		workunit,
		badge_name1,
		badge_value1,
		badge_name2,
		badge_value2,
		badge_name3,
		badge_value3,
		performances
	},
}) => {
	return (
		<>
			<Card className="border mb-1 p-3" style={{ minWidth: "140px", marginBottom:"0.5rem" }}>
				<Row>
					<Col md={1} className="align-self-center">
						<p className="mb-0 fw-semibold">{rank}</p>
					</Col>
					<Col className="d-flex">
						<img
							src={set_profile(photo_url || "")}
							className="rounded me-2 d-block"
							width="50px"
							height="50px"
							onError={e => img_error(e)}
						/>
						<div className="align-self-center">
							<p className="fw-semibold mb-0 fs-12">{name}</p>
							<p className="fs-10 text-muted mb-0">{workunit}</p>
						</div>
					</Col>
					<Col md={4}>
						{/* <img src={LineChart} alt="" width="100%" /> */}
						<RangkingPerformance performances={performances}/>
					</Col>
					<Col md={4} className="d-flex justify-content-center gap-3 mt-4">
						<div className="text-center">
							<p className="mb-0 mt-1 fs-12 fw-semibold">{badge_value1}</p>
							<p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
								{badge_name1}
							</p>
						</div>
						<div className="text-center">
							<p className="mb-0 mt-1 fs-12 fw-semibold">{badge_value2}</p>
							<p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
								{badge_name2}
							</p>
						</div>
						<div className="text-center">
							<p className="mb-0 mt-1 fs-12 fw-semibold">{badge_value3}</p>
							<p className="fs-10 d-block mb-0" style={{ marginTop: "-4px" }}>
								{badge_name3}
							</p>
						</div>
					</Col>
				</Row>
			</Card>
		</>
	);
};

export default CardSimpleRow