import {
  Badge,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import styles from "./ChatBox.module.css";
import { Link } from "react-router-dom";
import SearchInput from "../../Components/Common/search-input";
import { useDispatch, useSelector } from "react-redux";
import DateTimeFormat from "../../helpers/time_parser";
import { getMsgByRoom, getRoomArchive, getRoomChat } from "../../slices/thunks";
import { openChatBox } from "../../slices/chat/reducer";
import avatar2 from "../../assets/images/default-user.png";
import SimpleBar from "simplebar-react";
import { useContext, useEffect, useState } from "react";
import { SocketContext } from "../../context/SocketContext";
import set_profile, { img_error } from "../../helpers/default_profile";

const ChatArchive = (props) => {
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.Chat);
  const { unArchiveMsg } = useContext(SocketContext);

  useEffect(() => {
    dispatch(getRoomArchive());
  }, []);

  useEffect(() => {}, [chat.roomArchiveList]);

  const handleClick = (id) => {
    dispatch(getMsgByRoom(id));
    dispatch(openChatBox(true));
  };

  const cancelArchive = (id) => {
    unArchiveMsg(id);
    dispatch(getRoomChat());
    dispatch(getRoomArchive());
  };

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchSubmit = (searchText) => {
    if (searchText.trim() === "") {
      setIsSearching(false);
      setSearchResults([]);
    } else {
      const filteredResults = chat.roomArchiveList.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
      setIsSearching(true);
      setSearchResults(filteredResults);
    }
  };

  return (
    <div
      className={`${styles.chat_left_side} ${
        props.show && styles.chat_left_side_show
      }`}
    >
      <Row className="align-items-center">
        <Col>
          <h4 className="m-0 fs-20"> Arsip </h4>
        </Col>
        <div
          className="col-auto"
          style={{ display: "flex", alignItems: "center", gap: "5px" }}
        >
          <i
            className={`ri-close-line fs-20`}
            style={{ cursor: "pointer" }}
            onClick={() => props.setShowLeft(false)}
          ></i>
        </div>
      </Row>
      <SearchInput submit={(searchText) => handleSearchSubmit(searchText)} />
      <SimpleBar
        style={{ height: props.height }}
        className={`${styles[props.classList]} pe-2 mt-3`}
      >
        {(isSearching ? searchResults : chat.roomArchiveList).map(
          (items, index) => (
            <div
              key={index}
              className={`
                        text-reset 
                        notification-item 
                        d-block dropdown-item 
                        position-relative mt-2 
                        ${
                          chat.chatActive?.id === items.id && styles.chat_active
                        }
                        ${items.is_un_read && styles.is_unread}
                    `}
              onClick={() => handleClick(items.id)}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={set_profile(items.avatar)}
                    onError={(e) => img_error(e)}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-1">
                    <Link to="#" className="stretched-link">
                      <h6
                        className={`${
                          items.is_un_read ? "fw-bold" : "fw-semibold"
                        } mt-0 mb-1 fs-13`}
                      >
                        {items.name}
                      </h6>
                    </Link>
                    <div
                      className={`${
                        items.is_un_read ? "fw-bold" : "fw-semibold"
                      } fs-13 text-muted`}
                    >
                      <p className="mb-1">{items.last_message}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div className="d-flex flex-column align-items-end">
                    <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                      <span>{DateTimeFormat.getTimeAgo(items.time)}</span>
                    </p>
                    {items.un_read_message !== 0 && (
                      <Badge color="success">{items.un_read_message}</Badge>
                    )}
                  </div>
                  <UncontrolledDropdown
                    className="align-self-center message-box-drop"
                    style={{ zIndex: "10" }}
                  >
                    <DropdownToggle tag="a" role="button">
                      <i className=" ri-more-2-fill fs-20"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => cancelArchive(items.id)}
                        className="d-flex align-items-center gap-2"
                      >
                        <i className=" ri-close-fill fs-20"></i> Batalkan
                        Arsipkan
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </div>
          )
        )}
      </SimpleBar>
    </div>
  );
};

export default ChatArchive;
