let color = ["#8ff80e", "#ad0000", "#088efc", "#ff007f", "#fcc200", "#a020f0", "#b5b35c","#7f6000","#783f04","#4c1130","#660000"]

const random_color = (arr) => {
    let newArr = []

    arr?.map(data => {
        if(data == ""){
            newArr.push(color[Math.floor(Math.random() * color.length)])
        }else{
            newArr.push(data)
        }
    })

    return newArr
}

export default random_color