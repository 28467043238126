import React, { useState } from "react";
import { AudioRecorder } from "react-audio-voice-recorder";
import { Button } from "reactstrap";

const SoundMessage = (props) => {
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);

  const handleRecordingComplete = (blob, time) => {
    setAudioBlob(blob);
    setIsRecording(false); // Recording is complete
    // props.submitAudio(blob)
  };

  const handleClearRecording = () => {
    setAudioBlob(null);
    setIsRecording(false); // Clear the recording
  };

  const handleUploadRecording = () => {
    if (audioBlob) {
      // Implement your upload logic here

      // Clear the audio recording after upload
      setAudioBlob(null);
      setIsRecording(false);
      props.submitAudio(audioBlob);
      props.toggle();
    }
  };

  return (
    <div className="bg-dark p-4">
      <div className="text-white">
        {audioBlob && (
          <div className="d-flex justify-content-center gap-2 mb-3">
            <Button onClick={handleClearRecording}>Clear</Button>
            <Button onClick={handleUploadRecording} color="light">
              Upload
            </Button>
          </div>
        )}
        <div className="d-flex justify-content-center">
          <AudioRecorder
            onRecordingComplete={handleRecordingComplete}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadOnSavePress={false}
            downloadFileExtension="ogg"
          />
        </div>
      </div>
    </div>
  );
};

export default SoundMessage;
