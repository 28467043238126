import { APIClient } from "../../../helpers/api_helper";
import { NOTIF_SIACC_BASE } from "../../../helpers/url_helper";
import { createAsyncThunk } from "@reduxjs/toolkit";

const api = new APIClient();

export const getNotifSiacc = createAsyncThunk(
	"notifications/siacc/get",
	async payload => {
		try {
			const response = await api.get(
				NOTIF_SIACC_BASE + `/notification?uuid=${payload}`
			);
			return response.data.result;
		} catch (error) {
			return null;
		}
	}
);

export const updateNotifSiacc = createAsyncThunk(
	"notifications/siacc/put",
	async (payload) => {
		try {
			const response = await api.put(
				NOTIF_SIACC_BASE + `/notification/read`,
				payload
			); 
			return response.data.result;
		} catch (error) {
			return null;
		}
	}
);
