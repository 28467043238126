import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ModalDelete = ({openModalSave,toggleOpenModalSave,handleSave}) => {
  return (
    <Modal isOpen={openModalSave} toggle={toggleOpenModalSave}>
      <ModalHeader toggle={toggleOpenModalSave}>Konfirmasi</ModalHeader>
      <ModalBody className="d-flex justify-content-center">
        Apakah Anda yakin ingin menghapus widget ini?
      </ModalBody>
      <ModalFooter className="gap-2 justify-content-center">
        <Button color="primary" outline onClick={toggleOpenModalSave}>
          Tidak
        </Button>
        <Button color="primary" onClick={handleSave}>
          Ya
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalDelete;
