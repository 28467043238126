import { Fragment } from "react"
import { Search } from "react-feather"
import { Input, InputGroup, InputGroupText } from "reactstrap"
import PropTypes from 'prop-types';


const CustomTableSearch = props => {

    const {
        title,
        onSearch
    } = props

    const onKeyUp = (e) => {
        if (e.charCode === 13) {
            onSearch(e.target.value)
        }
    }

    return (
        <Fragment>
            <div>
                <div className="form-icon">
                    <Input  
                        className="form-control form-control-icon" 
                        id="iconInput" 
                        placeholder={title}
                        onKeyPress={(e) => onKeyUp(e)} />
                </div>
            </div>

        </Fragment>
    )
}            


CustomTableSearch.prototype = {
    title: PropTypes.string.isRequired,
    onChange: PropTypes.func
}


export default CustomTableSearch