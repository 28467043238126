const WidgetEmpty = () => {
  return (
    <div
      className="d-flex gap-2 w-100 justify-content-center align-items-center"
      style={{ height: "calc(100vh - calc(130px + 4rem))" }}
    >
      <div className="text-center">
        <div className="fs-4">Widget Kosong</div>
        <div className="fs-5 opacity-75">Silahkan Tambah Widget</div>
      </div>
    </div>
  );
};

export default WidgetEmpty;
