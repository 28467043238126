import { useEffect, useState } from "react";
import { getWidget } from "../../../../helpers/widget_helper";
import classNames from "classnames";

const ExportTableMultipleData = ({ source, name, data, type }) => {
  const [datas, setDatas] = useState(data ?? null);

  useEffect(() => {
    if (!data) {
      getWidget({ source }).then((res) => {
        setDatas(res);
      });
    }
  }, []);

  return (
    <div
      className={classNames({
        "d-flex justify-content-center": !data,
      })}
    >
    {datas?.length > 0 && (
      <table
        className={classNames({
          "table table-hover table-striped table-bordered w-75 mb-4": !data,
          "table-pdf-news-multiple-ranking": data,
        })}
      >
        <thead>
          <tr>
            {
              (type == "excel" && (
                <tr>
                  <td className="fw-bold text-center" colSpan={datas && Object?.keys(datas?.datasets).length + 3}>
                    &nbsp;
                  </td>
                </tr>
              ))
            }
            <th
              className="fw-bold text-center"
              colSpan={datas && Object?.keys(datas?.datasets).length + 3}
            >
              {name}
            </th>
          </tr>
          <tr>
            <th className="fw-bold text-center">No</th>
            <th className="fw-bold text-center" colSpan={2}>
              Data
            </th>
            <th className="fw-bold text-center">Jumlah</th>
          </tr>
        </thead>

        <tbody>
          {datas &&
            datas.labels.map(function (item, i) {
              return (
                <>
                  <tr>
                    <td
                      className="text-center fw-bold"
                      rowSpan={
                        datas && Object?.keys(datas?.datasets).length + 1
                      }
                    >
                      {i + 1}
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={datas && Object?.keys(datas?.datasets).length}>
                      {item}
                    </td>
                    <td>{datas?.datasets[0].label}</td>
                    <td>{datas?.datasets[0].data[0]}</td>
                  </tr>
                  {datas &&
                    Object?.keys(datas?.datasets).map((d, j) => {
                      if (j === 0) return;
                      return (
                        <tr>
                          <td>{datas?.datasets[j].label}</td>
                          <td>{datas?.datasets[j].data[i]}</td>
                        </tr>
                      );
                    })}
                </>
              );
            })}
        </tbody>
      </table>
    )}
    </div>
  );
};

export default ExportTableMultipleData;
