import ExportTableGIS from "./gis";
import ExportTableMultipleData from "./multiple-data";
import ExportTableNews from "./news";
import ExportTableRanking from "./ranking";
import ExportTableSingleData from "./single-data";
import ExportTableData from "./table";

const ExportTableExcel = ({ dashboards, data }) => {
  return (
    <table id="table-to-xls" style={{ display: "none" }}>
      {dashboards?.current?.widget?.map((widget, index) => {
        let source = widget.source;
        let data_type = source.split("/")[2];

        switch (data_type) {
          case "single-data":
            return (
              <ExportTableSingleData
                source={source}
                data={data[index]}
                name={widget.name}
                type={"excel"}
              />
            );
          case "multiple-data":
            return (
              <ExportTableMultipleData
                source={source}
                data={data[index]}
                name={widget.name}
                type={"excel"}
              />
            );
          case "table":
            return (
              <ExportTableData
                source={source}
                data={data[index]}
                name={widget.name}
                type={"excel"}
              />
            );
          case "ranking":
            return (
              <ExportTableRanking
                source={source}
                data={data[index]}
                name={widget.name}
                type={"excel"}
              />
            );
          case "news":
            return (
              <ExportTableNews
                source={source}
                data={data[index]}
                name={widget.name}
                type={"excel"}
              />
            );
          case "gis":
            return (
              <ExportTableGIS
                source={source}
                data={data[index]}
                name={widget.name}
                type={"excel"}
              />
            );
        }
      })}
    </table>
  );
};

export default ExportTableExcel;
