import { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  FormFeedback,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import ChooseContact from "./ChooseContact";
import ContactSelected from "./ContactSelected";
import SendBroadcast from "./SendBroadcast";
import CustomToast from "../CustomToast";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmployeBiodata,
  postAttachment,
  postBroadcast,
} from "../../../slices/thunks";
import CustomTableLoading from "../custom-table/loading";

const CreateBroadcast = ({ onClose }) => {
  const getEmployee = useSelector((state) => state.EmployeBiodata);
  const dispatch = useDispatch();
  const { data: employeData } = getEmployee;

  useEffect(() => {
    dispatch(getEmployeBiodata());
  }, []);

  const [modal, setModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [listContact, setListContact] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentPreview, setDocumentPreview] = useState(null);

  // const handleVideoChange = (e) => {
  //     const selectedFile = e.target.files[0];

  //     if (selectedFile) {
  //         setSelectedVideo(selectedFile);
  //         const videoObjectURL = URL.createObjectURL(selectedFile);
  //         setVideoPreview(videoObjectURL);
  //     }
  // };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setSelectedDocument([selectedFile]);
      const videoObjectURL = URL.createObjectURL(selectedFile);
      setDocumentPreview(videoObjectURL);
    }
  };

  const handleDocumentChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setSelectedDocument([selectedFile]);
      const documentObjectURL = URL.createObjectURL(selectedFile);
      setDocumentPreview(documentObjectURL);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const sendToSIACC = () => {
    setModal(!modal);
    return CustomToast("success", "Pesan Siaran Berhasil Terkirim");
  };

  const sendToWA = () => {
    setModal(!modal);
    return CustomToast("success", "Pesan Siaran Berhasil Terkirim");
  };

  const [content, setContent] = useState("");
  const [texError, setTextError] = useState("");

  const handleSend = async (type) => {
    const val = {
      member_id: Array.from(listContact, (u) => u.uuid),
      content: content,
      type: "text",
      option: type,
      attachment_id: [],
    };

    try {
      if (selectedImage && selectedImage.length > 0) {
        const formData = new FormData();
        selectedImage.forEach((file) => {
          formData.append("attachment[]", file);
        });
        // Assuming postAttachment is your action creator for posting attachments
        const response = await dispatch(postAttachment(formData));

        // Extract attachment_id from the response and update val.attachment_id
        if (response.payload && response.payload.length > 0) {
          val.attachment_id = response.payload.map((item) => item.id);
        }
      }
      if (selectedDocument && selectedDocument.length > 0) {
        const formData = new FormData();
        selectedDocument.forEach((file) => {
          formData.append("attachment[]", file);
        });
        // Assuming postAttachment is your action creator for posting attachments
        const response = await dispatch(postAttachment(formData));

        // Extract attachment_id from the response and update val.attachment_id
        if (response.payload && response.payload.length > 0) {
          val.attachment_id = response.payload.map((item) => item.id);
        }
      }

      // Dispatch action to send broadcast message
      await dispatch(postBroadcast(val));

      setModal(false);
      onClose();

      const successMessage =
        type === "whatsapp"
          ? "Pesan Siaran WhatsApp Berhasil Terkirim"
          : "Pesan Siaran Siacc Berhasil Terkirim";

      CustomToast("success", successMessage);
    } catch (error) {
      console.error("Error sending broadcast:", error);
      // Handle error here if needed
    }
  };

  const removeContact = (contactId) => {
    const updatedList = listContact.filter(
      (contact) => contact.uuid !== contactId
    );
    setListContact(updatedList);
  };

  const addContact = (contactId) => {
    const newContact = employeData.find(
      (contact) => contact.uuid === contactId
    );
    if (
      newContact &&
      !listContact.some((contact) => contact.uuid === contactId)
    ) {
      const updatedList = [...listContact, { ...newContact, selected: true }];
      setListContact(updatedList);
    }
  };

  const toggleContact = (contactId) => {
    const existingContact = listContact.find(
      (contact) => contact.uuid === contactId
    );

    if (existingContact) {
      const updatedList = listContact.map((contact) => {
        if (contact.uuid === contactId) {
          return { ...contact, selected: !contact.selected };
        }
        return contact;
      });

      setListContact(updatedList);
    } else {
      const newContact = employeData.find(
        (contact) => contact.uuid === contactId
      );
      if (newContact) {
        const updatedList = [...listContact, { ...newContact, selected: true }];
        setListContact(updatedList);
      }
    }
  };

  const toggleChecked = (toggleId) => {
    const existingContact = listContact.find(
      (contact) => contact.uuid === toggleId
    );

    if (existingContact && existingContact.selected) {
      return true;
    }

    return false;
  };

  const sendBroadcast = () => {
    if (content == "") {
      setTextError("Isi pesan text");
    } else if (listContact.length === 0) {
      CustomToast("warning", "Belum memilih contact");
    } else {
      setModalType("send");
      toggle();
    }
  };

  return (
    <Fragment>
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        style={{
          borderRadius: "10px",
          width: "100%",
          height: "143px",
          background: "#eff2f7",
          padding: "10px",
          border: "none",
        }}
        placeholder="Apa yang terjadi"
      ></textarea>
      {texError && (
        <div
          style={{
            paddingBottom: "5px",
            paddingLeft: "5px",
            color: "red",
            opacity: 0.7,
          }}
        >
          {texError}
        </div>
      )}
      {/* <Select placeholder="Pilih Penerima" /> */}
      {/* <button
                onClick={toggle}
                style={{
                    width: "100%",
                    padding: " 10px",
                    border: "none",
                    borderRadius: "5px",
                    background: "#eff2f7",
                    display: "flex",
                    alignItems: "center",
                    color: "gray",
                    justifyContent: "space-between"
                }}>Pilih Penerima
                <i className="bx bx-chevron-down fs-3"></i>
            </button> */}
      <ContactSelected
        toggle={toggle}
        listContact={listContact}
        showAdd={true}
        removeContact={removeContact}
      />
      {/* <SelectSingleUser /> */}
      {/* {videoPreview && (
                <div>
                    <video controls style={{ width: "100%", height: "200px" }}>
                        <source src={videoPreview} type="video/mp4" />
                    </video>
                </div>
            )} */}
      {imagePreview && (
        <div>
          <img
            src={imagePreview}
            alt=""
            style={{ width: "100%", height: "200px" }}
          />
        </div>
      )}
      {selectedDocument && (
        // <p>File terpilih: {selectedDocument.name}</p>
        <div
          className="p-2 "
          style={{
            border: "2px solid #d1d1d1",
            borderRadius: "9px",
          }}
        >
          <h6>Media Pendukung</h6>
          <div
            style={{
              backgroundColor: "rgb(23, 97, 56)",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "50px",
                color: "#FFFFFF",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                <i className="ri-file-line fs-5"></i>
                {selectedDocument[0].name}
              </div>
              <i
                onClick={() => setSelectedDocument(null)}
                role="button"
                className="ri-close-line fs-5"
              ></i>
            </div>
          </div>
        </div>
      )}
      <div>
        Tambahkan ke postingan Anda
        {/* <label
                    htmlFor="videoInput"
                    className="btn p-2 m-0"
                    style={{ cursor: "pointer" }}
                >
                    <i className="ri-video-line fs-5"></i>
                </label>
                <input
                    type="file"
                    id="videoInput"
                    accept="video/*"
                    style={{ display: "none" }}
                    onChange={handleVideoChange}
                /> */}
        <label
          htmlFor="fileInput"
          className="btn p-2 m-0"
          style={{ cursor: "pointer" }}
        >
          <i className="ri-image-line fs-5"></i>
        </label>
        <input
          type="file"
          id="fileInput"
          accept=".jpg,.jpeg,.png,.mp4,.mkv,.avi"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        {/* <button type="submit" className="btn p-2"><i className=" ri-links-line fs-5"></i></button> */}
        {/* <button type="submit" className="btn p-2"><i className=" ri-mic-line fs-5"></i></button> */}
        <label
          htmlFor="docInput"
          className="btn p-2 m-0"
          style={{ cursor: "pointer" }}
        >
          <i className="ri-file-line fs-5"></i>
        </label>
        <input
          type="file"
          id="docInput"
          accept=".pdf,.doc,.docx"
          style={{ display: "none" }}
          onChange={handleDocumentChange}
        />
      </div>
      <button
        className="btn btn-primary mt-3"
        onClick={() => sendBroadcast()}
        style={{
          width: "100%",
          padding: "15px",
        }}
      >
        Kirim
      </button>
      <Modal isOpen={modal} centered onClosed={() => setModalType("")}>
        <ModalHeader className="modal-title" toggle={toggle}>
          {modalType === "send" ? "Kirim Pesan Siaran" : "Pilih Kontak"}
        </ModalHeader>
        <ModalBody>
          {modalType === "send" ? (
            <SendBroadcast send={handleSend} />
          ) : (
            <ChooseContact
              toggleChecked={toggleChecked}
              toggleContact={toggleContact}
              employeData={employeData}
              listContact={listContact}
              addContact={addContact}
              removeContact={removeContact}
              toggle={toggle}
            />
          )}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default CreateBroadcast;
