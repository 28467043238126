import { APIClient } from "../../../helpers/api_helper";
import { WIDGET_SYNC_BASE } from "../../../helpers/url_helper";
import { createAsyncThunk } from "@reduxjs/toolkit";

const api = new APIClient();

export const getNotifDashboard = createAsyncThunk(
	"notifications/dashboard/get",
	async payload => {
		try {
			const response = await api.get(
				WIDGET_SYNC_BASE + `/sync/notification/${payload}/user-id`
			);
			return response.data.result;
		} catch (error) {
			return null;
		}
	}
);

export const updateNotifDashboard = createAsyncThunk(
	"notifications/dashboard/put",
	async (payload) => {
		try {
			const response = await api.put(
				WIDGET_SYNC_BASE + `/sync/notification/${payload}/notification-id`,
			);
			return response.data.result;
		} catch (error) {
			return null;
		}
	}
);
