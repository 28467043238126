import { Trash2 } from "feather-icons-react/build/IconComponents"
import { v4 as uuidv4 } from 'uuid';
import { useState } from "react"
import { Plus } from "react-feather"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

const DynamicInput = ({ setDataKeys }) => {
    const [inputCount, setInputCount] = useState([
        {
            id: uuidv4(),
            key: "",
            key_type: "",
            value_data: "",
            index: 0
        }
    ])

    const handleInputChange = (index, field, value) => {

        let currentIndex = inputCount.findIndex(input => input.id === index)

        let updatedInput = {...inputCount[currentIndex], [field]: value}

        const newInput = [...inputCount]
        newInput[currentIndex] = updatedInput
        setInputCount(newInput)
        
        setDataKeys([...newInput])
    }

    const addInput = (index) => {
        setInputCount([...inputCount, {id: uuidv4(),
            key: "",
            key_type: "default",
            value_data: "",
            index: index
        }])
    }

    const removeInput = (index) => {
        console.log(index)
        let filterd = inputCount.filter(input => {
            return input.id !== index
        })
        setInputCount(filterd)
    }
    return (
        inputCount.map((item, index) => (
            <Row>
                <Col md={3}>
                    <FormGroup>
                        <Label>Label</Label>
                        <Input
                            id={item.id}
                            type="text"
                            name={item.value_data}
                            value={item.value_data}
                            placeholder={""}
                            onChange={(e) => {
                                handleInputChange(item.id,
                                    "value_data",
                                    e.target.value)
                            }
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label>Type</Label>
                        <Input
                            id={item.field_type}
                            name={item.field_type}
                            type="select"
                            value={item.key_type}
                            disabled
                            onChange={(e) =>
                                handleInputChange(item.id, "key_type", e.target.value)
                            }
                        >
                            <option value="default" defaultValue>
                                Default
                            </option>
                            <option value="outter_key">Outer Key</option>
                            <option value="default_value">Default Value</option>
                            <option value="array">Array</option>
                            <option value="ranking_performance">Ranking Performance</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col md={3}>
                    <FormGroup>
                        <Label>Value Data</Label>
                        <Input
                            id={item.index}
                            placeholder={"Masukkan Value Data"}
                            name={item.key}
                            type="text"
                            value={item.key}
                            onChange={(e) => {
                                handleInputChange(item.id, "key", e.target.value);
                            }
                            }
                        />
                    </FormGroup>
                </Col>
                <Col md={3} className="align-self-center">
                    <div className="d-flex gap-2">
                        <Button color="primary" size="sm" onClick={() => addInput()}><Plus /></Button>
                        {index !== 0 && (
                            <Button color="danger" size="sm" onClick={() => removeInput(item.id)}><Trash2 /></Button>
                        )}
                    </div>
                </Col>
            </Row>
        ))

    )
}

export default DynamicInput