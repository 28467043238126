import React, { Suspense, useEffect, useState } from "react";
import { getDashboardsWidget, getMenuDashboards } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { APIClient } from "../../helpers/api_helper";
import { WIDGET_SYNC_BASE } from "../../helpers/url_helper";
import { Responsive, WidthProvider } from "react-grid-layout";
import img404 from "../../assets/images/404-error.png";
import ExportShare from "../../Components/Common/ExportShare";

const LazyWidgetCard = React.lazy(() =>
  import("../../Components/Common/WidgetCard")
);

const Share = () => {
  const ResponsiveGridLayout = WidthProvider(Responsive);

  const dispatch = useDispatch();
  const params = useParams();
  const dashboards = useSelector((state) => state.Dashboards);
  const [widget, setWidget] = useState(null);

  const api = new APIClient();

  useEffect(() => {
    if (dashboards.isInitial || dashboards.isUpdate) {
      dispatch(getMenuDashboards());
    }
  }, [dashboards.isInitial, dashboards.isUpdate]);

  useEffect(() => {
    dispatch(getDashboardsWidget({ _id: params.id }));
  }, [dashboards.isInitialWidget]);

  useEffect(() => {
    const getWidget = async () => {
      try {
        const response = await api.get(
          WIDGET_SYNC_BASE + `/sync/dashboard/${params.id}`
        );
        setWidget(response.data.result);
      } catch (err) {
        if (err === "Sorry! the data you are looking for could not be found") {
          setWidget(null);
        }
      }
    };
    getWidget();
  }, [params]);

  if (widget === null) {
    return (
      <div className="page-content">
        <Container fluid>
          <div
            className="d-flex gap-2 w-100 justify-content-center align-items-center"
            style={{ height: "calc(100vh - calc(130px + 4rem))" }}
          >
            <div className="text-center">
              <img src={img404} alt="404" style={{ width: "50%" }} />
              <div className="fs-4">Data Tidak Ditemukan</div>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <div className="p-4">
      <Container fluid>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <h2>Dashboard Excecutive</h2>
            <h5>Dashboard : {widget.label}</h5>
          </div>

          <ExportShare isShare />
        </div>

        <ResponsiveGridLayout
          layouts={{
            lg: widget?.layout,
          }}
          draggableHandle="._drag_area"
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 8, md: 8, sm: 2, xs: 2, xxs: 1 }}
          rowHeight={40}
          width={1000}
          isResizable={false}
        >
          {widget?.widget?.map((w, key) => (
            <div key={w.key}>
              <Suspense>
                <LazyWidgetCard widget={w} isShare></LazyWidgetCard>
              </Suspense>
            </div>
          ))}
        </ResponsiveGridLayout>
      </Container>
    </div>
  );
};

export default Share;
