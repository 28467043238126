
export default [
    {
        title: "No",
        size: 1
    },
    {
        title: "Name",
        size: 7
    },
    {
        title: "Aksi",
        size: 3
    },
];