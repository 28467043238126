import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIClient }        from "../../../helpers/api_helper";
import CustomToast          from "../../../Components/Common/CustomToast";
import { AUTH_BASE }        from "../../../helpers/url_helper";

const api = new APIClient();

export const patchProfile = createAsyncThunk(
  "profile/patch",
  async (data, { rejectWithValue }) => {
    const payload = { ...data };
    delete payload.child_id;
    payload.user_group     = data.user_group.map((item) => item.id);
    payload.old_user_group = data.user_group.map((item) => item.id);
    try {
      const response = await api.put(
        `${AUTH_BASE}/update?update_type=bio`,
        payload
      );
      CustomToast("success", "Berhasil perbarui profile!");
      return data;
    } catch (error) {
      CustomToast("danger", "Gagal perbarui profile!");
      return rejectWithValue(error.response.data);
    }
  }
);
export const patchProfilePhoto = createAsyncThunk(
  "profile/photo/patch",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.put(
        `${AUTH_BASE}/update?update_type=photo`,
        payload
      );
      CustomToast("success", "Berhasil perbarui foto profile!");
      // Return the data or any relevant response
      return response.data.result;
    } catch (error) {
      CustomToast("danger", "Gagal perbarui foto profile!");
      return rejectWithValue(error.response.payload);
    }
  }
);
export const patchPassword = createAsyncThunk(
  "profile/password/patch",
  async(payload, {rejectWithValue}) => {
   try{
    await api.put(
      `${AUTH_BASE}/update?update_type=password`,
      payload
    )
    CustomToast("success", "Berhasil perbarui password");
    return payload;
   } catch(error){
      CustomToast("danger", "Gagal perbarui password!");
      return rejectWithValue(error.response.data);
   }
  }
)

export const editProfile = (user) => async (dispatch) => {
  try {
    let response;

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //     response = fireBaseBackend.editProfileAPI(
    //         user.username,
    //         user.idx
    //     );

    // } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {

    //     response = postJwtProfile(
    //         {
    //             username : user.username,
    //             idx      : user.idx,
    //         }
    //     );

    // } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
    //     response = postFakeProfile(user);
    // }

    response   = postFakeProfile(user);
    const data = await response;

    if (data) {
      dispatch(profileSuccess(data));
    }
  } catch (error) {
    dispatch(profileError(error));
  }
};

export const resetProfileFlag = () => {
  try {
    const response = resetProfileFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
