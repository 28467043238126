import { getAnnouncement } from "./thunk";
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const initialState = {
  datas: [],
  bannerData: null,
  landingPageData: null,
  isLoading: true,
};

const announcement = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    setAunnouncement: (state, action) => {
      state.datas = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAnnouncement.fulfilled, (state, { payload }) => {
      if (!payload) {
        Cookies.set("landingpage", true);
        Cookies.set("banner", true);
        state.isLoading = false;
        return;
      }

      state.datas = payload;
      let checkLandingPage = payload.filter(
        (item) => item.information_type == "landing_page"
      ).length;

      if (checkLandingPage) {
        state.landingPageData = payload.find(
          (item) => item.information_type == "landing_page"
        );
        Cookies.set("landingpage", false);
      }

      let checkBanner = payload.filter(
        (item) => item.information_type == "banner"
      ).length;

      if (checkBanner) {
        state.bannerData = payload.find(
          (item) => item.information_type == "banner"
        );
        Cookies.set("banner", false);
      }
      state.isLoading = false;
    });
  },
});

export const { setAunnouncement } = announcement.actions;

export default announcement.reducer;
