import React, { useState } from "react";
import { Card, Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";

const SidebarNotification = ({ title }) => {
	const isActive = "bg-primary text-white py-2 px-3 rounded";

	return (
		<div className="px-3 py-4">
			<Link to={process.env.PUBLIC_URL + "/notifications"}>
				<div
					className={`d-flex cursor-pointer mb-3 text-dark ${
						title === "semua notifikasi" ? `${isActive}` : ``
					}`}
				>
					<span className="bx bxs-bell me-2 fs-4"></span>
					<p className="mb-0 fw-semibold fs-13 align-self-center">
						Semua Notifikasi
					</p>
				</div>
			</Link>
			<Link to={process.env.PUBLIC_URL + "/notifications/siacc"}>
				<div
					className={`d-flex cursor-pointer mb-3 text-dark ${
						title === "notifications siacc" ? `${isActive}` : ``
					}`}
				>
					<span className="bx bxs-notification me-2 fs-4"></span>
					<p className="mb-0 fw-semibold fs-13 align-self-center">
						SIACC
					</p>
				</div>
			</Link>
			<Link to={process.env.PUBLIC_URL + "/notifications/dashboard"}>
				<div
					className={`d-flex cursor-pointer mb-3 text-dark ${
						title === "notifications dashboard" ? `${isActive}` : ``
					}`}
				>
					<span className="bx bxs-notification me-2 fs-4"></span>
					<p className="mb-0 fw-semibold fs-13 align-self-center">
						Dashboard Executive
					</p>
				</div>
			</Link>
		</div>
	);
};

export default SidebarNotification;
