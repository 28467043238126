import ExportTableGIS from "../export-table/gis";
import ExportTableMultipleData from "../export-table/multiple-data";
import ExportTableNews from "../export-table/news";
import ExportTableRanking from "../export-table/ranking";
import ExportTableSingleData from "../export-table/single-data";
import ExportTableData from "../export-table/table";

const ExportToExcel = ({ data, child }) => {
  return (
    <table id="table-to-xls" style={{ display: "none" }}>
      <DataTypeCondition
        data_type={child?.source?.split("/")[2]}
        source={child.source}
        data={data}
      />
    </table>
  );
};

export default ExportToExcel;

const DataTypeCondition = ({data_type, source, data}) => {
    switch (data_type) {
        case "single-data":
          return (
            <ExportTableSingleData
              source={source}
              data={data}
              name={widget.name}
              type={"excel"}
            />
          );
        case "multiple-data":
          return (
            <ExportTableMultipleData
              source={source}
              data={data}
              name={widget.name}
              type={"excel"}
            />
          );
        case "table":
          return (
            <ExportTableData
              source={source}
              data={data}
              name={widget.name}
              type={"excel"}
            />
          );
        case "ranking":
          return (
            <ExportTableRanking
              source={source}
              data={data}
              name={"Pengguna"}
              type={"excel"}
            />
          );
        case "news":
          return (
            <ExportTableNews
            //   source={source}
              data={data}
              name={"Berita"}
              type={"excel"}
            />
          );
        case "gis":
          return (
            <ExportTableGIS
              source={source}
              data={data}
              name={"Berita"}
              type={"excel"}
            />
          );
    }
}
