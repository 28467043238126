import { useEffect, useState } from "react";
import "./Splash.css";
import LogoSiacc from "../../assets/images/logo/logo-siacc.svg";
import { isAdmin } from "../../helpers/user_info";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncement } from "../../slices/announcement/thunk";

const Splash = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const announcement = useSelector((state) => state.Announcement);

  const getData = () => {
    if (!announcement.isLoading) {
      if (announcement.landingPageData) {
        navigate("/landing-page");
      } else {
        navigate("/");
      }
    }
  };

  useEffect(() => {
    dispatch(getAnnouncement());
    getData();
  }, [announcement.isLoading]);

  return (
    <div className="splash_wrapper">
      <div className="centered_content">
        <img src={LogoSiacc} alt="Logo Siacc" />
        <div className="d-flex mt-3">
          <p style={{ color: "#016B3B" }}>RESTRICTED ACCESS</p>
          <div className="mx-2">|</div>
          <p style={{ color: "#e1bd03" }}>
            PROPERTY OF THE JAM INTEL KEJAKSAAN AGUNG R.I.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Splash;
