import { useEffect, useState } from "react";
import { getWidget } from "../../../../helpers/widget_helper";
import classNames from "classnames";

const ExportTableRanking = ({ source, name, data, type }) => {
  const [datas, setDatas] = useState(data ?? null);
  useEffect(() => {
    if (!data) {
      getWidget({ source }).then((res) => {
        setDatas(res);
      });
    }
  }, []);
  return (
    <div
      className={classNames({
        "d-flex justify-content-center": !data,
      })}
    >
    {datas?.length > 0 && (
      <table
        className={classNames({
          "table table-hover table-striped table-bordered w-75 mb-4": !data,
          "table-pdf-news-multiple-ranking": data,
        })}
      >
        <thead>
          {type == "excel" && (
            <tr>
              <td className="fw-bold text-center" colSpan={5}>
                &nbsp;
              </td>
            </tr>
          )}
          <tr>
            <th className="fw-bold text-center" colSpan={5}>
              {name}
            </th>
          </tr>
          <tr>
            <th className="fw-bold text-center">No</th>
            <th className="fw-bold text-center">Nama</th>
            <th className="fw-bold text-center" colSpan={2}>
              Data
            </th>
          </tr>
        </thead>

        <tbody>
          {datas?.map((data, i) => (
            <>
              <tr>
                <td rowSpan={3}>{i + 1}</td>
                <td rowSpan={3}>
                  {data.name} <br /> <small>{data.workunit}</small>
                </td>
                <th>Berita</th>
                <td>{data.badge_value1}</td>
              </tr>
              <tr>
                <td>Viewer</td>
                <td>{data.badge_value2}</td>
              </tr>
              <tr>
                <td>Trofi</td>
                <td>{data.badge_value3}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    )}
    </div>
  );
};

export default ExportTableRanking;
