import set_profile, { img_error } from "../../../helpers/default_profile";
import avatar2 from "../../../assets/images/default-user.png"

const ContactSelected = ({ listContact, removeContact, toggle, showAdd }) => {
    return (
        <ul style={{
            listStyle: "none",
            border: "2px solid #d1d1d1",
            borderRadius: "9px",
            minHeight: "50px",
            padding: "10px", textAlign: "center"
        }}>
            {listContact && (
                listContact.map((contact, idx) => {
                    if (contact.selected) {
                        return (
                            <li style={{
                                display: "inline-block",
                                margin: "2px", padding: "5px", backgroundColor: "#176138", fontSize: "15px", color: "white", borderRadius: "5px"
                            }}>
                                <img
                                    src={contact.photo ? set_profile(contact.photo) : avatar2}
                                    onError={(e)=> img_error(e)}
                                    className="me-1 rounded-circle avatar-xs p-1"
                                    alt="user-pic"
                                />
                                {contact.name}
                                <span role="button" style={{ margin: "10px" }} onClick={() => removeContact(contact.uuid)}><i className="mdi mdi-close"></i></span>
                            </li>
                        )
                    }
                })
            )}
            {showAdd && (
                <li onClick={() => toggle()} style={{
                    display: "inline-block",
                    margin: "2px", padding: "5px", backgroundColor: "#176138", fontSize: "15px", color: "white", borderRadius: "5px"
                }} role="button">
                    <span style={{ margin: "10px" }} ><i className="mdi mdi-plus"></i></span>
                </li>

            )}
        </ul>
    )
}

export default ContactSelected