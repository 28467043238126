import { Fragment } from "react"
import { Badge, Button, Input, Label } from "reactstrap"
import SimpleBar from "simplebar-react"
import ContactSelected from "./ContactSelected"
import avatar2 from "../../../assets/images/default-user.png"
import SearchInput from "../search-input"
import { useDispatch } from "react-redux"
import { getEmployeBiodata } from "../../../slices/thunks"
import set_profile, { img_error } from "../../../helpers/default_profile";

const ChooseContact = ({ toggleChecked, toggleContact, employeData, listContact, toggle, removeContact, addContact }) => {
  
    const dispatch = useDispatch()


    const search = (text) => {
        dispatch(
          getEmployeBiodata({
            keyword: text,
          })
        );
      };
    return (
        <Fragment>
          <SearchInput submit={search} />

            <SimpleBar style={{ height: '300px' }} className={`pe-2 mt-3`}>
                {employeData && (
                    employeData.map((contact, idx) => (
                        <div
                            key={idx}
                            className={`text-reset notification-item d-block dropdown-item position-relative mt-2`}
                        //  onClick={() => handleClick(items.id)}
                        >
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={contact.photo ? set_profile(contact.photo) : avatar2}
                                        onError={(e)=> img_error(e)}
                                        className="me-3 rounded-circle avatar-xs"
                                        alt="user-pic"
                                    />
                                    <div className="flex-1">
                                        <h6 className={`fw-semibold mt-0 mb-1 fs-13`}>
                                            {contact.name}
                                        </h6>
                                        <div className={`fw-semibold fs-13 text-muted`}>
                                            <p className="mb-1">{contact.position}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column align-items-end">
                                    <div className="form-check form-check-success mb-3">
                                        <Input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={() =>
                                                toggleContact(contact.uuid)
                                            }
                                            checked={toggleChecked(contact.uuid)}
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </SimpleBar>
            <ContactSelected listContact={listContact} showAdd={false} removeContact={removeContact} />
            <button className="btn btn-primary mt-3" onClick={() => toggle()} style={{
                width: "100%",
                padding: "15px"
            }}>
                Pilih Kontak
            </button>
        </Fragment>
    )
}

export default ChooseContact