import React, {
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
  useState,
} from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import user_info from "../../helpers/user_info";
import DateTimeFormat from "../../helpers/time_parser";
import avatar2 from "../../assets/images/default-user.png";
import { SocketContext } from "../../context/SocketContext";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import set_profile, { img_error } from "../../helpers/default_profile";
import { Link } from "react-router-dom";

const ChatBubble = (props) => {
  const { revokeMe, revokeAll } = useContext(SocketContext);
  const { item, isLast, showAvatar } = props;
  const isLeft = item.sender_id !== user_info().uuid;
  const isRead = item.is_read.filter((element) => {
    if (element.status == false) {
      return true;
    }

    return false;
  }).length;

  const bubbleRef = useRef(null);

  useLayoutEffect(() => {
    if (props.messageBox && isLast) {
      const messageBox = props.messageBox;
      const distanceToBottom =
        messageBox.scrollHeight -
        (messageBox.scrollTop + messageBox.clientHeight);

      if (distanceToBottom < 10) {
        messageBox.scrollTop =
          bubbleRef.current.offsetTop - messageBox.offsetTop;
      }
    }
  }, [props.messageBox, item, isLast]);

  return isLeft ? (
    <LeftChat item={item} bubbleRef={bubbleRef}>
      <div className="chat-avatar align-self-start">
        {showAvatar && (
          <img
            src={set_profile(item.sender.avatar)}
            alt="profile_pict"
            onError={(e) => img_error(e)}
          />
        )}
      </div>

      <div className="user-chat-content">
        <div
          className="mb-0 ctext-wrap d-flex flex-column shadow"
          style={{
            borderRadius: "10px",
            background: "#f3f3f3",
            padding: "10px",
            marginLeft: !showAvatar ? "27px" : "0",
          }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <h6 className="text-primary">{item.sender.name}</h6>
              <MessageAttachment item={item} />
            </div>

            <BubbleOption
              revokeMe={revokeMe}
              revokeAll={revokeAll}
              item={item}
              setReplyContent={props.setReplyContent}
            />
          </div>
          <div className="conversation-name">
            <span className="text-muted time" style={{ fontSize: "0.6rem" }}>
              {DateTimeFormat.dateTime(item.created_at)}
            </span>{" "}
          </div>
        </div>
      </div>
    </LeftChat>
  ) : (
    <RightChat bubbleRef={bubbleRef}>
      <div className="chat-avatar align-self-start">
        {showAvatar && (
          <img
            src={
              set_profile(
                item.sender.uuid === user_info().uuid && item.sender.avatar
              ) || ""
            }
            alt="profile_pic"
            onError={(e) => img_error(e)}
          />
        )}
      </div>

      <div className="user-chat-content">
        <div
          className="mb-0 ctext-wrap d-flex flex-column shadow"
          style={{
            borderRadius: "10px",
            background:
              "linear-gradient(to top, rgb(40 127 88) 0%, #3cba92 100%)",
            color: "white",
            padding: "10px",
            marginRight: !showAvatar ? "27px" : "0",
          }}
        >
          <div className="d-flex justify-content-between text-white">
            <BubbleOption
              right
              revokeMe={revokeMe}
              item={item}
              revokeAll={revokeAll}
              setReplyContent={props.setReplyContent}
            />
            <div style={{ color: "white" }}>
              <h6 style={{ color: "#68ff68 " }}>{item.sender.name}</h6>
              <MessageAttachment item={item} />
            </div>
          </div>
          <div className="conversation-name">
            <span className="time text-white" style={{ fontSize: "0.6rem" }}>
              {DateTimeFormat.dateTime(item.created_at)}
            </span>{" "}
            {isRead > 0 ? (
              <span className="text-success check-message-icon">
                <i className="ri-check-line align-bottom"></i>
              </span>
            ) : (
              <span className="text-success check-message-icon">
                <i className="ri-check-double-line align-bottom"></i>
              </span>
            )}
          </div>
        </div>
      </div>
    </RightChat>
  );
};

export default ChatBubble;

const BubbleOption = (props) => {
  const balas = (item) => {
    props.setReplyContent(item);
  };
  return (
    <UncontrolledDropdown
      className="align-self-start message-box-drop"
      style={{ order: 0 }}
    >
      <DropdownToggle href="#" className="btn nav-btn" tag="a">
        <i
          className="ri-more-2-fill "
          style={props.right && { color: "white" }}
        ></i>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          href="#"
          className="reply-message"
          onClick={() => {
            balas(props.item);
          }}
        >
          <i className="ri-reply-line me-2  align-bottom"></i>
          Balas Percakapan
        </DropdownItem>
        {/* <DropdownItem href="#">
					<i className="ri-share-line me-2 text-muted align-bottom"></i>
					Teruskan Percakapan
				</DropdownItem> */}
        <DropdownItem
          href="#"
          onClick={() => {
            props.revokeMe(props.item.id);
          }}
        >
          <i className=" ri-close-line me-2 text-muted align-bottom"></i>
          Hapus Percakapan Untuk Saya
        </DropdownItem>
        <DropdownItem
          href="#"
          onClick={() => {
            props.revokeAll(props.item.id);
          }}
        >
          <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>
          Hapus Percakapan Semua
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

function LeftChat(props) {
  return (
    <li className="chat-list left" ref={props.bubbleRef}>
      <div className="conversation-list">{props.children}</div>
    </li>
  );
}

function RightChat(props) {
  return (
    <li className="chat-list right" ref={props.bubbleRef}>
      <div className="conversation-list">{props.children}</div>
    </li>
  );
}

function MessageAttachment({ item }) {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  function attachment(item) {
    if (item.attachment !== null) {
      switch (item.attachment[0].type || item.attachment !== undefined) {
        case "Image":
          return (
            <>
              <div
                onClick={() => openLightbox(0)}
                style={{ width: "15vw", height: "20vh", overflow: "hidden" }}
                role="button"
              >
                <img
                  width="100%"
                  src={item.attachment[0].content}
                  alt="Attachment"
                />
              </div>
              {lightboxOpen && (
                <Lightbox
                  reactModalStyle={{ overlay: { zIndex: 99999 } }}
                  mainSrc={item.attachment[0].content}
                  onCloseRequest={closeLightbox}
                />
              )}
            </>
          );
        case "Video":
          return (
            <div style={{ width: "15vw" }}>
              <video width="100%" controls>
                <source src={item.attachment[0].content} type="video/mp4" />
                <source src={item.attachment[0].content} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </div>
          );
        case "Document":
          return (
            <a
              className="mb-0 ctext-content"
              href={item.attachment[0].content}
              download
              style={{ color: "inherit" }}
            >
              <i className=" ri-file-line"></i> {item.attachment[0].name}{" "}
              <i className=" ri-download-2-line"></i>
            </a>
          );
        case "Other":
          if (item.attachment[0].extension === "webm") {
            return (
              <audio controls style={{ width: "210px", height: "35px" }}>
                <source src={item.attachment[0].content} type="audio/ogg" />
                Your browser does not support the audio tag.
              </audio>
            );
          }
          return (
            <a
              className="mb-0 ctext-content"
              href={item.attachment[0].content}
              download
            >
              <i className=" ri-file-line"></i> {item.attachment[0].name}{" "}
              <i className=" ri-download-2-line"></i>
            </a>
          );
      }
    }
    return <p className="mb-0 ctext-content">{item.content}</p>;
  }

  if (item.content_type === "dashboard") {
    return (
      <Link
        to={window.location.protocol + "//" + item.content}
        target="_blank"
        className="mb-0 ctext-content"
      >
        Dashboard
      </Link>
    );
  }

  if (item.parent !== null) {
    return (
      <div>
        <div
          style={{
            background: "rgb(98, 98, 98, 0.5)",
            width: "100%",
            padding: "8px",
          }}
          className="shadow rounded-2 mb-2"
        >
          <h6 style={{ color: "#68ff68 " }}>{item.parent.sender.name}</h6>
          {attachment(item.parent)}
        </div>
        <p className="mb-0 ctext-content fw-20">{item.content}</p>
      </div>
    );
  }
  return attachment(item);
}
