import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import * as XLSX from "xlsx";
import Widget from "../Widget";
import { useState } from "react";
import { APIClient } from "../../../helpers/api_helper";
import { API_BASEURL } from "../../../helpers/url_helper";
import { widgetDetailParams } from "../../../helpers/widget_detail_helper";
import ExportToExcel from "./ExportToExcel";

const ModalDetail = ({ openModalDetail, toggleOpenModalDetail, child }) => {
  let api = new APIClient();
  // states
  const [resWidget, setResWidget] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [allData, setAllData] = useState([]);

  let widget = {
    source: [child?.source + "?period=" + child?.period],
    endpoint_id: child?.endpoint_root_id,
    type: child?.type,
  };

  const getData = async (query) => {
    var paramsBody = await widgetDetailParams({
      type: child?.childType,
      endpointId: child?.endpoint_root_id,
      paramChild: child?.param_child,
      condition: child?.condition
    });
    return await api.create(API_BASEURL + widget.source[0] + query, paramsBody);
  };

  const onOpenDetail = async () => {
    setIsLoading(true);
    var paramsBody = await widgetDetailParams({
      type: child?.childType,
      endpointId: child?.endpoint_root_id,
      paramChild: child?.param_child,
      condition: child?.condition
    });
    const res = await api.create(API_BASEURL + widget.source[0], paramsBody);
    setResWidget(res.data);
    const all = await getData("&list=true");
    setAllData(all.data);
    setPagination(res?.pagination);
    setIsLoading(false);
  };

  const goToPage = async (page) => {
    setIsLoading(true);
    const res = await getData("&page=" + page);
    setResWidget(res.data);
    setPagination(res?.pagination);
    setIsLoading(false);
  };

  const onClose = () => {
    setResWidget({})
    setPagination(null)
  }


  const getExcel = async () => {
    const toExcel = XLSX.utils.table_to_book(
      document.getElementById("table-to-xls")
    );
    XLSX.writeFile(toExcel, "Detail" + ".xlsx", {
      bookType: "xlsx",
      type: "buffer",
    });
    return false;
  };

  const GenerateArrayPaginate = (current_page,last_page, onSides = 3) => {
    // pages
    let pages = [];
    // Loop through
    for (let i = 1; i <= last_page; i++) {
        // Define offset
        let offset = (i == 1 || last_page) ? onSides + 1 : onSides;
        // If added
        if (i == 1 || (current_page - offset <= i && current_page + offset >= i) || 
            i == current_page || i == last_page) {
            pages.push(i);
        } else if (i == current_page - (offset + 1) || i == current_page + (offset + 1)) {
            pages.push('...');
        }
    }
    return pages;
  }


  return (
    <Modal
      isOpen={openModalDetail}
      toggle={toggleOpenModalDetail}
      centered
      size="xl"
      onOpened={onOpenDetail}
      onClosed={() => onClose()}
    >
      <ModalHeader toggle={toggleOpenModalDetail}>Detail</ModalHeader>
      <ModalBody className="d-flex flex-column align-items-end">
        {resWidget.length !== 0 && !isLoading && (
          <>
            <Button color="primary" size="sm" onClick={getExcel} className="mb-2">
              {" "}
              <i className="ri-external-link-line" /> Export Excel
            </Button>
            <ExportToExcel data={allData} child={child} />
          </>
        )}

        <div
          style={{ width: "100%" }}
          className="d-flex justify-content-center"
        >
          {isLoading ? (
            <Spinner size="lg" />
          ) : (
            <>
              <Widget
                widget={{ type: child?.type, period: child?.period, datas: resWidget, pagination }}
              />
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center flex-column">
        {pagination && resWidget.length !== 0 && (
          <Pagination className="d-flex justify-content-center" size="lg">
            <PaginationItem disabled={pagination?.page === 1}>
              <PaginationLink first onClick={() => goToPage(1)} />
            </PaginationItem>

            <PaginationItem disabled={!pagination?.has_prev}>
              <PaginationLink
                onClick={() => goToPage(pagination?.page - 1)}
                previous
              />
            </PaginationItem>
            
            {pagination?.total_page <= 10 ?
            [...Array(pagination?.total_page)].map((page, key) => (
              <PaginationItem key={key} active={key + 1 === pagination?.page}>
                <PaginationLink onClick={() => goToPage(key + 1)}>
                  {key + 1}
                </PaginationLink>
              </PaginationItem>
            ))
          : 
          GenerateArrayPaginate(pagination?.page,pagination?.total_page).map((page, key) => (
            <PaginationItem key={page} active={page === pagination?.page}>
              <PaginationLink onClick={() => (page != "...") && goToPage(page)}>
                {page}
              </PaginationLink>
            </PaginationItem>
          ))
          }

            <PaginationItem disabled={!pagination?.has_next}>
              <PaginationLink
                onClick={() => goToPage(pagination?.page + 1)}
                next
              />
            </PaginationItem>
            <PaginationItem
              disabled={pagination?.total_page === pagination?.page}
            >
              <PaginationLink
                onClick={() => goToPage(pagination?.total_page)}
                last
              />
            </PaginationItem>
          </Pagination>
        )}
      </ModalFooter>
    </Modal>
  );
};



export default ModalDetail;
