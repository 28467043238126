import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import user_info from "../../helpers/user_info";
import set_profile, { img_error } from "../../helpers/default_profile";

const SidebarProfile = ({ title }) => {
	const profile = useSelector(state => state.Profile);

	const isActive = "bg-primary text-white py-2 px-3 rounded";

	return (
		<>
			<div className="d-flex mb-4">
				<img
					src={set_profile(profile?.data?.photo || "")}
					onError={e => img_error(e)}
					alt="photo"
					className="rounded"
					width={80}
					style={{ objectFit: "cover" }}
				/>
				<div className="ms-3">
					<p className="fs-13 fw-bold">{profile.data.name}</p>
					<p className="mb-0 text-muted fs-12 fw-semibold">
						{profile.data.workunit_level}
					</p>
					<p className="mb-0 text-muted fs-12">{profile.data.position}</p>
				</div>
			</div>
			<Card style={{ height:"66vh", overflow:"auto" }}>
				<div className="px-3 py-4">
					<Link to={process.env.PUBLIC_URL + "/profile"}>
						<div
							className={`d-flex cursor-pointer mb-2 text-dark ${
								title === "profile" ? `${isActive}` : ``
							}`}
						>
							<span className="mdi mdi-account-circle-outline me-2 fs-4"></span>
							<p className="mb-0 fw-semibold fs-13 align-self-center">
								Akun Saya
							</p>
						</div>
					</Link>
					<Link to={process.env.PUBLIC_URL + "/ubahpassword"}>
						<div
							className={`d-flex cursor-pointer mb-2 text-dark ${
								title === "ubah password" ? `${isActive}` : ``
							}`}
						>
							<span className="mdi mdi-lock-outline me-2 fs-4"></span>
							<p className="mb-0 fw-semibold fs-13 align-self-center">
								Ubah Password
							</p>
						</div>
					</Link>
					{user_info().user_group[0].title !== "admin" ? (
						<>
							<Link to={process.env.PUBLIC_URL + "/notifikasi"}>
								<div
									className={`d-flex cursor-pointer mb-2 text-dark ${
										title === "notifikasi" ? `${isActive}` : ``
									}`}
								>
									<span className="mdi mdi-bell-outline me-2 fs-4"></span>
									<p className="mb-0 fw-semibold fs-13 align-self-center">
										Notifikasi
									</p>
								</div>
							</Link>
						</>
					) : null}
					<Link to={process.env.PUBLIC_URL + "/logupdate"}>
						<div
							className={`d-flex cursor-pointer mb-2 text-dark ${
								title === "logupdate" ? `${isActive}` : ``
							}`}
						>
							<span className="mdi mdi-history me-2 fs-4"></span>
							<p className="mb-0 fw-semibold fs-13 align-self-center">
								Log Update Dashboard
							</p>
						</div>
					</Link>
				</div>
			</Card>
		</>
	);
};

export default SidebarProfile;
