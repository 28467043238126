import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import user_info from "../../helpers/user_info";
import { getNotifSiacc } from "../../slices/thunks";
import { Card, Col, Row, Container } from "reactstrap";
import SidebarNotification from "./SidebarNotification";
import SimpleBar from "simplebar-react";

const SiaccNotification = () => {
	const dispatch = useDispatch();
	const notifSiacc = useSelector(state => state.NotificationSiacc);
	const { data, pagination } = notifSiacc;

	useEffect(() => {
		if (!data || data.length === 0) {
			dispatch(getNotifSiacc(user_info().uuid));
		}
	}, [dispatch]);

	const handleScroll = () => {
		if (
			window.innerHeight + document.documentElement.scrollTop ===
			document.documentElement.offsetHeight
		) {
			if (pagination && pagination.has_next) {
				const nextPage = pagination.current_page + 1;
				dispatch(getNotifSiacc(user_info().uuid, nextPage));
			}
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [pagination]);

	const formatIndonesianDateTime = timestamp => {
		const date = new Date(timestamp);
		const day = date.getDate();
		const month = new Intl.DateTimeFormat("id-ID", { month: "long" }).format(
			date
		);
		const year = date.getFullYear();
		const hours = date.getHours();
		const minutes = date.getMinutes();
		const seconds = date.getSeconds();
		const amOrPm = hours >= 12 ? "PM" : "AM";
		const formattedTime = `${hours % 12 || 12}.${minutes
			.toString()
			.padStart(2, "0")}.${seconds.toString().padStart(2, "0")} ${amOrPm}`;
		return `${day} ${month} ${year}, ${formattedTime}`;
	};

	return (
		<div className="page-content pb-0">
			<Container fluid>
				<Row>
					<Col md="3">
						<Card style={{ height: "82vh", overflow: "auto" }}>
							<SidebarNotification title="notifications siacc" />
						</Card>
					</Col>
					<Col md="9">
						<Card style={{ height: "82vh" }}>
							<SimpleBar style={{ maxHeight: "82vh" }}>
								<div className="p-4">
									<p className="fs-18 fw-semibold mb-0">Notifikasi SIACC</p>
									<p className="text-muted fs-14 mb-4">
										Menampilkan pemberitahuan Notifikasi Siacc
									</p>
									{data &&
										data.map(item => (
											<Row key={item.id}>
												<Col>
													<div className="d-flex">
														<p className="text-muted fs-12 mb-0">
															{item.notification.title}
														</p>
														<p className="text-muted fs-12 mb-0 ms-auto">
															{formatIndonesianDateTime(item.created_at)}
														</p>
													</div>
													<p className="fs-14">{item.notification.body}</p>
													<hr />
												</Col>
											</Row>
										))}
								</div>
							</SimpleBar>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default SiaccNotification;
